import { url } from "inspector";
import Header from "../components/template/Header";
import SlideBar from "../components/template/SlideBar";
import InvestSteps from "../components/InvestSteps";
import Subscribe from "../components/Subscribe";
import NeedHelp from "../components/NeedHelp";
import Footer from "../components/template/Footer";
import { Link, useNavigate } from "react-router-dom";
import { bondTypes, OrderStatus, PageLinks } from "../common/Constants";
import { Fragment, useEffect, useState } from "react";
import OrderStepsComponent from "../components/OrderStepsComponent";
import { APIData } from "../common/DataTypes";
import { ServerAPI } from "../common/ServerAPI";
import moment from "moment";
import { numberWithCommas } from "../common/Utilities";
import { Tab, TabList, TabPanel, Tabs } from "react-tabs";
import ReactPaginate from "react-paginate";
import SortBy from "../components/template/SortBy";
import SortByOrder from "../components/template/SortByOrder";
import { InitialData } from "../components/Form/InitialData";

const OrderListing: React.FC = () => {
    const [orderOpenIndex, setorderOpenIndex] = useState('');
    const [ordersList, setOrdersList] = useState<APIData.order[]>([]);
    const navigate = useNavigate();
    const [pageno, setPageno] = useState(1);
    const [totalCount, setTotalCount] = useState(0);
    const [bondType, setBondType] = useState(0);
    const [selectedFilters, setSelectedFilters] = useState<APIData.OrderFilters>({ ...InitialData.OrderFilterData });

    const orderslist = (pageno: number,selectedFilters:any) => {
        ServerAPI.GetOrders(pageno,selectedFilters).then((response: any) => {
            if (response != undefined) {
                setOrdersList(response['data']);
                setTotalCount(response['count']);
            }
        })
    }


    const [openDropDown, setOpenDropDown] = useState(true);
    const toggleDropdown = () => setOpenDropDown(!openDropDown);

    useEffect(() => {
        console.log(pageno);
        console.log(selectedFilters);
        orderslist(pageno,selectedFilters);
    }, [pageno,selectedFilters])

    return (
        <div className="main-index-wrapper">
            <Header />
            <SlideBar />
            <div className="body-overlay"></div>
            <main>
                <section className="page-title-area listing-search fix">
                    <div className="container-fluid">
                        <div className="row align-items-center">
                            <div className="col-lg-12">
                                <div className="widget-search-content faq-que-search">
                                    <form className="subscribe-form">
                                        <input type="text" placeholder="Bond name, issuer or bond type" />
                                        <button className="search-icon" onClick={(e: any) => {
                                            e.preventDefault();
                                        }}>Search</button>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="order_listing pt-30 pb-50 pt-md-45 pb-md-40 pt-xs-45 pb-xs-40">
                    <div className="container-fluid">
                        <div className="col-lg-12">
                            <div className="row align-items-left">
                                <div className="col-lg-12 breadcums">
                                    <nav>
                                        <ul>
                                            <li className="cursor" onClick={(e: any) => {
                                                e.preventDefault();
                                                navigate(PageLinks.PROFILE);
                                            }}>Home</li>
                                            <li>My Account</li>
                                            <li className="active">OrderBook</li>
                                        </ul>
                                    </nav>
                                </div>
                            </div>
                            <Tabs>
                                <TabList>
                                    <Tab onClick={(e: any) => {
                                        e.preventDefault();
                                    }}>On going Negotiations</Tab>
                                    <Tab onClick={(e: any) => {
                                        e.preventDefault();
                                    }}>My Orders</Tab>
                                    <Tab onClick={(e: any) => {
                                        e.preventDefault();
                                    }}>Sell Bonds</Tab>
                                    <div className="text-end">
                                        <SortByOrder sortFilter={setSelectedFilters} />
                                    </div>
                                </TabList>
                                <TabPanel>
                                    <Fragment>
                                        <div className="text-center mt-5">
                                            <img src="assets/img/no_orders.png" />
                                            <p className="source sourceSansPro-bold no-result-main">No Negotiations Found</p>
                                            <p className="source sourceSansPro-regular no-result-sub">Tap the button to explore Bonds.</p>
                                        </div>
                                        <div className="text-center mt-3">
                                            <Link to={PageLinks.PROFILE_LISTING} className="apply-gold-btn  text-white">Explore All Bonds</Link>
                                        </div>
                                    </Fragment>
                                </TabPanel>
                                <TabPanel className="mt-3">
                                    {
                                        ordersList != undefined && ordersList.length > 0 ?
                                            ordersList.map((order: APIData.order, index: number) => {
                                                return (
                                                    <div className="row align-items-center" key={index}>
                                                        <div className="col-lg-12 col-md-6 " data-wow-delay="0.1s">
                                                            <div className="order mb-30">
                                                                <div className="row">
                                                                    <div className="col-lg-9" style={{ padding: "8px 30px" }}>
                                                                        <div className="d-flex">
                                                                            <div className="col-lg-2 order_list_img">
                                                                                <div className="order-icon-img">
                                                                                    <img className="icon-img" src={order.bond_logo != undefined ? order.bond_logo : "assets/img/img_placeholder.png"} alt="" />
                                                                                </div>
                                                                            </div>
                                                                            <div className="col-lg-10 align-self-center ml-20">
                                                                                <div className="row d-flex align-items-center justify-content-center">
                                                                                    <div className="col-lg-6 p-0">
                                                                                        <div className="order-icon-head">
                                                                                            <h5>{order.bond_name != undefined ? order.bond_name : ""}</h5>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="col-lg-6 p-0">
                                                                                        <div className="d-flex align-items-center order_items">
                                                                                            <div className="col-lg-6 align-self-center">
                                                                                                <p>Order No</p>
                                                                                                <h6>{order.order_id}</h6>
                                                                                            </div>
                                                                                            <div className="col-lg-6 align-self-center">
                                                                                                <p>Order Date</p>
                                                                                                <h6>{moment(order.order_datetime).format("MMMM Do YYYY")}</h6>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                {
                                                                                    orderOpenIndex != '' && orderOpenIndex == index + "" ?
                                                                                        <div className="row d-flex pt-10 pb-10">
                                                                                            <div className="row dropdown-sub-content p-10 align-items-center justify-content-center">
                                                                                                <div className="col-lg-3">
                                                                                                    <p>Amount Invested</p>
                                                                                                    <h5>₹ {numberWithCommas(order.order_amount)}</h5>
                                                                                                </div>
                                                                                                <div className="col-lg-3">
                                                                                                    {order.bond_type != undefined && order.bond_type == bondTypes.SGB ?
                                                                                                        <Fragment>
                                                                                                            <p>Gold Quantity</p>
                                                                                                            <h5>{order.order_quantity}Gram</h5>
                                                                                                        </Fragment>
                                                                                                        :
                                                                                                        <Fragment>
                                                                                                            <p>Quantity</p>
                                                                                                            <h5>{order.order_quantity}</h5>
                                                                                                        </Fragment>
                                                                                                    }
                                                                                                </div>
                                                                                                <div className="col-lg-3">
                                                                                                    <p>Payment status</p>
                                                                                                    <h5>Successful</h5>
                                                                                                </div>
                                                                                            </div>
                                                                                            <OrderStepsComponent step={2} />
                                                                                        </div>
                                                                                        : ""
                                                                                }
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-lg-3 d-flex">
                                                                        <button className="order_process_btn">{order.order_status == OrderStatus.SUCCESS ? "Success" : "Processing"}</button>
                                                                        <div style={{ float: "right" }}>
                                                                            <div className="order_dropdown_box align-items-center justify-content-center" onClick={() => {
                                                                                if(orderOpenIndex==index+""){
                                                                                    setorderOpenIndex('');
                                                                                }else{
                                                                                    setorderOpenIndex(index+"");
                                                                                }
                                                                                 }}>
                                                                                <img src="assets/img/drop_down_arrow.svg"></img>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )
                                            })
                                            :
                                            <Fragment>
                                                <div className="text-center mt-5">
                                                    <img src="assets/img/no_orders.png" />
                                                    <p className="source sourceSansPro-bold no-result-main">No Orders Found</p>
                                                    <p className="source sourceSansPro-regular no-result-sub">Tap the button to explore Bonds.</p>
                                                </div>
                                                <div className="text-center mt-3">
                                                    <Link to={PageLinks.PROFILE_LISTING} className="apply-gold-btn  text-white">Explore All Bonds</Link>
                                                </div>
                                            </Fragment>
                                    }
                                    < ReactPaginate
                                        onPageChange={(e: any) => {
                                            setPageno(e.selected + 1);
                                        }}
                                        breakLabel="..."
                                        nextLabel=">"
                                        pageRangeDisplayed={20}
                                        pageCount={totalCount / 20}
                                        previousLabel="<"
                                        containerClassName="pagination"
                                        breakClassName="page-item"
                                        breakLinkClassName="page-link"
                                        activeClassName="active"
                                        pageClassName="page-item"
                                        pageLinkClassName="page-link"
                                        previousClassName="page-item"
                                        previousLinkClassName="page-link"
                                        nextClassName="page-item"
                                        nextLinkClassName="page-link"
                                    />
                                </TabPanel>
                                <TabPanel>
                                    <Fragment>
                                        <div className="text-center mt-5">
                                            <img src="assets/img/no_orders.png" />
                                            <p className="source sourceSansPro-bold no-result-main">No Bonds Found</p>
                                            <p className="source sourceSansPro-regular no-result-sub">Tap the button to explore Bonds.</p>
                                        </div>
                                        <div className="text-center mt-3">
                                            <Link to={PageLinks.PROFILE_LISTING} className="apply-gold-btn  text-white">Explore All Bonds</Link>
                                        </div>
                                    </Fragment>
                                </TabPanel>
                            </Tabs>
                        </div>
                    </div>
                </section>
            </main>
            <Footer />
        </div>
    )
}

export default OrderListing;
