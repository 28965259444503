import React from 'react'
interface FormParameters{
    document:string
}
const DownloadIssueDocuments: React.FC<FormParameters> = ({document}) => {
    return (
        <section className="pt-30 pb-30 pb-md-20 pt-md-60 pt-xs-60 pb-xs-20">
            <div className="container-fluid">
                <div className="row  align-items-center detail-view-one-download-documents">
                    <div className="col-lg-1">
                        <img className="img" src="assets/img/Report.png" alt="" />
                    </div>
                    <div className="col-lg-8 mt-3">
                        <h4>DOWNLOAD ISSUE DOCUMENTS</h4>
                        <p>The document contains high-quality data which is fetched from SEBI and Exchange websites to help you make an informed decision.</p>
                    </div>
                    <div className="col-lg-3 cursor" onClick={(e:any)=>{
                        e.preventDefault();
                        window.open(document);
                    }}>
                        <button className="download-btn">Download Now <i className="fa fa-download" aria-hidden="true"></i></button>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default DownloadIssueDocuments