import { useEffect } from "react";
import { Link } from "react-router-dom";
import { useAppStateAPI } from "../common/AppStateAPI";
import { PageLinks } from "../common/Constants";

const PassportPreview: React.FC = () => {

    return (
        <div className="main-index-wrapper">
            <main>
            <section className="subscribe-letter-area pt-20 pb-20">
                    <div className="line-shape d-none d-lg-inline-block">
                        <img className="img-fluid" src="assets/img/Intersection_4.svg" alt="" />
                    </div>

                    <div className="container custom-container-subs">
                        <div className="sub-bg">
                            <div className="row justify-content-center">
                                <div className="col-xl-12">
                                    <div className="subscribe-wrapper">
                                        <div className="section-title section-title-3 text-center">
                                           <img className="header-logo" src="assets/img/logo/header_logo_one.png" alt="logo" />
                                        </div>
                                    </div>
                                    <div className="progress-indicator-element">
                                        <ul className="progress-indicator"> 
                                            <li className="completed">
                                                <span className="stacked-text">
                                                    <span className="subdued"><span className="small">Step 01</span> <br></br> Personal Details</span>
                                                </span>
                                                {/* <span className="bubble"></span> */}
                                            </li>
                                            <li className="completed msg_contain">
                                                <span className="stacked-text">
                                                    <span className="subdued"><span className="small">Step 02</span> <br></br> Bank Details</span>
                                                </span>
                                                {/* <span className="bubble"></span> */}
                                                <div className="bse-order-no">
                                                </div>
                                                <div className="bse-order-msg msg-success">
                                                </div>
                                            </li>
                                            <li className="completed">
                                                <span className="stacked-text">
                                                    <span className="subdued"><span className="small">Step 03</span> <br></br> Demat Details</span>
                                                </span>
                                                {/* <span className="bubble"></span> */}
                                            </li>
                                        </ul>
                                    <div className='line-container'>
                                        <div className='progress-line'>
                                            <div className='progress '></div>
                                            <div className='status'>
                                                <div className='dot completed'></div>
                                            </div>
                                            <div className='status'>
                                                <div className='dot current'></div>
                                            </div>
                                            <div className='status'>
                                                <div className='dot'></div>
                                            </div>
                                        </div>
                                    </div>
                                    </div>
                                    <div className="step_box_content">
                                        <div className="sub-bg">
                                            <div className="row justify-content-center">
                                                <div className="col-xl-12">
                                                    <div className="subscribe-wrapper">
                                                        <div className="section-title section-title-3">
                                                            <h6 className="mb--10 wow " data-wow-delay="0.1s">Personal Details <span className="step_count">STEPS - 1 of 2</span></h6>
                                                            <Link to={PageLinks.PROFILE_PERSONAL_DETAILS} className="theme_btn profile_close_btn sub-btn wow " data-wow-delay="0.5s"><i className="far fa-times-circle"></i> Close</Link>
                                                            <hr></hr>
                                                            <form className="quote-form mb-10" action="#">
                                                                <div className="col-xl-12 pl-0 float-left">
                                                                    <h6 className="pt-20 mb--10 " data-wow-delay="0.1s">Hey Jairaj, Please Verify, We Fetched This Information From PAN And KRA Records, As Provided By You.</h6>
                                                                    <div className="col-xl-4 pl-0 float-left mt-20">
                                                                        <div className="email-input input_field pl-0">
                                                                            <label className="input-title">Aadhaar Number</label>
                                                                            <input type="text" placeholder="Enter Aadhaar Number" value="1234-5678-9012" />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="col-xl-12 p-0 float-left">
                                                                    <div className="email-input input_field pl-0">
                                                                        <label className="input-title">Address (As per Aadhaar)</label>
                                                                        <textarea className="text-field">A 1XX4, XXXXX XXXXX XXX, XXXXX, XXXXX XXXXX, XXXXX - XXXXX, XXXXX - XXXX64, XXXXXXXXXX, XXXXX, XXXXX XXX, XXXXX, XXXXX XXXXX, XXXXX - XXXXX, XXXXX - XXXX64, XXXXXXXXXX, XXXXX.</textarea>
                                                                    </div>
                                                                </div>
                                                                <div className="col-xl-12 p-0 float-left">
                                                                    <div className="choice-list">
                                                                        <span className="input-title">What is your gender?</span>
                                                                        <span className="input-title-filled gender_col">
                                                                            <a href="javascript:void(0);" className="gender_btn selected"> Male <input type="checkbox" className="d-none" /></a>
                                                                            <a href="javascript:void(0);" className="gender_btn keypressed"> Female <input type="checkbox" className="d-none" /></a>
                                                                            <a href="javascript:void(0);" className="gender_btn"> Trans. <input type="checkbox" className="d-none" /></a>
                                                                        </span>
                                                                    </div>
                                                                    <div className="choice-list">
                                                                        <span className="input-title">What is your marital status?</span>
                                                                        <span className="input-title-filled gender_col">
                                                                            <a href="javascript:void(0);" className="gender_btn selected"> Single <input type="checkbox" className="d-none" /></a>
                                                                            <a href="javascript:void(0);" className="gender_btn"> Married <input type="checkbox" className="d-none" /></a>
                                                                            <a href="javascript:void(0);" className="gender_btn"> Others <input type="checkbox" className="d-none" /></a>
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                                <div className="col-xl-12 p-0 float-left">
                                                                    <div className="col-xl-4 pl-0 float-left mt-20">
                                                                        <div className="email-input input_field pl-0">
                                                                            <label className="input-title">What is your Occupaion</label>
                                                                            <select className="">
                                                                                <option>Select</option>
                                                                            </select>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="col-xl-12 p-0 float-left">
                                                                    <div className="choice-list">
                                                                        <span className="input-title">What is your annual income?</span>
                                                                        <span className="input-title-filled gender_col">
                                                                            <a href="javascript:void(0);" className="gender_btn"> Below 1 Lakh <input type="checkbox" className="d-none" /></a>
                                                                            <a href="javascript:void(0);" className="gender_btn"> 1 - 5 Lakhs <input type="checkbox" className="d-none" /></a>
                                                                            <a href="javascript:void(0);" className="gender_btn"> 5 - 10 Lakhs <input type="checkbox" className="d-none" /></a>
                                                                            <a href="javascript:void(0);" className="gender_btn"> 10 - 25 Lakhs <input type="checkbox" className="d-none" /></a>
                                                                            <a href="javascript:void(0);" className="gender_btn"> Above 25 Lakhs <input type="checkbox" className="d-none" /></a>
                                                                        </span>
                                                                    </div>
                                                                    <div className="choice-list">
                                                                        <span className="input-title">What is your trading experience?</span>
                                                                        <span className="input-title-filled gender_col">
                                                                            <a href="javascript:void(0);" className="gender_btn"> Less than 1 year <input type="checkbox" className="d-none" /></a>
                                                                            <a href="javascript:void(0);" className="gender_btn"> 1 - 2 Years <input type="checkbox" className="d-none" /></a>
                                                                            <a href="javascript:void(0);" className="gender_btn"> 2 - 5 Years <input type="checkbox" className="d-none" /></a>
                                                                            <a href="javascript:void(0);" className="gender_btn"> 5 - 10 Years <input type="checkbox" className="d-none" /></a>
                                                                            <a href="javascript:void(0);" className="gender_btn"> 10 - 20 Years <input type="checkbox" className="d-none" /></a>
                                                                            <a href="javascript:void(0);" className="gender_btn"> 20 - 25 Years <input type="checkbox" className="d-none" /></a>
                                                                            <a href="javascript:void(0);" className="gender_btn"> Above 25 Years <input type="checkbox" className="d-none" /></a>
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                                <div className="col-xl-12 p-0 float-left">
                                                                    <div className="col-xl-4 pl-0 float-left mt-20">
                                                                        <div className="email-input input_field pl-0">
                                                                            <label className="input-title">Mother’s Maiden Name</label>
                                                                            <input type="text" placeholder="Enter Mother's Maiden Name" value="" />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="col-xl-12 p-0 float-left text-center mt-20">
                                                                    <Link to={PageLinks.ACCOUNT_OPENING_PENNY_DROP} className="theme_btn primary_btn_one sub-btn wow " data-wow-delay="0.5s">Continue</Link>
                                                                </div>
                                                            </form>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <div className="blur_back_instruct"></div>
                <section className="otp_popup instruct">
                    <div className="container custom-container-subs">
                        <div className="sub-bg pt-150 pb-100 pr-md-50 pl-md-50 pr-xs-0 pl-xs-0">
                            <div className="row justify-content-center step_box_content_shadow">
                                <div className="step_box_content">
                                    <div className="sub-bg">
                                        <div className="">
                                            <div className="row justify-content-center">
                                                <div className="col-xl-12">
                                                    <div className="subscribe-wrapper">
                                                        <div className="section-title section-title-3">
                                                            <h6 className="mb--10 wow " data-wow-delay="0.1s">Preview</h6>
                                                            <a href="javascript:void(0);" className="theme_btn profile_close_btn sub-btn wow " data-wow-delay="0.5s"><i className="far fa-times-circle"></i> Close</a>
                                                            <hr></hr>
                                                            <div className="identity_photo_whole">
                                                                <div className="passport_photo_single">
                                                                    <Link to={PageLinks.VERIFY_YOUR_IDENTITY_PHOTO_POPUP}>
                                                                        <img src="assets/img/icon/passport.png" />
                                                                    </Link>
                                                                </div>
                                                            </div>
                                                            <div className="col-xl-12 continue_btn_whole">
                                                                <Link to={"#"} className="theme_btn primary_btn_one sub-btn wow  mr-10" data-wow-delay="0.5s">Delete</Link>
                                                                <Link to={PageLinks.AUTHENTICATE_AADHAAR_DETAIL} className="theme_btn primary_btn_one sub-btn wow " data-wow-delay="0.5s">Reupload</Link>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </main>
        </div>
        )
}

export default PassportPreview;
