import React, { useEffect, useState } from "react";
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import { TransitionProps } from '@mui/material/transitions';
import { DialogTitle } from "@mui/material";
import AlertSuccess from "./AlertSuccess";

interface FormParameters {
    showModal: boolean,
    closeModal: any,
}

const Alert: React.FC<FormParameters> = ({ showModal, closeModal }) => {
    const [alertSuccessPopup, setAlertSuccessPopup] = useState(false);

    return (
        <div className="main-index-wrapper">
            <main>
                <Dialog className="dialog-background"
                    open={showModal}
                    keepMounted
                    onClose={closeModal}
                    aria-describedby="alert-dialog-slide-description"
                    PaperProps={{
                    }}
                    maxWidth="md"
                >
                    <DialogTitle> Set Alert
                        <button onClick={(e: any) => {
                            e.preventDefault();
                            closeModal();
                        }} className="theme_btn profile_close_btn alert-close-button sub-btn wow  "><i className="far fa-times-circle"></i> Close</button>
                    </DialogTitle>
                    <div className="alert-div-borderline"></div>
                    <DialogContent className="cancelled-cheque-bg" style={{ overflow: "hidden" }}>
                        <DialogContentText style={{ overflow: "hidden" }} id="alert-dialog-slide-description">
                            <div className="container custom-container-subs">
                                <form className="quote-form">
                                    <div className="input_field pl-0">
                                        <label className="input-title">Select Date</label>
                                        <input type="date" className="date-picker-input profile-calendar mobile_number" required name="dob" min="1900-01-01" />
                                    </div>
                                    <div className="email-input input_field pl-0">
                                        <label className="input-title">Choose Time</label>
                                        <select className="" name="time">
                                            <option>select</option>
                                            <option value="0">10.00am to 11.00 am</option>
                                            <option value="1">10.00am to 11.00 am</option>
                                            <option value="2">10.00am to 11.00 am</option>
                                        </select>
                                    </div>
                                    <div className="email-input mobile-input input_field pl-0 login-btn" style={{ margin: "20px 0px 20px 0px" }}>
                                        <button type="submit" onClick={(e: any) => {
                                            e.preventDefault();
                                            closeModal();
                                            setAlertSuccessPopup(true);
                                        }} style={{ marginLeft: "0px" }} className="theme_btn btn-change primary_btn_1 sub-btn wow otp-verify" data-wow-delay="0.5s" >Set Alert</button>
                                    </div>
                                </form>
                            </div>
                        </DialogContentText>
                    </DialogContent>
                </Dialog>
                <AlertSuccess showModal={alertSuccessPopup} closeModal={() => { setAlertSuccessPopup(false) }} />
            </main>
        </div>
    )
}

export default Alert;