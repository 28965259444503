import { url } from "inspector";
import Header from "../components/template/Header";
import SlideBar from "../components/template/SlideBar";
import Footer from "../components/template/Footer";
import { Tab, TabList, TabPanel, Tabs } from "react-tabs";
import InvestSteps from "../components/InvestSteps";
import YourStory from "../components/YourStory";
import Subscribe from "../components/Subscribe";
import NeedHelp from "../components/NeedHelp";

const CollectionofBonds: React.FC = () => {

    return (
        <div className="main-index-wrapper">
            <Header />
            <SlideBar />
            <div className="body-overlay"></div>
            <main>
                <section className="collection_of_bonds what-we-do-area pt-110 pb-30 pt-md-45 pb-md-40 pt-xs-45 pb-xs-40">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="row align-items-left">
                                    <div className="col-lg-6 breadcums">
                                        <nav>
                                            <ul>
                                                <li>Home</li>
                                                <li>Bonds</li>
                                                <li className="active">Collection of Bonds</li>
                                            </ul>
                                        </nav>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-lg-12 collection_box">
                                <div className="col-lg-8 collection_left">
                                    <Tabs>
                                        <TabList>
                                            <Tab>Trending</Tab>
                                            <Tab>Popular</Tab>
                                            <Tab>Entire Collection</Tab>
                                        </TabList>
                                        <TabPanel>
                                            <div className="collection_bonds_box">
                                                <div className="collection_bonds_box_left">
                                                    <img src="assets/img/icon/short_term.svg" />
                                                </div>
                                                <div className="collection_bonds_box_right mt-3">Ultra Short Term Bonds</div>
                                            </div>
                                            <div className="collection_bonds_box">
                                                <div className="collection_bonds_box_left">
                                                    <img src="assets/img/icon/safebonds.svg" />
                                                </div>
                                                <div className="collection_bonds_box_right mt-3">Highly Safe Bonds</div>
                                            </div>
                                            <div className="collection_bonds_box">
                                                <div className="collection_bonds_box_left">
                                                    <img src="assets/img/icon/taxfreebond.svg" />
                                                </div>
                                                <div className="collection_bonds_box_right mt-3">Tax Free Bond</div>
                                            </div>
                                            <div className="collection_bonds_box">
                                                <div className="collection_bonds_box_left">
                                                    <img src="assets/img/icon/senior.svg" />
                                                </div>
                                                <div className="collection_bonds_box_right">Bonds for Senior Citizens</div>
                                            </div>
                                            <div className="collection_bonds_box">
                                                <div className="collection_bonds_box_left">
                                                    <img src="assets/img/icon/bank.svg" />
                                                </div>
                                                <div className="collection_bonds_box_right">Public Sector Bank Bonds</div>
                                            </div>
                                            <div className="collection_bonds_box">
                                                <div className="collection_bonds_box_left">
                                                    <img src="assets/img/icon/guaranteed.svg" />
                                                </div>
                                                <div className="collection_bonds_box_right">State Government Guaranteed Bonds</div>
                                            </div>
                                        </TabPanel>
                                        <TabPanel>
                                            <div className="collection_bonds_box">
                                                <div className="collection_bonds_box_left">
                                                    <img src="assets/img/icon/tax_free.png" />
                                                </div>
                                                <div className="collection_bonds_box_right mt-3">Tax Free</div>
                                            </div>
                                            <div className="collection_bonds_box">
                                                <div className="collection_bonds_box_left">
                                                    <img src="assets/img/icon/new_arrivals.png" />
                                                </div>
                                                <div className="collection_bonds_box_right mt-3">New Arrivals</div>
                                            </div>
                                            <div className="collection_bonds_box">
                                                <div className="collection_bonds_box_left">
                                                    <img src="assets/img/icon/best_ipo.png" />
                                                </div>
                                                <div className="collection_bonds_box_right mt-3">Best Ongoing IPO</div>
                                            </div>
                                            <div className="collection_bonds_box">
                                                <div className="collection_bonds_box_left">
                                                    <img src="assets/img/icon/high_yielding.png" />
                                                </div>
                                                <div className="collection_bonds_box_right">High Yielding</div>
                                            </div>
                                            <div className="collection_bonds_box">
                                                <div className="collection_bonds_box_left">
                                                    <img src="assets/img/icon/fixed_income.png" />
                                                </div>
                                                <div className="collection_bonds_box_right">Monthly Fixed Income Bonds</div>
                                            </div>
                                            <div className="collection_bonds_box">
                                                <div className="collection_bonds_box_left">
                                                    <img src="assets/img/icon/nbfc_bonds.png" />
                                                </div>
                                                <div className="collection_bonds_box_right">NBFC Bonds</div>
                                            </div>
                                        </TabPanel>
                                        <TabPanel>
                                            <div className="collection_bonds_box">
                                                <div className="collection_bonds_box_left">
                                                    <img src="assets/img/icon/short_term.svg" />
                                                </div>
                                                <div className="collection_bonds_box_right mt-3">Ultra Short Term Bonds</div>
                                            </div>
                                            <div className="collection_bonds_box">
                                                <div className="collection_bonds_box_left">
                                                    <img src="assets/img/icon/safebonds.svg" />
                                                </div>
                                                <div className="collection_bonds_box_right mt-3">Highly Safe Bonds</div>
                                            </div>
                                            <div className="collection_bonds_box">
                                                <div className="collection_bonds_box_left">
                                                    <img src="assets/img/icon/taxfreebond.svg" />
                                                </div>
                                                <div className="collection_bonds_box_right mt-3">Tax Free Bond</div>
                                            </div>
                                            <div className="collection_bonds_box">
                                                <div className="collection_bonds_box_left">
                                                    <img src="assets/img/icon/senior.svg" />
                                                </div>
                                                <div className="collection_bonds_box_right">Bonds for Senior Citizens</div>
                                            </div>
                                            <div className="collection_bonds_box">
                                                <div className="collection_bonds_box_left">
                                                    <img src="assets/img/icon/bank.svg" />
                                                </div>
                                                <div className="collection_bonds_box_right">Public Sector Bank Bonds</div>
                                            </div>
                                            <div className="collection_bonds_box">
                                                <div className="collection_bonds_box_left">
                                                    <img src="assets/img/icon/guaranteed.svg" />
                                                </div>
                                                <div className="collection_bonds_box_right">State Government Guaranteed Bonds</div>
                                            </div>
                                            <div className="collection_bonds_box">
                                                <div className="collection_bonds_box_left">
                                                    <img src="assets/img/icon/tax_free.png" />
                                                </div>
                                                <div className="collection_bonds_box_right mt-3">Tax Free</div>
                                            </div>
                                            <div className="collection_bonds_box">
                                                <div className="collection_bonds_box_left">
                                                    <img src="assets/img/icon/new_arrivals.png" />
                                                </div>
                                                <div className="collection_bonds_box_right mt-3">New Arrivals</div>
                                            </div>
                                            <div className="collection_bonds_box">
                                                <div className="collection_bonds_box_left">
                                                    <img src="assets/img/icon/best_ipo.png" />
                                                </div>
                                                <div className="collection_bonds_box_right mt-3">Best Ongoing IPO</div>
                                            </div>
                                            <div className="collection_bonds_box">
                                                <div className="collection_bonds_box_left">
                                                    <img src="assets/img/icon/high_yielding.png" />
                                                </div>
                                                <div className="collection_bonds_box_right mt-3">High Yielding</div>
                                            </div>
                                            <div className="collection_bonds_box">
                                                <div className="collection_bonds_box_left">
                                                    <img src="assets/img/icon/fixed_income.png" />
                                                </div>
                                                <div className="collection_bonds_box_right">Monthly Fixed Income Bonds</div>
                                            </div>
                                            <div className="collection_bonds_box">
                                                <div className="collection_bonds_box_left">
                                                    <img src="assets/img/icon/nbfc_bonds.png" />
                                                </div>
                                                <div className="collection_bonds_box_right mt-3">NBFC Bonds</div>
                                            </div>
                                            <div className="collection_bonds_box">
                                                <div className="collection_bonds_box_left">
                                                    <img src="assets/img/icon/psu.svg" />
                                                </div>
                                                <div className="collection_bonds_box_right mt-3">PSU</div>
                                            </div>
                                            <div className="collection_bonds_box">
                                                <div className="collection_bonds_box_left">
                                                    <img src="assets/img/icon/g-sec.png" />
                                                </div>
                                                <div className="collection_bonds_box_right mt-3">G-Sec/SDL</div>
                                            </div>
                                            <div className="collection_bonds_box">
                                                <div className="collection_bonds_box_left">
                                                    <img src="assets/img/icon/structured.png" />
                                                </div>
                                                <div className="collection_bonds_box_right mt-3">Structured</div>
                                            </div>
                                            <div className="collection_bonds_box">
                                                <div className="collection_bonds_box_left">
                                                    <img src="assets/img/icon/capital_gains.png" />
                                                </div>
                                                <div className="collection_bonds_box_right mt-3">Capital Gains (54EC)</div>
                                            </div>
                                            <div className="collection_bonds_box">
                                                <div className="collection_bonds_box_left">
                                                    <img src="assets/img/icon/profit.png" />
                                                </div>
                                                <div className="collection_bonds_box_right mt-3">Profit</div>
                                            </div>
                                        </TabPanel>
                                    </Tabs>
                                </div>
                                <div className="col-lg-4 p-0 collection_right">
                                    <img src="assets/img/icon/collection_bonds.png" />
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </main>
            <Footer />
        </div>
    )
}

export default CollectionofBonds;
