import { useEffect, useState } from "react";
import { BrowserRouter as Router, Navigate, Route, Routes, useLocation } from "react-router-dom";
import Preloader from "./components/template/Preloader";
import Profile from "./pages/Profile";
import Login from "./pages/Login";
import Signup from "./pages/Signup";
import VerifyOTPLogin from "./pages/VerifyOTPLogin";
import PersonalDetailsEdit from "./pages/PersonalDetailsEdit";
import PersonalDetailsEditVerifyEmail from "./pages/PersonalDetailsEditVerifyEmail";
import EmailVerification from "./pages/EmailVerification";
import EmailVerificationOTP from "./pages/EmailVerificationOTP";
import PersonalDetailsEditEmailVerified from "./pages/PersonalDetailsEditEmailVerified";
import PersonalDetailsEditValidatePan from "./pages/PersonalDetailsEditValidatePan";
import PanVerification from "./pages/PanVerification";
import AccountOpeningpennyDrop from "./pages/AccountOpeningpennyDrop";
import VerifyYourIdentity from "./pages/VerifyYourIdentity";
import VerifyYourIdentityPhoto from "./pages/VerifyYourIdentityPhoto";
import VerifyYourIdentityPhotoSuccess from "./pages/VerifyYourIdentityPhotoSuccess";
import BankDetails from "./pages/BankDetails";
import BankDetailsAddedSuccess from "./pages/BankDetailsAddedSuccess";
import DematDetails from "./pages/DematDetails";
import DematDetailsAddedSuccess from "./pages/DematDetailsAddedSuccess";
import KYCSuccess from "./pages/KYCSuccess";
import MyAccountprofile from "./pages/MyAccountprofile";
import AccountOpeningpennyDropPopup from "./pages/AccountOpeningpennyDropPopup";
import AreYouSureAlertPopup from "./pages/AreYouSureAlertPopup";
import AppologiesPopup from "./pages/AppologiesPopup";
import DematSignaturePreview from "./pages/DematSignaturePreview";
import SignatureSuccess from "./pages/SignatureSuccess";
import AddNominee from "./pages/AddNominee";
import EsignAadhaar from "./pages/EsignAadhaar";
import FormSuccess from "./pages/FormSuccess";
import AuthenticateAadhaar from "./pages/AuthenticateAadhaar";
import AuthenticateAadhaarDetail from "./pages/AuthenticateAadhaarDetail";
import PassportPreview from "./pages/PassportPreview";
import PanNotMatchPopup from "./pages/PanNotMatchPopup";
import UploadCancelledCheque from "./pages/UploadCancelledCheque";
import CancelledChequePreview from "./pages/CancelledChequePreview";
import PanStatusPopup from "./pages/PanStatusPopup";
import DijiSuccessPopup from "./pages/DijiSuccessPopup";
import PanVerificationDeactivate from "./pages/PanVerificationDeactivate";
import EmailVerified from "./pages/EmailVerified";
import EsignSuccessPopup from "./pages/EsignSuccessPopup";
import ProfileListing from "./pages/ProfileListing";
import ProfileListingRow from "./pages/ProfileListingRow";
import CollectionofBonds from "./pages/CollectionofBonds";
import SovereignBondsLandingPage from "./pages/SovereignBondsLandingPage"
import DetailViewOne from "./pages/DetailViewOne";
import ApplyBondPurchase from "./pages/ApplyBondPurchase";
import AlertImagePopup from "./pages/AlertImagePopup";
import OrderListing from "./pages/OrderListing";
import ProfileSettings from "./pages/ProfileSettings";
import AlertLists from "./pages/AlertLists";
import Chat from "./pages/Chat";
import LearnFromBond from "./pages/LearnFromBond";
import WhatAreAdvantages from "./pages/WhatAreAdvantages";
import PaymentBond from "./pages/PayBondPayment";
import BondConfirmed from "./pages/BondConfirmedOrder";
import AboutUs from "./pages/AboutUs";
import NewsAndInsights from "./pages/NewsAndInsights";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import TermsAndConditions from "./pages/TermsAndConditions";
import ContactUs from "./pages/ContactUs";
import Disclaimer from "./pages/Disclaimer";
import ApplyBondNonGold from "./pages/ApplyBondNonGold";
import EditNominee from "./pages/EditNominee";
import SetAlert from "./pages/SetAlert";
import Faq from "./pages/Faq";
import InstructionScreen from "./pages/Instruction_Screen";
import VerifyYourEmailSuccess from "./pages/VerifyYourEmailSuccess";
import ScrollToTop from "./components/template/ScrollToTop";
import BondIPOFlow from "./pages/BondIPOFlow";
import BondIPOPurchase from "./pages/BondIPOPurchase";
import ApplicationDownload from "./pages/ApplicationDownload";
import { ServerAPI } from "./common/ServerAPI";
import { PageLinks } from "./common/Constants";
import { isExpired, decodeToken } from "react-jwt";
import RefundPolicy from "./pages/RefundPolicy";
import VerifyYourEmailSuccessContact from "./pages/VerifyYourEmailSuccessContact";


const AppNavigate: React.FC = () => {
    const [sessionValid, setSessionValid] = useState(false);
    const clearCacheData = () => {
        caches.keys().then((names) => {
            names.forEach((name) => {
                caches.delete(name);
            });
        });
    };

    const RefreshLoginToken = () => {
        let login = localStorage.getItem('login');
        if (login != null && login != undefined && login != '0') {
            let token = localStorage.getItem('token');
            if (token != null) {
                const myDecodedToken = decodeToken(token);
                console.log(myDecodedToken);
                let tokendetail: any = myDecodedToken;
                if (tokendetail.exp * 1000 < Date.now()) {
                    ServerAPI.RefreshToken().then((response: any) => {
                        if (response == true) {
                            setSessionValid(true);
                        }else{
                            setSessionValid(false);
                        }
                    });
                } else {
                    setSessionValid(true);
                }
            }
        }else{
            setSessionValid(true);
        }
    }
    useEffect(() => {
        const win: Window = window;
        if (!window.location.host.startsWith("www")) {
            win.location = window.location.protocol + "//" + "www." + window.location.host + window.location.pathname;
        }

        clearCacheData();
    }, [])

    useEffect(() => {
        if (sessionValid == false) {
            RefreshLoginToken();
        }
    }, [sessionValid])


    return (
        <Router>
            <ScrollToTop />
            <Preloader />
            {sessionValid ?
                <Routes>
                    <Route path={PageLinks.PROFILE} element={<Profile />} />
                    <Route path={PageLinks.LOGIN} element={<Login />} />
                    <Route path={PageLinks.SIGNUP} element={<Signup />} />
                    <Route path={PageLinks.VERIFY_OTP_LOGIN} element={<VerifyOTPLogin />} />
                    <Route path={PageLinks.PROFILE_PERSONAL_DETAILS_EDIT} element={<PersonalDetailsEdit />} />
                    <Route path={PageLinks.PROFILE_PERSONAL_DETAILS_EDIT_VERIFY_EMAIL} element={<PersonalDetailsEditVerifyEmail />} />
                    <Route path={PageLinks.EMAIL_VERIFICATION} element={<EmailVerification />} />
                    <Route path={PageLinks.EMAIL_VERIFICATION_OTP} element={<EmailVerificationOTP />} />
                    <Route path={PageLinks.PROFILE_PERSONAL_DETAILS_EDIT_EMAIL_VERIFIED} element={<PersonalDetailsEditEmailVerified />} />
                    <Route path={PageLinks.PROFILE_PERSONAL_DETAILS_EDIT_VALIDATE_PAN} element={<PersonalDetailsEditValidatePan />} />
                    <Route path={PageLinks.PAN_VERIFICATION} element={<PanVerification />} />
                    <Route path={PageLinks.ACCOUNT_OPENING_PENNY_DROP} element={<AccountOpeningpennyDrop />} />
                    <Route path={PageLinks.VERIFY_YOUR_IDENTITY} element={<VerifyYourIdentity />} />
                    <Route path={PageLinks.VERIFY_YOUR_IDENTITY_PHOTO} element={<VerifyYourIdentityPhoto />} />
                    <Route path={PageLinks.VERIFY_YOUR_IDENTITY_SUCCESS} element={<VerifyYourIdentityPhotoSuccess />} />
                    <Route path={PageLinks.BANK_DETAILS} element={<BankDetails />} />
                    <Route path={PageLinks.BANK_DETAILS_ADDED_SUCCESS} element={<BankDetailsAddedSuccess />} />
                    <Route path={PageLinks.DEMAT_DETAILS} element={<DematDetails />} />
                    <Route path={PageLinks.DEMAT_DETAILS_ADDED_SUCCESS} element={<DematDetailsAddedSuccess />} />
                    <Route path={PageLinks.KYC_SUCCESS} element={<KYCSuccess />} />
                    <Route path={PageLinks.MY_ACCOUNT_PROFILE} element={<MyAccountprofile />} />
                    <Route path={PageLinks.ACCOUNT_OPENING_PENNY_DROP_POPUP} element={<AccountOpeningpennyDropPopup />} />
                    <Route path={PageLinks.ARE_YOU_SURE_ALERT} element={<AreYouSureAlertPopup />} />
                    <Route path={PageLinks.APPOLOGIES_POPUP} element={<AppologiesPopup />} />
                    <Route path={PageLinks.DEMAT_SIGNATURE_PREVIEW} element={<DematSignaturePreview />} />
                    <Route path={PageLinks.SIGNATURE_SUCCESS} element={<SignatureSuccess />} />
                    <Route path={PageLinks.ADD_NOMINEE} element={<AddNominee />} />
                    <Route path={PageLinks.ESIGN_AADHAAR} element={<EsignAadhaar />} />
                    <Route path={PageLinks.FORM_SUCCESS} element={<FormSuccess />} />
                    <Route path={PageLinks.AUTHENTICATE_AADHAAR} element={<AuthenticateAadhaar />} />
                    <Route path={PageLinks.AUTHENTICATE_AADHAAR_DETAIL} element={<AuthenticateAadhaarDetail />} />
                    <Route path={PageLinks.PASSPORT_PREVIEW} element={<PassportPreview />} />
                    <Route path={PageLinks.PAN_NOT_MATCH_POPUP} element={<PanNotMatchPopup />} />
                    <Route path={PageLinks.UPLOAD_CANCELLED_CHEQUE} element={<UploadCancelledCheque />} />
                    <Route path={PageLinks.CANCELLED_CHEQUE_PREVIEW} element={<CancelledChequePreview />} />
                    <Route path={PageLinks.PAN_STATUS_POPUP} element={<PanStatusPopup />} />
                    <Route path={PageLinks.DIJI_SUCCESS_POPUP} element={<DijiSuccessPopup />} />
                    <Route path={PageLinks.PAN_INVALID} element={<PanVerificationDeactivate />} />
                    <Route path={PageLinks.EMAIL_UPDATE} element={<EmailVerified />} />
                    <Route path={PageLinks.PROFILE_LISTING} element={<ProfileListing />} />
                    <Route path={PageLinks.PROFILE_LISTING_ROW} element={<ProfileListingRow />} />
                    <Route path={PageLinks.COLLECTION_OF_BONDS} element={<CollectionofBonds />} />
                    <Route path={PageLinks.SOVEREIGN_BONDS_LANDING_PAGE} element={<SovereignBondsLandingPage />} />
                    <Route path={PageLinks.DETAIL_VIEW_ONE} element={<DetailViewOne />} />
                    <Route path={PageLinks.APPLYBONDPURCHASE} element={<ApplyBondPurchase />} />
                    <Route path={PageLinks.APPLYBONDNONGOLD} element={<ApplyBondNonGold />} />
                    <Route path={PageLinks.ALERT_IMAGE_POPUP} element={<AlertImagePopup />} />
                    <Route path={PageLinks.ORDER_LISTING} element={<OrderListing />} />
                    <Route path={PageLinks.PROFILE_SETTINGS} element={<ProfileSettings />} />
                    <Route path={PageLinks.ALERT_LISTS} element={<AlertLists />} />
                    <Route path={PageLinks.CHAT} element={<Chat />} />
                    <Route path={PageLinks.LEARN_FROM_BOND} element={<LearnFromBond />} />
                    <Route path={PageLinks.WHAT_ARE_ADVANTAGES} element={<WhatAreAdvantages />} />
                    <Route path={PageLinks.PAYMENTBOND} element={<PaymentBond />} />
                    <Route path={PageLinks.BOND_CONFIRMED_ORDER + "/*"} element={<BondConfirmed />} />
                    <Route path={PageLinks.ABOUTUS} element={<AboutUs />} />
                    <Route path={PageLinks.NEWSANDINSIGHTS} element={<NewsAndInsights />} />
                    <Route path={PageLinks.PRIVACY_POLICY} element={<PrivacyPolicy />} />
                    <Route path={PageLinks.REFUND_POLICY} element={<RefundPolicy />} />
                    <Route path={PageLinks.TERMS_AND_CONDITIONS} element={<TermsAndConditions />} />
                    <Route path={PageLinks.CONTACT_US} element={<ContactUs />} />
                    <Route path={PageLinks.DISCLAIMER} element={<Disclaimer />} />
                    <Route path={PageLinks.ESIGN_SUCCESS_POPUP} element={<EsignSuccessPopup />} />
                    <Route path={PageLinks.EDIT_NOMINEE + "/*"} element={<EditNominee />} />
                    <Route path={PageLinks.SET_ALERT} element={<SetAlert />} />
                    <Route path={PageLinks.FAQ} element={<Faq />} />
                    <Route path={PageLinks.INSTRUCTION_SCREEN} element={<InstructionScreen />} />
                    <Route path={PageLinks.EMAIL_VERIFED_SUCCESS} element={<VerifyYourEmailSuccess />} />
                    <Route path={PageLinks.BOND_IPO_FLOW} element={<BondIPOFlow />} />
                    <Route path={PageLinks.BOND_IPO_PURCHASE} element={<BondIPOPurchase />} />
                    <Route path={PageLinks.DOWNLOAD_APPLICATION} element={<ApplicationDownload />} />
                    <Route path={PageLinks.ENQUIRY_ADDED_SUCCESS} element={<VerifyYourEmailSuccessContact />} />



                    <Route path="/" element={<Navigate to={PageLinks.PROFILE} />} />
                </Routes>
                :
                <div />
            }
        </Router>
    )
}

export default AppNavigate;
