import { APIData } from "../../common/DataTypes";
import moment from "moment";

export namespace InitialData {
    export const BankDetails: APIData.bankDetails = {
        number_of_account: "",
        ifsc_code: "",
        account_number: "",
        account_type_1: 0,
        account_type_2: 0,
        is_primary: 0,
        cancel_cheque_image: "",
        dividend_bank_account_type: "",
        dividend_bank_account_code: "",
        dividend_bank_account_number: "",
        dividend_bank_CCY: 0,
        dividend_bank_IFSC_code: "",
        dividend_currency: 0,
        RBI_reference_no: "",
        RBI_approval_date: "",
        account_number_confirm: ""
    }

    export const verify_otp: APIData.otp_verify = {
        otp: '',
    }
    export const verify_email: APIData.email_Verify = {
        email_id: ""
    }

    export const signUp: APIData.signup = {
        agreed: false,
        firstName: "",
        lastName: "",
        mobile_number: "",
        terms: false,
        partnerterms: false
    }

    export const UserDetails: APIData.userDetail = {
        firstname: '',
        lastname: '',
        fname: '',
        lname: '',
        pan_number: "",
        pan_name: "",
        aadhar_number: "",
        aadhar_name: "",
        dob: "",
        country_code: "",
        smart_card_required: 0,
        smart_card_number: "",
        smart_card_PIN: "",
        gender: 0,
        married_status: 0,
        mothers_maiden_name: "",
        mobile_number: "",
        is_mobile_verified: 0,
        annual_income: 0,
        trading_experience: 0,
        occupation: "",
        lifestyle: "",
        geogriphical_code: "",
        education_degree: "",
        address_line_1: "",
        address_line_2: "",
        address_line_3: "",
        address_zip: "",
        address_state_code: "",
        email_id: "",
        is_email_verified: 0,
        address_state: "",
        address_city: "",
        city_sequence_no: "",
        family_account: "",
        mental_disability: "",
        is_pan_verified: 0,
        is_aadhar_verified: 0,
        signature: "",
        signature_file_flag: "",
        profile_image: "",
        verification_video: "",
        in_person_verification: 0,
        proof_type: "",
        proof_front_image: "",
        proof_back_image: "",
        manager_id: 0,
        is_politically_exposed: 0,
        would_you_like_to_activate: 0,
        check_box_share_data_with_company: 0,
        check_box_share_data_with_govt: 0,
        mediaBlob: undefined,
        filled_itr_last_2years: 0,
        ekyc_application_status: "",
        existing_demat_account_count: 0,
        new_demat_account_count: 0,
        activation_date: "",
    }


    export const ExistingDematAccount: APIData.ExistingDematAccount = {
        depository: 0,
        dp_id: "",
        dp_name: "",
        dp_id_confirm: ""
    }

    export const CancelledCheque: any = {
        cancelled_cheque_image: "",
    }

    export const ProofImage: any = {
        proof_image: "",
    }

    export const updateAadharDetails: any = {
        addressProofType: "",
        proof_back_image: "",
        proof_front_image: "",
        Address_line_1: "",
        Address_line_2: "",
        pincode: "",
        state: "",
        city: "",
    }
    export const newDematAccount: APIData.NewDematAccount = {
        dp_resident_india: 0,
        dp_resident_usa: 0,
        check_box_terms_selected: 0,
        check_box_account_statement_electronic: 0,
        demat_signature_image: '',
        born_place: '',
        primary_source: ''
    }

    export const nomineeDetail: APIData.NomineeDetails = {
        allocation: 0,
        title: "",
        fname: "",
        mname: "",
        lname: "",
        relationship: "",
        identification: 0,
        mobile_number: "",
        dob: "",
        address_line_1: "",
        address_line_2: "",
        address_line_3: "",
        address_zip: "",
        address_state_code: "",
        address_state: "",
        address_city: "",
        city_sequence_no: "",
        current_address_line_1: "",
        current_address_line_2: "",
        current_address_line_3: "",
        current_address_zip: "",
        current_address_state_codecurrent: "",
        current_address_state: "",
        current_address_city: "",
        current_city_sequence_no: "",
        identification_number: ""
    }

    export const NomineeData: APIData.NomineeData = {
        column_1: "",
        column_2: "",
        id: 0,
        nominee_details_address_city: "",
        nominee_details_address_line_1: "",
        nominee_details_address_line_2: "",
        nominee_details_address_line_3: "",
        nominee_details_address_state: "",
        nominee_details_address_state_code: "",
        nominee_details_address_zip: "",
        nominee_details_allocation: "",
        nominee_details_city_sequence_no: "",
        nominee_details_created_at: "",
        nominee_details_current_address_city: "",
        nominee_details_current_address_line_1: "",
        nominee_details_current_address_line_2: "",
        nominee_details_current_address_line_3: "",
        nominee_details_current_address_state: "",
        nominee_details_current_address_state_codecurrent: "",
        nominee_details_current_address_zip: "",
        nominee_details_current_city_sequence_no: "",
        nominee_details_dob: "",
        nominee_details_fname: "",
        nominee_details_identification: 0,
        nominee_details_identification_number: "",
        nominee_details_lname: "",
        nominee_details_mname: "",
        nominee_details_mobile_number: "",
        nominee_details_relationship: "",
        nominee_details_title: "",
        nominee_details_updated_at: ""

    }

    export const FilterData: APIData.Filters = {
        brand_tag_id: [],
        bond_rating_list_id: [],
        payment_frequency: [],
        tenure_from: 0,
        tenure_to: 0,
        yield_from: 0,
        yield_to: 0,
    }

    export const OrderFilterData: APIData.OrderFilters = {
        bond_type: 0,
        order_from_date: "",
        order_to_date: "",
    }

}