import { url } from "inspector";
import Header from "../components/template/Header";
import SlideBar from "../components/template/SlideBar";
import InvestSteps from "../components/InvestSteps";
import Subscribe from "../components/Subscribe";
import NeedHelp from "../components/NeedHelp";
import Footer from "../components/template/Footer";
import { Link } from "react-router-dom";
import { PageLinks } from "../common/Constants";
import StepsComponent from "../components/StepsComponent";
import { useState } from "react";

const AlertLists: React.FC = () => {

    const order_list = [
        {
            id: 1,
            title: "Sovereign Gold Bonds Scheme 2021-22 - Series X",
            mode: false,
            img: "assets/img/mahendra_logo.png"
        },
        {
            id: 2,
            title: "Sovereign Gold Bonds Scheme 2021-22 - Series X",
            mode: false,
            img: "assets/img/icon/mahi_logo.png"
        },
        {
            id: 3,
            title: "Sovereign Gold Bonds Scheme 2021-22 - Series X",
            mode: false,
            img: "assets/img/mahendra_logo.png"
        },
        {
            id: 4,
            title: "Sovereign Gold Bonds Scheme 2021-22 - Series X",
            mode: false,
            img: "assets/img/icon/mahi_logo.png"
        },
        {
            id: 5,
            title: "Sovereign Gold Bonds Scheme 2021-22 - Series X",
            mode: false,
            img: "assets/img/mahendra_logo.png"
        },
    ]
    const [openDropDown, setOpenDropDown] = useState(true);
    const toggleDropdown = () => setOpenDropDown(!openDropDown);

    return (
        <div className="main-index-wrapper">
            <Header />
            <SlideBar />
            <div className="body-overlay"></div>
            <main>
                <section className="profile_listing_row profile_listing pt-110 pb-160 pt-md-45 pb-md-40 pt-xs-45 pb-xs-40">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="row align-items-left">
                                    <div className="col-lg-6 breadcums">
                                        <nav>
                                            <ul>
                                                <li>Home</li>
                                                <li className="active">Alerts</li>
                                            </ul>
                                        </nav>
                                    </div>
                                </div>
                                {
                                    order_list.map((list: any, index: number) => {
                                        return (
                                            <div className="row align-items-center" key={list.id}>
                                                <div className="col-lg-12 col-md-6 " data-wow-delay="0.1s">
                                                    <div className="alert-list mb-30">
                                                        <div className="row alert_box">
                                                            <div className="col-lg-12" style={{ padding: "10px 10px" }}>
                                                                <div className="d-flex">
                                                                    <div className="col-lg-4 d-flex align-items-center">
                                                                        <img src={list.img} alt="" />
                                                                        <div className="order-icon-head">
                                                                            <h5>{list.title}</h5>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-lg-8 align-self-center alert-list-sub">
                                                                        <div className="row d-flex align-items-center justify-content-center">
                                                                            <div className="col-lg-4">
                                                                                <p>Issue Name</p>
                                                                                <h6>2020-21 Series Xl - Sovereign Gold Bonds</h6>
                                                                            </div>
                                                                            <div className="col-lg-4">
                                                                                <p>Subscription Dates</p>
                                                                                <h6>Oct 12, 2020 - Oct 16, 2020</h6>
                                                                            </div>
                                                                            <div className="col-lg-4">
                                                                                <p>Allotment Date</p>
                                                                                <h6>October 20, 2020</h6>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    })
                                }
                            </div>
                        </div>
                    </div>
                </section>
            </main>
            <Footer />
        </div>
    )
}

export default AlertLists;
