import { useEffect } from "react";
import { Link } from "react-router-dom";
import { useAppStateAPI } from "../common/AppStateAPI";
import { PageLinks } from "../common/Constants";

const UploadCancelledCheque: React.FC = () => {

    return (
        <div className="main-index-wrapper">
            <main>
            <section className="subscribe-letter-area pt-20 pb-20">
                    <div className="line-shape d-none d-lg-inline-block">
                        <img className="img-fluid" src="assets/img/Intersection_4.svg" alt="" />
                    </div>

                    <div className="container custom-container-subs">
                        <div className="sub-bg">
                            <div className="row justify-content-center">
                                <div className="col-xl-12">
                                    <div className="subscribe-wrapper">
                                        <div className="section-title section-title-3 text-center">
                                           <img className="header-logo" src="assets/img/logo/header_logo_one.png" alt="logo" />
                                        </div>
                                    </div>
                                    <div className="progress-indicator-element">
                                        <ul className="progress-indicator">
                                            <li className="completed">
                                                <span className="stacked-text">
                                                    <span className="subdued"><span className="small">Step 01</span> <br></br> Personal Details</span>
                                                </span>
                                                {/* <span className="bubble"></span> */}
                                            </li>
                                            <li className="completed msg_contain">
                                                <span className="stacked-text">
                                                    <span className="subdued"><span className="small">Step 02</span> <br></br> Bank Details</span>
                                                </span>
                                                {/* <span className="bubble"></span> */}
                                                <div className="bse-order-no">
                                                </div>
                                                <div className="bse-order-msg msg-success">
                                                </div>
                                            </li>
                                            <li className="completed">
                                                <span className="stacked-text">
                                                    <span className="subdued"><span className="small">Step 03</span> <br></br> Demat Details</span>
                                                </span>
                                                {/* <span className="bubble"></span> */}
                                            </li>
                                        </ul>
                                    <div className='line-container'>
                                        <div className='progress-line'>
                                            <div className='progress '></div>
                                            <div className='status'>
                                                <div className='dot completed'></div>
                                            </div>
                                            <div className='status'>
                                                <div className='dot current'></div>
                                            </div>
                                            <div className='status'>
                                                <div className='dot'></div>
                                            </div>
                                        </div>
                                    </div>
                                    </div>
                                    <div className="step_box_content">
                                        <div className="sub-bg">
                                            <div className="row justify-content-center">
                                                <div className="col-xl-12">
                                                    <div className="subscribe-wrapper">
                                                        <div className="section-title section-title-3">
                                                            <h6 className="mb--10 wow " data-wow-delay="0.1s">Upload cancelled cheque</h6>
                                                            <Link to={PageLinks.PAN_NOT_MATCH_POPUP} className="theme_btn profile_close_btn sub-btn wow " data-wow-delay="0.5s"><i className="far fa-times-circle"></i> Close</Link>
                                                            <hr></hr>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <div className="blur_back_instruct"></div>
                <section className="otp_popup instruct">
                    <div className="container custom-container-subs">
                        <div className="sub-bg pt-150 pb-100 pr-md-50 pl-md-50 pr-xs-0 pl-xs-0">
                            <div className="row justify-content-center step_box_content_shadow">
                                <div className="step_box_content">
                                    <div className="sub-bg">
                                        <div className="">
                                            <div className="row justify-content-center">
                                                <div className="col-xl-12">
                                                    <div className="subscribe-wrapper">
                                                        <div className="section-title section-title-3">
                                                            <h6 className="mb--10 wow " data-wow-delay="0.1s">Upload cancelled cheque</h6>
                                                            <a href="javascript:void(0);" className="theme_btn profile_close_btn sub-btn wow " data-wow-delay="0.5s"><i className="far fa-times-circle"></i> Close</a>
                                                            <hr></hr>
                                                            <div className="cancelled_cheque_box">
                                                                <div className="signature_preview_box_head">Upload Preview</div>
                                                                <div className="signature_preview_box_content mt-80">
                                                                    <div className="upload_img"><img src="assets/img/icon/Upload.png" /></div>
                                                                    <p className="upload_img_text">Upload Cancelled Cheque</p>
                                                                    <p className="signature_preview_box_content_text_one">upload your Signature <Link to={'#'}>JPG</Link>, <Link to={'#'}>JPEG</Link> or PNG in less than <Link to={'#'}>10 MB</Link></p>
                                                                </div>
                                                            </div>
                                                            <div className="signature_preview_box_with_sign">
                                                                <div className="signature_preview_box_head">Passport Frontside Preview</div>
                                                                <div className="signature_preview_box_content">
                                                                    <div className="signature_preview_box_content_sign">
                                                                        <Link to={PageLinks.CANCELLED_CHEQUE_PREVIEW}>
                                                                            <img src="assets/img/icon/canceled_cheque.png" />
                                                                        </Link>
                                                                    </div>
                                                                    <div className="signature_preview_box_content_replace_btn">Replace</div>
                                                                    <p className="signature_preview_box_content_text_one">upload your Signature <Link to={'#'}>JPG</Link>, <Link to={'#'}>JPEG</Link> or PNG in less than <Link to={'#'}>10 MB</Link></p>
                                                                </div>
                                                            </div>
                                                            <p className="personal_sub_text grayed_out">*Note: If you do not have your Cheque book with you for the cancelled cheque, you can upload a copy of your Bank Statement or Bank Passbook</p>
                                                            <div className="col-xl-12 continue_btn_whole">
                                                                <Link to={PageLinks.AUTHENTICATE_AADHAAR_DETAIL} className="theme_btn primary_btn_one sub-btn wow " data-wow-delay="0.5s">Submit</Link>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </main>
        </div>
        )
}

export default UploadCancelledCheque;
