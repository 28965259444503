import React, { Fragment, useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import { AccountTypeValues1, PageLinks } from "../../common/Constants";
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { ServerAPI } from "../../common/ServerAPI";
import toast from "react-hot-toast";
import AddressProofPreview from "./AddressProofPreview";
import { useAppStateAPI } from "../../common/AppStateAPI";
import { APIData } from "../../common/DataTypes";

interface FormParameters {
    showModal: boolean,
    closeModal: any,
    bondID:number,
    bankDetailsId?: any,

}

const BankAccounts: React.FC<FormParameters> = ({ showModal, closeModal,bondID,bankDetailsId }) => {
    const { setShowPreloader } = useAppStateAPI();
    const [bankDetails, setBankDetails] = useState<any[]>([]);
    const [dematType, setDematType] = useState('');

    const getbankAccounts = () => {
        setShowPreloader(true);
        ServerAPI.OrderBankList(bondID).then((response: any) => {
            setBankDetails(response);
        }).finally(() => {
            setShowPreloader(false);
        })
    }

    useEffect(() => {
        if(showModal==true){
            getbankAccounts();
        }
    }, [showModal])

    const maskingFunction = (acno: string) => {
        let subNum = acno.replace(/\d(?=\d{3})/g, "*");
        return (subNum);
    };




    return (
        <div className="main-index-wrapper">
            <main>
                <Dialog
                    open={showModal}
                    keepMounted
                    onClose={closeModal}
                    aria-describedby="alert-dialog-slide-description"
                    scroll="body"
                    maxWidth="lg"
                    fullWidth={true}
                >
                    <DialogTitle>Select Bank Account
                        <button onClick={(e: any) => {
                            e.preventDefault();
                            closeModal();
                        }} className="theme_btn profile_close_btn sub-btn wow "><i className="far fa-times-circle"></i> Close</button>
                    </DialogTitle>
                    <DialogContent className="cancelled-cheque-bg" style={{ overflow: "hidden" }}>
                        <DialogContentText id="alert-dialog-slide-description">
                            <div className="col-xl-12 p-0 d-lg-flex">
                                <div className="col-xl-12 col-12 p-0 row">
                                    {bankDetails != undefined && bankDetails.length > 0 && bankDetails.map((detail: any) => {
                                        return (
                                            <div className="col-xl-6 row">
                                                <div className="col-xl-1">
                                                    <input type="radio" style={{ position: "initial" }} disabled={detail.is_asba!=1?true:false} name="demat_account" onChange={(e: any) => {
                                                        bankDetailsId(detail.bank_details_id);
                                                    }} />
                                                </div>
                                                <div className="col-xl-10">
                                                    <div className={detail.is_asba==1?"bank_card_full mb-3 w-100":"bank_card_full-disabled mb-3 w-100"}>
                                                        <div className={detail.bank_details_status == 1 ? "bank_card_left" : "bank_card_left bank-disabled"}>
                                                            <div className="bank_card_icon">
                                                                <i className="fal fa-piggy-bank"></i>
                                                            </div>
                                                            <div className="bank_card_text">
                                                                <div className="bank_card_text_head">
                                                                    {detail.bank_name}
                                                                </div>
                                                                <p className={detail.bank_details_status == 1 ? "bank_saving_btn" : "bank_saving_btn bank-disabled-primary"}>{detail.account_type_1 == AccountTypeValues1.savings ? "Savings" : "Current"} A/C</p>
                                                                <div className="bank_card_text_num">{maskingFunction(detail.account_number + "")}</div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    })}
                                </div>
                            </div>
                            <div className="col-xl-12 continue_btn_whole" onClick={(e: any) => {
                                e.preventDefault();
                                closeModal();
                            }}>
                                <a className="theme_btn primary_btn_one sub-btn wow " data-wow-delay="0.5s" href="/authenticate-aadhaar-detail">Submit</a>
                            </div>
                        </DialogContentText>
                    </DialogContent>
                </Dialog>
            </main>
        </div>
    )
}

export default BankAccounts;