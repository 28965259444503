import { useEffect, useRef, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useAppStateAPI } from "../common/AppStateAPI";
import { AccountTypeValues1, AccountTypeValues2, kycStatus, PageLinks } from "../common/Constants";
import { Formik, FormikProps } from "formik";
import { APIData } from "../common/DataTypes";
import { InitialData } from "../components/Form/InitialData";
import { group } from "console";
import { ServerAPI } from "../common/ServerAPI";
import toast from "react-hot-toast";
import BankDetailsError from "../components/Modals/BankDetailsError";
import ChequeUpload from "../components/Modals/ChequeUpload";
import StepsComponent from "../components/StepsComponent";
import { kycStatusUpdate } from "../common/Utilities";
import ConfirmationKYCModel from "../components/Modals/ConfirmationKYCModel";


const BankDetails: React.FC = () => {
    const [typeOfAccount, setTypeOfAccount] = useState(AccountTypeValues1.NotSelected);
    const [accountType, setAccountType] = useState(AccountTypeValues2.NotSelected);
    const [bankDetails, setBankDetails] = useState<APIData.bankDetails>(InitialData.BankDetails);
    const [ifscDetails, setIfscDetails] = useState<APIData.IfscDetails>();
    const [userDetail, setUserDetails] = useState<APIData.userDetail>();
    const navigate = useNavigate();
    const location = useLocation();
    const prevData: any = location.state;
    const [openChequePopup, setOpenChequePopup] = useState(false);
    const [openChequeUploadPopup, setOpenChequeUploadPopup] = useState(false);
    const [chequeUrl, setChequeUrl] = useState('');
    const [ifscCode, setIfscCode] = useState('');
    const formikRef = useRef<FormikProps<APIData.bankDetails>>(null);
    const { setShowPreloader } = useAppStateAPI();
    const [KycExit, setKycExit] = useState(false);
    let AccountRedirect = localStorage.getItem('bank');

    const validate = (values: APIData.bankDetails) => {
        let errors: any = {};
        if (!ifscCode.length) {
            errors.ifsc_code = "Ifsc Code is required";
        }
        if (ifscCode.length < 11) {
            errors.ifsc_code = "Please Enter Valid Ifsc Code";
        }
        if (values.account_type_1 == 0) {
            errors.account_type_1 = "Account Type is required";
        }
        if (values.account_type_2 == 0) {
            errors.account_type_2 = "Type of Account is required";
        }
        if (!values.account_number) {
            errors.account_number = "Please Enter Your Account Number";
        }
        if (values.account_number.length < 4) {
            errors.account_number = "Please Enter Valid Account Number";
        }
        if (values.account_number != values.account_number_confirm) {
            errors.account_number_confirm = "Account Number Does Not Match !";
        }
        return errors;
    }


    const ifscCaps = (value: string) => {
        let ifscValue = value.toLocaleUpperCase();
        setIfscCode(ifscValue);
    }

    useEffect(() => {
        if (formikRef.current?.values) {
            if (ifscCode != '') {
                console.log(ifscCode);
                let currValues: APIData.bankDetails = { ...formikRef.current?.values };
                if (currValues) {
                    currValues.ifsc_code = ifscCode;
                    setBankDetails(currValues);
                }
            }
        }
    }, [ifscCode])

    useEffect(() => {
        setShowPreloader(true);
        ServerAPI.userDetailData().then((res: any) => {
            setUserDetails(res);
        }).finally(() => {
            setShowPreloader(false);
        })
    }, [])

    const ifsc = (value: string) => {
        setShowPreloader(true);
        ServerAPI.IfscCode(value).then((response: APIData.IfscDetails) => {
            setIfscDetails(response);
        }).finally(() => {
            setShowPreloader(false);
        })
    }

    return (
        <div className="main-index-wrapper">
            <main>
                {/* Profile-details-edit-area start */}
                <section className="subscribe-letter-area pt-20 pb-20">
                    <div className="line-shape d-none d-lg-inline-block">
                        <img className="img-fluid" src="assets/img/Intersection_4.svg" alt="" />
                    </div>

                    <div className="container custom-container-subs">
                        <div className="sub-bg">
                            <div className="row justify-content-center">
                                <div className="col-xl-12">
                                    <div className="subscribe-wrapper">
                                        <div className={AccountRedirect != 'true' ? "section-title section-title-3 text-center" : "section-title section-title-3 text-center mb-5"}>
                                            <img className="header-logo" src="assets/img/logo/header_logo_one.png" alt="logo" />
                                        </div>
                                    </div>
                                    {AccountRedirect != 'true' &&
                                        <StepsComponent step={2} />
                                    }
                                    <div className="step_box_content">
                                        <div className="sub-bg">
                                            <div className="row justify-content-center">
                                                <div className="col-xl-12">
                                                    <div className="subscribe-wrapper">
                                                        <div className="section-title section-title-3">
                                                            <h6 className="wow " data-wow-delay="0.1s">Bank Details</h6>

                                                            <hr></hr>
                                                            <Formik initialValues={bankDetails} validate={validate} enableReinitialize onSubmit={(values, { setSubmitting }) => {
                                                                if (chequeUrl == '') {
                                                                    localStorage.setItem('animation', 'bank');
                                                                    setShowPreloader(true);
                                                                    ServerAPI.RazorPaybankDetailData(values.ifsc_code, values.account_number, values.account_type_1 + "", values.account_type_2 + "").then((res: any) => {
                                                                        if (res != undefined) {
                                                                            if (res['status']=='failed') {
                                                                                setOpenChequePopup(true);
                                                                            } else if (res == 'bank_details maximum 4 accounts only add') {
                                                                                toast.error(res);
                                                                            } else if (res['message']) {
                                                                                toast.success(res['message']);
                                                                                navigate(PageLinks.BANK_DETAILS_ADDED_SUCCESS);
                                                                            } else {
                                                                                toast.error(res);
                                                                            }

                                                                        }
                                                                    }).finally(() => {
                                                                        setShowPreloader(false);
                                                                        localStorage.setItem('animation', 'default');
                                                                    })
                                                                } else {
                                                                    setShowPreloader(true);
                                                                    ServerAPI.bankDetailData(values.ifsc_code, values.account_number, values.account_type_1 + "", values.account_type_2 + "", chequeUrl).then((res: any) => {
                                                                        if (res != undefined) {
                                                                            toast.success(res.message);
                                                                            toast.success(res['message']);
                                                                            navigate(PageLinks.BANK_DETAILS_ADDED_SUCCESS);
                                                                        }
                                                                    }).finally(() => {
                                                                        setShowPreloader(false);
                                                                    })
                                                                }
                                                            }}>
                                                                {({
                                                                    values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting,
                                                                }) => (
                                                                    <form className="quote-form mb-10" onSubmit={handleSubmit}>
                                                                        <div className="col-xl-12 pl-0 float-left">
                                                                            <h6 className="pt-20 mb-10 " data-wow-delay="0.1s">Hey {userDetail?.pan_name}, Enter Your Bank Details</h6>
                                                                            <p className="personal_sub_text">We will deposit ₹1 in your bank account to verify it.</p>
                                                                            <div className="col-xl-12 pl-0 float-left mt-20 row ms-0">
                                                                                <div className="email-input input_field pl-0 col-xl-3">
                                                                                    <label className={errors.ifsc_code && touched.ifsc_code ? "error" : "input-title"}>IFSC Code *</label>
                                                                                    <input type="text" placeholder="Enter IFSC Code" maxLength={11} name="ifsc_code" value={ifscCode}
                                                                                        onChange={(e: any) => {
                                                                                            ifscCaps(e.target.value);
                                                                                            handleChange(e);
                                                                                        }}
                                                                                        className={errors.ifsc_code && touched.ifsc_code ? "input-error" : ""}
                                                                                    />
                                                                                    {errors.ifsc_code && touched.ifsc_code && (
                                                                                        <span className="error">{errors.ifsc_code}</span>
                                                                                    )}
                                                                                    {/* <p className="ifsc-code-msg"><span className="ifsc-code-popup cursor" onClick={(e: any) => {
                                                                                    e.preventDefault();
                                                                                }}><u>Click here</u></span> for help with the IFSC code.</p> */}
                                                                                </div>
                                                                                <div className="col-xl-1 bank-ifsc-get-div mr-5">
                                                                                    <button className={ifscCode.length == 11 ? "bank-ifsc-get-btn-active" : "bank-ifsc-get-btn"} onClick={(e: any) => {
                                                                                        if (ifscCode.length == 11) {
                                                                                            e.preventDefault();
                                                                                            ifsc(ifscCode);

                                                                                        } else {
                                                                                            e.preventDefault();

                                                                                        }
                                                                                    }}>GET</button>
                                                                                </div>
                                                                                {ifscDetails != undefined &&
                                                                                    <div className="bank-details col-xl-5">
                                                                                        <p>{ifscDetails.BANK}</p>
                                                                                        <table>
                                                                                            <thead>
                                                                                                <tr>
                                                                                                    <th colSpan={2} className="bank-details-table-head">IFSC Code</th>
                                                                                                    <th colSpan={2} className="bank-details-table-head">Branch</th>
                                                                                                    <th colSpan={2} className="bank-details-table-head">Address</th>
                                                                                                </tr>
                                                                                            </thead>
                                                                                            <tbody>
                                                                                                <tr>
                                                                                                    <td colSpan={2} className="bank-details-table-body">{ifscDetails.IFSC}</td>
                                                                                                    <td colSpan={2} className="bank-details-table-body">{ifscDetails.BRANCH}</td>
                                                                                                    <td colSpan={2} className="bank-details-table-body">{ifscDetails.ADDRESS}</td>
                                                                                                </tr>
                                                                                            </tbody>
                                                                                        </table>

                                                                                    </div>
                                                                                }
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-xl-4 p-0 float-left">
                                                                            <div className="email-input input_field pl-0">
                                                                                <label className={errors.ifsc_code && touched.ifsc_code ? "error" : "input-title"}>Bank Account Number *</label>
                                                                                <input type="password" placeholder="Enter Bank Account Number" maxLength={16}
                                                                                    onChange={handleChange}
                                                                                    onBlur={handleBlur} name="account_number" value={values.account_number}
                                                                                    className={errors.account_number && touched.account_number ? "input-error" : ""} />
                                                                                {errors.account_number && touched.account_number && (
                                                                                    <span className="error">{errors.account_number}</span>
                                                                                )}
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-xl-4 p-0 float-left">
                                                                            <div className="email-input input_field pl-0">
                                                                                <label className={errors.ifsc_code && touched.ifsc_code ? "error" : "input-title"}>Re-enter Bank Account Number *</label>
                                                                                <input type="text" maxLength={20} className={errors.account_number_confirm && touched.account_number_confirm ? "input-error" : ""} placeholder="Enter Bank Account Number" onChange={handleChange}
                                                                                    onBlur={handleBlur} name="account_number_confirm" />
                                                                            </div>
                                                                            {errors.account_number_confirm && touched.account_number_confirm && (
                                                                                <span className="error">{errors.account_number_confirm}</span>
                                                                            )}
                                                                        </div>
                                                                        <div className="col-xl-12 p-0 float-left">
                                                                            <div className="choice-list">
                                                                                <span className={errors.account_type_1 && touched.account_type_1 || errors.account_type_2 && touched.account_type_2 ? "error" : "input-title"}>Account Type? *</span>
                                                                                <span className="input-title-filled gender_col" role={"group"}>
                                                                                    <div className={typeOfAccount == AccountTypeValues1.savings ? "gender_btn selected cursor" : "gender_btn cursor"} onClick={(e: any) => {
                                                                                        e.preventDefault();
                                                                                        setTypeOfAccount(AccountTypeValues1.savings);
                                                                                        values.account_type_1 = AccountTypeValues1.savings;
                                                                                    }}>
                                                                                        <input onChange={(e: any) => {
                                                                                            if (e.target.checked) {
                                                                                                setTypeOfAccount(AccountTypeValues1.savings);
                                                                                            }
                                                                                            handleChange(e);
                                                                                        }} onBlur={handleBlur} type="radio" value={1} checked={typeOfAccount == AccountTypeValues1.savings ? true : false} className="d-none" name="account_type_1" id="account_type_1_option_1" />
                                                                                        <label
                                                                                            htmlFor="account_type_1_option_1"
                                                                                            className={typeOfAccount == AccountTypeValues1.savings ? "gender-btn-label-selected" : "gender_btn-label"}
                                                                                        >
                                                                                            Savings
                                                                                        </label>
                                                                                    </div>
                                                                                    <div className={typeOfAccount == AccountTypeValues1.current ? "gender_btn selected cursor" : "gender_btn cursor"} onClick={(e: any) => {
                                                                                        e.preventDefault();
                                                                                        setTypeOfAccount(AccountTypeValues1.current);
                                                                                        values.account_type_1 = AccountTypeValues1.current;
                                                                                    }}>
                                                                                        <input onChange={(e: any) => {
                                                                                            if (e.target.checked) {
                                                                                                setTypeOfAccount(AccountTypeValues1.current);
                                                                                            }
                                                                                            handleChange(e);
                                                                                        }} onBlur={handleBlur} type="radio" value={2} checked={typeOfAccount == AccountTypeValues1.current ? true : false} className="d-none" name="account_type_1" id="account_type_1_option_2" />
                                                                                        <label
                                                                                            htmlFor="account_type_1_option_2"
                                                                                            className={typeOfAccount == AccountTypeValues1.current ? "gender-btn-label-selected" : "gender_btn-label"}
                                                                                        >
                                                                                            Current
                                                                                        </label>
                                                                                    </div>
                                                                                </span>
                                                                                {errors.account_type_1 && touched.account_type_1 && (
                                                                                    <span className="error">{errors.account_type_1}</span>
                                                                                )}
                                                                            </div>
                                                                            <div className="choice-list">
                                                                                <span className="input-title-filled gender_col" role={"group"}>
                                                                                    <div className={accountType == AccountTypeValues2.single ? "gender_btn selected cursor" : "gender_btn cursor"} onClick={(e: any) => {
                                                                                        e.preventDefault();
                                                                                        setAccountType(AccountTypeValues2.single);
                                                                                        values.account_type_2 = AccountTypeValues2.single;
                                                                                    }}>
                                                                                        <input onChange={(e: any) => {
                                                                                            if (e.target.checked) {
                                                                                                setAccountType(AccountTypeValues2.single);
                                                                                            }
                                                                                            handleChange(e);
                                                                                        }} onBlur={handleBlur} value={1} type="radio" checked={accountType == AccountTypeValues2.single ? true : false} className="d-none" name="account_type_2" id="account_type_2_option_1" />
                                                                                        <label
                                                                                            htmlFor="account_type_2_option_1"
                                                                                            className={accountType == AccountTypeValues2.single ? "gender-btn-label-selected cursor" : "gender_btn-label cursor"}
                                                                                        >
                                                                                            Single
                                                                                        </label>
                                                                                    </div>
                                                                                    <div className={accountType == AccountTypeValues2.joint ? "gender_btn selected cursor" : "gender_btn cursor"} onClick={(e: any) => {
                                                                                        e.preventDefault();
                                                                                        setAccountType(AccountTypeValues2.joint);
                                                                                        values.account_type_2 = AccountTypeValues2.joint;
                                                                                    }}>
                                                                                        <input onChange={(e: any) => {
                                                                                            if (e.target.checked) {
                                                                                                setAccountType(AccountTypeValues2.joint);
                                                                                            }
                                                                                            handleChange(e);
                                                                                        }} onBlur={handleBlur} type="radio" value={2} checked={accountType == AccountTypeValues2.joint ? true : false} className="d-none" name="account_type_2" id="account_type_2_option_2" />
                                                                                        <label
                                                                                            htmlFor="account_type_2_option_2"
                                                                                            className={accountType == AccountTypeValues2.joint ? "gender-btn-label-selected" : "gender_btn-label"}
                                                                                        >
                                                                                            Joint
                                                                                        </label>
                                                                                    </div>
                                                                                </span>
                                                                                {errors.account_type_2 && touched.account_type_2 && (
                                                                                    <span className="error">{errors.account_type_2}</span>
                                                                                )}
                                                                            </div>
                                                                            {chequeUrl != '' &&
                                                                                <div className="col-xl-4 p-0 float-left">
                                                                                    <div className="email-input input_field pl-0">
                                                                                        <label className={errors.ifsc_code && touched.ifsc_code ? "error" : "input-title"}>Cancelled Signed Cheque</label>
                                                                                        <input type="text" className="" name="cancel_cheque_image" value={chequeUrl.split('/').pop()} />
                                                                                        <span onClick={(e: any) => {
                                                                                            e.preventDefault();
                                                                                            setOpenChequeUploadPopup(true);
                                                                                        }} className="inline-icon cursor"><i className="far fa-edit"></i></span>
                                                                                    </div>
                                                                                </div>
                                                                            }
                                                                        </div>
                                                                        <div className="col-xl-12 continue_btn_whole mb-3">
                                                                            <button type="submit" className="theme_btn primary_btn_one sub-btn wow " data-wow-delay="0.5s">Continue</button>
                                                                        </div>
                                                                        <div onClick={(e: any) => {
                                                                            e.preventDefault();
                                                                            setKycExit(true);
                                                                        }} className="save_continue_btn text-center cursor"><u>Save & Complete Later</u></div>
                                                                    </form>
                                                                )}
                                                            </Formik>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                {/* Profile-details-edit-area end */}
            </main>
            <BankDetailsError showModal={openChequePopup} closeModal={() => { setOpenChequePopup(false) }} setChequeUrl={setChequeUrl} />
            <ChequeUpload showModal={openChequeUploadPopup} closeModal={() => { setOpenChequeUploadPopup(false) }} setShowModel={() => { setOpenChequePopup(true) }} setChequeUrl={setChequeUrl} />
            <ConfirmationKYCModel showModal = {KycExit} closeModal = {()=>{setKycExit(false)}} confirmtype={'bank'}/>
        </div>
    )
}

export default BankDetails;
