interface FormParameters {
    step: number
}
const StepsComponent: React.FC<FormParameters> = ({ step }) => {
    return (
        <div className="progress-indicator-element mt-3">
            <ul className="progress-indicator">
                <li className="completed">
                    <div className="stacked-text step-component-text">
                        <span className={step >= 1 ? "step-component-active subdued" : "subdued"}><span className="small step-component-text-step">Step 01</span> <br></br> Personal Details</span>
                    </div>
                </li>
                <li className="completed msg_contain">
                    <div className="stacked-text step-component-text">
                        <span className={step >= 2 ? "step-component-active subdued" : " step-component-inactive subdued"}><span className="small step-component-text-step">Step 02</span> <br></br> Bank Details</span>
                    </div>
                    <div className="bse-order-no">
                    </div>
                    <div className="bse-order-msg msg-success">
                    </div>
                </li>
                <li className="completed">
                    <div className="stacked-text step-component-text">
                        <span className={step == 3 ? "step-component-active subdued" : "step-component-inactive subdued"}><span className="small step-component-text-step">Step 03</span> <br></br> Demat Details</span>
                    </div>
                </li>
            </ul>
            <div className='line-container'>
                <div className='progress-line'>
                    {step == 1 &&
                        <div className='progress'></div>
                    }
                    {step == 2 &&
                        <div className='progress w-50'></div>
                    }
                    {step == 3 &&
                        <div className='progress w-100'></div>
                    }

                    <div className='status'>
                        <div className='dot completed'></div>
                    </div>
                    <div className='status'>
                        <div className={step >= 2 ? 'dot completed' : 'dot'}></div>
                    </div>
                    <div className='status'>
                        <div className={step == 3 ? 'dot completed' : 'dot'}></div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default StepsComponent;


