import React, { Fragment, useState } from 'react';
import { styled, alpha } from '@mui/material/styles';
import Button from '@mui/material/Button';
import Menu, { MenuProps } from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';

interface FormParameters {
    review?: boolean;
    rating?: any;
}

const StyledMenu = styled((props: MenuProps) => (
    <Menu
        elevation={0}
        anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
        }}
        transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
        }}
        {...props}
    />
))(({ theme }) => ({
    '& .MuiBackdrop-root': {
        backdropFilter: 'none'
    },
    '& .MuiPaper-root': {
        borderRadius: 6,
        marginTop: theme.spacing(1),
        minWidth: 180,
        color:
            theme.palette.mode === 'light' ? 'rgb(55, 65, 81)' : theme.palette.grey[300],
        boxShadow:
            'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
        '& .MuiMenu-list': {
            padding: '4px 0',
        },
        '& .MuiMenuItem-root': {
            paddingBottom: '5px !important',
            paddingTop: '9px !important',
            backgroundColor: "#22263D",
            borderBottom: "1px solid #fff",


            '& .MuiSvgIcon-root': {
                fontSize: 18,
                color: theme.palette.text.secondary,
                marginRight: theme.spacing(1.5),
            },
            // '&:active': {
            //     backgroundColor: alpha(
            //         theme.palette.primary.main,
            //         theme.palette.action.selectedOpacity,
            //     ),
            // },
            // '&:hover': {
            //     backgroundColor: '#833db2 !important',
            //     color: '#FFF !important'
            // }
        },
    },
}));

const SortBy: React.FC<FormParameters> = ({ review, rating }) => {
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const [selectedSort, setSelectedSort] = useState(0);
    const open = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <Fragment>
            <Button style={{ backgroundColor: "fbf3f8", textTransform: 'lowercase', background: "none", color: "#22263D" }}
                // id="demo-customized-button"
                aria-controls={open ? 'demo-customized-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
                variant="contained"
                disableElevation
                onClick={handleClick}
            >
                <div className="sort_by">Sort By <i id="simple-popover" className="fal fa-sort-alt"></i></div>
                &nbsp;
            </Button>
            <StyledMenu
                id="demo-customized-menu"
                MenuListProps={{
                    'aria-labelledby': 'demo-customized-button',
                }}
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
            >
                <Fragment>
                    <MenuItem id='4' disableRipple>
                        <p style={{ color: "#fff" }}>Invesment</p>
                    </MenuItem>
                    <MenuItem id='4' disableRipple>
                        <div className="gender_btn">
                            <input type="radio" className="d-none" name="account_type_1" id="account_type_1_option_1" value="1" />
                            <label htmlFor="account_type_1_option_1" className="gender_btn-label gender_btn-label-sort">High Investment</label>
                        </div>
                        <div className="gender_btn">
                            <input type="radio" className="d-none" name="account_type_1" id="account_type_1_option_1" value="1" />
                            <label htmlFor="account_type_1_option_1" className="gender_btn-label gender_btn-label-sort">Low Investment</label>
                        </div>
                    </MenuItem>
                    <MenuItem id='4' disableRipple>
                        <p style={{ color: "#fff" }}>ISafety (Credit Rating)</p>
                    </MenuItem>
                    <MenuItem id='4' disableRipple>
                        <div className="gender_btn">
                            <input type="radio" className="d-none" name="account_type_1" id="account_type_1_option_1" value="1" />
                            <label htmlFor="account_type_1_option_1" className="gender_btn-label gender_btn-label-sort">High Safety</label>
                        </div>
                        <div className="gender_btn">
                            <input type="radio" className="d-none" name="account_type_1" id="account_type_1_option_1" value="1" />
                            <label htmlFor="account_type_1_option_1" className="gender_btn-label gender_btn-label-sort">Low Safety</label>
                        </div>
                    </MenuItem>
                    <MenuItem id='4' disableRipple>
                        <p style={{ color: "#fff" }}>Yield</p>
                    </MenuItem>
                    <MenuItem id='4' disableRipple>
                        <div className="gender_btn">
                            <input type="radio" className="d-none" name="account_type_1" id="account_type_1_option_1" value="1" />
                            <label htmlFor="account_type_1_option_1" className="gender_btn-label gender_btn-label-sort">High Yield</label>
                        </div>
                        <div className="gender_btn">
                            <input type="radio" className="d-none" name="account_type_1" id="account_type_1_option_1" value="1" />
                            <label htmlFor="account_type_1_option_1" className="gender_btn-label gender_btn-label-sort">Low Yield</label>
                        </div>
                    </MenuItem>
                    <MenuItem id='4' disableRipple>
                        <p style={{ color: "#fff" }}>Tenure</p>
                    </MenuItem>
                    <MenuItem id='4' disableRipple>
                        <div className="gender_btn">
                            <input type="radio" className="d-none" name="account_type_1" id="account_type_1_option_1" value="1" />
                            <label htmlFor="account_type_1_option_1" className="gender_btn-label gender_btn-label-sort">High Tenure</label>
                        </div>
                        <div className="gender_btn">
                            <input type="radio" className="d-none" name="account_type_1" id="account_type_1_option_1" value="1" />
                            <label htmlFor="account_type_1_option_1" className="gender_btn-label gender_btn-label-sort">Low Tenure</label>
                        </div>
                    </MenuItem>
                    <MenuItem id='4' disableRipple>
                        <div className="spotlight_readmore_box">
                            <a className="trustclearbtn" onClick={(e:any)=>{
                                e.preventDefault();
                                handleClose();
                            }}>Clear All</a>
                            <a className="spotlight_readmore" style={{color:"#fff"}} onClick={(e:any)=>{
                                e.preventDefault();
                                handleClose();
                            }}>Apply Filters</a>
                        </div>
                    </MenuItem>
                </Fragment>
            </StyledMenu>
        </Fragment>
    );
}
export default SortBy;
