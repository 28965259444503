import { Formik } from "formik";
import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { Link, useNavigate } from "react-router-dom";
import { useAppStateAPI } from "../common/AppStateAPI";
import { PageLinks } from "../common/Constants";
import { APIData } from "../common/DataTypes";
import { ServerAPI } from "../common/ServerAPI";
import { InitialData } from "../components/Form/InitialData";



const Signup: React.FC = () => {
    const navigate = useNavigate();
    const [signUpDetail, setSingUpDetail] = useState<APIData.signup>(InitialData.signUp);
    const { setShowPreloader } = useAppStateAPI();

    const validate = (values: APIData.signup) => {
        let errors: any = {};
        if (values.mobile_number == "") {
            errors.mobile_number = "Mobile Number is required";
        }
        if (values.mobile_number.length < 10) {
            errors.mobile_number = "Please Enter Valid Mobile Number";
        }
        if (values.firstName == "") {
            errors.firstName = "FirstName is required";
        }
        if (values.lastName == "") {
            errors.lastName = "lastName is required";
        }
        if (values.agreed == false) {
            errors.agreed = "";
        }
        if (values.terms == false) {
            errors.terms = "";
        }
        if (values.partnerterms == false) {
            errors.partnerterms = "";
        }


        return errors;
    }
    return (
        <div className="main-index-wrapper">
            <main>
                {/* subscribe-letter-area start */}
                <section className="subscribe-letter-area pt-20 pb-20">
                    <div className="line-shape d-none d-lg-inline-block">
                        <img className="img-fluid" src="assets/img/Intersection_4.svg" alt="" />
                    </div>
                    <div className="container custom-container-subs">
                        <div className="sub-bg pr-150 pl-150 pr-md-50 pl-md-50 pr-xs-0 pl-xs-0">
                            <div className="row justify-content-center">
                                <div className="col-xl-11">
                                    <div className="subscribe-wrapper">
                                        <div className="section-title section-title-3 text-center">
                                            <img src="assets/img/logo/header_logo_one.png" className="main_logo" alt="logo" />
                                            <h4 className="sub-title-register wow " data-wow-delay="0.3s">Sign Up</h4>
                                            <Formik initialValues={signUpDetail} validate={validate} enableReinitialize onSubmit={(values, { setSubmitting }) => {
                                                localStorage.setItem('animation', 'default');
                                                setShowPreloader(true);
                                                ServerAPI.Register(values).then((response) => {
                                                    toast.success(response["message"]);
                                                    localStorage.setItem('mobile', values.mobile_number);
                                                    localStorage.setItem('hashkey', response.hash_key);
                                                    localStorage.setItem('loginType', 'signup');
                                                    localStorage.setItem('customerFirstName', values.firstName);
                                                    localStorage.setItem('customerLastName', values.lastName);
                                                    navigate(PageLinks.VERIFY_OTP_LOGIN);
                                                    return;
                                                }).finally(() => {
                                                    setShowPreloader(false);
                                                })
                                            }}>
                                                {({
                                                    values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting,
                                                }) => (

                                                    <form onSubmit={handleSubmit}>
                                                        <div className="login-form quote-form mb-30">
                                                            <div className="email-input input_field pl-0">
                                                                <label className={errors.firstName && touched.firstName ? "error" : "input-title"}>First Name*</label>
                                                                <input type="text" placeholder="Enter First Name"
                                                                    onChange={handleChange}
                                                                    onBlur={handleBlur}
                                                                    name="firstName"
                                                                    value={values.firstName}
                                                                    className={errors.firstName && touched.firstName ? "input-error" : ""} />
                                                                {errors.firstName && touched.firstName && (
                                                                    <span className="error">{errors.firstName}</span>
                                                                )}
                                                            </div>
                                                            <div className="email-input input_field pl-0">
                                                                <label className={errors.lastName && touched.lastName ? "error" : "input-title"}>Last Name *</label>
                                                                <input type="text" placeholder="Enter Last Name"
                                                                    onChange={handleChange}
                                                                    onBlur={handleBlur}
                                                                    name="lastName"
                                                                    value={values.lastName}
                                                                    className={errors.lastName && touched.lastName ? "input-error" : ""} />
                                                                {errors.lastName && touched.lastName && (
                                                                    <span className="error">{errors.lastName}</span>
                                                                )}
                                                            </div>
                                                            <div className="email-input mobile-input input_field pl-0">
                                                                <label className={errors.mobile_number && touched.mobile_number ? "error" : "input-title"}>Mobile Number *</label>
                                                                <input type="text" placeholder="Enter Mobile Number"
                                                                    onChange={handleChange}
                                                                    onBlur={handleBlur}
                                                                    name="mobile_number"
                                                                    value={values.mobile_number}
                                                                    maxLength={10}
                                                                    size={10}
                                                                    className={errors.mobile_number && touched.mobile_number ? "input-error email_input mobile_number" : "email_input mobile_number"} />
                                                                {errors.mobile_number && touched.mobile_number && (
                                                                    <span className="error">{errors.mobile_number}</span>
                                                                )}
                                                                <div className={errors.mobile_number && touched.mobile_number ? "country_box_with_error" : "country_box"}><img src="assets/img/icon/flag.png" /><span className="input-title-mobile"> +91</span></div>
                                                            </div>

                                                        </div>
                                                        <div className="d-flex">
                                                            <input className="signup-conditons signup" type="checkbox" id="signup" name="agreed" onChange={handleChange} onBlur={handleBlur} />&nbsp;&nbsp;
                                                            <label htmlFor="signup" className="m-0">
                                                            <p className="review-text text-left">
                                                                <span> I understand and agree to allow Trust Capital to contact me on the details, I shared via <span className="terms-signup-highlight">Calls, SMSes, E-mails, WhatsApp</span> etc, for any future communication.</span>
                                                            </p>
                                                            </label>
                                                        </div>
                                                        <div className="d-flex">
                                                            <input className="signup-conditons signup" type="checkbox" id="partnerterms" name="partnerterms" onChange={handleChange} onBlur={handleBlur} />&nbsp;&nbsp;
                                                            <label htmlFor="partnerterms" className=" m-0">
                                                                <p className="review-text text-left">
                                                                    I Give Consent To Share My Details With Partner Commpanies, <span className="terms-signup-highlight">“Trust Capital Services India Pvt Ltd”, “Trust Financial Consultancy Services Pvt. Ltd”, “Trust Investment Advisors Pvt. Ltd”.</span>
                                                                </p>
                                                            </label>
                                                        </div>
                                                        <div className="d-flex">
                                                            <input className=" signup" type="checkbox" id="terms" name="terms" onChange={handleChange} onBlur={handleBlur} />&nbsp;&nbsp;
                                                            <label htmlFor="terms" className="m-0">
                                                                <p className="review-text text-left mt-2">
                                                                    I accept the <u className="signup-terms_conditions"><span className="terms-signup-highlight">Terms &amp; Conditions</span></u>
                                                                </p>
                                                            </label>
                                                        </div>
                                                        <div className="email-input mobile-input input_field pl-0 login-btn">
                                                            <button className={(values.agreed != false && values.terms != false && values.firstName != "" && values.lastName != "" && values.mobile_number != "" && values.partnerterms != false) ? "theme_btn btn-change primary_btn_1 sub-btn wow w-48" : "theme_btn primary_btn sub-btn wow w-48"} data-wow-delay="0.5s" type="submit">Continue</button>
                                                        </div>
                                                    </form>

                                                )}
                                            </Formik>
                                            <p className="review-text text-center mt-3">Already have an account ?&nbsp; <Link to={PageLinks.LOGIN}><u className="signup-terms_conditions">Sign in</u></Link></p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                {/* subscribe-letter-area end */}
            </main>
        </div>
    )
}

export default Signup;
