import { Formik } from "formik";
import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { Link, useNavigate } from "react-router-dom";
import { useAppStateAPI } from "../common/AppStateAPI";
import { PageLinks } from "../common/Constants";
import { APIData } from "../common/DataTypes";
import { ServerAPI } from "../common/ServerAPI";
import { InitialData } from "../components/Form/InitialData";



const Login: React.FC = () => {
    const navigate = useNavigate();
    const [useDetails, setUseDetails] = useState<APIData.userDetail>(InitialData.UserDetails);
    const { setShowPreloader } = useAppStateAPI();

    const validate = (values: APIData.userDetail) => {
        let errors: any = {};
        if (values.mobile_number == "") {
            errors.mobile_number = "Mobile Number is required";

        }

        return errors;
    }



    return (
        <div className="main-index-wrapper">
            <main>
                {/* subscribe-letter-area start */}
                <section className="subscribe-letter-area pt-20 pb-20">
                    <div className="line-shape d-none d-lg-inline-block">
                        <img className="img-fluid" src="assets/img/Intersection_4.svg" alt="" />
                    </div>
                    
                    <div className="container custom-container-subs">
                        <div className="sub-bg pr-150 pl-150 pr-md-50 pl-md-50 pr-xs-0 pl-xs-0">
                            <div className="row justify-content-center">
                                <div className="col-xl-11">
                                    <div className="subscribe-wrapper">
                                        <div className="section-title section-title-3 text-center">
                                           <img src="assets/img/logo/header_logo_one.png" className="main_logo" alt="logo" />
                                            <h4 className="sub-title-login wow " data-wow-delay="0.3s">Welcome Back, Sign In</h4>
                                            <Formik initialValues={useDetails} validate={validate} enableReinitialize onSubmit={(values, { setSubmitting }) => {
                                                setShowPreloader(true);
                                                ServerAPI.Login(values.mobile_number).then((res: any) => {
                                                    if (res != undefined) {
                                                        localStorage.setItem('mobile',values.mobile_number);
                                                        localStorage.setItem('hashkey',res.hash_key);
                                                        localStorage.setItem('loginType','login');
                                                        localStorage.setItem('customerFirstName',res.first_name);
                                                        localStorage.setItem('customerLastName',res.last_name);
                                                        toast.success(res['message']);
                                                        navigate(PageLinks.VERIFY_OTP_LOGIN)
                                                    }
                                                }).finally(() => {
                                                    setShowPreloader(false);
                                                })
                                            }}>
                                                {({
                                                    values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting,
                                                }) => (

                                                    <form className="login-form quote-form mb-20" onSubmit={handleSubmit}>
                                                        <div className="email-input mobile-input input_field pl-0">
                                                            <label className={errors.mobile_number && touched.mobile_number ? "error" : "input-title"}>Mobile Number *</label>
                                                            <input type="text" placeholder="Enter Mobile Number"
                                                                onChange={handleChange}
                                                                onBlur={handleBlur}
                                                                name="mobile_number"
                                                                value={values.mobile_number}
                                                                maxLength={10}
                                                                size={10}
                                                                className={errors.mobile_number && touched.mobile_number ? "input-error email_input mobile_number" : "email_input mobile_number"} />
                                                            {errors.mobile_number && touched.mobile_number && (
                                                                <span className="error">{errors.mobile_number}</span>
                                                            )}
                                                            <div className={errors.mobile_number && touched.mobile_number ? "country_box_with_error" : "country_box"}><img src="assets/img/icon/flag.png" /><span className="input-title-mobile"> +91</span></div>
                                                        </div>
                                                        <div className="login_bottom">
                                                            <div className="email-input mobile-input input_field pl-0">
                                                                <button type="submit" className={values.mobile_number.length == 10 ? "theme_btn btn-change primary_btn_1 sub-btn wow w-100" : "theme_btn primary_btn sub-btn wow w-100"} data-wow-delay="0.5s">Sign In</button>
                                                            </div>
                                                            <p className="review-text text-center">Don't have an account?<u className="signup-terms_conditions"> <Link to={PageLinks.SIGNUP}>Register here</Link></u></p>
                                                        </div>
                                                    </form>
                                                )}
                                            </Formik>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                {/* subscribe-letter-area end */}
            </main>
        </div>
    )
}

export default Login;
