import { Formik, FormikProps } from "formik";
import moment from "moment";
import { Fragment, useEffect, useState } from "react";
import DatePicker from "react-date-picker";
import toast from "react-hot-toast";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useAppStateAPI } from "../common/AppStateAPI";
// import { useAppStateAPI } from "../common/AppStateAPI";
import { Gender, kycStatus, PageLinks } from "../common/Constants";
import { APIData } from "../common/DataTypes";
import { ServerAPI } from "../common/ServerAPI";
import { kycStatusUpdate } from "../common/Utilities";
import { InitialData } from "../components/Form/InitialData";
import HeaderLogoContent from "../components/HeaderLogoContent";
import AddressProofPreview from "../components/Modals/AddressProofPreview";
import AddressProofUpload from "../components/Modals/AddressProofUpload";
import ConfirmationKYCModel from "../components/Modals/ConfirmationKYCModel";
import StepsComponent from "../components/StepsComponent";

const AuthenticateAadhaarDetail: React.FC = () => {

    const navigate = useNavigate();
    const [aadharDetails, setAadharDetails] = useState<any>();
    const [addressProof, setAddressProof] = useState<APIData.AddressProof[]>([]);
    const [stateValue, setStateValue] = useState<any[]>([]);
    const [updateAddress, setAddress] = useState(InitialData.updateAadharDetails)
    const [cityValue, setCityValue] = useState('');
    const [userDetail, setUserDetails] = useState<APIData.userDetail>();
    const [state, setState] = useState('');
    const [pincode, setPincode] = useState('');
    const [showAddressUpdate, setShowAddressUpdate] = useState(false);
    const [showAddressProofModel, setShowAddressProofModel] = useState(false);
    const [showAddressProofPreviewModel, setShowAddressProofPreviewModel] = useState(false);
    const [addressProofType, setAddressProofType] = useState('Address Proof');
    const [proofFrontImage, setProofFrontImage] = useState<string>('');
    const [proofRearImage, setProofRearImage] = useState<string>('');
    const [proofUrl, setProofUrl] = useState<string>('');
    const [KycExit, setKycExit] = useState(false);
    const { setShowPreloader } = useAppStateAPI();
    let storeData: any = localStorage.getItem('update_datas');
    let data = JSON.parse(storeData);

    useEffect(() => {
        setShowPreloader(true);
        ServerAPI.AadharDetails().then((response: APIData.AadharDetails) => {
            setAadharDetails(response);
        }).finally(() => {
            setShowPreloader(false);
        })
        if (localStorage.getItem('login') != '0' && localStorage.getItem('login') != undefined && localStorage.getItem('login') != null) {
            setShowPreloader(true);
            ServerAPI.userDetailData().then((res: any) => {
                setUserDetails(res)
            }).finally(() => {
                setShowPreloader(false);
            })
        }
        ServerAPI.State().then((res: any) => {
            setStateValue(res);
        }).finally(() => {
            setShowPreloader(false);
        })
        setShowPreloader(true);
        ServerAPI.AddressProof().then((response: APIData.AddressProof[]) => {
            setAddressProof(response);
        }).finally(() => {
            setShowPreloader(false);
        });
        console.log(userDetail);

    }, []);

    useEffect(() => {
        setShowPreloader(true);
        if (pincode != '') {
            ServerAPI.pincodeSearch(pincode).then((response: any) => {
                setState(response[0]['pincode_state_name']);
                setCityValue(response[0]['pincode_district']);
            }).catch((error: any) => {
                if (error.response) {
                    const errorData: any = error.response.data;
                    if (errorData.errors && errorData.errors.length > 0) {
                        if (errorData.errors) {
                            toast.error(errorData.errors);
                        } else {
                            toast.error(errorData.errors);
                        }
                    }
                }
            }).finally(() => {
                setShowPreloader(false);
            })
        }

    }, [pincode])

    const validate = (values: APIData.updateAadharDetails) => {
        let errors: any = {};
        if (showAddressUpdate == true) {
            if (values.address_proof == "") {
                errors.address_proof = "Address Proof is required";
            }
            if (values.Address_line_1 == "") {
                errors.Address_line_1 = " Address line 1 is required";
            }
            if (values.Address_line_2 == "") {
                errors.Address_line_2 = " Address line 2 is required";
            }
            if (values.pincode == "") {
                errors.pincode = " Pincode is required";
            }
            if (state == "") {
                errors.state = "State is required"
            }
            if (cityValue == "") {
                errors.city = "City is required"
            }
        }
        return errors;

    }



    return (
        <div className="main-index-wrapper">
            <main>
                <section className="subscribe-letter-area pt-20 pb-20">
                    <div className="line-shape d-none d-lg-inline-block">
                        <img className="img-fluid" src="assets/img/Intersection_4.svg" alt="" />
                    </div>
                    <div className="container custom-container-subs">
                        <div className="sub-bg">
                            <div className="row justify-content-center">
                                <div className="col-xl-12">
                                    <HeaderLogoContent />
                                    <StepsComponent step={1} />
                                    <div className="step_box_content">
                                        <div className="sub-bg">
                                            <div className="row justify-content-center">
                                                <div className="col-xl-12">
                                                    <div className="subscribe-wrapper">
                                                        <div className="section-title section-title-3">
                                                            <h6 className="wow page-main-heading" data-wow-delay="0.1s">Personal Details <span className="page-main-heading-steps">STEPS - 1 of 2</span></h6>
                                                            <hr></hr>
                                                            <Formik initialValues={updateAddress} validate={validate} enableReinitialize onSubmit={(values, { setSubmitting }) => {
                                                                let data: any = { ...userDetail };
                                                                if (showAddressUpdate == true) {
                                                                    let proof_back_image = proofRearImage;
                                                                    let proof_front_image = proofFrontImage;
                                                                    data.proof_type = addressProofType;
                                                                    data.proof_front_image = proof_front_image;
                                                                    data.proof_back_image = proof_back_image;
                                                                    data.address_city = cityValue;
                                                                    data.address_state = state;
                                                                    data.address_line_1 = values.Address_line_1;
                                                                    data.address_line_2 = values.Address_line_2;
                                                                    data.address_zip = values.pincode;
                                                                    data.aadhar_number = aadharDetails.aadhar_number;
                                                                    data.aadhar_name = aadharDetails.name;
                                                                    if (aadharDetails.gender == 'M') {
                                                                        data.gender = Gender.Male;
                                                                    } else if (aadharDetails.gender == 'F') {
                                                                        data.gender = Gender.Female;
                                                                    } else {
                                                                        data.gender = Gender.Trans
                                                                    }
                                                                    setShowPreloader(true);
                                                                    ServerAPI.add_profile_details(data).then((res) => {
                                                                        navigate(PageLinks.ACCOUNT_OPENING_PENNY_DROP);
                                                                    }).finally(() => {
                                                                        setShowPreloader(false);
                                                                    })

                                                                } else {
                                                                    data.address_city = aadharDetails.district;
                                                                    data.address_state = aadharDetails.state;
                                                                    data.address_line_1 = aadharDetails.house_no + "," + aadharDetails.street + "," + aadharDetails.village_town_city;
                                                                    data.address_line_2 = aadharDetails.district;
                                                                    data.address_zip = aadharDetails.pincode;
                                                                    data.aadhar_number = aadharDetails.aadhar_number;
                                                                    data.aadhar_name = aadharDetails.name;
                                                                    if (aadharDetails.gender == 'M') {
                                                                        data.gender = Gender.Male;
                                                                    } else if (aadharDetails.gender == 'F') {
                                                                        data.gender = Gender.Female;
                                                                    } else {
                                                                        data.gender = Gender.Trans
                                                                    }
                                                                    setShowPreloader(true);
                                                                    ServerAPI.add_profile_details(data).then((res) => {
                                                                        navigate(PageLinks.ACCOUNT_OPENING_PENNY_DROP);
                                                                    }).finally(() => {
                                                                        setShowPreloader(false);
                                                                    })

                                                                }
                                                            }}>
                                                                {({
                                                                    values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting,
                                                                }) => (

                                                                    <form className="quote-form mb-10" onSubmit={handleSubmit}>
                                                                        <div className="col-xl-12 pl-0 float-left">
                                                                            <h6 className="pt-20 mb--10  pb-10" data-wow-delay="0.1s">Hey {userDetail?.pan_name}, Let’s Authenticate Your Aadhaar</h6>
                                                                        </div>

                                                                        <div className="col-xl-10 pl-0 float-left">
                                                                            <div className="col-xl-6 pl-0 float-left mt-20">
                                                                                <div className="email-input input_field pl-0">
                                                                                    <label className="input-title">Aadhar Number<span className="error-area">*</span></label>
                                                                                    <input type="text" value={aadharDetails?.aadhar_number} disabled />
                                                                                    <span className="verified"><i className="far fa-check-circle"></i></span>
                                                                                </div>
                                                                            </div>
                                                                            <div className="col-xl-12 p-0 float-left">
                                                                                <div className="email-input input_field pl-0">
                                                                                    <label className="input-title">Address (As per Aadhaar)</label>
                                                                                    <textarea rows={4} disabled className="text-field" value={(aadharDetails?.care_of != "null" && aadharDetails?.care_of != null ? (aadharDetails?.care_of) : " " + ",") + "," + "\n" + (aadharDetails?.house_no != "null" && aadharDetails?.house_no != null ? (aadharDetails?.house_no) : " " + ",") + (aadharDetails?.street != null && aadharDetails?.street != "null" ? (aadharDetails?.street) : " " + ",") + "," + "\n" + (aadharDetails?.district != null && aadharDetails?.district != "null" ? aadharDetails?.district : " ") + "," + " - " + (aadharDetails?.pincode != null && aadharDetails?.pincode != "null" ? aadharDetails?.pincode : " ") + "," + "\n" + (aadharDetails?.state != null && aadharDetails?.state != "null" ? aadharDetails?.state : " ") + "."

                                                                                    }></textarea>
                                                                                </div>
                                                                            </div>
                                                                            <div className="col-xl-12 p-0 mt-10 mb-10 float-left"><input type="checkbox" id="signup" className="signup" onChange={(e: any) => {
                                                                                if (e.target.checked) {
                                                                                    setShowAddressUpdate(true);
                                                                                } else {
                                                                                    setShowAddressUpdate(false);
                                                                                }

                                                                            }} /> I wish to update my address</div>
                                                                            {showAddressUpdate &&
                                                                                <form className="quote-form mb-10" >
                                                                                    <Fragment>
                                                                                        <div className="col-xl-6 pl-0 float-left">
                                                                                            <div className="email-input input_field pl-0">
                                                                                                <label className={errors.address_proof && touched.address_proof ? "error" : "input-title"}>Address Proof <span className="error-area">*</span></label>
                                                                                                <select className={errors.address_proof && touched.address_proof ? "input-error" : ""} placeholder="Select Address Proof" onChange={(e: any) => {
                                                                                                    if (e.target.value == 0) {
                                                                                                        setAddressProofType('Address Proof');
                                                                                                    } else {
                                                                                                        setAddressProofType(e.nativeEvent.target[e.target.selectedIndex].text);
                                                                                                    };
                                                                                                }
                                                                                                }>
                                                                                                    <option value={0}>Select</option>
                                                                                                    {addressProof != undefined && addressProof.length > 0 && addressProof.map((proof: APIData.AddressProof, index) => {
                                                                                                        return (
                                                                                                            <option key={index} value={proof.id}>{proof.address_proof}</option>
                                                                                                        )
                                                                                                    })

                                                                                                    }
                                                                                                </select>
                                                                                                {errors.address_proof && touched.address_proof && (
                                                                                                    <span className="error">{errors.address_proof}</span>
                                                                                                )}
                                                                                            </div>
                                                                                        </div>

                                                                                        <div className="col-xl-12 pl-0 float-left">
                                                                                            <p className="error">*Enter your address details exactly as per your document otherwise your application will get rejected.</p>
                                                                                        </div>

                                                                                        <div className="col-xl-12 p-0 float-left mt-20">
                                                                                            <button onClick={(e: any) => {
                                                                                                if (addressProofType == 'Address Proof') {
                                                                                                    e.preventDefault();
                                                                                                } else {
                                                                                                    e.preventDefault();
                                                                                                    setShowAddressProofModel(true);
                                                                                                }
                                                                                            }} className={addressProofType == 'Address Proof' ? "disabled_btn theme_btn btn-change theme_btn btn-change esign_btn sub-btn wow " : "theme_btn btn-change esign_btn sub-btn wow "} data-wow-delay="0.5s">Upload {addressProofType} Scans</button>
                                                                                        </div>

                                                                                        {proofFrontImage && proofRearImage &&
                                                                                            <div className="col-xl-8 p-0 float-left">
                                                                                                <div className="col-xl-6 pl-0 float-left mt-20">
                                                                                                    <div className="email-input input_field pl-0">
                                                                                                        <label className={errors.proof_front_image && touched.proof_front_image ? "error" : "input-title"}>{addressProofType} Frontside Image <span className="error-area">*</span></label>
                                                                                                        <input type="text" name="proof_front_image" value={proofFrontImage.split('/').pop()} onChange={handleChange} />
                                                                                                        <span onClick={(e: any) => {
                                                                                                            e.preventDefault();
                                                                                                            setShowAddressProofPreviewModel(true);
                                                                                                            setProofUrl(proofFrontImage);
                                                                                                        }} className={errors.proof_front_image && touched.proof_front_image ? "input-error edit_input cursor" : "edit_input cursor"}><i className="far fa-edit"></i></span>
                                                                                                    </div>
                                                                                                    {errors.proof_front_image && touched.proof_front_image && (
                                                                                                        <span className="error">{errors.proof_front_image}</span>
                                                                                                    )}
                                                                                                </div>
                                                                                                <div className="col-xl-6 pl-0 float-left mt-20">
                                                                                                    <div className="email-input input_field pl-0">
                                                                                                        <label className={errors.proof_back_image && touched.proof_back_image ? "error" : "input-title"}>{addressProofType} Backside Image <span className="error-area">*</span></label>
                                                                                                        <input type="text" name="proof_back_image" value={proofRearImage.split('/').pop()} onChange={(e) => console.log(e.target.value)}
                                                                                                        />
                                                                                                        <span
                                                                                                            onClick={(e: any) => {
                                                                                                                e.preventDefault();
                                                                                                                setShowAddressProofPreviewModel(true);
                                                                                                                setProofUrl(proofRearImage);
                                                                                                            }}
                                                                                                            className={errors.proof_back_image && touched.proof_back_image ? "input-error edit_input cursor" : "edit_input cursor"}><i className="far fa-edit"></i></span>
                                                                                                    </div>
                                                                                                    {errors.proof_back_image && touched.proof_back_image && (
                                                                                                        <span className="error">{errors.proof_back_image}</span>
                                                                                                    )}
                                                                                                </div>
                                                                                            </div>
                                                                                        }
                                                                                        <div className="col-xl-4 p-0 float-left"></div>
                                                                                        <div className="col-xl-12 pl-0 float-left">
                                                                                            <h6 className="pt-20 mb--10  pb-10" data-wow-delay="0.1s">Enter Address Detail</h6>
                                                                                        </div>
                                                                                        <div className="col-xl-8 p-0 float-left">
                                                                                            <div className="col-xl-6 pl-0 float-left mt-20">
                                                                                                <div className="email-input input_field pl-0">
                                                                                                    <label className={errors.Address_line_1 && touched.Address_line_1 ? "error" : "input-title"}>Address line 1 <span className="error-area">*</span></label>
                                                                                                    <input className={errors.Address_line_1 && touched.Address_line_1 ? "input-error" : ""} type="text" placeholder="Enter Address line 1" name="Address_line_1" onChange={handleChange} />
                                                                                                    {errors.Address_line_1 && touched.Address_line_1 && (
                                                                                                        <span className="error">{errors.Address_line_1}</span>
                                                                                                    )}
                                                                                                </div>
                                                                                            </div>
                                                                                            <div className="col-xl-6 pl-0 float-left mt-20">
                                                                                                <div className="email-input input_field pl-0">
                                                                                                    <label className={errors.Address_line_2 && touched.Address_line_2 ? "error" : "input-title"}>Address line 2 <span className="error-area">*</span></label>
                                                                                                    <input className={errors.Address_line_2 && touched.Address_line_2 ? "input-error" : ""} type="text" placeholder="Enter Address line 2" name="Address_line_2" onChange={handleChange} />
                                                                                                    {errors.Address_line_2 && touched.Address_line_2 && (
                                                                                                        <span className="error">{errors.Address_line_2}</span>
                                                                                                    )}
                                                                                                </div>
                                                                                            </div>
                                                                                            <div className="col-xl-6 pl-0 float-left">
                                                                                                <div className="email-input input_field pl-0">
                                                                                                    <label className={errors.pincode && touched.pincode ? "error" : "input-title"}>Pincode <span className="error-area">*</span></label>
                                                                                                    <input className={errors.pincode && touched.pincode ? "input-error" : ""} type="text" placeholder="Enter Pincode" name="pincode" maxLength={6} onChange={(e: any) => {
                                                                                                        handleChange(e);
                                                                                                    }}

                                                                                                        onBlur={(e: any) => {
                                                                                                            setPincode(e.target.value);
                                                                                                        }} />
                                                                                                    {errors.pincode && touched.pincode && (
                                                                                                        <span className="error">{errors.pincode}</span>
                                                                                                    )}
                                                                                                </div>
                                                                                            </div>
                                                                                            <div className="col-xl-6 pl-0 float-left">
                                                                                                <div className="email-input input_field pl-0">
                                                                                                    <label className={errors.state && touched.state ? "error" : "input-title"}>State <span className="error-area">*</span></label>
                                                                                                    <input className={errors.state && touched.state ? "input-error" : ""} type="text" placeholder="Enter State" name="state" value={state} onChange={handleChange} />
                                                                                                    {errors.state && touched.state && (
                                                                                                        <span className="error">{errors.state}</span>
                                                                                                    )}
                                                                                                </div>
                                                                                            </div>
                                                                                            <div className="col-xl-6 pl-0 float-left">
                                                                                                <div className="email-input input_field pl-0">
                                                                                                    <label className={errors.city && touched.city ? "error" : "input-title"}>City <span className="error-area">*</span></label>
                                                                                                    <input className={errors.city && touched.city ? "input-error" : ""} type="text" value={cityValue} placeholder="Enter City" name="city" onChange={handleChange} />
                                                                                                    {errors.city && touched.city && (
                                                                                                        <span className="error">{errors.city}</span>
                                                                                                    )}
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className="col-xl-4 p-0 float-left">

                                                                                        </div>
                                                                                        <AddressProofUpload showModal={showAddressProofModel} setShowModel={() => { setShowAddressProofModel(true) }} closeModal={() => { setShowAddressProofModel(false) }} proofType={addressProofType} setProofFrontImage={setProofFrontImage} setProofRearImage={setProofRearImage} />
                                                                                        <AddressProofPreview showModal={showAddressProofPreviewModel} closeModal={() => { setShowAddressProofPreviewModel(false) }} url={proofUrl} setShowUploadModel={() => { setShowAddressProofModel(true) }} />
                                                                                    </Fragment>
                                                                                </form>

                                                                            }
                                                                        </div>
                                                                        <div className="col-xl-12 continue_btn_whole mb-3">
                                                                            <button type="submit" className="theme_btn primary_btn_one sub-btn wow w-auto" data-wow-delay="0.5s">Continue</button>
                                                                        </div>
                                                                        <div onClick={(e: any) => {
                                                                            e.preventDefault();
                                                                            setKycExit(true);
                                                                        }} className="save_continue_btn text-center cursor"><u>Save & Complete Later</u></div>
                                                                    </form>
                                                                )}
                                                            </Formik>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <ConfirmationKYCModel showModal={KycExit} closeModal={() => { setKycExit(false) }} confirmtype={'aadhar'} />
                {/* Profile-details-edit-area end */}
            </main>
        </div >
    )
}

export default AuthenticateAadhaarDetail;
