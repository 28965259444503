import React, { useRef, useEffect, useState } from "react";
import toast from "react-hot-toast";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useAppStateAPI } from "../common/AppStateAPI";
import { kycStatus, PageLinks } from "../common/Constants";
import { APIData } from "../common/DataTypes";
import { ServerAPI } from "../common/ServerAPI";
import StepsComponent from "../components/StepsComponent";
import { useRecordWebcam, RecorderOptions, CAMERA_STATUS } from "react-record-webcam";
import type { RecordWebcamOptions, RecordWebcamHook } from "react-record-webcam";
import PhotoExample from "../components/Modals/PhotoExample";
import HeaderLogoContent from "../components/HeaderLogoContent";
import Countdown from 'react-countdown';
import { kycStatusUpdate } from "../common/Utilities";
import ConfirmationKYCModel from "../components/Modals/ConfirmationKYCModel";


const VerifyYourIdentity: React.FC = () => {

    const navigate = useNavigate();
    const [userDetail, setUserDetails] = useState<APIData.userDetail>();
    const [videoPlaying, setVideoPlaying] = useState(false);
    const [showSample, setShowSample] = useState(false);
    const [timerStart, setTimerStart] = useState(false);
    const [KycExit, setKycExit] = useState(false);

    const { setShowPreloader } = useAppStateAPI();
    const OPTIONS: RecordWebcamOptions = {
        filename: "test-filename",
        fileType: "mp4",
        recordingLength: 15,
        width: 100,
        height: 100
    };

    useEffect(() => {
        setShowPreloader(true);
        ServerAPI.userDetailData().then((res: any) => {
            setUserDetails(res)
        }).finally(() => {
            setShowPreloader(false);
        })
    }, [])
    const recordWebcam: RecordWebcamHook = useRecordWebcam(OPTIONS);

    const getRecordingFileHooks = async () => {
        localStorage.setItem('animation', 'identity');
        const blob = await recordWebcam.getRecording();
        if (blob != undefined && blob != null) {
            setShowPreloader(true);
            ServerAPI.upload_vidio(blob).then((res: any) => {
                let data: any = { ...userDetail };
                data.mediaBlob = blob;
                data.verification_video = res['video'];
                data.in_person_verification = 1;
                ServerAPI.add_profile_details(data).then((res) => {
                    navigate(PageLinks.VERIFY_YOUR_IDENTITY_SUCCESS);
                }).finally(() => {
                    localStorage.setItem('animation', 'default');
                    setShowPreloader(false);
                })
            })
        } else {
            toast.error("Please Capture Video to Proceed");
        }
        console.log({ blob });
    };


    const [codeNumber, setCodeNumber] = useState<string>('')

    useEffect(() => {
        setShowPreloader(true);
        ServerAPI.Verify_code().then((res) => {
            setCodeNumber(res.random_number)
        }).finally(() => {
            setShowPreloader(false);
        })
    }, []);



    return (
        <div className="main-index-wrapper">
            <main>
                <section className="subscribe-letter-area pt-20 pb-20">
                    <div className="line-shape d-none d-lg-inline-block">
                        <img className="img-fluid" src="assets/img/Intersection_4.svg" alt="" />
                    </div>

                    <div className="container custom-container-subs">
                        <div className="sub-bg">
                            <div className="row justify-content-center">
                                <div className="col-xl-12">
                                    <HeaderLogoContent />
                                    <StepsComponent step={1} />
                                    <div className="step_box_content">
                                        <div className="sub-bg">
                                            <div className="row justify-content-center">
                                                <div className="col-xl-12">
                                                    <div className="subscribe-wrapper">
                                                        <div className="section-title section-title-3 identity_profile">
                                                            <h6 className="wow " data-wow-delay="0.1s">Personal Details <span className="step_count">STEPS - 2 of 2</span></h6>

                                                            <hr></hr>
                                                            <div className="row">
                                                                <div className="col-lg-6">
                                                                    <div className="col-xl-12 pl-0 float-left mb-3 pb-2">
                                                                        <h6 className="pt-20 mb--10  personal_sub_head" data-wow-delay="0.1s">Verify Your Identity</h6>
                                                                        <p className="personal_sub_text">We will now verify your identity via photo verification as required by SEBI.</p>
                                                                    </div>
                                                                    <div className="identity_photo_whole col-xl-12">
                                                                        <div className="col-xl-10 ml-5 mr-5">
                                                                            <div className={recordWebcam.status == "CLOSED" ? "identity_photo_single mt-2 mb-2" : "identity_photo_single_start mt-1"}>
                                                                                {(recordWebcam.status === CAMERA_STATUS.CLOSED || recordWebcam.status === CAMERA_STATUS.INIT) &&
                                                                                    <img src="../assets/img/icon/user_icon.png" />
                                                                                }
                                                                                <video
                                                                                    controlsList=""
                                                                                    ref={recordWebcam.webcamRef}
                                                                                    width={'auto'}
                                                                                    height={'auto'}
                                                                                    style={{
                                                                                        display: `${recordWebcam.status === CAMERA_STATUS.OPEN ||
                                                                                            recordWebcam.status === CAMERA_STATUS.RECORDING
                                                                                            ? "block"
                                                                                            : "none"
                                                                                            }`,
                                                                                        borderRadius: '8px',
                                                                                        width: '100%'

                                                                                    }}
                                                                                    autoPlay
                                                                                />

                                                                                <div className="wrapper">
                                                                                    <video
                                                                                        id="video"
                                                                                        controls={false}
                                                                                        onClick={(e: any) => {
                                                                                            if (e.target.paused) {
                                                                                                e.target.play();
                                                                                                setVideoPlaying(true);
                                                                                            } else {
                                                                                                e.target.pause();
                                                                                                setVideoPlaying(false);
                                                                                            }
                                                                                        }}
                                                                                        ref={recordWebcam.previewRef}
                                                                                        width={'auto'}
                                                                                        height={'auto'}
                                                                                        style={{
                                                                                            display: `${recordWebcam.status === CAMERA_STATUS.PREVIEW ? "block" : "none"
                                                                                                }`,
                                                                                            borderRadius: '8px',
                                                                                            width: "100%"
                                                                                        }}
                                                                                    />
                                                                                    {recordWebcam.status === CAMERA_STATUS.PREVIEW && !videoPlaying &&
                                                                                        <div className="playButton"></div>
                                                                                    }
                                                                                </div>
                                                                            </div>
                                                                            {recordWebcam.status === CAMERA_STATUS.RECORDING &&
                                                                                <Countdown autoStart={timerStart} date={Date.now() + 15000} />}
                                                                        </div>
                                                                    </div>

                                                                    <div className="capture_btn_full">

                                                                        {recordWebcam.status == 'CLOSED' &&
                                                                            <div className="capture_btn mt-4">
                                                                                <button
                                                                                    type="button"
                                                                                    className="capture-btn"
                                                                                    onClick={recordWebcam.open}
                                                                                >
                                                                                    Capture
                                                                                </button>
                                                                            </div>
                                                                        }
                                                                        {recordWebcam.status == 'OPEN' &&
                                                                            <div className="capture-btn-start">
                                                                                <button
                                                                                    className="start-recording-btn"
                                                                                    type="button"
                                                                                    onClick={() => {
                                                                                        recordWebcam.start();
                                                                                        setTimerStart(true);
                                                                                    }}
                                                                                >
                                                                                    Start Recording
                                                                                </button>
                                                                            </div>
                                                                        }
                                                                        {recordWebcam.status == 'RECORDING' &&
                                                                            <div className="capture-btn-start">
                                                                                <button
                                                                                    type="button"
                                                                                    className="stop-recording-btn"
                                                                                    onClick={recordWebcam.stop}
                                                                                >
                                                                                    Stop recording
                                                                                </button>
                                                                            </div>
                                                                        }
                                                                        {recordWebcam.status == 'PREVIEW' &&
                                                                            <div className="capture-btn-start">
                                                                                <button className="capture-btn-retake" onClick={(e: any) => { e.preventDefault(); recordWebcam.retake(); }}>Retake</button>
                                                                            </div>
                                                                        }


                                                                    </div>
                                                                    <div className="col-xl-12 continue_btn_whole mt-3" >
                                                                        <a onClick={getRecordingFileHooks} className="theme_btn primary_btn_one sub-btn wow " data-wow-delay="0.5s">Continue</a>
                                                                    </div>
                                                                    <div onClick={(e: any) => {
                                                                        e.preventDefault();
                                                                        setKycExit(true);
                                                                    }} className="save_continue_btn text-center cursor mt-3"><u>Save & Complete Later</u></div>
                                                                </div>


                                                                <div className="col-lg-6 verify-your-identity-right-main">
                                                                    <div className="mx-5 py-5">
                                                                        <p className="text-center ipv-right-head"><b> Instruction for IPV</b></p>
                                                                        <div className="my-3">
                                                                            <p className="verify-your-identity-instruction">
                                                                                Write down this code on blank paper and keep it ready. Once you start<br /> recording, you will have to show the paper. Speak your name <br />and read the code.<span className="instruc_img_colored_text text-style" onClick={(e: any) => {
                                                                                    e.preventDefault();
                                                                                }}>see example below</span>
                                                                            </p>
                                                                            <p className="text-center mt-5">Speak this While Holding Your Code</p>
                                                                            <div className="mt-3 verify-your-identity-right-content mx-5">
                                                                                <p className="text-center verify-your-identity-right-content-text">My Name is <b>{userDetail?.pan_name}</b></p>
                                                                                <p className="text-center verify-your-identity-right-content-text">And My Code <b>{codeNumber}</b></p>
                                                                                <div className="d-flex">
                                                                                    <img className="ml-5" style={{ width: "72%" }} src="../assets/img/vyi.png" />
                                                                                    <span className="centered-vyi">{codeNumber}</span>
                                                                                </div>
                                                                            </div>
                                                                        </div>

                                                                        <div className="mt-5">
                                                                            {/* <p className="camara-text "> */}
                                                                            <p className="verify-your-identity-instruction">Tips for taking a good Video / Photograph / Mug shot</p>

                                                                            {/* </p> */}
                                                                        </div>
                                                                        <div className="instruc_img_whole">
                                                                            <div className="instruc_img_box">
                                                                                <div className="instruc_img_icon">
                                                                                    <img src="assets/img/icon/Light.png" />
                                                                                </div>
                                                                                <div className="instruc_img_text">Good Light</div>
                                                                            </div>
                                                                            <div className="instruc_img_box">
                                                                                <div className="instruc_img_icon">
                                                                                    <img src="assets/img/icon/Cam.png" />
                                                                                </div>
                                                                                <div className="instruc_img_text">Look Into Webcam</div>
                                                                            </div>
                                                                            <div className="instruc_img_box">
                                                                                <div className="instruc_img_icon">
                                                                                    <img src="assets/img/icon/Sunglases.png" />
                                                                                </div>
                                                                                <div className="instruc_img_text instruc_img_colored_text">No Specs Or Sunglases</div>
                                                                            </div>
                                                                            <div className="instruc_img_box">
                                                                                <div className="instruc_img_icon">
                                                                                    <img src="assets/img/icon/Hats.png" />
                                                                                </div>
                                                                                <div className="instruc_img_text instruc_img_colored_text">No Caps / Hats</div>
                                                                            </div>
                                                                        </div>
                                                                        <div className="instruc_note instruc_img_colored_text camara-text verify-your-identity-instruction">Note: Keep your face within this circle only. if the face is not visible properly then your application might get rejected.</div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <PhotoExample showModal={showSample} closeModal={() => { setShowSample(false) }} />
                <ConfirmationKYCModel showModal={KycExit} closeModal={() => { setKycExit(false) }} confirmtype={'ipv'} />
            </main>
        </div>
    )
}

export default VerifyYourIdentity;

