import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import { PageLinks } from "../../common/Constants";
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { ServerAPI } from "../../common/ServerAPI";
import toast from "react-hot-toast";
import AddressProofPreview from "./AddressProofPreview";
import { useAppStateAPI } from "../../common/AppStateAPI";

interface FormParameters {
    showModal: boolean,
    closeModal: any,
    setShowModel?: any,
    proofType: string,
    setProofFrontImage: any,
    setProofRearImage: any

}

const AddressProofUpload: React.FC<FormParameters> = ({ showModal, closeModal, setShowModel, proofType, setProofFrontImage, setProofRearImage }) => {
    const [proofFrontImage, setProofFront] = useState<string>('');
    const [proofRearImage, setProofRear] = useState<string>('');
    const [proofSide, setProofSide] = useState<string>('');
    const [proofUrl, setProofUrl] = useState<string>('');
    const [openProofPreviewPopup, setOpenProofPreviewPopup] = useState(false);
    const { setShowPreloader } = useAppStateAPI();


    useEffect(() => {
        if (showModal == true) {
            setProofFrontImage('');
            setProofRearImage('');
        }
    }, [])

    useEffect(() => {
        if (showModal == true && proofSide == 'front') {
            setProofFront('');
        } else if (showModal == true && proofSide == 'rear') {
            setProofRear('');
        }
    }, [showModal])


    const loadUploadedfile = (fileInput: any, imageSide: string) => {
        let filetype = fileInput.files[0].type;
        filetype = filetype.split("/")[0];
        if (filetype == 'image') {
            setShowPreloader(true);
            if (imageSide == 'front') {
                ServerAPI.proofImageUpload(proofType, fileInput.files[0]).then(response => {
                    if (response['front-image'] != undefined && response['front-image'] != "") {
                        setProofFront(response['front-image']);
                        toast.success('Image Uploaded Successfully');
                    }
                }).finally(() => {
                    setShowPreloader(false);
                });
            } else if (imageSide == 'rear') {
                ServerAPI.proofImageUpload(proofType, '', fileInput.files[0]).then(response => {
                    if (response['back-image'] != undefined && response['back-image'] != "") {
                        setProofRear(response['back-image']);
                        toast.success('Image Uploaded Successfully');
                    }
                }).finally(() => {
                    setShowPreloader(false);
                });
            } else {
                toast.error('unable to upload the image please try again!');
            }
        } else {
            toast.error("Uploaded file is not an Image!");
        }
    }

    return (
        <div className="main-index-wrapper">
            <main>
                <Dialog
                    open={showModal}
                    keepMounted
                    onClose={closeModal}
                    aria-describedby="alert-dialog-slide-description"
                    scroll="body"
                    maxWidth="lg"
                >
                    <DialogTitle> Upload {proofType} Scans
                        <button onClick={(e: any) => {
                            e.preventDefault();
                            closeModal();
                        }} className="theme_btn profile_close_btn sub-btn wow "><i className="far fa-times-circle"></i> Close</button>
                    </DialogTitle>
                    <DialogContent className="cancelled-cheque-bg" style={{ overflow: "hidden" }}>
                        <DialogContentText id="alert-dialog-slide-description">
                            <div className="col-xl-12">
                                <div className="subscribe-wrapper">
                                    <div className="section-title section-title-3">
                                        <div className="signature_preview_box_whole">
                                            <div className="signature_preview_box">
                                                <div className="signature_preview_box_head">{proofType} FrontSide Preview</div>
                                                <div className="signature_preview_box_content mt-80">
                                                    <div className="upload_img cursor">
                                                        <label htmlFor="front">
                                                            <img className="cursor"
                                                                onClick={(e: any) => {
                                                                    if (proofFrontImage != "") {
                                                                        e.preventDefault();
                                                                        closeModal();
                                                                        setProofSide('front');
                                                                        setProofUrl(proofFrontImage);
                                                                        setOpenProofPreviewPopup(true);
                                                                    }
                                                                }}
                                                                src={proofFrontImage != undefined && proofFrontImage != undefined && proofFrontImage != "" ? proofFrontImage : "assets/img/icon/Upload.png"} />
                                                        </label>
                                                        <input
                                                            type="file"
                                                            id="front"
                                                            hidden
                                                            name="front_image"
                                                            onChange={(e: any) => {
                                                                loadUploadedfile(e.target, 'front');
                                                            }}
                                                        />
                                                    </div>
                                                    <p className="upload_img_text">Upload your {proofType} FrontSide</p>
                                                    {proofFrontImage != "" &&
                                                        <div className="replace-btn-address-proof" >
                                                            <label htmlFor="front">Replace</label></div>
                                                    }
                                                    <br />
                                                    <p className="signature_preview_box_content_text_one">upload your {proofType}  <Link to={'#'}>JPG</Link>, <Link to={'#'}>JPEG</Link> or PNG in less than <Link to={'#'}>10 MB</Link></p>
                                                </div>
                                            </div>
                                            <div className="signature_preview_box">
                                                <div className="signature_preview_box_head">{proofType} backside Preview</div>
                                                <div className="signature_preview_box_content mt-80">
                                                    <div className="upload_img cursor">
                                                        <label htmlFor="back">
                                                            <img className="cursor"
                                                                onClick={(e: any) => {
                                                                    if (proofRearImage != "") {
                                                                        e.preventDefault();
                                                                        closeModal();
                                                                        setProofSide('rear');
                                                                        setProofUrl(proofRearImage);
                                                                        setOpenProofPreviewPopup(true);
                                                                    }
                                                                }}
                                                                src={proofRearImage != undefined && proofRearImage != undefined && proofRearImage != "" ? proofRearImage : "assets/img/icon/Upload.png"} />
                                                        </label>

                                                        <input
                                                            type="file"
                                                            id="back"
                                                            hidden
                                                            name="back_image"
                                                            onChange={(e: any) => {
                                                                loadUploadedfile(e.target, 'rear');
                                                            }}
                                                        />
                                                    </div>
                                                    <p className="upload_img_text">Upload your {proofType} backside</p>
                                                    {proofRearImage != "" &&
                                                        <div className="replace-btn-address-proof" >
                                                            <label htmlFor="back">Replace</label></div>
                                                    }
                                                    <br />
                                                    <p className="signature_preview_box_content_text_one">upload your {proofType}  <Link to={'#'}>JPG</Link>, <Link to={'#'}>JPEG</Link> or PNG in less than <Link to={'#'}>10 MB</Link></p>
                                                </div>
                                            </div>
                                        </div>
                                        <p className="personal_sub_text">*TIP: Scan or take a photo of your {proofType} on the white-coloured background to avoid rejection</p>
                                        <div className="col-xl-12 continue_btn_whole" onClick={(e: any) => {
                                            if (proofFrontImage != '' && proofRearImage != '') {
                                                e.preventDefault();
                                                setProofFrontImage(proofFrontImage);
                                                setProofRearImage(proofRearImage);
                                                closeModal();
                                            } else {
                                                e.preventDefault();
                                                toast.error("Please Upload Both Side of the Address Proof");
                                            }
                                        }}>
                                            <a className="theme_btn primary_btn_one sub-btn wow " data-wow-delay="0.5s" href="/authenticate-aadhaar-detail">Submit</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </DialogContentText>
                    </DialogContent>
                </Dialog>
                <AddressProofPreview showModal={openProofPreviewPopup} closeModal={() => { setOpenProofPreviewPopup(false) }} url={proofUrl} setShowUploadModel={setShowModel} />
            </main>
        </div>
    )
}

export default AddressProofUpload;