import moment from "moment";
import { ServerAPI } from "./ServerAPI";

export const LoginDetails = (customerid: string, customerFirstName: string, customerLastName: string, refreshToken: string, token: string, mobile_number: string, last_login: string) => {
  localStorage.setItem('login', customerid ? '1' : '0');
  localStorage.setItem('token', token ? token : '');
  localStorage.setItem('customerid', customerid ? customerid + "" : '');
  localStorage.setItem('customerFirstName', customerFirstName ? customerFirstName : '');
  localStorage.setItem('customerLastName', customerLastName ? customerLastName : '');
  localStorage.setItem('refresh_token', refreshToken ? refreshToken : '');
  localStorage.setItem('customer_mobile_number', mobile_number ? mobile_number : "");
  localStorage.setItem('last_login', last_login ? last_login : "");
}

export const getFormattedLocalDateTime = (utcDateTime: string) => {
  if (utcDateTime && utcDateTime != '') {
    return moment(utcDateTime).format('DD MMM YYYY hh:mm a');
  }
  return 'N/A';
}

export const kycStatusUpdate = (status: number) => {
  ServerAPI.ProgressBarStatus(status).then((response) => {
    console.log(response);
  })

}

export const ClearLocalStorageData = () =>{
  localStorage.removeItem('token');
  localStorage.removeItem('customerid');
  localStorage.removeItem('customerFirstName');
  localStorage.removeItem('customerLastName');
  localStorage.removeItem('refresh_token');
  localStorage.removeItem('customer_mobile_number');
  localStorage.removeItem('last_login');
  localStorage.setItem('login','0');
}

export const numberWithCommas = (x: any) => {
  if (x != "" && x != null) {
    var res = parseInt(x).toLocaleString("en-IN");
    return res;
  } else {
    return 0;
  }
}

export const getAuthString = () => {
  let token = localStorage.getItem('token');
  if (token == null) {
      token = "";
  }
  return token;
}

