import { Formik } from "formik";
import moment from "moment";
import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { kycStatus, PageLinks } from "../common/Constants";
import { APIData } from "../common/DataTypes";
import { ServerAPI } from "../common/ServerAPI";
import { InitialData } from "../components/Form/InitialData";
import DatePicker from 'react-date-picker';
import PoliticallyExposed from "../components/Modals/PoliticallyExposed";
import StepsComponent from "../components/StepsComponent";
import { useAppStateAPI } from "../common/AppStateAPI";
import HeaderLogoContent from "../components/HeaderLogoContent";
import { kycStatusUpdate } from "../common/Utilities";
import ConfirmationKYCModel from "../components/Modals/ConfirmationKYCModel";
const maxDate = moment(new Date()).format('YYYY-MM-DD');
const PersonalDetailsEdit: React.FC = () => {
    const navigate = useNavigate();
    const [userDetail, setUserDetails] = useState<APIData.userDetail>(InitialData.UserDetails);
    const [politicallyExposed, setPoliticallyExposed] = useState(false);
    const [agree1, setAgree1] = useState(false);
    const [agree2, setAgree2] = useState(false);
    const [KycExit, setKycExit] = useState(false);
    const { setShowPreloader } = useAppStateAPI();


    useEffect(() => {
        setShowPreloader(true);
        ServerAPI.userDetailData().then((res: APIData.userDetail) => {
            setUserDetails(res);
        }).finally(() => {
            setShowPreloader(false);
        })
    }, []);


    const validate = (values: APIData.userDetail) => {
        console.log(values);
        let errors: any = {};
        if (values.firstname == '' || values.firstname == null) {
            errors.firstname = "First Name is Required";
        }
        if (values.lastname == '' || values.lastname == null) {
            errors.lastname = "Last Name is Required";
        }
        if (values.dob == '') {
            errors.dob = "Please Enter Valid Date of Birth";
        }
        let agelimitvalidate = agelimit(new Date(values.dob));
        if (agelimitvalidate == false) {
            errors.dob = "To open an account the user should be at-least 18 years of age";
        }
        if (agree1 == false) {
            errors.check_box_share_data_with_company = "Please check this box if you want to proceed.";
        }
        if (agree2 == false) {
            errors.check_box_share_data_with_govt = "Please check this box if you want to proceed.";
        }
        return errors;
    }

    const agelimit = (dob: Date) => {
        const date18YrsAgo = new Date();
        date18YrsAgo.setFullYear(date18YrsAgo.getFullYear() - 18);
        return dob <= date18YrsAgo;
    }

    return (
        <div className="main-index-wrapper">
            <main>
                {/* Profile-details-edit-area start */}
                <section className="subscribe-letter-area pt-20 pb-20">
                    <div className="line-shape d-none d-lg-inline-block">
                        <img className="img-fluid" src="assets/img/Intersection_4.svg" alt="" />
                    </div>

                    <div className="container custom-container-subs">
                        <div className="sub-bg">
                            <div className="row justify-content-center">
                                <div className="col-xl-12">
                                    <HeaderLogoContent />
                                    <StepsComponent step={1} />
                                    <div className="step_box_content">
                                        <div className="sub-bg">
                                            <div className="row justify-content-center">
                                                <div className="col-xl-12">
                                                    <div className="subscribe-wrapper">
                                                        <div className="section-title section-title-3">
                                                            <h6 className="wow page-main-heading" data-wow-delay="0.1s">Personal Details <span className="page-main-heading-steps">STEPS - 1 of 2</span></h6>

                                                            <hr></hr>
                                                            <Formik initialValues={userDetail} validate={validate} enableReinitialize onSubmit={(values, { setSubmitting }) => {
                                                                setShowPreloader(true);
                                                                let profiledetails = { ...values };
                                                                profiledetails.is_politically_exposed = 0;
                                                                ServerAPI.add_profile_details(profiledetails).then((res: APIData.userDetail) => {
                                                                    if (res != undefined && userDetail.is_email_verified == 1) {
                                                                        navigate(PageLinks.PROFILE_PERSONAL_DETAILS_EDIT_EMAIL_VERIFIED);
                                                                    }
                                                                    else if (res != undefined && userDetail.is_email_verified == 0) {
                                                                        navigate(PageLinks.PROFILE_PERSONAL_DETAILS_EDIT_VERIFY_EMAIL);
                                                                    }
                                                                }).finally(() => {
                                                                    setShowPreloader(false);
                                                                })
                                                            }}>
                                                                {({
                                                                    values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting,
                                                                }) => (

                                                                    <form className="quote-form mb-10" onSubmit={handleSubmit}>
                                                                        <div className="col-xl-8 pl-0 float-left">
                                                                            <div className="col-xl-6 pl-0 float-left">
                                                                                <div className="email-input input_field pl-0">
                                                                                    <label className={errors.firstname && touched.firstname ? "error" : "input-title"}>First Name *</label>
                                                                                    <input type="text" placeholder="Enter First Name"
                                                                                        onChange={handleChange}
                                                                                        onBlur={handleBlur}
                                                                                        name="firstname"
                                                                                        value={values.firstname}
                                                                                        className={errors.firstname && touched.firstname ? "input-error" : ""} />
                                                                                    {errors.firstname && touched.firstname && (
                                                                                        <span className="error">{errors.firstname}</span>
                                                                                    )}
                                                                                </div>

                                                                            </div>
                                                                            <div className="col-xl-6 pl-0 float-left">
                                                                                <div className="email-input input_field pl-0">
                                                                                    <label className={errors.lastname && touched.lastname ? "error" : "input-title"}>Last Name <span className="error-area">* &nbsp;</span>
                                                                                        {errors.lastname && touched.lastname && (
                                                                                            <span className="error">({errors.lastname})</span>
                                                                                        )}</label>
                                                                                    <input type="text" className={errors.lastname && touched.lastname ? "input-error" : ""} placeholder="Enter Last Name" name="lastname" value={values.lastname} onChange={handleChange}
                                                                                        onBlur={handleBlur} />

                                                                                    {errors.lastname && touched.lastname && (
                                                                                        <span className="error">{errors.lastname}</span>
                                                                                    )}
                                                                                </div>
                                                                            </div>
                                                                            <div className="col-xl-6 pl-0 float-left">
                                                                                <div className="email-input mobile-input input_field pl-0">
                                                                                    <label className="input-title">Mobile Number <span className="error-area">*</span></label>
                                                                                    <input type="text" className="email_input mobile_number pe-4" placeholder="Enter Mobile Number" name="mobile_number" value={values.mobile_number} readOnly />
                                                                                    {values.is_mobile_verified && values.mobile_number.length == 10 ?
                                                                                        <span className="verified"><i className="far fa-check-circle"></i></span>
                                                                                        :
                                                                                        <span></span>
                                                                                    }
                                                                                    <div className={errors.mobile_number && touched.mobile_number ? "country_box_with_error" : "country_box"}><img src="assets/img/icon/flag.png" /><span className="input-title-mobile"> +91</span>&nbsp;&nbsp;&nbsp;</div>
                                                                                </div>
                                                                            </div>
                                                                            <div className="col-xl-6 pl-0 float-left">
                                                                                <div className=" input_field pl-0">
                                                                                    <label className={errors.dob ? "error" : "input-title"}>Date of Birth - Should be as per Aadhar <span className="error-area">*</span>
                                                                                    </label>
                                                                                    <input type="date" className="date-picker-input profile-calendar mobile_number" required name="dob" max={maxDate} min="1900-01-01" value={values.dob} onChange={handleChange}
                                                                                        onBlur={handleBlur} />
                                                                                    {errors.dob && (
                                                                                        <span className="error">({errors.dob})</span>
                                                                                    )}
                                                                                    {/* {errors.dob && touched.dob && (
                                                                                        <span className="error">{errors.dob}</span>
                                                                                    )} */}
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-xl-4 p-0 float-left">
                                                                        </div>
                                                                        <div className="col-xl-8 p-0 float-left mb-5 political_switch-div mt-3">
                                                                            <div className="choice-list political_switch">
                                                                                <span className="input-title">Are You Politically Exposed</span>
                                                                                <div className="input-title-filled">
                                                                                    <div className="custom-switch custom-switch-label-yesno pl-0">
                                                                                        <input className="custom-switch-input" id="is_politically_exposed" type="checkbox" checked={values.is_politically_exposed == 1 ? true : false} name="is_politically_exposed" onChange={(e: any) => {
                                                                                            if (e.target.checked) {
                                                                                                setPoliticallyExposed(true);
                                                                                                handleChange(e);
                                                                                            }
                                                                                        }} />
                                                                                        <label className="custom-switch-btn" htmlFor="is_politically_exposed"></label>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div className="choice-list future_switch mt-2">
                                                                                <span className="input-title future_switch-span">Would Like To Activate Your Futures & Options, Currency & Commodity Segment As Well?</span>
                                                                                <div className="input-title-filled">
                                                                                    <div className="custom-switch custom-switch-label-yesno pl-0">
                                                                                        <input className="custom-switch-input" name="would_you_like_to_activate" id="would_you_like_to_activate" value={values.would_you_like_to_activate} type="checkbox" onChange={(e: any) => {
                                                                                            if (e.target.checked) {
                                                                                                values.would_you_like_to_activate = 1;
                                                                                            } else {
                                                                                                values.would_you_like_to_activate = 0;
                                                                                            }
                                                                                        }} />
                                                                                        <label className="custom-switch-btn" htmlFor="would_you_like_to_activate"></label>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <label htmlFor="terms" className="review-text mx-1 mb-0 p-0">
                                                                                <p className="review-text text-left mt-5"><input type="checkbox" id="terms" name="check_box_share_data_with_company" onChange={(e: any) => {
                                                                                    if (e.target.checked == true) {
                                                                                        setAgree1(true)
                                                                                        values.check_box_share_data_with_company = 1;
                                                                                    } else {
                                                                                        setAgree1(false);
                                                                                        values.check_box_share_data_with_company = 0;
                                                                                    }
                                                                                }} /> I understand and agree to allow Trust Money to share my data with its group companies.</p>
                                                                            </label>
                                                                            {errors.check_box_share_data_with_company && touched.check_box_share_data_with_company && (
                                                                                <p className="error_text">{errors.check_box_share_data_with_company}</p>
                                                                            )}
                                                                            <label htmlFor="signup" className="review-text mx-1 mb-0 p-0">
                                                                                <p className="review-text text-left pb-0">
                                                                                    <input type="checkbox" id="signup" className="signup" name="check_box_share_data_with_govt" onChange={(e: any) => {

                                                                                        if (e.target.checked == true) {
                                                                                            setAgree2(true)
                                                                                            values.check_box_share_data_with_govt = 1;
                                                                                        } else {
                                                                                            setAgree2(false)
                                                                                            values.check_box_share_data_with_govt = 0;
                                                                                        }
                                                                                    }} /> I understand and agree to allow Trust Money to share my data with companies mandated by the Govt.</p>
                                                                            </label>
                                                                            {errors.check_box_share_data_with_govt && touched.check_box_share_data_with_govt && (
                                                                                <p className="error_text">{errors.check_box_share_data_with_govt}</p>
                                                                            )}
                                                                        </div>
                                                                        <div className="col-xl-4 p-0 float-left">

                                                                        </div>
                                                                        <div className="col-xl-12 continue_btn_whole mb-3">
                                                                            <button className={(values.firstname != "" && values.lastname != '' && values.dob != "" && agree1 == true && agree2 == true) ? "theme_btn btn-change primary_btn_1 sub-btn wow" : "theme_btn primary_btn sub-btn wow"} data-wow-delay="0.5s" type="submit">Continue</button>
                                                                        </div>
                                                                        <div onClick={(e: any) => {
                                                                            e.preventDefault();
                                                                            setKycExit(true);
                                                                        }} className="save_continue_btn text-center cursor"><u>Save & Complete Later</u></div>
                                                                    </form>
                                                                )}
                                                            </Formik>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <ConfirmationKYCModel showModal={KycExit} closeModal={() => { setKycExit(false) }} confirmtype={'personaldetails'} />
                <PoliticallyExposed showModal={politicallyExposed} closeModal={() => { setPoliticallyExposed(false) }} />
            </main>
        </div >
    )
}

export default PersonalDetailsEdit;
