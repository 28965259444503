import { url } from "inspector";
import Header from "../components/template/Header";
import SlideBar from "../components/template/SlideBar";
import Footer from "../components/template/Footer";
import { Tab, TabList, TabPanel, Tabs } from "react-tabs";
import InvestSteps from "../components/InvestSteps";
import YourStory from "../components/YourStory";
import Subscribe from "../components/Subscribe";
import NeedHelp from "../components/NeedHelp";
import { bondTypes, interestPaymentFrequency, kycStatus, PageLinks } from "../common/Constants";
import { Link, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { ServerAPI } from "../common/ServerAPI";
import { useAppStateAPI } from "../common/AppStateAPI";
import { APIData } from "../common/DataTypes";
import { InitialData } from "../components/Form/InitialData";
import { numberWithCommas } from "../common/Utilities";
import SocialMediaShare from "../components/SocialMediaShare";

const Profile: React.FC = () => {
    const [customerFirstName, setCustomerFirstName] = useState('');
    const [customerLastName, setCustomerLastName] = useState('');
    const [url, setUrl] = useState('');
    const [progressStatus, setProgressStatus] = useState(0);
    const [userDetail, setUserDetails] = useState<APIData.userDetail>(InitialData.UserDetails);
    const { setShowPreloader } = useAppStateAPI();
    const [bondsList, setBondsList] = useState<APIData.BondlistData[]>([]);
    const navigate = useNavigate();
    const sites = ['facebook', 'twitter', 'whatsapp', 'telegram', 'linkedin', 'mail'];
    const [openShareModal, setOpenShareModal] = useState(false);

    const goToItemDetail = (bond_type: number, id: number, isin: string) => {

        if (bond_type == bondTypes.ADDEDBOND || bond_type == bondTypes.EXISTINGBOND) {
            navigate({
                pathname: PageLinks.DETAIL_VIEW_ONE,
                search: "?isin=" + isin,
            });
        } else if (bond_type == bondTypes.IPO) {
            navigate({
                pathname: PageLinks.BOND_IPO_FLOW,
                search: "?id=" + id,
            });
        } else if (bond_type == bondTypes.SGB) {
            navigate({
                pathname: PageLinks.SOVEREIGN_BONDS_LANDING_PAGE,
                search: "?isin=" + isin,
            });
        }
    }


    useEffect(() => {
        let firstName: any = localStorage.getItem("customerFirstName");
        let lastName: any = localStorage.getItem("customerLastName");
        setCustomerFirstName(firstName);
        setCustomerLastName(lastName);

        if (firstName != undefined && firstName != null) {
            setShowPreloader(true);
            ServerAPI.ProgressBarStatusGet().then((response) => {
                setProgressStatus(response['message']);
            }).finally(() => {
                setShowPreloader(false);
            })
            setShowPreloader(true);
            ServerAPI.userDetailData().then((res: APIData.userDetail) => {
                setUserDetails(res);
            }).finally(() => {
                setShowPreloader(false);
            })
        }
    }, [])

    const bondlist = () => {
        localStorage.setItem('animation', 'default');
        setShowPreloader(true);
        ServerAPI.BondsList(1).then((response: any) => {
            if (response != undefined) {
                setBondsList(response['data']);
            }
        }).finally(() => {
            setShowPreloader(false);
        })
    }
    useEffect(() => {
        bondlist();
    }, [])



    return (
        <div className="main-index-wrapper">
            {userDetail.ekyc_application_status == '0' || userDetail.ekyc_application_status == '' || userDetail.ekyc_application_status == null &&
                <div className="top_kyc_status_bar cursor" onClick={(e: any) => {
                    e.preventDefault();
                    navigate(PageLinks.INSTRUCTION_SCREEN);
                }}><i className="fal fa-exclamation-circle"></i>Complete Your KYC</div>
            }
            <Header />
            <SlideBar />
            <div className="body-overlay"></div>
            <main>
                <section className="slider-area slider-bg pos-rel">
                    <div className="slider-active">
                        <div className="single-slider slider-height-2 pos-rel pt-275 pb-190 pt-md-200 pt-xs-50 pb-md-50 pb-xs-50">
                            <div className="slider__img-two d-none d-lg-block">
                                <div className="slider-card">
                                    <div className="icon">
                                        <img src="assets/img/icon/icon16.svg" alt="" />
                                    </div>
                                    <p>Quick, easy and hassle free</p>
                                </div>
                                <div className="slider-card card-2">
                                    <div className="icon">
                                        <img src="assets/img/icon/icon16.svg" alt="" />
                                    </div>
                                    <div className="slider-text">
                                        <h5>Regular Income Stream on Trust Money.</h5>
                                        <span>Learn More</span>
                                    </div>
                                </div>
                            </div>
                            <div className="container-fluid">
                                <div className="row align-items-center">
                                    <div className="col-xl-6 col-lg-8">
                                        <div className="slider__content slider__content-2 text-left">
                                            <h6>From the makers of the most Trusted Bonds <br></br>Investment House.</h6>
                                            <h1 className="main-title mb-35" data-animation="fadeInUp2" data-delay=".2s">
                                                Hello I am <br></br>
                                                <span> Bond, Trust Bond</span>  <span className="banner_man_icon"><img src="assets/img/icon/man_icon.png" /></span><br></br>
                                                <span className="small_text">Licensed to make money for you.</span>
                                                <span className="lets_find_box">Let’s find the right BOND for you</span>
                                            </h1>
                                            <ul className="input-box mt-10">
                                                <li>
                                                    <i className="far fa-search"></i> <input className="input-box-bond-slider" type="text" name="bond" placeholder="Bond name, issuer or bond type" />
                                                </li>
                                                <li>
                                                    <button className="trustBlackbtn" tabIndex={0}> Search</button>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="what-we-do-area pt-10 pb-100 pb-md-20 pt-md-60 pt-xs-60 pb-xs-20">
                    <div className="container-fluid">
                        <div className="view_all mobile_hide">
                            <Link to={PageLinks.PROFILE_LISTING} className="explore_btn">Explore All Bonds <i className="fas fa-arrow-right"></i></Link>
                        </div>
                        <Tabs>
                            <TabList>
                                <Tab>Current IPOs</Tab>
                                <Tab>Bonds in Spotlight</Tab>
                            </TabList>
                            <TabPanel>
                                <div className="row align-items-center">
                                    {bondsList != undefined && bondsList.length > 0 &&
                                        bondsList.slice(0, 4).map((bond: APIData.BondlistData) => {
                                            return (
                                                <div className="col-lg-3 col-md-6 " data-wow-delay="0.1s">
                                                    <div className="do-box do-box-2 mb-30 home_profile">
                                                        <div className="icon-box d-flex">
                                                            <div className="icon-img">
                                                                <img src={bond.bond_logo ? bond.bond_logo : "assets/img/img_placeholder.png"} alt="" />
                                                            </div>
                                                            <div className="icon-head">
                                                                {bond.bond_name}
                                                            </div>
                                                            <SocialMediaShare url={bond.share_link} />
                                                        </div>
                                                        <div className="spotlight_box_one">
                                                            <div className="spotlight_box_inner">
                                                                <div className="spotlight_box_inner_text1">Coupon</div>
                                                                <div className="spotlight_box_inner_text2">{bond.bond_coupon_on != null && bond.bond_coupon_on != '' ? bond.bond_coupon_on + "%" : "N/A"}</div>
                                                            </div>
                                                            <div className="spotlight_box_inner">
                                                                <div className="spotlight_box_inner_text1">Yeild</div>
                                                                <div className="spotlight_box_inner_text2">{bond.bonds_yeild != null && bond.bonds_yeild != '' ? bond.bonds_yeild + "%" : "N/A"}</div>
                                                            </div>
                                                        </div>
                                                        <div className="spotlight_box_two">
                                                            <div className="spotlight_box_inner">
                                                                <div className="spotlight_box_inner_text1">Interest Payment</div>
                                                                <div className="spotlight_box_inner_text2">
                                                                    {parseInt(bond?.bond_issuer_frequency_of_interest!) == interestPaymentFrequency.FOR_ANNUAL &&
                                                                        "Annual"
                                                                    }
                                                                    {parseInt(bond?.bond_issuer_frequency_of_interest!) == interestPaymentFrequency.FOR_MONTHLY &&
                                                                        "Monthly"
                                                                    }
                                                                    {parseInt(bond?.bond_issuer_frequency_of_interest!) == interestPaymentFrequency.FOR_QUARTERLY &&
                                                                        "Quarterly"
                                                                    }
                                                                    {parseInt(bond?.bond_issuer_frequency_of_interest!) == interestPaymentFrequency.FOR_SEMI_ANNUAL &&
                                                                        "Semi-Annual"
                                                                    }
                                                                    {bond?.bond_issuer_frequency_of_interest == null &&
                                                                        "N/A"
                                                                    }
                                                                </div>
                                                            </div>
                                                            <div className="spotlight_box_inner">
                                                                <div className="spotlight_box_inner_text1">Min. Investment</div>
                                                                <div className="spotlight_box_inner_text2">{bond.bond_minimum_application != null && bond.bond_minimum_application != '' ? "₹" + numberWithCommas(bond.bond_minimum_application) : "N/A"}</div>
                                                            </div>
                                                        </div>
                                                        <div className="speed-img">
                                                        <img src={bond.bond_rating_image != null && bond.bond_rating_image != "https://trust-api.trustmony.com/api/v1/image_download?name=None" && bond.bond_rating_image!="https://api.trustmony.com/api/v1/image_download?name=None" ? bond.bond_rating_image : "../assets/img/no_rating.png"} alt="" />
                                                        </div>
                                                        <div className="spotlight_readmore_box">
                                                            <div className="ipo_text">{bond.bond_type == bondTypes.IPO &&
                                                                "IPO"
                                                            }</div>
                                                            <button onClick={(e: any) => {
                                                                e.preventDefault();
                                                                goToItemDetail(bond.bond_type, bond.bond_type != bondTypes.IPO ? 0 : bond.bond_id, bond.bond_type == bondTypes.IPO ? '' : bond.bond_isin_number)
                                                            }} className="spotlight_readmore bonds-detail-btn" style={{ padding: "3px 20px" }}>Read More</button>
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                        })
                                    }
                                    <div className="note_text">
                                        Note: The listing of products above should not be considered an endorsement or recommendation to invest. Please use your own discretion before you transact. The listed products and their price or yield are subject to availability and market cutoff times.
                                    </div>
                                </div>
                            </TabPanel>
                            <TabPanel>
                                <div className="row align-items-center">
                                    {bondsList != undefined && bondsList.length > 0 &&
                                        bondsList.slice(4, 8).map((bond: APIData.BondlistData) => {
                                            return (
                                                <div className="col-lg-3 col-md-6 " data-wow-delay="0.1s">
                                                    <div className="do-box do-box-2 mb-30 home_profile">
                                                        <div className="icon-box d-flex">
                                                            <div className="icon-img">
                                                                <img src={bond.bond_logo ? bond.bond_logo : "assets/img/img_placeholder.png"} alt="" />
                                                            </div>
                                                            <div className="icon-head">
                                                                {bond.bond_name}
                                                            </div>
                                                            <SocialMediaShare url={bond.share_link} />
                                                        </div>
                                                        <div className="spotlight_box_one">
                                                            <div className="spotlight_box_inner">
                                                                <div className="spotlight_box_inner_text1">Coupon</div>
                                                                <div className="spotlight_box_inner_text2">{bond.bond_coupon_on != null && bond.bond_coupon_on != '' ? bond.bond_coupon_on + "%" : "N/A"}</div>
                                                            </div>
                                                            <div className="spotlight_box_inner">
                                                                <div className="spotlight_box_inner_text1">Yeild</div>
                                                                <div className="spotlight_box_inner_text2">{bond.bonds_yeild != null && bond.bonds_yeild != '' ? bond.bonds_yeild + "%" : "N/A"}</div>
                                                            </div>
                                                        </div>
                                                        <div className="spotlight_box_two">
                                                            <div className="spotlight_box_inner">
                                                                <div className="spotlight_box_inner_text1">Interest Payment</div>
                                                                <div className="spotlight_box_inner_text2">
                                                                    {parseInt(bond?.bond_issuer_frequency_of_interest!) == interestPaymentFrequency.FOR_ANNUAL &&
                                                                        "Annual"
                                                                    }
                                                                    {parseInt(bond?.bond_issuer_frequency_of_interest!) == interestPaymentFrequency.FOR_MONTHLY &&
                                                                        "Monthly"
                                                                    }
                                                                    {parseInt(bond?.bond_issuer_frequency_of_interest!) == interestPaymentFrequency.FOR_QUARTERLY &&
                                                                        "Quarterly"
                                                                    }
                                                                    {parseInt(bond?.bond_issuer_frequency_of_interest!) == interestPaymentFrequency.FOR_SEMI_ANNUAL &&
                                                                        "Semi-Annual"
                                                                    }
                                                                    {bond?.bond_issuer_frequency_of_interest == null &&
                                                                        "N/A"
                                                                    }
                                                                </div>
                                                            </div>
                                                            <div className="spotlight_box_inner">
                                                                <div className="spotlight_box_inner_text1">Min. Investment</div>
                                                                <div className="spotlight_box_inner_text2">{bond.bond_minimum_application != null && bond.bond_minimum_application != '' ? "₹" + numberWithCommas(bond.bond_minimum_application) : "N/A"}</div>
                                                            </div>
                                                        </div>
                                                        <div className="speed-img">
                                                        <img src={bond.bond_rating_image != null && bond.bond_rating_image != "https://trust-api.trustmony.com/api/v1/image_download?name=None" && bond.bond_rating_image!="https://api.trustmony.com/api/v1/image_download?name=None" ? bond.bond_rating_image : "../assets/img/no_rating.png"} alt="" />
                                                        </div>
                                                        <div className="spotlight_readmore_box">
                                                            <div className="ipo_text">{bond.bond_type == bondTypes.IPO &&
                                                                "IPO"
                                                            }</div>
                                                            <button onClick={(e: any) => {
                                                                e.preventDefault();
                                                                goToItemDetail(bond.bond_type, bond.bond_type != bondTypes.IPO ? 0 : bond.bond_id, bond.bond_type == bondTypes.IPO ? '' : bond.bond_isin_number)
                                                            }} className="spotlight_readmore bonds-detail-btn" style={{ padding: "3px 20px" }}>Read More</button>
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                        })
                                    }
                                    <div className="note_text">
                                        Note: The listing of products above should not be considered an endorsement or recommendation to invest. Please use your own discretion before you transact. The listed products and their price or yield are subject to availability and market cutoff times.
                                    </div>
                                </div>
                            </TabPanel>
                        </Tabs>
                    </div>
                </section>
                <section className="why-chose-us-area pt-100 pb-110 pt-md-45 pb-md-40 pt-xs-45 pb-xs-40">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-lg-6">
                                <div className="section-title section-title-3 text-left text-md-left mb-30 pr-80 pr-xs-0">
                                    <h3 className="mb-30">Why Invest In Bonds?</h3>
                                    <h4 className="sub-title mb-40">Trust Money provides steady interest income to investors throughout the life of the bond and also reduce the overall risk in an investment portfolio</h4>
                                    <a style={{ color: "#fff" }} onClick={(e: any) => {
                                        e.preventDefault();
                                        navigate(PageLinks.PROFILE_LISTING);
                                    }} className="explore_btn">Explore More <i className="fas fa-arrow-right"></i></a>
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <ul className="chose-list">
                                    <li>
                                        <div className="chose-box " data-wow-delay="0.1s">
                                            <div className="chose-box-icon">
                                                <img src="assets/img/icon/regular_income.svg" alt="" />
                                            </div>
                                            <div className="chose-box-text">
                                                <h6>Regular Income Stream</h6>
                                                <h3>Fixed-income securities provide investors with a a steady stream of income.</h3>
                                            </div>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="chose-box " data-wow-delay="0.3s">
                                            <div className="chose-box-icon">
                                                <img src="assets/img/icon/safety_principle.svg" alt="" />
                                            </div>
                                            <div className="chose-box-text">
                                                <h6>Safety - Principal Protection</h6>
                                                <h3>Investors benefit by preserving and increasing their invested capital.</h3>
                                            </div>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="chose-box " data-wow-delay="0.5s">
                                            <div className="chose-box-icon">
                                                <img src="assets/img/icon/zero_credit.svg" alt="" />
                                            </div>
                                            <div className="chose-box-text">
                                                <h6>Zero Credit Risk</h6>
                                                <h3>No default risk while investing in Govt Bonds.</h3>
                                            </div>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </section>
                <InvestSteps />
                {/* <YourStory /> */}
                <Subscribe />
                {/* <NeedHelp /> */}
            </main>
            <Footer />
        </div>
    )
}

export default Profile;
