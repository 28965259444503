import { Fragment, useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useAppStateAPI } from "../../common/AppStateAPI";
import { kycStatus, PageLinks } from "../../common/Constants";
import { APIData } from "../../common/DataTypes";
import { ServerAPI } from "../../common/ServerAPI";
import { ClearLocalStorageData } from "../../common/Utilities";
import { InitialData } from "../Form/InitialData";

const Header: React.FC = () => {

    const [customerFirstName, setCustomerFirstName] = useState('');
    const [customerLastName, setCustomerLastName] = useState('');
    const [customerId, setCustomerId] = useState('');
    const [sortName, setSortName] = useState('');
    const [headerClass, setHeaderClass] = useState('main-header-area main-head-two');
    const [progressStatus, setProgressStatus] = useState(0);
    const navigate = useNavigate();
    const location = useLocation();
    const [userDetail, setUserDetails] = useState<APIData.userDetail>(InitialData.UserDetails);
    const { setShowPreloader } = useAppStateAPI();
    const [scrollTop, setScrollTop] = useState(0);
    const [scrolling, setScrolling] = useState(false);
    const [headerListClass, setHeaderListClass] = useState('non-scroll-header');
    const [headerListIconClass, setHeaderListIconClass] = useState('');
    const [headerListLineClass, setHeaderListLineClass] = useState('');
    const [headerLogo, setHeaderLogo] = useState('assets/img/logo/header_logo_dark.svg');


    useEffect(() => {
        let login = localStorage.getItem('login');
        let firstName: any = localStorage.getItem("customerFirstName");
        let lastName: any = localStorage.getItem("customerLastName");
        let customer_id: any = localStorage.getItem("customerid");
        setCustomerFirstName(firstName);
        setCustomerLastName(lastName);
        setCustomerId(customer_id);
        if (firstName && lastName != null && firstName && lastName != undefined) {
            let fullname = firstName.split("")[0] + lastName.split("")[0];
            let nameFirstLetter = fullname.toUpperCase();
            setSortName(nameFirstLetter);

            setShowPreloader(true);
            ServerAPI.ProgressBarStatusGet().then((response) => {
                setProgressStatus(response['message']);
            }).finally(() => {
                setShowPreloader(false);
            })
            console.log(login);
            if (login != undefined && login != null && login!='0') {
                ServerAPI.userDetailData().then((res: any) => {
                    if (res != undefined) {
                        setUserDetails(res);
                    }
                })
            }
        }



    }, []);

    useEffect(() => {
        if (location.pathname == PageLinks.PROFILE) {
            setHeaderClass('main-header-area main-head-two');
            setHeaderLogo('assets/img/logo/header_logo_dark.svg');
            setHeaderListClass('non-scroll-header');
            setHeaderListIconClass('header-search-icon');
            setHeaderListLineClass('vl');
        } else {
            setHeaderClass('main-header-area main-head-two header-bg');
            setHeaderLogo('assets/img/logo/header_logo_white.svg');
            setHeaderListClass('scroll-header');
            setHeaderListIconClass('header-search-icon-white');
            setHeaderListLineClass('vl-white');
        }

    }, [])

    useEffect(() => {
        const onScroll = (e: any) => {
            if (window.scrollY == 0 && location.pathname == PageLinks.PROFILE) {
                setHeaderClass('main-header-area main-head-two');
                setHeaderLogo('assets/img/logo/header_logo_dark.svg');
                setHeaderListClass('non-scroll-header');
                setHeaderListIconClass('header-search-icon');
                setHeaderListLineClass('vl');
            } else {
                setHeaderClass('main-header-area main-head-two header-bg');
                setHeaderLogo('assets/img/logo/header_logo_white.svg');
                setHeaderListClass('scroll-header');
                setHeaderListIconClass('header-search-icon-white');
                setHeaderListLineClass('vl-white');
            }
            setScrollTop(e.target.documentElement.scrollTop);
            setScrolling(e.target.documentElement.scrollTop > scrollTop);
        };
        window.addEventListener("scroll", onScroll);
        return () => window.removeEventListener("scroll", onScroll);
    }, [scrollTop]);


    return (
        <header id="top-menu" className="transparent-head sticky-header">
            {/* For Header with BG call > header-bg  */}
            <div id="headerdiv" className={location.pathname == PageLinks.PROFILE ? headerClass : 'main-header-area main-head-two header-bg'}>
                <div className="container-fluid">
                    <div className="row align-items-center justify-content-between">
                        <div className="col-xl-2 col-lg-2 col-md-6 col-6">
                            <div className="hamburger-menu d-md-block d-lg-none">
                                <a >
                                    <i style={{color:"#ffff"}} className="far fa-bars"></i>
                                </a>
                            </div>
                            <div className="logo">
                                <a className="logo-img" onClick={(e: any) => {
                                    e.preventDefault();
                                    navigate(PageLinks.PROFILE);
                                }} >
                                    <img className="logo-1a" src={headerLogo} alt="" />
                                    <img className="logo-2" src={headerLogo} alt="" />
                                    <img className="logo-3" src={headerLogo} alt="" />
                                </a>
                            </div>
                        </div>
                        <div className="col-xl-10 col-lg-10 col-md-6 col-6 text-right text-lg-center text-xl-right">
                            <div className="main-nav d-md-flex align-items-center justify-content-md-end">
                                <div className="main-menu d-none d-lg-block">
                                    <nav>
                                        <ul>
                                            <li><a onClick={(e: any) => {
                                                e.preventDefault();
                                                navigate(PageLinks.PROFILE);
                                            }} className={headerListClass}>Home</a>
                                            </li>
                                            <li><a className={headerListClass}>Bonds  <i className="far fa-chevron-down"></i></a>
                                                <ul className="submenu">
                                                    <li><a onClick={(e: any) => {
                                                        e.preventDefault();
                                                        navigate(PageLinks.PROFILE_LISTING)
                                                    }}>Explore Bonds</a>
                                                    </li>
                                                    {/* <li><a onClick={(e: any) => {
                                                        e.preventDefault();
                                                        navigate(PageLinks.COLLECTION_OF_BONDS)
                                                    }}>Collection of Bonds</a></li> */}
                                                    {/* <li><a>How does it work?</a></li> */}
                                                    {/* <li onClick={(e: any) => {
                                                        e.preventDefault();
                                                        navigate(PageLinks.FAQ);
                                                    }}><a>Frequently Asked Questions</a></li> */}
                                                    {/* <li><a onClick={(e: any) => {
                                                        e.preventDefault();
                                                        navigate(PageLinks.LEARN_FROM_BOND)
                                                    }}>Learn, from Bond, Trust Bond</a></li>
                                                    <li onClick={(e: any) => {
                                                        e.preventDefault();
                                                        navigate(PageLinks.NEWSANDINSIGHTS);
                                                    }}><a>Bond ki Khaberin</a></li> */}
                                                </ul>
                                            </li>
                                            {/* <li><a className={headerListClass}>Mutual Funds  <i className="far fa-chevron-down"></i></a></li>
                                            <li><a className={headerListClass}>Equity Trading  <i className="far fa-chevron-down"></i></a></li>
                                            <li><a className={headerListClass}>F&amp;O Trading  <i className="far fa-chevron-down"></i></a></li>
                                            <li><a className={headerListClass}>More  <i className="far fa-chevron-down"></i></a>
                                                <ul className="submenu">
                                                    <li className="hasdropdown"><a>Wealth Management <span className="dropdownarrow"><i className="far fa-angle-right"></i></span></a></li>
                                                    <li className="hasdropdown"><a>Insurance <span className="dropdownarrow"><i className="far fa-angle-right"></i></span></a>
                                                        <ul className="submenu_level2">
                                                            <li><a>Life Insurance</a></li>
                                                            <li><a>Investment Plans</a></li>
                                                            <li><a>Health Insurance</a></li>
                                                            <li><a>Car Insurance</a></li>
                                                            <li><a>Other Insurance</a></li>
                                                        </ul>
                                                    </li>
                                                </ul>
                                            </li> */}
                                            {/* <li><a onClick={(e: any) => {
                                                e.preventDefault();
                                                navigate(PageLinks.CONTACT_US);
                                            }} className={headerListClass}>Contact Us</a></li> */}
                                            <li className={headerListLineClass}><a></a></li>
                                            <li><i className={headerListIconClass + " fa fa-search fw-400"} aria-hidden="true"></i></li>
                                        </ul>
                                    </nav>
                                </div>
                                <div className="right-nav d-flex align-items-center justify-content-end">


                                    <div className="hp-main-layout">
                                        <header>
                                            {customerFirstName == undefined || customerFirstName == null || customerLastName == undefined || customerLastName == null || customerId == undefined || customerId == null ?
                                                <Fragment>
                                                    <div className="quote-btn d-sm-block" onClick={(e: any) => {
                                                        e.preventDefault();
                                                        navigate(PageLinks.LOGIN);
                                                    }}>
                                                        <p className="profile-signup trustWhitebtn">Sign Up / Sign In</p>
                                                    </div>
                                                </Fragment> :
                                                <div className="hover-dropdown-fade w-auto px-0 ms-6 position-relative hp-cursor-pointer">
                                                    <div className="avatar-item d-flex align-items-center justify-content-center rounded-circle">
                                                        {sortName}
                                                    </div>
                                                    <div className="quote-btn d-none d-sm-block">
                                                        <a className="theme_btn profile_top_btn">{customerFirstName} {customerLastName} <i className="far fa-chevron-down"></i></a>
                                                    </div>
                                                    <div className="hp-header-profile-menu dropdown-fade position-absolute pt-18">
                                                        <div className="border_bottom_radius hp-border-color-black-40 hp-bg-black-0 hp-bg-dark-100 hp-border-color-dark-80">
                                                            <div className="profile_top_section p-12">
                                                                <div className="avatar-item d-flex align-items-center justify-content-center rounded-circle">
                                                                    {sortName}
                                                                </div>
                                                                <div className="">
                                                                    <span className="d-block hp-text-color-black-100 hp-text-color-dark-0 mb-6 avatar-item_text">{customerFirstName} {customerLastName} </span>
                                                                    <span className="d-block hp-text-color-black-100 hp-text-color-dark-0 mb-6 avatar-item_id">Customer ID: {customerId}</span>
                                                                </div>
                                                                {userDetail.ekyc_application_status == '0' || userDetail.ekyc_application_status == '' || userDetail.ekyc_application_status == null ?
                                                                    <div className="theme_btn profile_btn sub-btn cursor" onClick={(e: any) => {
                                                                        e.preventDefault();
                                                                        navigate(PageLinks.INSTRUCTION_SCREEN);
                                                                    }}>Profile <span className="status_highlight">(KYC Pending)</span></div>
                                                                    :
                                                                    <div className="theme_btn profile_btn sub-btn cursor" onClick={(e: any) => {
                                                                        e.preventDefault();
                                                                        navigate(PageLinks.MY_ACCOUNT_PROFILE);
                                                                    }}>Profile <span className="status_complete">(KYC Complete)</span></div>
                                                                }
                                                            </div>
                                                            <div className="row p-24">
                                                                {/* <div className="col-12">
                                                                    <a className="d-flex align-items-center hp-p1-body py-4 px-10 hp-transition hp-hover-bg-primary-4 hp-hover-bg-dark-primary hp-hover-bg-dark-80 rounded" style={{ marginLeft: "-10px", marginRight: "-10px" }}>
                                                                        <span className="ml-8 profile-menu-list">Watchlist</span>
                                                                    </a>
                                                                </div>
                                                                <div className="col-12">
                                                                    <a className="d-flex align-items-center hp-p1-body py-4 px-10 hp-transition hp-hover-bg-primary-4 hp-hover-bg-dark-primary hp-hover-bg-dark-80 rounded" style={{ marginLeft: "-10px", marginRight: "-10px" }}>
                                                                        <span className="hp-ml-8 profile-menu-list">Portfolio</span>
                                                                    </a>
                                                                </div> */}
                                                                <div className="col-12">
                                                                    <Link to={PageLinks.ORDER_LISTING} className="d-flex align-items-center hp-p1-body py-4 px-10 hp-transition hp-hover-bg-primary-4 hp-hover-bg-dark-primary hp-hover-bg-dark-80 rounded" style={{ marginLeft: "-10px", marginRight: "-10px" }}>
                                                                        <span className="hp-ml-8 profile-menu-list">Orders</span>
                                                                    </Link>
                                                                </div>
                                                                {/* <div className="col-12">
                                                                    <a className="d-flex align-items-center hp-p1-body py-4 px-10 hp-transition hp-hover-bg-primary-4 hp-hover-bg-dark-primary hp-hover-bg-dark-80 rounded" style={{ marginLeft: "-10px", marginRight: "-10px" }}>
                                                                        <span className="hp-ml-8 profile-menu-list">Add Funds</span>
                                                                    </a>
                                                                </div>
                                                                <div className="col-12">
                                                                    <a className="d-flex align-items-center hp-p1-body py-4 px-10 hp-transition hp-hover-bg-primary-4 hp-hover-bg-dark-primary hp-hover-bg-dark-80 rounded" style={{ marginLeft: "-10px", marginRight: "-10px" }}>
                                                                        <span className="hp-ml-8 profile-menu-list">Transaction Report</span>
                                                                    </a>
                                                                </div>
                                                                <div className="col-12">
                                                                    <a className="d-flex align-items-center hp-p1-body py-4 px-10 hp-transition hp-hover-bg-primary-4 hp-hover-bg-dark-primary hp-hover-bg-dark-80 rounded" style={{ marginLeft: "-10px", marginRight: "-10px" }}>
                                                                        <span className="hp-ml-8 profile-menu-list">Settings</span>
                                                                    </a>
                                                                </div> */}
                                                                <div className="col-12 cursor" onClick={(e: any) => {
                                                                    e.preventDefault();
                                                                    ClearLocalStorageData();
                                                                    navigate(PageLinks.LOGIN);
                                                                }}>
                                                                    <a className="d-flex align-items-center hp-p1-body py-4 px-10 hp-transition hp-hover-bg-primary-4 hp-hover-bg-dark-primary hp-hover-bg-dark-80 rounded" style={{ marginLeft: "-10px", marginRight: "-10px" }}>
                                                                        <span className="hp-ml-8 profile-menu-list">Logout</span>
                                                                    </a>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            }
                                        </header>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </header>
    )
}

export default Header;
