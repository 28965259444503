import React from 'react'
import { useNavigate } from 'react-router-dom';

const Chat: React.FC = () => {
    const navigate = useNavigate();
    return (
        <section className="otp_popup">
            <div className="blur_back chat-bg"></div>
            <div id="live-chat">
                <header className="clearfix">
                    <div className='d-flex chat-heading-seperator'>
                        <div className='col-lg-10 mb-2'>
                            <p className="wow chat-text-heading" data-wow-delay="0.1s">Chat</p>
                        </div>
                        <div className='col-lg-2'>
                            <div className='chat-close-btn' onClick={() => { navigate(-1) }}>
                                <i className="far fa-times-circle"></i>
                            </div>
                        </div>
                    </div>
                </header>
                <div className="chat">
                    <div className="chat-history">
                        <div className="d-flex flex-row justify-content-start">
                            <div className='chat-message-content-m-r'>
                                <p className="small p-2 ms-3 mb-2 rounded-3 chat-text-bg">Hi, I'm your virtual assistant.</p>
                                <div className='chat-message-bg'>
                                    <p className="p-2 ms-3 mb-1 rounded-3 chat-text-bg">Alright! here's how I can help you</p>
                                    <div className='row d-flex small m-10 ms-3 mb-1'>
                                        <p className='chat-msg-sub-content'>Open an Account</p>
                                        <p className='chat-msg-sub-content'>KYC Verification</p>
                                        <p className='chat-msg-sub-content'>Register</p>
                                        <p className='chat-msg-sub-content'>Buy Bonds</p>
                                        <p className='chat-msg-sub-content'>Transactions</p>
                                        <p className='chat-msg-sub-content'>IPO and Public Offerings</p>
                                        <p className='chat-msg-sub-content'>Login Support</p>
                                        <p className='chat-msg-sub-content'>Bank</p>
                                        <p className='chat-msg-sub-content'>Investment</p>
                                        <p className='chat-msg-sub-content'>Selling</p>
                                        <p className='chat-msg-sub-content'>IPO Applications</p>
                                        <p className='chat-msg-sub-content'>IPV</p>
                                        <p className='chat-msg-sub-content'>Others</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
                <div className="card-footer chat-footer d-flex justify-content-start align-items-center p-2">
                    <input type="text" className="form-control form-control-lg chat-input" id="exampleFormControlInput1"
                        placeholder="Write your message…" />
                    <i className="fa fa-arrow-circle-right" aria-hidden="true"></i>
                </div>
            </div>
            <hr />
        </section>
    )
}

export default Chat
