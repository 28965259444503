import React, { useEffect, useState } from "react";
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import Slide from '@mui/material/Slide';
import { TransitionProps } from '@mui/material/transitions';
import { DialogTitle } from "@mui/material";

interface FormParameters {
    showModal: boolean,
    closeModal: any,
    url: string,
    setShowUploadModel?:any
}

const AddressProofPreview: React.FC<FormParameters> = ({ showModal, closeModal, url,setShowUploadModel}) => {

    return (
        <div className="main-index-wrapper">
            <main>
                <Dialog
                    open={showModal}
                    onClose={closeModal}
                    aria-describedby="alert-dialog-slide-description"
                    scroll="body"
                    maxWidth="md"
                >
                    <DialogTitle>Preview
                        <button onClick={(e: any) => {
                            e.preventDefault();
                            closeModal();
                        }} className="theme_btn profile_close_btn sub-btn wow " data-wow-delay="0.5s"><i className="far fa-times-circle"></i> Close</button>
                    </DialogTitle>
                    <DialogContent className="cancelled-cheque-bg" style={{ overflow: "hidden" }}>
                        <DialogContentText id="alert-dialog-slide-description">
                            <div className="col-xl-12">
                                <div className="subscribe-wrapper">
                                    <div className="section-title section-title-3">
                                        <div className="identity_photo_whole">
                                            <div className="passport_photo_single">
                                                <img src={url} />
                                            </div>
                                        </div>
                                        <div className="col-xl-12 continue_btn_whole">
                                            <button onClick={(e:any)=>{
                                                e.preventDefault();
                                                closeModal();
                                                setShowUploadModel();
                                            }} className="theme_btn primary_btn_one sub-btn wow " data-wow-delay="0.5s">Reupload</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </DialogContentText>
                    </DialogContent>
                </Dialog>
            </main>
        </div>
    )
}

export default AddressProofPreview;