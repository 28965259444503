import { useEffect, useRef, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useAppStateAPI } from "../common/AppStateAPI";
import { AccountTypeValues1, AccountTypeValues2, kycStatus, PageLinks } from "../common/Constants";
import { Formik, FormikProps } from "formik";
import { APIData } from "../common/DataTypes";
import { InitialData } from "../components/Form/InitialData";
import { ServerAPI } from "../common/ServerAPI";

const InstructionScreen: React.FC = () => {
    
    let AccountRedirect = localStorage.getItem('bank');
    const [progressStatus, setProgressStatus] = useState(0);
    const navigate = useNavigate();
    const { setShowPreloader } = useAppStateAPI();
    const [userDetail, setUserDetails] = useState<APIData.userDetail>(InitialData.UserDetails);
    const year = new Date().getFullYear();

    const navigateFunc = (progressStatus: number) => {
        if (progressStatus == kycStatus.AADHAR) {
            navigate(PageLinks.AUTHENTICATE_AADHAAR_DETAIL);
        } else if (progressStatus == kycStatus.EMAIL) {
            navigate(PageLinks.PROFILE_PERSONAL_DETAILS_EDIT_EMAIL_VERIFIED);
        } else if (progressStatus == kycStatus.KRA_RECORDS) {
            navigate(PageLinks.ACCOUNT_OPENING_PENNY_DROP);
        }else if (progressStatus == kycStatus.IPV) {
            navigate(PageLinks.VERIFY_YOUR_IDENTITY);
        } else if (progressStatus == kycStatus.BANK) {
            navigate(PageLinks.DEMAT_DETAILS);
        } else if (progressStatus == kycStatus.DEMAT) {
            navigate(PageLinks.DEMAT_DETAILS);
        } else if (progressStatus == kycStatus.NOT_STARTED || progressStatus == kycStatus.PERSONAL_DETAILS) {
            navigate(PageLinks.PROFILE_PERSONAL_DETAILS_EDIT);
        }else if (progressStatus == kycStatus.PAN) {
            navigate(PageLinks.PROFILE_PERSONAL_DETAILS_EDIT_VALIDATE_PAN);
        }else if (progressStatus == kycStatus.KYC_COMPLETE) {
            navigate(PageLinks.MY_ACCOUNT_PROFILE);
        }else if (progressStatus == kycStatus.APPLICATION_DOWNLOAD) {
            navigate(PageLinks.DOWNLOAD_APPLICATION);
        }else if (progressStatus == kycStatus.ESIGN) {
            navigate(PageLinks.ESIGN_AADHAAR);
        }
    }

    useEffect(()=>{
        setShowPreloader(true);
        ServerAPI.ProgressBarStatusGet().then((response) => {
            setProgressStatus(response['message']);
        }).finally(() => {
            setShowPreloader(false);
        })
        setShowPreloader(true);
            ServerAPI.userDetailData().then((res: APIData.userDetail) => {
                setUserDetails(res);
            }).finally(() => {
                setShowPreloader(false);
            })
    },[])

    return (
        <div className="main-index-wrapper">
            <main>
                {/* Profile-details-edit-area start */}
                <section className="subscribe-letter-area pt-20 pb-20">
                    <div className="line-shape d-none d-lg-inline-block">
                        <img className="img-fluid" src="assets/img/Intersection_4.svg" alt="" />
                    </div>

                    <div className="container custom-container-subs">
                        <div className="sub-bg">
                            <div className="row justify-content-center">
                                <div className="col-xl-12">
                                    <div className="subscribe-wrapper">
                                        <div className={AccountRedirect != 'true'? "section-title section-title-3 instruct_logo":"section-title section-title-3 mb-5"}>
                                           <img className="header-logo" src="assets/img/logo/header_logo_one.png" alt="logo" />
                                        </div>
                                    </div>
                                    <div className="step_box_content">
                                        <div className="instruction_screen_full">
                                            <div className="col-lg-8 col-md-8 col-sm-12 col-xs-12 col-12 instruction_screen_full_left">
                                                <div className="instruction_screen_full_left_text">
                                                    Complete Your KYC Online And Start Investing In Just Few Minutes
                                                </div>
                                                <div className="mobile_show text-center"><img src="assets/img/icon/instruction.svg" /></div>
                                                <p pt-15>For Paperless KYC ensure that the following information is ready with you</p>
                                                <div className="instruction_bonds_box">
                                                    <div className="collection_bonds_box">
                                                        <div className="collection_bonds_box_left">
                                                            <img src="assets/img/icon/pan.svg" />
                                                        </div>
                                                        <div className="collection_bonds_box_right">PAN Number</div>
                                                    </div>
                                                    <div className="collection_bonds_box">
                                                        <div className="collection_bonds_box_left">
                                                            <img src="assets/img/icon/aadhaar.svg" />
                                                        </div>
                                                        <div className="collection_bonds_box_right">Aadhaar Number</div>
                                                    </div>
                                                    <div className="collection_bonds_box">
                                                        <div className="collection_bonds_box_left">
                                                            <img src="assets/img/icon/bank.svg" />
                                                        </div>
                                                        <div className="collection_bonds_box_right">Bank Details</div>
                                                    </div>
                                                    <div className="collection_bonds_box">
                                                        <div className="collection_bonds_box_left">
                                                            <img src="assets/img/icon/demat.svg" />
                                                        </div>
                                                        <div className="collection_bonds_box_right">Demat Details</div>
                                                    </div>
                                                </div>
                                                <div className="instruct_btns">
                                                    <Link to={PageLinks.PROFILE} className="goto_home_btn">Go to Home Page</Link>
                                                    <button onClick={(e:any)=>{
                                                        e.preventDefault();
                                                        navigateFunc(progressStatus)
                                                    }} className="continue_kyc_btn">Continue to KYC</button>
                                                </div>
                                            </div>
                                            <div className="col-lg-4 col-md-4 col-sm-12 col-xs-12 col-12 mobile_hide">
                                                <div className="instruction_screen_full_right_text">
                                                    <img src="assets/img/icon/instruction.svg" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <div className="inner_footer">
                    © {year}Trust Capital Pvt Ltd.
                </div>
            </main>
        </div>
    )
}

export default InstructionScreen;
