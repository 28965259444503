
import { Dialog, DialogActions, DialogContent } from "@mui/material";

interface FormParameters {
    showModal: boolean,
    closeModal: any,
    reloadlist: any
}


const ProfileUpdateSuccess: React.FC<FormParameters> = ({ showModal, closeModal, reloadlist }) => {

    return (
        <div className="main-index-wrapper">
            <main>
                <Dialog
                    open={showModal}
                    keepMounted
                    onClose={(_, reason) => {
                        if (reason !== "backdropClick") {
                            closeModal();
                        }
                    }}
                    aria-describedby="alert-dialog-slide-description"
                    className="empty-bg"
                    scroll="body"
                    maxWidth="md"
                >
                    <DialogContent className="profile-update-bg empty-bg" style={{ overflow: "hidden" }}>
                        <div className="cursor" onClick={(e: any) => {
                            e.preventDefault();
                            reloadlist();
                            closeModal();
                        }}>
                            <div className="icon mb-25 icon_complete_img">
                                <img src="assets/animations/icon_animations/profile_update.gif" alt="" />
                            </div>
                            <h5 className="email_update_text">Your Personal Details Updated Successfully.</h5>
                        </div>
                    </DialogContent>
                </Dialog>
            </main>
        </div>
    )
}

export default ProfileUpdateSuccess;