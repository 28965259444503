import { url } from "inspector";
import Header from "../components/template/Header";
import SlideBar from "../components/template/SlideBar";
import InvestSteps from "../components/InvestSteps";
import Subscribe from "../components/Subscribe";
import NeedHelp from "../components/NeedHelp";
import Footer from "../components/template/Footer";
import { Link, useNavigate } from "react-router-dom";
import { bondTypes, interestPaymentFrequency, PageLinks } from "../common/Constants";
import Popover from '@mui/material/Popover';
import Typography from '@mui/material/Typography';
import React, { useEffect, useState } from "react";
import { useAppStateAPI } from "../common/AppStateAPI";
import { ServerAPI } from "../common/ServerAPI";
import { APIData } from "../common/DataTypes";
import ReactPaginate from "react-paginate";
import Listing from "../components/Svg/Listing";
import { numberWithCommas } from "../common/Utilities";
import { InitialData } from "../components/Form/InitialData";
import SortBy from "../components/template/SortBy";
import { Menu } from "@mui/material";
import MetaDecorator from "../components/MetaDecorator";
import SocialMediaShare from "../components/SocialMediaShare";

const ProfileListingRow: React.FC = () => {

    const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);
    const [bondsList, setBondsList] = useState<APIData.BondlistData[]>([]);
    const { setShowPreloader } = useAppStateAPI();
    const navigate = useNavigate();
    const [pageno, setPageno] = useState(1);
    const [totalCount, setTotalCount] = useState(0);
    const [searchText, setSeatchText] = useState('');
    const [searchBarText, setSeatchBarText] = useState(''); const [selectedFilters, setSelectedFilters] = useState<APIData.Filters>({ ...InitialData.FilterData });
    const [bondsFilters, setBondsFilters] = useState<APIData.BondFilters>();
    const sites = ['facebook', 'twitter', 'whatsapp', 'telegram', 'linkedin', 'mail'];

    const handleSelectedFilter = (type: string, updateValue: number, checked: boolean) => {
        console.log(type);
        console.log(updateValue);
        console.log(checked);
        if (checked === true) {
            if (type === 'brand_tag') {
                setSelectedFilters((selFilters: any) => ({ ...selFilters, brand_tag_id: [...selectedFilters.brand_tag_id, updateValue + ""] }))
            } else if (type === 'yield_from') {
                setSelectedFilters((selFilters: any) => ({ ...selFilters, yield_from: updateValue }))
            } else if (type === 'yield_to') {
                setSelectedFilters((selFilters: any) => ({ ...selFilters, yield_to: updateValue }))
            } else if (type === 'tenure_from') {
                setSelectedFilters((selFilters: any) => ({ ...selFilters, tenure_from: updateValue }))
            } else if (type === 'tenure_to') {
                setSelectedFilters((selFilters: any) => ({ ...selFilters, tenure_to: updateValue }))
            } else if (type === 'rating') {
                setSelectedFilters((selFilters: any) => ({ ...selFilters, bond_rating_list_id: [...selFilters.bond_rating_list_id, updateValue + ""] }))
            } else if (type === 'frequency') {
                setSelectedFilters((selFilters: any) => ({ ...selFilters, payment_frequency: [...selFilters.payment_frequency, updateValue + ""] }))
            }
        } else {
            if (type === 'brand_tag') {
                setSelectedFilters((selFilters: any) => ({ ...selFilters, brand_tag_id: selFilters.brand_tag_id.filter((val: any) => val != updateValue) }));
            } else if (type === 'yield_from') {
                setSelectedFilters((selFilters: any) => ({ ...selFilters, yield_from: 0 }))
            } else if (type === 'yield_to') {
                setSelectedFilters((selFilters: any) => ({ ...selFilters, yield_to: 0 }))
            } else if (type === 'tenure_from') {
                setSelectedFilters((selFilters: any) => ({ ...selFilters, tenure_from: 0 }))
            } else if (type === 'tenure_to') {
                setSelectedFilters((selFilters: any) => ({ ...selFilters, tenure_to: 0 }))
            } else if (type === 'frequency') {
                setSelectedFilters((selFilters: any) => ({ ...selFilters, payment_frequency: selFilters.payment_frequency.filter((val: any) => val != updateValue) }));
            } else if (type === 'rating') {
                setSelectedFilters((selFilters: any) => ({ ...selFilters, bond_rating_list_id: selFilters.bond_rating_list_id.filter((val: any) => val != updateValue) }));
            }
        }
    }

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
        console.log('clicked');
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const bondFilters = () => {
        setShowPreloader(true);
        ServerAPI.GetBondFilter().then((response: any) => {
            if (response != undefined) {
                setBondsFilters(response);
            }
        }).finally(() => {
            setShowPreloader(false);
        })
    }

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    const goToItemDetail = (bond_type: number, id: number, isin: string) => {

        if (bond_type == bondTypes.ADDEDBOND || bond_type == bondTypes.EXISTINGBOND) {
            navigate({
                pathname: PageLinks.DETAIL_VIEW_ONE,
                search: "?isin=" + isin,
            });
        } else if (bond_type == bondTypes.IPO) {
            navigate({
                pathname: PageLinks.BOND_IPO_FLOW,
                search: "?id=" + id,
            });
        } else if (bond_type == bondTypes.SGB) {
            navigate({
                pathname: PageLinks.SOVEREIGN_BONDS_LANDING_PAGE,
                search: "?isin=" + isin,
            });
        }
    }

    const bondlist = (selectedFilters: APIData.Filters) => {
        localStorage.setItem('animation', 'default');
        setShowPreloader(true);
        ServerAPI.BondsList(pageno, '', selectedFilters).then((response: any) => {
            if (response != undefined) {
                setBondsList(response['data']);
                setTotalCount(response['total'])
            }
        }).finally(() => {
            setShowPreloader(false);
        })
    }
    const bondlistWithSearch = (text: string) => {
        console.log(text);
        localStorage.setItem('animation', 'default');
        setShowPreloader(true);
        ServerAPI.BondsList(pageno, text).then((response: any) => {
            if (response != undefined) {
                setBondsList(response['data']);
                setTotalCount(response['total'])
            }
        }).finally(() => {
            setShowPreloader(false);
        })
    }
    useEffect(() => {
        bondlist(selectedFilters);
        bondFilters();
    }, [])

    useEffect(() => {
        bondlist(selectedFilters);
    }, [pageno])

    useEffect(() => {
        if (searchBarText == '') {
            bondlistWithSearch(searchBarText);
        }
    }, [searchBarText])

    const filterApply = () => {
        bondlist(selectedFilters);
    }

    return (
        <div className="main-index-wrapper">
            <Header />
            <SlideBar />
            <div className="body-overlay"></div>
            <main>
                <section className="page-title-area listing-search fix pb-20 pt-110">
                    <div className="container-fluid">
                        <div className="row align-items-center">
                            <div className="col-lg-12">
                                <div className="widget-search-content faq-que-search">
                                    <form className="subscribe-form" action="form.php">
                                        <i className="far fa-search"></i>
                                        <input type="text" placeholder="Bond name, issuer or bond type" onChange={(e: any) => {
                                            setSeatchBarText(e.target.value);
                                        }} />
                                        <button className="trustBlackbtn" onClick={(e: any) => {
                                            e.preventDefault();
                                            let value = searchBarText + "";
                                            value = value.toUpperCase();
                                            setSeatchText(value);
                                        }}>Search</button>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="profile_listing_row profile_listing pt-30 pb-110 pt-md-45 pb-md-40 pt-xs-45 pb-xs-40">
                    <div className="container-fluid">
                        <div className="row">
                            {bondsFilters != undefined &&
                                <div className="col-lg-3">
                                    <div className="filter_box">
                                        {bondsFilters.brand_tags != undefined && bondsFilters.brand_tags.length > 0 &&
                                            <div className="filter_head">
                                                Investment Purpose
                                            </div>
                                        }
                                        <span className="input-title-filled gender_col" role="group">
                                            {bondsFilters.brand_tags != undefined && bondsFilters.brand_tags.length > 0 && bondsFilters.brand_tags.map((tags: APIData.FilterBrandTags, index: number) => {
                                                return (
                                                    <div className={selectedFilters.brand_tag_id.includes(tags.brand_tag_id + "") ? "gender_btn selected" : "gender_btn"}>
                                                        <input type="checkbox" className="d-none"
                                                            onClick={(e: any) => {
                                                                handleSelectedFilter('brand_tag', tags.brand_tag_id, e.target.checked);
                                                            }}
                                                            name={tags.brand_tag_name} id={tags.brand_tag_id + ""} value={tags.brand_tag_id} />
                                                        <label htmlFor={tags.brand_tag_id + ""} className="gender_btn-label">{tags.brand_tag_name}</label>
                                                    </div>
                                                )
                                            })}
                                        </span>
                                    </div>
                                    <div className="filter_box">
                                        {bondsFilters.bond_ratings != undefined && bondsFilters.bond_ratings.length > 0 &&
                                            <div className="filter_head">
                                                Safety (Credit Rating)
                                            </div>
                                        }
                                        <span className="input-title-filled gender_col" role="group">
                                            {bondsFilters.bond_ratings != undefined && bondsFilters.bond_ratings.length > 0 && bondsFilters.bond_ratings.map((rating: APIData.FilterBondRatings) => {
                                                return (
                                                    <div className={selectedFilters.bond_rating_list_id.includes(rating.bond_rating_list_id + "") ? "gender_btn selected" : "gender_btn"}>
                                                        <input type="checkbox" onClick={(e: any) => { handleSelectedFilter('rating', rating.bond_rating_list_id, e.target.checked) }} className="d-none" name={rating.bond_rating_name} id={"rating" + rating.bond_rating_list_id + ""} value={rating.bond_rating_list_id} />
                                                        <label htmlFor={"rating" + rating.bond_rating_list_id + ""} className="gender_btn-label">{rating.bond_rating_name}</label>
                                                    </div>
                                                )

                                            })}
                                        </span>
                                    </div>
                                    <div className="filter_box">
                                        <div className="filter_head">
                                            Yield
                                        </div>
                                        <span className="input-title-filled gender_col" role="group">
                                            <div className={selectedFilters.yield_to == 8 ? "gender_btn selected" : "gender_btn"}>
                                                <input type="checkbox" className="d-none" name="yield_1" id="yield_1" value="1" onClick={(e: any) => { handleSelectedFilter('yield_from', 1, e.target.checked); handleSelectedFilter('yield_to', 8, e.target.checked) }} />
                                                <label htmlFor="yield_1" className="gender_btn-label">Upto 8%</label>
                                            </div>
                                            <div className={selectedFilters.yield_to == 11 ? "gender_btn selected" : "gender_btn"}>
                                                <input type="checkbox" className="d-none" name="yield_2" id="yield_2" value="2" onClick={(e: any) => { handleSelectedFilter('yield_from', 8, e.target.checked); handleSelectedFilter('yield_to', 11, e.target.checked) }} />
                                                <label htmlFor="yield_2" className="gender_btn-label">8 - 11%</label>
                                            </div>
                                            <div className={selectedFilters.yield_to == 100 ? "gender_btn selected" : "gender_btn"}>
                                                <input type="checkbox" className="d-none" name="yield_3" id="yield_3" value="3" onClick={(e: any) => { handleSelectedFilter('yield_from', 11, e.target.checked); handleSelectedFilter('yield_to', 100, e.target.checked) }} />
                                                <label htmlFor="yield_3" className="gender_btn-label">11% +</label>
                                            </div>
                                        </span>
                                    </div>
                                    <div className="filter_box">
                                        {bondsFilters.payment_frequency != undefined && bondsFilters.payment_frequency.length > 0 &&
                                            <div className="filter_head">
                                                Payment Frequency
                                            </div>
                                        }
                                        <span className="input-title-filled gender_col" role="group">
                                            {bondsFilters.payment_frequency != undefined && bondsFilters.payment_frequency.length > 0 && bondsFilters.payment_frequency.map((frequency: APIData.FilterPaymentFrequency) => {
                                                return (
                                                    <div className={selectedFilters.payment_frequency.includes(frequency.payment_frequency_type + "") ? "gender_btn selected" : "gender_btn"}>
                                                        <input type="checkbox" className="d-none" name={"frequency" + frequency.payment_frequency_type + ""} id={"frequency" + frequency.payment_frequency_type + ""} value={frequency.payment_frequency_type} onClick={(e: any) => { handleSelectedFilter('frequency', frequency.payment_frequency_type, e.target.checked) }} />
                                                        {frequency.payment_frequency_type == interestPaymentFrequency.FOR_ANNUAL &&
                                                            <label htmlFor={"frequency" + frequency.payment_frequency_type + ""} className="gender_btn-label">Annual</label>
                                                        }
                                                        {frequency.payment_frequency_type == interestPaymentFrequency.FOR_MONTHLY &&
                                                            <label htmlFor={"frequency" + frequency.payment_frequency_type + ""} className="gender_btn-label">Monthly</label>
                                                        }
                                                        {frequency.payment_frequency_type == interestPaymentFrequency.FOR_QUARTERLY &&
                                                            <label htmlFor={"frequency" + frequency.payment_frequency_type + ""} className="gender_btn-label">Quarterly</label>
                                                        }
                                                        {frequency.payment_frequency_type == interestPaymentFrequency.FOR_SEMI_ANNUAL &&
                                                            <label htmlFor={"frequency" + frequency.payment_frequency_type + ""} className="gender_btn-label">Semi-Annual</label>
                                                        }
                                                    </div>
                                                )
                                            })
                                            }
                                        </span>
                                    </div>
                                    <div className="filter_box">
                                        <div className="filter_head">
                                            Tenure Remaining
                                        </div>
                                        <span className="input-title-filled gender_col" role="group">
                                            <div className={selectedFilters.tenure_to == 1 ? "gender_btn selected" : "gender_btn"}>
                                                <input type="checkbox" className="d-none" name="tenure_1" id="tenure_1" value="1" onClick={(e: any) => { handleSelectedFilter('tenure_from', 0, e.target.checked); handleSelectedFilter('tenure_to', 1, e.target.checked) }} />
                                                <label htmlFor="tenure_1" className="gender_btn-label">Less than 1 year</label>
                                            </div>
                                            <div className={selectedFilters.tenure_to == 5 ? "gender_btn selected" : "gender_btn"}>
                                                <input type="checkbox" className="d-none" name="tenure_2" id="tenure_2" value="2" onClick={(e: any) => { handleSelectedFilter('tenure_from', 1, e.target.checked); handleSelectedFilter('tenure_to', 5, e.target.checked) }} />
                                                <label htmlFor="tenure_2" className="gender_btn-label">1 to 5 Years</label>
                                            </div>
                                            <div className={selectedFilters.tenure_to == 10 ? "gender_btn selected" : "gender_btn"}>
                                                <input type="checkbox" className="d-none" name="tenure_3" id="tenure_3" value="3" onClick={(e: any) => { handleSelectedFilter('tenure_from', 5, e.target.checked); handleSelectedFilter('tenure_to', 10, e.target.checked) }} />
                                                <label htmlFor="tenure_3" className="gender_btn-label">5 to 10 Years</label>
                                            </div>
                                            <div className={selectedFilters.tenure_to == 100 ? "gender_btn selected" : "gender_btn"}>
                                                <input type="checkbox" className="d-none" name="tenure_4" id="account_type_1_option_1" value="4" onClick={(e: any) => { handleSelectedFilter('tenure_from', 10, e.target.checked); handleSelectedFilter('tenure_to', 100, e.target.checked) }} />
                                                <label htmlFor="tenure_4" className="gender_btn-label">More than 10 years</label>
                                            </div>
                                        </span>
                                    </div>
                                    <div className="spotlight_readmore_box">
                                        <a className="trustclearbtn" onClick={(e: any) => {
                                            e.preventDefault();
                                            bondlist({ ...InitialData.FilterData });
                                            setSelectedFilters({ ...InitialData.FilterData });
                                        }}>Clear All</a>
                                        <a className="spotlight_readmore" style={{ color: "#fff" }} onClick={(e: any) => {
                                            e.preventDefault();
                                            filterApply();
                                        }}>Apply Filters</a>
                                    </div>
                                </div>
                            }
                            <div className="col-lg-9">
                                <div className="row align-items-left">
                                    <div className="col-lg-6 breadcums">
                                        <nav>
                                            <ul>
                                                <li>Home</li>
                                                <li>Bonds</li>
                                                <li className="active">Explore Bonds</li>
                                            </ul>
                                        </nav>
                                    </div>
                                    <div className="col-lg-6">
                                        <div className="sort">
                                            <Link to={PageLinks.PROFILE_LISTING}><div className="sort_column_light"><i className="fas fa-th-large"></i></div></Link>
                                            <Link to={PageLinks.PROFILE_LISTING_ROW}><div className="sort_row_dark"><Listing /></div></Link>
                                            <SortBy />
                                            <Menu
                                                id={id}
                                                open={open}
                                                anchorEl={anchorEl}
                                                onClose={handleClose}
                                                anchorOrigin={{
                                                    vertical: 'bottom',
                                                    horizontal: 'right',
                                                }}
                                                transformOrigin={{
                                                    vertical: 'top',
                                                    horizontal: 'right',
                                                }}
                                                className="sort-list-popover"
                                            >
                                                <div className="sort-list-content p-10">
                                                    <div className="filter_head">
                                                        Investment Purpose
                                                    </div>
                                                    <span className="input-title-filled gender_col" role="group">
                                                        <div className="gender_btn">
                                                            <input type="radio" className="d-none" name="account_type_1" id="account_type_1_option_1" value="1" />
                                                            <label htmlFor="account_type_1_option_1" className="gender_btn-label">Earn higher fixed income than FDs</label>
                                                        </div>
                                                        <div className="gender_btn">
                                                            <input type="radio" className="d-none" name="account_type_1" id="account_type_1_option_1" value="1" />
                                                            <label htmlFor="account_type_1_option_1" className="gender_btn-label">Pledge for F&O</label>
                                                        </div>
                                                        <div className="gender_btn">
                                                            <input type="radio" className="d-none" name="account_type_1" id="account_type_1_option_1" value="1" />
                                                            <label htmlFor="account_type_1_option_1" className="gender_btn-label">Invest for short term</label>
                                                        </div>
                                                        <div className="gender_btn selected">
                                                            <input type="radio" className="d-none" name="account_type_1" id="account_type_1_option_1" value="1" />
                                                            <label htmlFor="account_type_1_option_1" className="gender_btn-label">Invest in NRI eligible bonds</label>
                                                        </div>
                                                    </span>

                                                    <div className="filter_head">
                                                        Safety (Credit Rating)
                                                    </div>
                                                    <span className="input-title-filled gender_col" role="group">
                                                        <div className="gender_btn">
                                                            <input type="radio" className="d-none" name="account_type_1" id="account_type_1_option_1" value="1" />
                                                            <label htmlFor="account_type_1_option_1" className="gender_btn-label">AAA (High Safety)</label>
                                                        </div>
                                                        <div className="gender_btn selected">
                                                            <input type="radio" className="d-none" name="account_type_1" id="account_type_1_option_1" value="1" />
                                                            <label htmlFor="account_type_1_option_1" className="gender_btn-label">AA (Balanced)</label>
                                                        </div>
                                                        <div className="gender_btn">
                                                            <input type="radio" className="d-none" name="account_type_1" id="account_type_1_option_1" value="1" />
                                                            <label htmlFor="account_type_1_option_1" className="gender_btn-label">A & Below (High Yield)</label>
                                                        </div>
                                                    </span>

                                                    <div className="spotlight_readmore_box">
                                                        <a className="trustclearbtn" href="#">Clear All</a>
                                                        <a className="spotlight_readmore" href="#">Apply Filters</a>
                                                    </div>
                                                </div>
                                            </Menu>
                                        </div>
                                    </div>
                                </div>
                                {bondsList != undefined && bondsList.length > 0 ?
                                    <div className="row align-items-center listing_row">
                                        {bondsList.map((bond: APIData.BondlistData) => {
                                            return (
                                                <div className="col-lg-12 col-md-6 " data-wow-delay="0.1s">
                                                    <MetaDecorator title={bond.bond_name} description={"High Interest Rate Bonds"} image={bond.bond_logo ? bond.bond_logo : "assets/img/img_placeholder.png"} content={"https://trustmony.com"} />
                                                    <div className="do-box do-box-2 mb-30 d-flex">
                                                        <div className="icon-box">
                                                            <div className="icon-img">
                                                                <img src={bond.bond_logo ? bond.bond_logo : "assets/img/img_placeholder.png"} alt="" />
                                                            </div>
                                                            <div className="icon-head">
                                                                {bond.bond_name}
                                                            </div>
                                                            <SocialMediaShare url={bond.share_link} />
                                                        </div>
                                                        <div className="coupon_box">
                                                            <div className="col-lg-9 p-0">
                                                                <div className="spotlight_box_one">
                                                                    <div className="spotlight_box_inner">
                                                                        <div className="spotlight_box_inner_text1">Coupon</div>
                                                                        <div className="spotlight_box_inner_text2">{bond.bond_coupon_on != null && bond.bond_coupon_on != '' ? bond.bond_coupon_on + "%" : "N/A"}</div>
                                                                    </div>
                                                                    <div className="spotlight_box_inner">
                                                                        <div className="spotlight_box_inner_text1">Yeild</div>
                                                                        <div className="spotlight_box_inner_text2">{bond.bonds_yeild != null && bond.bonds_yeild != '' ? bond.bonds_yeild + "%" : "N/A"}</div>
                                                                    </div>
                                                                </div>
                                                                <div className="spotlight_box_two">
                                                                    <div className="spotlight_box_inner">
                                                                        <div className="spotlight_box_inner_text1">Interest Payment</div>
                                                                        <div className="spotlight_box_inner_text2">{parseInt(bond?.bond_issuer_frequency_of_interest!) == interestPaymentFrequency.FOR_ANNUAL &&
                                                                            "Annual"
                                                                        }
                                                                            {parseInt(bond?.bond_issuer_frequency_of_interest!) == interestPaymentFrequency.FOR_MONTHLY &&
                                                                                "Monthly"
                                                                            }
                                                                            {parseInt(bond?.bond_issuer_frequency_of_interest!) == interestPaymentFrequency.FOR_QUARTERLY &&
                                                                                "Quarterly"
                                                                            }
                                                                            {parseInt(bond?.bond_issuer_frequency_of_interest!) == interestPaymentFrequency.FOR_SEMI_ANNUAL &&
                                                                                "Semi-Annual"
                                                                            }
                                                                            {bond?.bond_issuer_frequency_of_interest == null &&
                                                                                "N/A"
                                                                            }{parseInt(bond?.bond_issuer_frequency_of_interest!) == 0 &&
                                                                                "N/A"
                                                                            }</div>
                                                                    </div>
                                                                    <div className="spotlight_box_inner">
                                                                        <div className="spotlight_box_inner_text1">Min. Investment</div>
                                                                        <div className="spotlight_box_inner_text2">{bond.bond_minimum_application != null && bond.bond_minimum_application != '' ? "₹" + numberWithCommas(bond.bond_minimum_application) : "N/A"}</div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="col-lg-3 p-1">
                                                                <div className="speed-img">
                                                                    <img src={bond.bond_rating_image != null && bond.bond_rating_image != "https://trust-api.trustmony.com/api/v1/image_download?name=None" && bond.bond_rating_image != "https://api.trustmony.com/api/v1/image_download?name=None" ? bond.bond_rating_image : "../assets/img/no_rating.png"} alt="" />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="spotlight_readmore_box">
                                                            <div className="ipo_text">
                                                                {bond.bond_type == bondTypes.IPO &&
                                                                    "IPO"
                                                                }</div>
                                                            <button onClick={(e: any) => {
                                                                e.preventDefault();
                                                                goToItemDetail(bond.bond_type, bond.bond_type != bondTypes.IPO ? 0 : bond.bond_id, bond.bond_type == bondTypes.IPO ? '' : bond.bond_isin_number)
                                                            }} className="spotlight_readmore bonds-detail-btn">Read More</button>
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                        })}
                                        <div className="">
                                            <ReactPaginate
                                                breakLabel="..."
                                                onPageChange={(e: any) => {
                                                    setPageno(e.selected + 1);
                                                }}
                                                nextLabel=">"
                                                pageRangeDisplayed={5}
                                                pageCount={totalCount / 50}
                                                previousLabel="<"
                                                containerClassName="pagination"
                                                breakClassName="page-item"
                                                breakLinkClassName="page-link"
                                                activeClassName="active"
                                                pageClassName="page-item"
                                                pageLinkClassName="page-link"
                                                previousClassName="page-item"
                                                previousLinkClassName="page-link"
                                                nextClassName="page-item"
                                                nextLinkClassName="page-link"
                                            />
                                        </div>
                                    </div>
                                    :
                                    <div className="text-center mt-5">
                                        <img src="assets/img/no_result.png" />
                                        <p className="source sourceSansPro-bold no-result-main">No Results Found</p>
                                        <p className="source sourceSansPro-regular no-result-sub">Try changing the filters or search for a different keyword.</p>
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                </section>
                <InvestSteps />
                <Subscribe />
                {/* <NeedHelp /> */}
            </main>
            <Footer />
        </div>
    )
}

export default ProfileListingRow;
