import { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useAppStateAPI } from "../common/AppStateAPI";
import { kycStatus, PageLinks } from "../common/Constants";
import { Formik } from "formik";
import { APIData } from "../common/DataTypes";
import { InitialData } from "../components/Form/InitialData";
import { ServerAPI } from "../common/ServerAPI";
import toast from "react-hot-toast";
import StepsComponent from "../components/StepsComponent";
import { kycStatusUpdate } from "../common/Utilities";
import SignatureExample from "../components/Modals/SignatureExample";
import ConfirmationKYCModel from "../components/Modals/ConfirmationKYCModel";

const DematDetails: React.FC = () => {
    const [exisitingDematDetails, setExisitingDematDetails] = useState<APIData.ExistingDematAccount>(InitialData.ExistingDematAccount);
    const [depositories, setDepositories] = useState<APIData.Depositories[]>([]);
    const [dematPage, setDematPage] = useState(1);
    const [dematSignUpload, setDematSignUpload] = useState(false);
    const navigate = useNavigate();
    const location = useLocation();
    const [wealthSource, setWealthSource] = useState<APIData.WealthSource[]>([]);
    const [bornCity, setBornCity] = useState<APIData.WhereYouBorn[]>([]);
    const [newDematDetails, setNewDematDetails] = useState<APIData.NewDematAccount>(InitialData.newDematAccount);
    const prevData: any = location.state;
    const [signUrl, setSignUrl] = useState<string>('');
    const [dematName, setDematName] = useState<string>('');
    const { setShowPreloader } = useAppStateAPI();
    let AccountRedirect = localStorage.getItem('demat');
    const [userDetail, setUserDetails] = useState<APIData.userDetail>();
    const [residentusa, setResidentusa] = useState(false);
    const [showSample, setShowSample] = useState(false);
    const [KycExit, setKycExit] = useState(false);

    useEffect(() => {
        setShowPreloader(true);
        ServerAPI.userDetailData().then((res: any) => {
            setUserDetails(res);
        }).finally(() => {
            setShowPreloader(false);
        })
    }, [])

    const validate = (values: APIData.ExistingDematAccount) => {
        let errors: any = {};
        if (values.depository == 0) {
            errors.depository = "Type of Account is required";
        }
        if (dematName == '') {
            errors.dp_name = "Please Enter Your DP Name";
        }
        if (values.dp_id == '') {
            errors.dp_id = "Please Enter Your DP ID";
        }
        if (values.dp_id != values.dp_id_confirm) {
            errors.dp_id_confirm = "DP -ID Does Not Match !";
        }
        return errors;
    }

    const validate_new = (values: APIData.NewDematAccount) => {
        let errors: any = {};
        if (values.born_place == '') {
            errors.born_place = "Select Country You were Born ";
        }
        if (values.dp_resident_india == 0) {
            errors.dp_resident_india = "Please Select the Above to Continue";
        }

        if (values.primary_source == '') {
            errors.primary_source = "Please Select Your Primary Source of Wealth";
        }
        if (values.check_box_account_statement_electronic == 0) {
            errors.check_box_account_statement_electronic = "Please Select the Checkbox to Proceed";
        }
        if (values.check_box_terms_selected == 0) {
            errors.check_box_terms_selected = "Please Select the Checkbox to Proceed";
        }
        return errors;
    }

    useEffect(() => {
        setShowPreloader(true);
        ServerAPI.Depository().then((response: any) => {
            setDepositories(response);
        }).finally(() => {
            setShowPreloader(false);
        })
        setShowPreloader(true);
        ServerAPI.WealthSource().then((response) => {
            setWealthSource(response);
        }).finally(() => {
            setShowPreloader(false);
        })
        setShowPreloader(true);
        ServerAPI.BornCity().then((response) => {
            setBornCity(response);
        }).finally(() => {
            setShowPreloader(false);
        })
    }, [])

    const addNewDematDetails = (values: APIData.NewDematAccount) => {
        let dematvalues = { ...values };
        dematvalues.demat_signature_image = signUrl;
        localStorage.setItem('animation', 'default');
        setShowPreloader(true);
        ServerAPI.newDematAdd(dematvalues).then((response: any) => {
            if (response['message'] != undefined) {
                toast.success(response['message']);
                navigate(PageLinks.SIGNATURE_SUCCESS);
            }
        }).finally(() => {
            setShowPreloader(false);
        })
    }

    const loadUploadedfile = (fileInput: any) => {
        let filetype = fileInput.files[0].type;
        filetype = filetype.split("/")[0];
        if (filetype == 'image') {
            setShowPreloader(true);
            ServerAPI.UploadSignature(fileInput.files[0]).then(response => {
                if (response.message != undefined && response.message != "") {
                    setSignUrl(response.message);
                    toast.success('Image Uploaded Successfully');
                } else {
                    toast.error('unable to upload the image please try again!');
                }
            }).finally(() => {
                setShowPreloader(false);
            })
        } else {
            toast.error("Uploaded file is not an Image!");
        }
    }


    return (
        <div className="main-index-wrapper">
            <main>
                <section className="subscribe-letter-area pt-20 pb-20">
                    <div className="line-shape d-none d-lg-inline-block">
                        <img className="img-fluid" src="assets/img/Intersection_4.svg" alt="" />
                    </div>

                    <div className="container custom-container-subs">
                        <div className="sub-bg">
                            <div className="row justify-content-center">
                                <div className="col-xl-12">
                                    <div className="subscribe-wrapper">
                                        <div className={AccountRedirect != 'true' ? "section-title section-title-3 text-center" : "section-title section-title-3 text-center mb-5"}>
                                            <img className="header-logo" src="assets/img/logo/header_logo_one.png" alt="logo" />
                                        </div>
                                    </div>
                                    {AccountRedirect != 'true' &&
                                        <StepsComponent step={3} />
                                    }
                                    <div className="step_box_content">
                                        <div className="sub-bg">
                                            <div className="row justify-content-center">
                                                <div className="col-xl-12">
                                                    <div className="subscribe-wrapper">
                                                        <div className="section-title section-title-3">
                                                            <h6 className="wow page-main-heading" data-wow-delay="0.1s">{dematPage == 1 ? "Demat Details" : "Open Demat Account"}{dematPage == 2 && dematSignUpload == false && <span className="page-main-heading-steps">STEPS - 1 of 4</span>}{dematPage == 2 && dematSignUpload == true && <span className="page-main-heading-steps">STEPS - 2 of 4</span>}</h6>
                                                            <hr></hr>
                                                            <h6 className="pt-20 mb-10 " data-wow-delay="0.1s">Hey {userDetail?.pan_name}, Enter Your Demat Account Information</h6>
                                                            <div className="col-xl-12 p-0">
                                                                <div className="choice-list">
                                                                    {dematPage == 2 && dematSignUpload == true ?
                                                                        <div className="col-xl-8 pl-0 float-left">
                                                                            <h6 className="pt-20 mb--10  pb-10" data-wow-delay="0.1s">Signature Upload</h6>
                                                                            <p className="personal_sub_text">Let’s Upload your Signature</p>
                                                                        </div>
                                                                        :
                                                                        <span className="input-title-filled gender_col">
                                                                            <div className={dematPage == 1 ? "gender_btn selected" : "gender_btn"} >
                                                                                <input onClick={(e: any) => {
                                                                                    if (userDetail?.existing_demat_account_count == 2) {
                                                                                        e.preventDefault();
                                                                                        toast.error("Exisiting Demat Account Limit Reached!")
                                                                                    } else {
                                                                                        e.preventDefault();
                                                                                        setDematPage(1);
                                                                                    }
                                                                                }}
                                                                                    type="radio" value={1} className="d-none" name="gender" id="gender_option_1" />
                                                                                <label
                                                                                    htmlFor="gender_option_1"
                                                                                    className={dematPage == 1 ? "gender-btn-label-selected" : "gender_btn-label"}
                                                                                >
                                                                                    Existing Demat
                                                                                </label>
                                                                            </div>
                                                                            <div className={dematPage == 2 ? "gender_btn selected" : "gender_btn"}>
                                                                                <input onClick={(e: any) => {
                                                                                    if (userDetail?.new_demat_account_count == 1) {
                                                                                        e.preventDefault();
                                                                                        toast.error("New Demat Account Limit Reached!")
                                                                                    } else {
                                                                                        e.preventDefault();
                                                                                        setDematPage(2);
                                                                                    }

                                                                                }} type="radio" value={2} className="d-none" name="gender" id="gender_option_2" />
                                                                                <label
                                                                                    htmlFor="gender_option_2"
                                                                                    className={dematPage == 2 ? "gender-btn-label-selected" : "gender_btn-label"}
                                                                                >
                                                                                    Open New Demat
                                                                                </label>
                                                                            </div>
                                                                        </span>
                                                                    }

                                                                </div>
                                                            </div>
                                                            {dematPage == 1 &&
                                                                <Formik initialValues={exisitingDematDetails} validate={validate} enableReinitialize onSubmit={(values, { setSubmitting }) => {
                                                                    localStorage.setItem('animation', 'exisitingdemat');
                                                                    if (userDetail?.existing_demat_account_count == 2) {
                                                                        toast.error("Exisiting Demat Account Limit Reached!");
                                                                    } else {
                                                                        setShowPreloader(true);
                                                                        ServerAPI.exisitingDematAccount(values.depository, values.dp_id, dematName).then((res: any) => {
                                                                            if (res != undefined) {
                                                                                toast.success(res['message']);
                                                                                navigate(PageLinks.DEMAT_DETAILS_ADDED_SUCCESS);
                                                                            }
                                                                        }).finally(() => {
                                                                            setShowPreloader(false);
                                                                            localStorage.setItem('animation', 'default');
                                                                        })
                                                                    }
                                                                }}>
                                                                    {({
                                                                        values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting,
                                                                    }) => (
                                                                        <form className="quote-form mb-10" onSubmit={handleSubmit}>
                                                                            <div className="col-xl-12 pl-0 float-left">
                                                                                <div className="col-xl-4 pl-0 float-left mt-20">
                                                                                    <div className="email-input input_field pl-0">
                                                                                        <label className={errors.depository && touched.depository ? "error" : "input-title"}>Depository *</label>
                                                                                        <select
                                                                                            placeholder="Enter Depository"
                                                                                            onChange={handleChange}
                                                                                            onBlur={handleBlur}
                                                                                            className={errors.depository && touched.depository ? "input-error" : ""} name="depository" value={values.depository}>
                                                                                            <option value={0}>Select</option>
                                                                                            {depositories != undefined && depositories.length > 0 && depositories.map((depository: APIData.Depositories, index) => {
                                                                                                return (
                                                                                                    <option value={depository.depository_id}>{depository.depository}</option>
                                                                                                )

                                                                                            })}
                                                                                        </select>
                                                                                        {errors.depository && touched.depository && (
                                                                                            <span className="error">{errors.depository}</span>
                                                                                        )}
                                                                                    </div>
                                                                                </div>
                                                                                <div className="col-xl-4 pl-0 float-left mt-20">
                                                                                    <div className="email-input input_field pl-0">
                                                                                        <label className={errors.dp_name && touched.dp_name ? "error" : "input-title"}>DP - Name *</label>
                                                                                        <input type="text" placeholder="Enter DP Name" name="dp_name" value={dematName}
                                                                                            onChange={(e: any) => {
                                                                                                let value = e.target.value;
                                                                                                const re =/^[a-zA-Z ]*$/;
                                                                                                if (value === "" || re.test(value)) {
                                                                                                    setDematName(value);
                                                                                                }
                                                                                            }}
                                                                                            onBlur={handleBlur}
                                                                                            className={errors.dp_name && touched.dp_name ? "input-error" : ""}
                                                                                        />
                                                                                        {errors.dp_name && touched.dp_name && (
                                                                                            <span className="error">{errors.dp_name}</span>
                                                                                        )}
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div className="col-xl-12 pl-0 float-left">
                                                                                <div className="col-xl-4 pl-0 float-left mt-20">
                                                                                    <div className="email-input input_field pl-0">
                                                                                        <label className={errors.dp_id && touched.dp_id ? "error" : "input-title"}>DP - ID *</label>
                                                                                        <input type="text" placeholder="Enter DP - ID" name="dp_id" value={values.dp_id}
                                                                                            onChange={handleChange}
                                                                                            onBlur={handleBlur}
                                                                                            maxLength={16}
                                                                                            className={errors.dp_id && touched.dp_id ? "input-error" : ""}
                                                                                        />
                                                                                        {errors.dp_id && touched.dp_id && (
                                                                                            <span className="error">{errors.dp_id}</span>
                                                                                        )}
                                                                                    </div>
                                                                                </div>
                                                                                <div className="col-xl-4 pl-0 float-left mt-20">
                                                                                    <div className="email-input input_field pl-0">
                                                                                        <label className={errors.dp_id_confirm && touched.dp_id_confirm ? "error" : "input-title"}>Re-enter DP - ID *</label>
                                                                                        <input type="text" placeholder="ReEnter DP - ID" name="dp_id_confirm" value={values.dp_id_confirm}
                                                                                            onChange={handleChange}
                                                                                            onBlur={handleBlur}
                                                                                            maxLength={16}
                                                                                            className={errors.dp_id_confirm && touched.dp_id_confirm ? "input-error" : ""}
                                                                                        />
                                                                                        {errors.dp_id_confirm && touched.dp_id_confirm && (
                                                                                            <span className="error">{errors.dp_id_confirm}</span>
                                                                                        )}
                                                                                    </div>
                                                                                </div>

                                                                            </div>
                                                                            <div className="col-xl-12 continue_btn_whole mb-3 mt-5">
                                                                                <button type="submit" className="theme_btn primary_btn_one sub-btn wow " data-wow-delay="0.5s">Add Demat Account</button>
                                                                            </div>
                                                                            <div onClick={(e: any) => {
                                                                                e.preventDefault();
                                                                                setKycExit(true);
                                                                            }} className="save_continue_btn text-center cursor"><u>Save & Complete Later</u></div>
                                                                        </form>
                                                                    )}
                                                                </Formik>
                                                            }
                                                            {dematPage == 2 && dematSignUpload == false &&
                                                                <Formik initialValues={newDematDetails} validate={validate_new} enableReinitialize onSubmit={(values, { setSubmitting }) => {
                                                                    setNewDematDetails(values);
                                                                    setDematSignUpload(true);
                                                                }}>
                                                                    {({
                                                                        values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting,
                                                                    }) => (
                                                                        <form className="quote-form mb-10" onSubmit={handleSubmit}>
                                                                            <div className="col-xl-8 pl-0 float-left">
                                                                                <h6 className="pt-20 mb--10  pb-10" data-wow-delay="0.1s">Mandatory Questions</h6>
                                                                                <p className="personal_sub_text">Sebi Requires the following details to open your Demat account</p>
                                                                                <div className="col-xl-6 pl-0 float-left mt-20">
                                                                                    <div className="email-input input_field pl-0">
                                                                                        <label className="input-title">Where were you born?</label>
                                                                                        <select name="born_place" className="" placeholder="Select Your Country" onChange={handleChange} onBlur={handleBlur} >
                                                                                            <option value={""}>Select</option>
                                                                                            {bornCity != undefined && bornCity.length > 0 && bornCity.map((country: APIData.WhereYouBorn, index) => {
                                                                                                return (
                                                                                                    <option key={index} value={country.country_name}>{country.country_name}</option>
                                                                                                )
                                                                                            })

                                                                                            }
                                                                                        </select>
                                                                                    </div>
                                                                                    {errors.born_place && touched.born_place && (
                                                                                        <span className="error">{errors.born_place}</span>
                                                                                    )}
                                                                                </div>
                                                                            </div>
                                                                            <div className="col-xl-4 p-0 float-left">
                                                                            </div>
                                                                            <div className="col-xl-8 p-0 float-left">
                                                                                <div className="col-xl-6 pl-0 float-left mt-20">
                                                                                    <div className="choice-list political_switch">
                                                                                        <span className="input-title">Are you a citizen of the U.S.A?</span>
                                                                                        <div className="input-title-filled">
                                                                                            <div>
                                                                                                <div className="custom-switch custom-switch-label-yesno pl-0">
                                                                                                    <input className="custom-switch-input" id="citizen" name="dp_resident_usa" type="checkbox" checked={values.dp_resident_usa == 1 ? true : false}
                                                                                                        onChange={(e: any) => {
                                                                                                            if (e.target.checked) {
                                                                                                                navigate(PageLinks.APPOLOGIES_POPUP);
                                                                                                                handleChange(e);
                                                                                                            }
                                                                                                        }}
                                                                                                    />
                                                                                                    <label className="custom-switch-btn" htmlFor="citizen"></label>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="choice-list future_switch">
                                                                                        <span className="input-title">Is Your Country Of Tax Residency, India?</span>
                                                                                        <div className="input-title-filled">
                                                                                            <div className="custom-switch custom-switch-label-yesno pl-0">
                                                                                                <input className="custom-switch-input" id="Residency" type="checkbox" onChange={(e: any) => {
                                                                                                    if (e.target.checked) {
                                                                                                        values.dp_resident_india = 1;
                                                                                                    } else {
                                                                                                        values.dp_resident_india = 0;
                                                                                                    }
                                                                                                }} name="dp_resident_india" />
                                                                                                <label className="custom-switch-btn" htmlFor="Residency"></label>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                    {errors.dp_resident_india && touched.dp_resident_india && (
                                                                                        <span className="error">{errors.dp_resident_india}</span>
                                                                                    )}
                                                                                </div>
                                                                            </div>
                                                                            <div className="col-xl-4 p-0 float-left">

                                                                            </div>
                                                                            <div className="col-xl-8 p-0 float-left">
                                                                                <div className="col-xl-6 pl-0 float-left mt-20">
                                                                                    <div className="email-input input_field pl-0">
                                                                                        <label className="input-title">Your primary source of wealth?</label>
                                                                                        <select className="" name="primary_source" placeholder="Select Your Primary Source" onChange={handleChange} onBlur={handleBlur} >
                                                                                            <option value={""}>Select</option>
                                                                                            {wealthSource != undefined && wealthSource.length > 0 && wealthSource.map((source: APIData.WealthSource, index) => {
                                                                                                return (
                                                                                                    <option key={index} value={source.primary_source_of_wealth_name}>{source.primary_source_of_wealth_name}</option>
                                                                                                )
                                                                                            })

                                                                                            }
                                                                                        </select>
                                                                                        {errors.primary_source && touched.primary_source && (
                                                                                            <span className="error">{errors.primary_source}</span>
                                                                                        )}
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div className="col-xl-4 p-0 float-left">

                                                                            </div>
                                                                            <div className="col-xl-8 p-0 float-left">
                                                                                <label htmlFor="statement" className="review-text mx-1 mb-0 p-0">
                                                                                    <p className="agree-text text-left"><input type="checkbox" className="signup" id="statement" name="check_box_account_statement_electronic signup" onChange={(e: any) => {
                                                                                        if (e.target.checked) {
                                                                                            values.check_box_account_statement_electronic = 1;
                                                                                        } else {
                                                                                            values.check_box_account_statement_electronic = 0;
                                                                                        }
                                                                                    }} /> I am aware & agree that <Link to={"#"}>MY ACCOUNT STATEMENTS</Link> will be in electronic & I do not require a <Link to={"#"}>DIS</Link> slip.</p>
                                                                                </label>
                                                                                {errors.check_box_account_statement_electronic && touched.check_box_account_statement_electronic && (
                                                                                    <span className="error">{errors.check_box_account_statement_electronic}</span>
                                                                                )}
                                                                                <label htmlFor="signup" className="review-text mx-1 mb-0 p-0">
                                                                                    <p className="review-text text-left pb-0"><input type="checkbox" className="signup" id="signup" name="check_box_terms_selected"
                                                                                        onChange={(e: any) => {
                                                                                            if (e.target.checked) {
                                                                                                values.check_box_terms_selected = 1;
                                                                                            } else {
                                                                                                values.check_box_terms_selected = 0;
                                                                                            }
                                                                                        }}
                                                                                    /> I accept the <Link to={"#"}>Terms & Conditions</Link></p>
                                                                                </label>
                                                                                {errors.check_box_terms_selected && touched.check_box_terms_selected && (
                                                                                    <span className="error">{errors.check_box_terms_selected}</span>
                                                                                )}
                                                                            </div>
                                                                            <div className="col-xl-4 p-0 float-left">

                                                                            </div>
                                                                            <div className="divider_15"></div>
                                                                            <div className="col-xl-12 continue_btn_whole mb-3" style={{ display: "inline-block" }}>
                                                                                <button className="theme_btn primary_btn_one sub-btn wow" data-wow-delay="0.5s">Continue</button>
                                                                            </div>
                                                                            <div onClick={(e: any) => {
                                                                                e.preventDefault();
                                                                                setKycExit(true);
                                                                            }} className="save_continue_btn text-center cursor"><u>Save & Complete Later</u></div>
                                                                        </form>
                                                                    )}
                                                                </Formik>
                                                            }
                                                            {dematPage == 2 && dematSignUpload == true &&

                                                                <div className="step_box_content">
                                                                    <div>
                                                                        <div className="">
                                                                            <div className="col-xl-12">
                                                                                <div className="subscribe-wrapper">
                                                                                    <div className="section-title section-title-3">
                                                                                        <form className="quote-form mb-10" action="#">
                                                                                            <div className="col-xl-12 p-0 float-left">
                                                                                                <div className="signature_preview_box">
                                                                                                    <div className="signature_preview_box_head">Signature Preview</div>
                                                                                                    <div className="signature_preview_box_content">
                                                                                                        <p className="signature_preview_box_content_text">Ensure that you sign on a blank paper in a clearly &nbsp;<u className="signup-terms_conditions"><span className="instruc_img_colored_text text-style cursor" onClick={(e: any) => {
                                                                                                            e.preventDefault();
                                                                                                            setShowSample(true);
                                                                                                        }}>See Example</span></u></p>
                                                                                                        <div className="upload_img cursor">
                                                                                                            <label htmlFor="back">
                                                                                                                <img className="cursor"
                                                                                                                    onClick={(e: any) => {
                                                                                                                        if (signUrl != "") {
                                                                                                                            e.preventDefault();
                                                                                                                        }
                                                                                                                    }}
                                                                                                                    src={signUrl != undefined && signUrl != undefined && signUrl != "" ? signUrl : "assets/img/icon/Upload.png"} />
                                                                                                            </label>

                                                                                                            <input
                                                                                                                type="file"
                                                                                                                id="back"
                                                                                                                hidden
                                                                                                                name="demat_signature_image"
                                                                                                                onChange={(e: any) => {
                                                                                                                    loadUploadedfile(e.target);
                                                                                                                }}
                                                                                                            />
                                                                                                        </div>
                                                                                                        <p className="upload_img_text">Upload your Signature</p>
                                                                                                        {signUrl != "" &&
                                                                                                            <div className="replace-btn-address-proof" >
                                                                                                                <label htmlFor="back">Replace</label></div>
                                                                                                        }
                                                                                                        <br />
                                                                                                        <p className="signature_preview_box_content_text_one">upload your Signature <Link to={'#'}>JPG</Link>, <Link to={'#'}>JPEG</Link> or PNG in less than <Link to={'#'}>10 MB</Link></p>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                            <div className="col-xl-12 p-0 float-left text-center mt-20">
                                                                                                <button type="button" className="theme_btn primary_btn_one sub-btn wow w-auto" data-wow-delay="0.5s" onClick={(e: any) => {
                                                                                                    e.preventDefault();
                                                                                                    addNewDematDetails(newDematDetails);
                                                                                                }}>Continue</button>
                                                                                            </div>
                                                                                        </form>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <SignatureExample showModal={showSample} closeModal={() => { setShowSample(false) }} />
                <ConfirmationKYCModel showModal={KycExit} closeModal={() => { setKycExit(false) }} confirmtype={'demat'} />
                {/* Profile-details-edit-area end */}
            </main>
        </div >
    )
}

export default DematDetails;
