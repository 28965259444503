import moment from "moment";
import React, { Fragment, useEffect, useState } from "react";
import { toast } from "react-hot-toast";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useAppStateAPI } from "../common/AppStateAPI";
import { bondPurchaseTypes, interestPaymentFrequency, listing, NatureofInstruments, PageLinks } from "../common/Constants";
import { APIData } from "../common/DataTypes";
import { ServerAPI } from "../common/ServerAPI";
import { numberWithCommas } from "../common/Utilities";
import DownloadIssueDocuments from "../components/DownloadIssueDocuments";
import { InitialData } from "../components/Form/InitialData";
import InvestSteps from "../components/InvestSteps";
import NeedHelp from "../components/NeedHelp";
import Subscribe from "../components/Subscribe";
import Copy from "../components/Svg/Copy";
import Download from "../components/Svg/Download"
import Footer from "../components/template/Footer";
import Header from "../components/template/Header";

const BondIPOPurchase = () => {
    const location = useLocation();
    const [id, setId] = useState('');
    const [checked, setChecked] = useState<Boolean[]>([]);
    const [qty, setQty] = useState<number[]>([]);
    const [individualTotal, setIndividualTotal] = useState<number[]>([]);
    const [ncds, setNCDs] = useState<APIData.IPONCDOrderSeires[]>([]);
    const [investmentCategory, setInvestmentCategory] = useState<APIData.InvestorCategory[]>([]);
    const [investmentTypes, setInvestmentTypes] = useState<APIData.InvestorTypes[]>([]);
    const [bondsDetail, setBondDetail] = useState<APIData.BondlistData>();
    const [userDetail, setUserDetails] = useState<APIData.userDetail>(InitialData.UserDetails);
    const { setShowPreloader, setIPOData } = useAppStateAPI();
    const [orderTotal, setOrderTotal] = useState(0);
    const [validationFailed, setValidationFailed] = useState(false);
    const [secondPanName, setSecondPanName] = useState('');
    const [secondPanNo, setSecondPanNo] = useState('');
    const [thirdPanName, setThirdPanName] = useState('');
    const [thirdPanNo, setThirdPanNo] = useState('');
    const [categoryid, setCategoryID] = useState(0);
    const [investorID, setInvestorID] = useState(0);
    const [subCategoryCode, setSubCategoryCode] = useState('');
    const navigate = useNavigate();
    const alphabets = ["A", "B", "C", "D", "E", "F", "G", "H", "I", "J", "K", "L", "M", "N"];
    var data: any = {};


    useEffect(() => {
        if (location.search) {
            const query = new URLSearchParams(location.search);
            if (query.has("id")) {
                const id = query.get("id");
                setId((id!));
            }
        }
    }, [location]);

    const goToBuy = (id: number) => {
        let totalQty = 0;
        ncds.map((value: any) => {
            totalQty += parseInt(value.ipo_order_ncd_series_quantity);
        })
        if(investorID==0){
            toast.error("Please Select Investor Type");
        }else if(categoryid==0){
            toast.error("Please Select Investor Category");
        }else if (userDetail.ekyc_application_status == '1') {
            if (totalQty != 0) {
                let items: any[] = [];
                let params: any = {};
                params.ipo_order_quantity = totalQty;
                params.ipo_order_amount = orderTotal;
                params.bond_id = id;
                params.bond_type = bondPurchaseTypes.IPO;
                params.bond_ncd_series = ncds;
                params.ipo_order_second_appl_name = secondPanName;
                params.ipo_order_second_pan = secondPanNo;
                params.ipo_order_third_appl_name = thirdPanName;
                params.ipo_order_third_pan = thirdPanNo;
                params.ipo_order_sub_category_code = subCategoryCode;
                params.ipo_order_investor_category_id = categoryid;
                params.ipo_order_investor_type_id = investorID;
                items.push(params);
                navigate(PageLinks.PAYMENTBOND, { state: { data: items } });
            } else {
                toast.error("Please Select Atleast One NCD Series To Subscribe to this IPO");
            }
        } else {
            toast.error("Please Complete Your KYC Before Proceeding To Buy This Bond")
        }
    }

    // const dataVerify = () => {
    //     let ncddata: any = [];
    //     data = location.state;
    //     if(data!=null){
    //         console.log(data['data'][0].bond_ncd_series);
    //     }
    //     if (data != null && data['data'][0] != undefined && data['data'][0].bond_ncd_series != null) {
    //     //         if (data['data'][0] != undefined && data['data'][0].bond_ncd_series != undefined) {
    //                 if (data['data'][0].bond_ncd_series.length > 0) {
    //                     let newqty = [...qty];
    //                     let newchecked = [...checked];
    //                     data['data'][0].bond_ncd_series.map((series:any,index:number)=>{
    //                         newqty[index] = series.ipo_order_ncd_series_quantity;
    //                         setQty(newqty);
    //                         newchecked[index] = true;
    //                         setChecked(newchecked);
    //                     })
    //                 }
    //     //         }
    //     //         setSecondPanName(data['data'][0].ipo_order_second_appl_name);
    //     //         setSecondPanNo(data['data'][0].ipo_order_second_pan);
    //     //         setThirdPanName(data['data'][0].ipo_order_third_appl_name);
    //     //         setThirdPanNo(data['data'][0].ipo_order_third_pan);
    //     //         setCategoryID(data['data'][0].ipo_order_investor_category_id);
    //     //         setSubCategoryCode(data['data'][0].ipo_order_sub_category_code);
    //     //     } else if (data['data'][0].bond_type == bondTypes.SGB) {
    //     //         setBondType(data['data'][0].bond_type);
    //     //         setBondID(data['data'][0].bond_id);
    //     //     }
    //     }
    // }
    // useEffect(() => {
    //     dataVerify();
    // }, [location]);



    useEffect(() => {
        if (id != '') {
            setShowPreloader(true);
            ServerAPI.GetIPODetail(parseInt(id)).then((response: any) => {
                if (response != undefined) {
                    setBondDetail(response);
                }
            }).finally(() => {
                setShowPreloader(false);
            })
        }
    }, [id])

    useEffect(() => {
        console.log(individualTotal);
        let totalOrder = 0;
        individualTotal.map((value: number) => {
            if (value != undefined) {
                totalOrder += value;
            }
        })
        setOrderTotal(totalOrder);

    }, [qty])

    useEffect(() => {
        if (localStorage.getItem('login') != '0' && localStorage.getItem('login') != undefined && localStorage.getItem('login') != null) {
            setShowPreloader(true);
            ServerAPI.userDetailData().then((res: APIData.userDetail) => {
                setUserDetails(res);
            }).finally(() => {
                setShowPreloader(false);
            })
            setShowPreloader(true);
            ServerAPI.GetInvestorCategories().then((res: any) => {
                setInvestmentCategory(res['data']);
            }).finally(() => {
                setShowPreloader(false);
            })
            setShowPreloader(true);
            ServerAPI.GetInvestorTypes().then((res: any) => {
                setInvestmentTypes(res['data']);
            }).finally(() => {
                setShowPreloader(false);
            })
        }
    }, []);


    return (
        <div className="main-index-wrapper">
            <Header />
            <div className="body-overlay"></div>
            <main>
                <section className="sovereign_gold_bonds pt-100 pt-md-45 pb-md-40 pt-xs-45 pb-xs-40">
                    <div className="container-fluid ">
                        <div className="row pt-3">
                            <div className="col-lg-12">
                                <div className="row align-items-left">
                                    <div className="col-lg-6 breadcums pt-4 ">
                                        <nav>
                                            <ul>
                                                <li>Home</li>
                                                <li>Bonds</li>
                                                <li>Explore Bonds</li>
                                                <li className="active">{bondsDetail?.bond_issuer_name}</li>
                                            </ul>
                                        </nav>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row py-3 mx-4 px-4 detail-view-calculation">
                            <div className="col-lg-12 col-md-12 col-sm-12 card-box py-3 row">
                                <div className="col-lg-6 col-md-6 col-sm-6 ">
                                    <div className="row  pb-3">
                                        <div className="col-lg-2 col-md-3 col-sm-6">
                                            <img src={bondsDetail?.bond_logo != '' ? bondsDetail?.bond_logo : "assets/img/img_placeholder.png"} alt="ipo" className="bond-title-img" />
                                        </div>
                                        <div className="col-lg-10 col-md-9 col-sm-6 ">

                                            <div className="d-flex my-2">
                                                <p className="title"><b>{bondsDetail?.bond_issuer_name}</b></p>
                                            </div>
                                            <div className="d-flex my-2">
                                                <span className="background-grey title-background-style">
                                                    <p className="fs-15"> <span>ISIN:&nbsp;</span>{bondsDetail?.bond_isin_number != null && bondsDetail?.bond_isin_number != '' ? bondsDetail?.bond_isin_number : "N/A"}&nbsp;<Copy /> </p>
                                                </span>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-lg-12 col-md-6 mt-3" data-wow-delay="0.1s">
                                        <div className="d-flex">
                                            <a className="background-lightblack text-white sourceSansPro-regular px-2 bond-investor-tag" >
                                                Eligible Investors*
                                            </a> <span className="triangle-right-ipo-tag"></span>

                                            {bondsDetail?.bond_eligible_investors.map((investor: APIData.BondEligibleInvestors) => {
                                                return (
                                                    <a className="background-grey px-2 mx-2 sourceSansPro-regular bond-investor-sub-tags">
                                                        {investor.eligible_investor_name}
                                                    </a>
                                                )
                                            })}
                                        </div>

                                        <div className=" mt-5 mb-5">
                                            <p className="title"><b> Key Points To Consider</b></p>
                                            <span className="text-light sourceSansPro-regular key-to-consider">Face Value (In INR) and Issue Size (In INR Crores)</span>
                                        </div>
                                    </div>

                                    <div className="mb-2">
                                        <table className="w-95 bond-table">
                                            <tr>
                                                <td className="w-50">
                                                    <p className="table-text-light sourceSansPro-regular">Issue on</p>
                                                    <p className="sourceSansPro-bold">{bondsDetail?.bond_opening_date != null && bondsDetail?.bond_opening_date != '' ? moment(bondsDetail?.bond_opening_date).format("MMMM Do YYYY") : "N/A"}</p>
                                                </td>
                                                <td>
                                                    <p className="table-text-light sourceSansPro-regular">Issue Closes on</p>
                                                    <p className="sourceSansPro-bold">{bondsDetail?.bond_closing_date != null && bondsDetail?.bond_closing_date != '' ? moment(bondsDetail?.bond_closing_date).format("MMMM Do YYYY") : "N/A"}</p>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <p className="table-text-light sourceSansPro-regular">Coupon Rate (Returns)</p>
                                                    <p className="sourceSansPro-bold">{bondsDetail?.bond_coupon_on != null && bondsDetail?.bond_coupon_on != '' ? bondsDetail?.bond_coupon_on + "% p.a" : "N/A"}</p>
                                                </td>
                                                <td>
                                                    <p className="table-text-light sourceSansPro-regular">Yeild</p>
                                                    <p className="sourceSansPro-bold">{bondsDetail?.bonds_yeild != null && bondsDetail?.bonds_yeild != '' ? bondsDetail?.bonds_yeild + "%" : "N/A"}</p>
                                                </td>
                                            </tr>
                                        </table>
                                    </div>
                                    <div className="mb-2">
                                        <table className="w-95 bond-table">
                                            <tr>
                                                <td className="w-50">
                                                    <p className="table-text-light sourceSansPro-regular">Face Value</p>
                                                    <p className="sourceSansPro-bold">{bondsDetail?.bond_face_value != null && bondsDetail?.bond_face_value != '' ? "₹" + numberWithCommas(bondsDetail?.bond_face_value) : "N/A"}</p>
                                                </td>
                                                <td>
                                                    <p className="table-text-light sourceSansPro-regular">MIN. INVESTMENT</p>
                                                    <p className="sourceSansPro-bold">{bondsDetail?.bond_minimum_application != null && bondsDetail?.bond_minimum_application != '' ? "₹" + numberWithCommas(bondsDetail?.bond_minimum_application) : "N/A"}</p>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <p className="table-text-light sourceSansPro-regular">Interest Payment Frequency</p>
                                                    {parseInt(bondsDetail?.bond_interest_type!) == interestPaymentFrequency.FOR_ANNUAL &&
                                                        <p className="sourceSansPro-bold">Annual</p>
                                                    }
                                                    {parseInt(bondsDetail?.bond_interest_type!) == interestPaymentFrequency.FOR_MONTHLY &&
                                                        <p className="sourceSansPro-bold">Monthly</p>
                                                    }
                                                    {parseInt(bondsDetail?.bond_interest_type!) == interestPaymentFrequency.FOR_QUARTERLY &&
                                                        <p className="sourceSansPro-bold">Quarterly</p>
                                                    }
                                                    {parseInt(bondsDetail?.bond_interest_type!) == interestPaymentFrequency.FOR_SEMI_ANNUAL &&
                                                        <p className="sourceSansPro-bold">Semi-Annual</p>
                                                    }
                                                    {bondsDetail?.bond_interest_type == null || bondsDetail?.bond_interest_type == '' &&
                                                        <p className="sourceSansPro-bold">N/A</p>
                                                    }
                                                </td>
                                                <td>
                                                    <p className="table-text-light sourceSansPro-regular">Maturity Date</p>
                                                    <p className="sourceSansPro-bold">{bondsDetail?.bond_maturity_date != null && bondsDetail?.bond_maturity_date != '' ? moment(bondsDetail?.bond_maturity_date).format("MMMM Do YYYY") : "N/A"}</p>
                                                </td>
                                            </tr>
                                        </table>
                                    </div>
                                    <div className="mb-2">
                                        <table className="w-95 bond-table">
                                            <tr>
                                                <td className="w-50">
                                                    <p className="table-text-light sourceSansPro-regular">Tax Free</p>
                                                    <p className="sourceSansPro-bold">{bondsDetail?.bond_nature_of_instrument == NatureofInstruments.TAXABLE ? "Taxable" : "Tax Free"}</p>
                                                </td>
                                                <td>
                                                    <p className="table-text-light sourceSansPro-regular">SERIES</p>
                                                    <p className="sourceSansPro-bold">{bondsDetail?.bond_issuer_details[0].bond_issuer_series != null && bondsDetail?.bond_issuer_details[0].bond_issuer_series != '' ? bondsDetail?.bond_issuer_details[0].bond_issuer_series : "N/A"}</p>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <p className="table-text-light sourceSansPro-regular">Listing</p>
                                                    {bondsDetail?.bond_listing == listing.BSE &&
                                                        <p className="sourceSansPro-bold">BSE</p>
                                                    }
                                                    {bondsDetail?.bond_listing == listing.NSE &&
                                                        <p className="sourceSansPro-bold">NSE</p>
                                                    }
                                                    {bondsDetail?.bond_listing == listing.BSE_NSE &&
                                                        <p className="sourceSansPro-bold">BSE & NSE</p>
                                                    }
                                                </td>
                                                <td>
                                                    <p className="table-text-light sourceSansPro-regular">Issue Size</p>
                                                    <p className="sourceSansPro-bold">{bondsDetail?.bond_issue_size != null && bondsDetail?.bond_issue_size != '' ? bondsDetail?.bond_issue_size : "N/A"}</p>
                                                </td>
                                            </tr>
                                        </table>
                                    </div>
                                    <div className="mb-2 w-95 ipo-category-table">
                                        <table className="w-100">
                                            <tr>
                                                <td colSpan={2}>
                                                    <table className="w-100 bond-ipo-sub-table">
                                                        <thead>
                                                            <tr>
                                                                <th className="sourceSansPro-regular">Category</th>
                                                                <th className="sourceSansPro-regular">I - Institutional</th>
                                                                <th className="sourceSansPro-regular">II - Non-Institutiona1</th>
                                                                <th className="sourceSansPro-regular">III - HNI</th>
                                                                <th className="sourceSansPro-regular">IV - Retail</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr>
                                                                <td className="sourceSansPro-semibold">Category Allocation</td>
                                                                <td className="sourceSansPro-semibold">{bondsDetail?.bond_category_institutional != null && bondsDetail?.bond_category_institutional != '' ? bondsDetail?.bond_category_institutional + "%" : "N/A"}</td>
                                                                <td className="sourceSansPro-semibold">{bondsDetail?.bond_category_non_institutional != null && bondsDetail?.bond_category_non_institutional != '' ? bondsDetail?.bond_category_non_institutional + "%" : "N/A"}</td>
                                                                <td className="sourceSansPro-semibold">{bondsDetail?.bond_category_hni != null && bondsDetail?.bond_category_hni != '' ? bondsDetail?.bond_category_hni + "%" : "N/A"}</td>
                                                                <td className="sourceSansPro-semibold">{bondsDetail?.bond_category_retail != null && bondsDetail?.bond_category_retail != '' ? bondsDetail?.bond_category_retail + "%" : "N/A"}</td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </td>
                                            </tr>
                                        </table>
                                    </div>
                                </div>
                                <div className="col-lg-6 col-md-6 col-sm-6 ipo-purchase-right-section">
                                    <div className="container-fluid">
                                        <div className="row detail-view-calculation">
                                            <div className="">
                                                <div className="row d-flex section-head px-3 py-3 mb-5">
                                                    <p className="ipo-purchase-p">
                                                        *Company shall allocate and allot Series {bondsDetail?.bond_issuer_details[0].bond_issuer_series} NCDs wherein the Applicants have not indicated the choice of the relevant NCD Series.
                                                    </p>
                                                </div>
                                                <div className="row section-form">
                                                    <div className="row">
                                                        <div className="col-lg-6 pt-10 pb-10">
                                                            <div>
                                                                <h6>INVESTOR TYPE</h6>
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-6 pt-10 pb-10 mb-3">
                                                            <div className="row">
                                                                <select className="input-valu2 box-shadow text-start bond-select" onChange={(e: any) => {
                                                                    setInvestorID(e.target.value);
                                                                }}>
                                                                    <option value={0}>Select Investor</option>
                                                                    {investmentTypes.map((types: APIData.InvestorTypes) => {
                                                                        return (
                                                                            <option value={types.investor_type_id}>{types.investor_type_name}</option>
                                                                        )
                                                                    })}
                                                                </select>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-lg-6 pt-10 pb-10 mb-3">
                                                            <div>
                                                                <h6>INVESTOR CATEGORY</h6>
                                                                <p>For the Complete List of categories Click Here</p>
                                                            </div>
                                                        </div>
                                                        {investmentCategory != undefined && investmentCategory.length > 0 &&
                                                            <div className="col-lg-6 pt-10 pb-10 mb-3">
                                                                <div className="row">
                                                                    <select className="input-valu2 box-shadow text-start bond-select" onChange={(e: any) => {
                                                                        setCategoryID(e.target.value);
                                                                        let subcategory = investmentCategory.filter(sub => sub.investor_category_id == e.target.value);
                                                                        setSubCategoryCode(subcategory[0].investor_category_sub_code);
                                                                    }}>
                                                                        <option value={0}>Select Category</option>
                                                                        {investmentCategory.map((category: APIData.InvestorCategory) => {
                                                                            return (
                                                                                <option value={category.investor_category_id}>{category.investor_category_name}</option>
                                                                            )
                                                                        })}
                                                                    </select>
                                                                </div>
                                                            </div>
                                                        }
                                                    </div>
                                                    <div className="row mb-3">
                                                        <div className="col-lg-6 pt-10 pb-10">
                                                            <div>
                                                                <h6>SUBCATEGORY</h6>
                                                                <p>For the Complete List of sub categories Click Here</p>
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-6 pt-10 pb-10">
                                                            <div className="row">
                                                                <input disabled={true} value={subCategoryCode} className="ipo-purchase-subcategory" />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="row mb-3">
                                                        <div className="col-lg-6 pt-10 pb-10">
                                                            <div>
                                                                <p>First Applicant</p>
                                                                <h6>{userDetail.pan_name}</h6>
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-6 pt-10 pb-10">
                                                            <div>
                                                                <p>PAN</p>
                                                                <h6>{userDetail.pan_number}</h6>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="row mb-3">
                                                        <div className="col-lg-6 pt-10 pb-10">
                                                            <div>
                                                                <p>Second Applicant</p>
                                                                <input placeholder="Enter Full Name" type="text" onChange={(e: any) => {
                                                                    setSecondPanName(e.target.value);
                                                                }} className="input-valu2 box-shadow text-start px-2 py-2 ipo-purchase-pan-input" />
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-6 pt-10 pb-10">
                                                            <div>
                                                                <p>Second Applicant PAN</p>
                                                                <input type="text" onChange={(e: any) => {
                                                                    setSecondPanNo(e.target.value);
                                                                }} placeholder="Enter 10 Digit PAN Number" className="input-valu2 box-shadow text-start px-2 py-2 ipo-purchase-pan-input" />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="row mb-3">
                                                        <div className="col-lg-6 pt-10 pb-10">
                                                            <div>
                                                                <p>Third Applicant</p>
                                                                <input placeholder="Enter Full Name" type="text" onChange={(e: any) => {
                                                                    setThirdPanName(e.target.value);
                                                                }} className="input-valu2 box-shadow text-start px-2 py-2 ipo-purchase-pan-input" />
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-6 pt-10 pb-10">
                                                            <div>
                                                                <p>Third Applicant PAN</p>
                                                                <input type="text" onChange={(e: any) => {
                                                                    setThirdPanNo(e.target.value);
                                                                }} placeholder="Enter 10 Digit PAN Number" className="input-valu2 box-shadow text-start px-2 py-2 ipo-purchase-pan-input" />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-12 section-left mt-5">
                                    {bondsDetail != undefined && bondsDetail?.bond_ncd_series.length > 0 &&
                                        <Fragment>
                                            <h3 className="ml-2">Specific Terms for Each Series of NCDs</h3>
                                            <table className={bondsDetail?.bond_ncd_series.length == 6 ? "w-100 mt-5 ipo-specific-terms mb-3 text-center" : "mt-5 ipo-specific-terms mb-3 text-center"}>
                                                <thead>
                                                    <tr>
                                                        <th className="br-top-12">Series</th>
                                                        {bondsDetail.bond_ncd_series.map((ncdseries: APIData.BondNcdSeries, index: number) => {
                                                            return (
                                                                <th className="right-br-ipo-specific-terms br-bt-bg">Series {index + 1}</th>
                                                            )
                                                        })
                                                        }

                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <td>Frequency of Interest Payment</td>
                                                        {bondsDetail.bond_ncd_series.map((ncdseries: APIData.BondNcdSeries) => {
                                                            if (ncdseries.bond_ncd_frequency_of_interest_payment == interestPaymentFrequency.FOR_ANNUAL) {
                                                                return (
                                                                    <td className="right-br-ipo-specific-terms br-bt-bg">Annual</td>
                                                                )
                                                            }
                                                            if (ncdseries.bond_ncd_frequency_of_interest_payment == interestPaymentFrequency.FOR_MONTHLY) {
                                                                return (
                                                                    <td className="right-br-ipo-specific-terms br-bt-bg">Monthly</td>
                                                                )
                                                            }
                                                            if (ncdseries.bond_ncd_frequency_of_interest_payment == interestPaymentFrequency.FOR_QUARTERLY) {
                                                                return (
                                                                    <td className="right-br-ipo-specific-terms br-bt-bg">Quarterly</td>
                                                                )
                                                            }
                                                            if (ncdseries.bond_ncd_frequency_of_interest_payment == interestPaymentFrequency.FOR_SEMI_ANNUAL) {
                                                                return (
                                                                    <td className="right-br-ipo-specific-terms br-bt-bg">Semi-Annual</td>
                                                                )
                                                            }
                                                        })
                                                        }
                                                    </tr>
                                                    <tr>
                                                        <td>Minimum Application</td>
                                                        {bondsDetail.bond_ncd_series.map((ncdseries: APIData.BondNcdSeries) => {
                                                            return (
                                                                <td className="right-br-ipo-specific-terms br-bt-bg">{numberWithCommas(ncdseries.bond_ncd_minimum_application)}</td>
                                                            )
                                                        })
                                                        }
                                                    </tr>
                                                    <tr>
                                                        <td>In Multiples of thereafter (₹)</td>
                                                        {bondsDetail.bond_ncd_series.map((ncdseries: APIData.BondNcdSeries) => {
                                                            return (
                                                                <td className="right-br-ipo-specific-terms br-bt-bg">{ncdseries.bond_ncd_in_multiple_terms_of_thereafter}</td>
                                                            )
                                                        })
                                                        }
                                                    </tr>
                                                    <tr>
                                                        <td>Face Value/ Issue Price of NCDs (`/ NCD)</td>
                                                        {bondsDetail.bond_ncd_series.map((ncdseries: APIData.BondNcdSeries) => {
                                                            return (
                                                                <td className="right-br-ipo-specific-terms br-bt-bg">{numberWithCommas(ncdseries.bond_ncd_issue_price_of_ncd)}</td>
                                                            )
                                                        })
                                                        }
                                                    </tr>
                                                    <tr>
                                                        <td>Tenor</td>
                                                        {bondsDetail.bond_ncd_series.map((ncdseries: APIData.BondNcdSeries) => {
                                                            return (
                                                                <td className="right-br-ipo-specific-terms br-bt-bg">{ncdseries.bond_ncd_tenor + " months "}</td>
                                                            )
                                                        })
                                                        }
                                                    </tr>
                                                    <tr>
                                                        <td>Coupon (% per annum)</td>
                                                        {bondsDetail.bond_ncd_series.map((ncdseries: APIData.BondNcdSeries) => {
                                                            return (
                                                                <td className="right-br-ipo-specific-terms br-bt-bg">{ncdseries.bond_ncd_coupon}</td>
                                                            )
                                                        })
                                                        }
                                                    </tr>
                                                    <tr>
                                                        <td>Effective Yield (% per annum)</td>
                                                        {bondsDetail.bond_ncd_series.map((ncdseries: APIData.BondNcdSeries) => {
                                                            return (
                                                                <td className="right-br-ipo-specific-terms br-bt-bg">{ncdseries.bond_ncd_effective_yield}</td>
                                                            )
                                                        })
                                                        }
                                                    </tr>
                                                    <tr>
                                                        <td>Mode of Interest Payment</td>
                                                        {bondsDetail.bond_ncd_series.map((ncdseries: APIData.BondNcdSeries) => {
                                                            return (
                                                                <td className="right-br-ipo-specific-terms br-bt-bg">{ncdseries.bond_ncd_mode_of_interest_payment}</td>
                                                            )
                                                        })
                                                        }
                                                    </tr>
                                                    <tr>
                                                        <td>Redemption Amount (₹ / NCD) on Maturity</td>
                                                        {bondsDetail.bond_ncd_series.map((ncdseries: APIData.BondNcdSeries) => {
                                                            return (
                                                                <td className="right-br-ipo-specific-terms br-bt-bg">{numberWithCommas(ncdseries.bond_ncd_redemption_amount)}</td>
                                                            )
                                                        })
                                                        }
                                                    </tr>
                                                    <tr>
                                                        <td className="br-bottom-12">Put and Call Option</td>
                                                        {bondsDetail.bond_ncd_series.map((ncdseries: APIData.BondNcdSeries) => {
                                                            return (
                                                                <td className="right-br-ipo-specific-terms br-bt-bg">{ncdseries.bond_ncd_put_and_option}</td>
                                                            )
                                                        })
                                                        }
                                                    </tr>
                                                    <tr>
                                                        <td className="ipo-purchase-series">Select NCDs Series</td>
                                                        {bondsDetail.bond_ncd_series.map((ncdseries: APIData.BondNcdSeries, index: number) => {
                                                            return (
                                                                <td className="right-br-ipo-specific-terms br-bt-bg bottom-br-ipo-specific-terms">
                                                                    <input type="checkbox" onChange={(e: any) => {
                                                                        let newOrderTotal = orderTotal;
                                                                        if (e.target.checked == true) {
                                                                            let newchecked = [...checked];
                                                                            newchecked[index] = true;
                                                                            setChecked(newchecked);
                                                                        } else {
                                                                            let newchecked = [...checked];
                                                                            newchecked[index] = false;
                                                                            setChecked(newchecked);
                                                                            let newNCDs = [...ncds];
                                                                            newNCDs.splice(index, 1);
                                                                            setNCDs(newNCDs);
                                                                            newOrderTotal = newOrderTotal - parseInt(bondsDetail.bond_ncd_series[index].bond_ncd_issue_price_of_ncd) * qty[index];
                                                                            let newqty = [...qty];
                                                                            newqty[index] = 0;
                                                                            setQty(newqty);
                                                                            let newIndividualTotal = [...individualTotal];
                                                                            newIndividualTotal[index] = 0;
                                                                            setIndividualTotal(newIndividualTotal);
                                                                        }
                                                                        setOrderTotal(newOrderTotal);
                                                                    }} className="ipo-purchase-series-checkbox signup" />
                                                                </td>
                                                            )
                                                        })
                                                        }
                                                    </tr>
                                                    <tr>
                                                        <td className="ipo-purchase-series">No. of NCDs applied</td>
                                                        {bondsDetail.bond_ncd_series.map((ncdseries: APIData.BondNcdSeries, index: number) => {
                                                            return (
                                                                <td className="right-br-ipo-specific-terms br-bt-bg bottom-br-ipo-specific-terms">
                                                                    <input placeholder="No. Of NCD"
                                                                        min="1"
                                                                        step="1"
                                                                        value={qty[index]} disabled={checked[index] == true ? false : true} type="number" onBlur={(e: any) => {
                                                                            let newNCDs = [...ncds];
                                                                            newNCDs.push({
                                                                                bond_ncd_series_id: ncdseries.bond_ncd_series_id,
                                                                                ipo_order_ncd_series_amount: ncdseries.bond_ncd_issue_price_of_ncd,
                                                                                ipo_order_ncd_series_quantity: e.target.value,
                                                                                ipo_order_ncd_series_total_amount: (e.target.value * parseInt(ncdseries.bond_ncd_issue_price_of_ncd) + "")
                                                                            })

                                                                            setNCDs(newNCDs);
                                                                        }} onChange={(e: any) => {
                                                                            if (e.key === ".") {
                                                                                e.preventDefault();
                                                                            }
                                                                            e.preventDefault();
                                                                            let newqty = [...qty];
                                                                            newqty[index] = parseInt(e.target.value);
                                                                            setQty(newqty);
                                                                            if (parseInt(bondsDetail.bond_ncd_series[index].bond_ncd_issue_price_of_ncd) * e.target.value < ncdseries.bond_ncd_minimum_application) {
                                                                                setValidationFailed(true);
                                                                            } else {
                                                                                setValidationFailed(false);
                                                                            }
                                                                            let newIndividualTotal = [...individualTotal];
                                                                            newIndividualTotal[index] = parseInt(bondsDetail.bond_ncd_series[index].bond_ncd_issue_price_of_ncd) * e.target.value;
                                                                            setIndividualTotal(newIndividualTotal);

                                                                        }} className="input-valu2 box-shadow px-2 py-2 ipo-purchase-pan-input w-100 text-center" />
                                                                </td>
                                                            )
                                                        })
                                                        }
                                                    </tr>
                                                    <tr>
                                                        <td className="ipo-purchase-series">Amount Payable (₹)</td>
                                                        {bondsDetail.bond_ncd_series.map((ncdseries: APIData.BondNcdSeries, index: number) => {
                                                            return (
                                                                <Fragment>
                                                                    <td className="right-br-ipo-specific-terms br-bt-bg bottom-br-ipo-specific-terms">
                                                                        <p>{(alphabets[index])}</p><br />
                                                                        {qty[index] ?
                                                                            <h6>{"₹" + numberWithCommas(individualTotal[index])}</h6>
                                                                            :
                                                                            <h6>N/A</h6>
                                                                        }
                                                                    </td>
                                                                </Fragment>
                                                            )
                                                        })
                                                        }
                                                    </tr>
                                                    <tr>
                                                        <td className="br-bottom-12 ipo-purchase-series">Grand Total (₹)</td>
                                                        {orderTotal ?
                                                            <td colSpan={6} className=" text-end pr-5"><strong>{"₹" + numberWithCommas(orderTotal)}</strong></td>
                                                            :
                                                            <td colSpan={6} className=" text-end pr-5"><strong>N/A</strong></td>
                                                        }
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </Fragment>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </section >
            </main >
            <div className="ml-4 mr-4">
                <div className="text-center">
                    <label htmlFor="signup" className="review-text mx-1 mb-0 p-0">
                        <p className="review-text text-left pb-0">
                            <input type="checkbox" id="signup" className="signup" name="check_box_share_data_with_govt" /> I have read and agreed to the <u className="signup-terms_conditions">disclaimer</u> and <u className="signup-terms_conditions">terms & conditions</u></p>
                    </label>
                    <div className="text-center p-0 detail-view-description mt-3">
                        <button className="ipo-buy-button" onClick={(e: any) => {
                            e.preventDefault();
                            if (validationFailed == true) {
                                toast.error("Please Fill in Minimum Applications Before Proceeding");
                            } else {
                                goToBuy(bondsDetail?.bond_id!);
                            }
                        }}><p className="click-me ipo-detail-buy-button">Subscribe to this IPO Now&nbsp;<img width="12px" height="12px" src="assets/img/icon/rightarrow.png"></img></p></button>
                    </div>
                </div>
            </div>

            <Footer />
        </div >
    );
}

export default BondIPOPurchase;
