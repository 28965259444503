import { url } from "inspector";
import Header from "../components/template/Header";
import SlideBar from "../components/template/SlideBar";
import Footer from "../components/template/Footer";
import Subscribe from "../components/Subscribe";
import NeedHelp from "../components/NeedHelp";
import ReactSimplyCarousel from 'react-simply-carousel';
import { useState } from "react";

const AboutUs: React.FC = () => {
    const [activeSlideIndex, setActiveSlideIndex] = useState(0);

    return (
        <div className="main-index-wrapper">
            <Header />
            <SlideBar />
            <div className="body-overlay"></div>
            <main>
                <section className="plan-coverage-area aboutus_page pos-rel about-style-1 pt-110 pb-50 pt-lg-50 pt-md-110 pb-md-30 pt-xs-10 pb-xs-30">
                    <div className="container-fluid">
                        <div className="col-lg-12">
                            <div className="row align-items-left">
                                <div className="col-lg-6 breadcums">
                                    <nav>
                                        <ul>
                                            <li>Home</li>
                                            <li className="active">About Us</li>
                                        </ul>
                                    </nav>
                                </div>
                            </div>
                        </div>
                        <div className="what_are_advantages_full_left_text">
                            <p className="text_bold">
                                Trust Securities Services is the Non-Institutional Clientele vertical of one of India's leading full-service financial houses, TRUST Group.
                            </p>
                            <p className="text_light_bold">
                                Under the parentage and market leadership of TRUST Group, Trust Securities services was incorporated in 2015 to serve as a 'one stop shop for financial products' across ticket sizes and client genre.
                            </p>
                            <p className="text_light_bold">
                                We wear the genesis of Trust Group as our emblem of pride, translating over 15 years of experience and a multitude of accolades to our name; we assure perennial partnerships and high value addition, with you, for you.
                            </p>
                            <p className="text_light_bold">
                                As a proactive debt specialist pioneering the Indian DCM market, Trust Securities services is present across the value chain with direct access to a plethora of debt, quasi-debt and quasi-equity products.
                            </p>
                        </div>
                        <div className="row align-items-center">
                            <div className="col-lg-5 offset-lg-1">
                                <div className="about_left_img">
                                    <img src="assets/img/icon/about_img_1.svg" />
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="plan-title-wrapper mb-30 pl-95 pl-lg-0 pl-md-0 pl-xs-0">
                                    <div className="section-title mb-55">
                                        <p className="mb-25 about-us-heading">OUR COMPANY</p>
                                        <h4 className="sub-title">
                                            We are a TRUSTed partner and innovator having proficiency across a repertoire of Fixed Income services encapsulating debt fund raising needs. From arranging to end execution of primary issuances to facilitation and through secondary market deliveries, we service all denominations suited to whole investment quantum - from retail to corporate.
                                        </h4>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row align-items-center pt-50">
                            <div className="col-lg-6">
                                <div className="plan-title-wrapper mb-30 pl-95 pl-lg-0 pl-md-0 pl-xs-0">
                                    <div className="section-title mb-55">
                                        <p className="mb-25 about-us-heading">We are Trust</p>
                                        <h4 className="sub-title">
                                            Comprehensively equipped with rich experience, a seasoned managerial team, insightful investment advisory and holistic pan India reach, Trust Securities services aims to be your go-to investment house for all your fixed income needs.
                                        </h4>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="about_left_img">
                                    <img src="assets/img/icon/about_img_2.svg" />
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="about_what_we_offer pt-30 pb-0">
                    <div className="container custom-container-team">
                        <div className="row justify-content-center">
                            <div className="col-lg-6">
                                <div className="team-title-wrapper">
                                    <div className="section-title text-center mb-5" data-wow-delay=".1s">
                                        <p className="mb-0 about-us-heading">WHAT WE OFFER</p>
                                        <h4 className="about-us-text">Lorem ipsum dolor sit amet, consectetur adipisicing elit,</h4>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row align-items-center">
                            <div className="about_what_we_offer_items about-us-bond-detail">
                                <div className="do-box skew-bg mb-10 active">
                                    <div className="icon mb-20">
                                        <img src="assets/img/icon/what_we_offer_1.svg" alt="" />
                                    </div>
                                    <h5 className="mb-10">Curated Bonds </h5>
                                    <h3 className="sub-title">Our Team of Financial experts brings you a whole host of different categories of Bonds under a single roof.</h3>
                                </div>
                            </div>
                            <div className="about_what_we_offer_items about-us-bond-detail">
                                <div className="do-box skew-bg mb-10">
                                    <div className="icon mb-20">
                                        <img src="assets/img/icon/what_we_offer_2.svg" alt="" />
                                    </div>
                                    <h5 className="mb-10">Bond Transaction</h5>
                                    <h3 className="sub-title">For hassle-free experience we facilitate our clients to buy or sell bonds in primary & secondary market.</h3>
                                </div>
                            </div>
                            <div className="about_what_we_offer_items about-us-bond-detail">
                                <div className="do-box skew-bg mb-10">
                                    <div className="icon mb-20">
                                        <img src="assets/img/icon/what_we_offer_3.svg" alt="" />
                                    </div>
                                    <h5 className="mb-10">Detailed Information</h5>
                                    <h3 className="sub-title">Smart investment is about Knowledge and well informed decisions, we provide all the bond related info including Information Memorandum, Cash flows, Rating Rationales etc.</h3>
                                </div>
                            </div>
                            <div className="about_what_we_offer_items about-us-bond-detail">
                                <div className="do-box skew-bg mb-10">
                                    <div className="icon mb-20">
                                        <img src="assets/img/icon/what_we_offer_4.svg" alt="" />
                                    </div>
                                    <h5 className="mb-10">Complete directory of Bonds</h5>
                                    <h3 className="sub-title">Providing complete Directory of Bonds under a single platform</h3>
                                </div>
                            </div>
                            <div className="about_what_we_offer_items about-us-bond-detail">
                                <div className="do-box skew-bg mb-10">
                                    <div className="icon mb-20">
                                        <img src="assets/img/icon/what_we_offer_5.svg" alt="" />
                                    </div>
                                    <h5 className="mb-10">Bond expert advice & help</h5>
                                    <h3 className="sub-title">Our Financial Experts are only a call away to assist you in the process of making your investments decisions.</h3>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="plan-coverage-area aboutus_page pos-rel about-style-1 pt-0 pb-50 pt-lg-50 pt-md-110 pb-md-30 pt-xs-10 pb-xs-30">
                    <div className="container-fluid">
                        <div className="row align-items-center pt-50">
                            <div className="col-lg-6">
                                <div className="about_left_img text-center">
                                    <img src="assets/img/icon/our_vision.svg" />
                                </div>
                            </div>
                            {/* <div className="col-lg-6">
                                <div className="plan-title-wrapper mb-30 pl-95 pl-lg-0 pl-md-0 pl-xs-0">
                                    <div className="section-title mb-55">
                                        <p className="mb-25 about-us-heading">Our Vision</p>
                                        <h4 className="sub-title">
                                            Lorem Ipsum is simply dummy text of the printing and typesetting industry.
                                            <h4 className="sub-title">
                                            </h4>
                                            Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.
                                        </h4>
                                    </div>
                                </div>
                            </div> */}
                        </div>
                    </div>
                </section>
                <section className="about_what_we_offer our_leadership pt-30 pb-0">
                    <div className="container custom-container-team">
                        <div className="row justify-content-center">
                            <div className="col-lg-6">
                                <div className="team-title-wrapper mb-3">
                                    <div className="section-title text-center " data-wow-delay=".1s">
                                        <p className="mb-0 about-us-heading">OUR Team</p>
                                        <h4 className="about-us-text">Lorem ipsum dolor sit amet, consectetur adipisicing elit,</h4>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row justify-content-center">
                            <div className="col-lg-5">
                                <div className="team-item">
                                    <div className="s-teams mb-30">
                                        <div className="s-teams__thumb mb-30">
                                            <img src="assets/img/icon/team_1.png" alt="" />
                                        </div>
                                        <div className="s-teams__content text-center">
                                            <h4 className="sub-title">NIPA SHETH</h4>
                                            <h5>Seldom do men and women in the Bond market make it to front pages. Nipa Sheth the founder Director of Trust Group is one such persona. She is a CA gold medalist and a CFA. Under her able leadership, Trust Group has transformed into a full service finance house and a leader in the Indian debt capital markets. She is the wind beneath trust's wings and its her vision, perseverance and dedication that has steered trust group to the top position in the Industry.</h5>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-5">
                                <div className="team-item">
                                    <div className="s-teams mb-30">
                                        <div className="s-teams__thumb mb-30">
                                            <img src="assets/img/icon/team_2.png" alt="" />
                                        </div>
                                        <div className="s-teams__content text-center">
                                            <h4 className="sub-title">PRANAV INAMDAR</h4>
                                            <h5>Pranav Inamdar, Director of Trust Group & Trust Securities services, is one of the founder member of Trust Securities services and drives a national network of niche relationships at Trust Group. An MBA from the University of Mumbai, he brings with him 17 years of rich experience in Capital Markets working across client profiles, nationally. With laurels of path breaking deals to his name, Mr. Inamdar's determination and dynamics resonates with ours.</h5>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="about_what_we_offer company_history pt-30 pb-0">
                    <div className="container custom-container-team">
                        {/* <div className="row justify-content-center">
                            <div className="col-lg-6">
                                <div className="team-title-wrapper">
                                    <div className="section-title text-center mb-3" data-wow-delay=".1s">
                                        <p className="mb-0 about-us-heading">WE HAVE 7 YEARS EXPERIENCED SEE OUR COMPANY HISTORY</p>
                                        <h4 className="about-us-text">Lorem ipsum dolor sit amet, consectetur adipisicing elit,</h4>
                                    </div>
                                </div>
                            </div>
                        </div> */}
                        <div className="row justify-content-center mb-30 mt-5">
                            <div className="invest_in_india_box">
                                <ReactSimplyCarousel
                                    infinite={true}
                                    activeSlideIndex={activeSlideIndex}
                                    onRequestChange={setActiveSlideIndex}
                                    itemsToShow={3}
                                    itemsToScroll={1}
                                    containerProps={{
                                        style: {
                                            width: "100%",
                                        }
                                    }}
                                    forwardBtnProps={{
                                        //here you can also pass className, or any other button element attributes
                                        style: {
                                            alignSelf: 'center',
                                            background: '#00C6D8',
                                            border: 'none',
                                            borderRadius: '0px 25px 25px 0px',
                                            color: 'white',
                                            cursor: 'pointer',
                                            fontSize: '20px',
                                            height: 49,
                                            lineHeight: 1,
                                            textAlign: 'center',
                                            width: 40,
                                            boxShadow: "0px 3px 30px #00000029",
                                        },
                                        children: <span>{`>`}</span>,
                                    }}
                                    backwardBtnProps={{
                                        //here you can also pass className, or any other button element attributes
                                        style: {
                                            alignSelf: 'center',
                                            background: '#00C6D8',
                                            border: 'none',
                                            borderRadius: '25px 0px 0px 25px',
                                            color: 'white',
                                            cursor: 'pointer',
                                            fontSize: '20px',
                                            height: 49,
                                            lineHeight: 1,
                                            textAlign: 'center',
                                            width: 40,
                                            boxShadow: "0px 3px 30px #00000029",
                                            marginRight: "30px"
                                        },
                                        children: <span>{`<`}</span>,
                                    }}
                                    responsiveProps={[
                                        {
                                            itemsToShow: 3,
                                            itemsToScroll: 1,
                                            minWidth: 768,
                                        },
                                    ]}
                                    speed={400}
                                    easing="linear"
                                >
                                    {/* here you can also pass any other element attributes. Also, you can use your custom components as slides */}

                                    <div className="story_box story-box-without-shadow">
                                        <div className="story_box_left">
                                            <div className="story_box_left_year"> 2016 TO 2018 </div>
                                            <div className="story_box_left_desc">Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incidid labore dolore magna aliqua. enim ad minim veniam quis nostrud exercitation ullamco laboris nisi aliquip commodo consequat fugiat nulla.</div>
                                        </div>
                                    </div>
                                    <div className="story_box story-box-without-shadow">
                                        <div className="story_box_left">
                                            <div className="story_box_left_year"> 2018 TO 2020 </div>
                                            <div className="story_box_left_desc">Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incidid labore dolore magna aliqua. enim ad minim veniam quis nostrud exercitation ullamco laboris nisi aliquip commodo consequat fugiat nulla.</div>
                                        </div>
                                    </div>
                                    <div className="story_box story-box-without-shadow">
                                        <div className="story_box_left">
                                            <div className="story_box_left_year"> 2020 TO 2022 </div>
                                            <div className="story_box_left_desc">Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incidid labore dolore magna aliqua. enim ad minim veniam quis nostrud exercitation ullamco laboris nisi aliquip commodo consequat fugiat nulla.</div>
                                        </div>
                                    </div>
                                    <div className="story_box story-box-without-shadow">
                                        <div className="story_box_left">
                                            <div className="story_box_left_year"> 2016 TO 2018 </div>
                                            <div className="story_box_left_desc">Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incidid labore dolore magna aliqua. enim ad minim veniam quis nostrud exercitation ullamco laboris nisi aliquip commodo consequat fugiat nulla.</div>
                                        </div>
                                    </div>
                                    <div className="story_box story-box-without-shadow">
                                        <div className="story_box_left">
                                            <div className="story_box_left_year"> 2018 TO 2020 </div>
                                            <div className="story_box_left_desc">Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incidid labore dolore magna aliqua. enim ad minim veniam quis nostrud exercitation ullamco laboris nisi aliquip commodo consequat fugiat nulla.</div>
                                        </div>
                                    </div>
                                    <div className="story_box story-box-without-shadow">
                                        <div className="story_box_left">
                                            <div className="story_box_left_year"> 2020 TO 2022 </div>
                                            <div className="story_box_left_desc">Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incidid labore dolore magna aliqua. enim ad minim veniam quis nostrud exercitation ullamco laboris nisi aliquip commodo consequat fugiat nulla.</div>
                                        </div>
                                    </div>
                                </ReactSimplyCarousel>
                            </div>
                        </div>
                    </div>
                </section>
                <Subscribe />
                {/* <NeedHelp /> */}
            </main>
            <Footer />
        </div>
    )
}

export default AboutUs;
