import moment from "moment";
import { Fragment, useEffect, useState } from "react";
import toast from "react-hot-toast";
import { Link, Navigate, useNavigate } from "react-router-dom";
import { Tab, TabList, TabPanel, Tabs } from "react-tabs";
import 'react-tabs/style/react-tabs.css';
import { useAppStateAPI } from "../common/AppStateAPI";
import { Annual_income_status, Experience, Mail_mobile_verify, PageLinks, Selected_gender, Selected_marital_status, true_false_value, AccountTypeValues1 } from "../common/Constants";
import { APIData } from "../common/DataTypes";
import { ServerAPI } from "../common/ServerAPI";
import ProfileUpdate from "../components/Modals/ProfileUpdate";
import Header from "../components/template/Header";
import { styled } from '@mui/system';
import SwitchUnstyled, { switchUnstyledClasses } from '@mui/base/SwitchUnstyled';
import ConfirmationModel from "../components/Modals/ConfirmationModel";

const MyAccountprofile: React.FC = () => {
    const [accountDetail, setAccountDetail] = useState<APIData.userDetail>();
    const [address, setAddress] = useState<any>([])
    const [bankDetails, setBankDetails] = useState<APIData.BankAccountDetails[]>([]);
    const [exisitingDematDetails, setExisitingDematBankDetails] = useState<APIData.ExisitingDematAccountDetails[]>([]);
    const [newDematDetails, setNewDematDetails] = useState<APIData.NewDematDetails[]>([]);
    const [nomineeDetails, setNomineeDetails] = useState<APIData.NomineeDetailsData[]>([]);
    const navigate = useNavigate();
    const [profileUpdateModel, setShowProfileUpdateModel] = useState(false);
    const [dematCheked, setDematCheked] = useState(false);
    const [confirmationModel, setConfirmationModel] = useState(false);
    const [profileUpdateKey, setShowProfileUpdateKey] = useState('');
    const [confirmtype, setConfirmType] = useState('');
    const [confirmID, setConfirmID] = useState(0);
    const { setShowPreloader } = useAppStateAPI();

    const loadUserDetails = () => {
        setShowPreloader(true);
        ServerAPI.userDetailData().then((res: APIData.userDetail) => {
            setAccountDetail(res);
            setAddress(() => res.address_line_1 != "" && res.address_line_2 != "" && res.address_line_3 != "" && [res.address_city, res.address_line_1, res.address_line_2, res.address_line_3, res.address_line_3, res.address_state, res.address_zip])
        }).finally(() => {
            setShowPreloader(false);
        })
    }

    useEffect(() => {
        loadUserDetails();
    }, [Tab])


    const getbankAccounts = () => {
        setShowPreloader(true);
        ServerAPI.BankDetails().then((response: any) => {
            setBankDetails(response);
        }).finally(() => {
            setShowPreloader(false);
        })
    }
    const getNomineeDetails = () => {
        setShowPreloader(true);
        ServerAPI.NomineeDetails().then((response: any) => {
            setNomineeDetails(response);
        }).finally(() => {
            setShowPreloader(false);
        })
    }

    const getExisitingDematAccounts = () => {
        setShowPreloader(true);
        ServerAPI.ExisitingDematAccountList().then((response: any) => {
            setExisitingDematBankDetails(response);
        }).finally(() => {
            setShowPreloader(false);
        })
    }
    const getNewDematAccounts = () => {
        setShowPreloader(true);
        ServerAPI.NewDematAccountList().then((response: any) => {
            setNewDematDetails(response);
        }).finally(() => {
            setShowPreloader(false);
        })
    }

    const bankStatusUpdate = (id: number, status: number) => {
        setShowPreloader(true);
        ServerAPI.BankStatus(id, status).then((response: any) => {
            getbankAccounts();
        }).finally(() => {
            setShowPreloader(false);
        })
    }
    const dematStatusUpdate = (id: number, status: number) => {
        setShowPreloader(true);
        ServerAPI.DematStatus(id, status).then((response: any) => {
            toast.success(response['message']);
            getNewDematAccounts();
        }).finally(() => {
            setShowPreloader(false);
        })
    }
    const ExistingdematStatusUpdate = (id: number, status: number) => {
        setShowPreloader(true);
        ServerAPI.ExisitingDematStatus(id, status).then((response: any) => {
            toast.success(response['message']);
            getExisitingDematAccounts();
        }).finally(() => {
            setShowPreloader(false);
        })
    }

    const DematSetasPrimary = (id: number, type: number) => {
        setShowPreloader(true);
        ServerAPI.setasPrimaryDemat(id, type).then((response: any) => {
            getExisitingDematAccounts();
            getNewDematAccounts();
        }).finally(() => {
            setShowPreloader(false);
        })
    }

    const BankSetasPrimary = (id: number) => {
        setShowPreloader(true);
        ServerAPI.setasPrimaryBankAccount(id).then((response: any) => {
            toast.success(response['message']);
            getbankAccounts();
        }).finally(() => {
            setShowPreloader(false);
        })
    }

    const deleteNomineeDetails = (id: number) => {
        setShowPreloader(true);
        ServerAPI.deleteNomineeDetails(id).then((response: any) => {
            if (response != undefined) {
                toast.success(response['message']);
                getNomineeDetails();
            }
        })
    }
    useEffect(() => {
        getbankAccounts();
        getExisitingDematAccounts();
        getNomineeDetails();
        getNewDematAccounts();
    }, [])

    const maskingFunction = (acno: string) => {
        let subNum = acno.replace(/\d(?=\d{3})/g, "*");
        return (subNum);
    };


    const Root = styled('span')(
        ({ theme }) => `
        font-size: 0;
        position: relative;
        display: inline-block;
        width: 40px;
        height: 24px;
        margin: 10px auto;
        cursor: pointer;
      
        &.${switchUnstyledClasses.disabled} {
          opacity: 0.4;
          cursor: not-allowed;
        }
      
        & .${switchUnstyledClasses.track} {
          background: #FF405A;
          border-radius: 16px;
          display: block;
          height: 100%;
          width: 100%;
          position: absolute;
        }
      
        & .${switchUnstyledClasses.thumb} {
          display: block;
          width: 16px;
          height: 16px;
          top: 4px;
          left: 4px;
          border-radius: 16px;
          background-color: #C8C7CD;
          position: relative;
          
          transition-property: all;
          transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
          transition-duration: 120ms;
        }
      
        &.${switchUnstyledClasses.focusVisible} .${switchUnstyledClasses.thumb} {
          background-color:#CCCCCC;
          box-shadow: 0 0 1px 8px rgba(0, 0, 0, 0.25);
        }
      
        &.${switchUnstyledClasses.checked} {
          .${switchUnstyledClasses.thumb} {
            left: 20px;
            top: 4px;
            background-color:#000000;
          }
      
          .${switchUnstyledClasses.track} {
            background: transparent;
            border:1px solid black;
          }
        }
      
        & .${switchUnstyledClasses.input} {
          cursor: inherit;
          position: absolute;
          width: 100%;
          height: 100%;
          top: 0;
          left: 0;
          opacity: 0;
          z-index: 1;
          margin: 0;
        }
        `,
    );

    return (
        <Fragment>
            <div className="main-index-wrapper">
                <Header />
                <main className="pt-150">
                    <div className="container my_account">
                        <div className="breadcrumb">
                            <ul>
                                <li>Home /</li>
                                <li>My Account /</li>
                                <li><Link to={PageLinks.MY_ACCOUNT_PROFILE}>Profile</Link></li>
                            </ul>
                        </div>
                        <Tabs>
                            <TabList>
                                <Tab>Personal Details</Tab>
                                <Tab>Bank Details</Tab>
                                <Tab>Demant Details</Tab>
                            </TabList>
                            <TabPanel>
                                <section className="subscribe-letter-area pb-100">
                                    <div className="container custom-container-subs">
                                        <div className="sub-bg">
                                            <div className="row justify-content-center">
                                                <div className="col-xl-12 p-0">
                                                    <div className="step_box_content">
                                                        <div className="sub-bg">
                                                            <div className="row justify-content-center">
                                                                <div className="col-xl-12">
                                                                    <div className="subscribe-wrapper">
                                                                        <div className="section-title section-title-3">
                                                                            <h6 className="" data-wow-delay="0.1s">Personal Details</h6>
                                                                            <Link to={PageLinks.PROFILE_PERSONAL_DETAILS_EDIT} className="theme_btn profile_close_btn sub-btn edit-personal-detail" data-wow-delay="0.5s"><i className="far fa-edit"></i> Edit</Link>
                                                                            <hr></hr>
                                                                        </div>
                                                                        <form className="quote-form mb-10 d-lg-flex" action="#">
                                                                            <div className="col-xl-6 p-0">
                                                                                <div className="choice-list">
                                                                                    <span className="my-account-input-title">Full Name</span>
                                                                                    <span className="my-account-input-title-filled">{accountDetail?.firstname} {accountDetail?.lastname}</span>
                                                                                </div>
                                                                                <div className="choice-list">
                                                                                    <span className="my-account-input-title">Mobile Number</span>
                                                                                    <span className="my-account-input-title-filled">
                                                                                        <div className=""><img className="preview-detail-flag" src="assets/img/icon/flag.png" />&nbsp;&nbsp;
                                                                                            {accountDetail?.country_code} {accountDetail?.mobile_number}
                                                                                            {accountDetail?.is_mobile_verified == Mail_mobile_verify.Verified ?
                                                                                                <a className="theme_btn verified_btn"> <i className="far fa-check-circle"></i> Verified</a>
                                                                                                : <a className="theme_btn not_verified_btn"> <i className="far fa-times-circle"></i> Not Verified</a>
                                                                                            }
                                                                                        </div>

                                                                                    </span>
                                                                                </div>
                                                                                <div className="choice-list">
                                                                                    <span className="my-account-input-title">Email ID</span>
                                                                                    <span className="my-account-input-title-filled">{accountDetail?.email_id}
                                                                                        {accountDetail?.is_email_verified == Mail_mobile_verify.Verified ?
                                                                                            <a className="theme_btn verified_btn"> <i className="far fa-check-circle"></i> Verified</a>
                                                                                            : <a className="theme_btn not_verified_btn"> <i className="far fa-times-circle"></i> Not Verified</a>
                                                                                        }
                                                                                        <a className="theme_btn update-button" onClick={(e: any) => {
                                                                                            e.preventDefault();
                                                                                            localStorage.setItem('update', 'true');
                                                                                            navigate(PageLinks.EMAIL_VERIFICATION);
                                                                                        }}> <i className="fas fa-edit"></i> Update</a>
                                                                                    </span>
                                                                                </div>
                                                                                <div className="choice-list">
                                                                                    <span className="my-account-input-title">Date of Birth - Should be as per Aadhar</span>
                                                                                    <span className="my-account-input-title-filled">{moment(accountDetail?.dob).format('DD/MM/YYYY')}  </span>
                                                                                </div>
                                                                                <div className="choice-list">
                                                                                    <span className="my-account-input-title">PAN Number</span>
                                                                                    <span className="my-account-input-title-filled"> {accountDetail?.pan_number != "" ? <>{accountDetail?.pan_number}</> : " XXXXXXXXXX"}
                                                                                        {accountDetail?.is_pan_verified == Mail_mobile_verify.Verified ?
                                                                                            <a className="theme_btn verified_btn"> <i className="far fa-check-circle"></i> Verified</a>
                                                                                            : <a className="theme_btn not_verified_btn"> <i className="far fa-times-circle"></i> Not Verified</a>
                                                                                        }
                                                                                    </span>
                                                                                </div>
                                                                                <div className="choice-list">
                                                                                    <span className="my-account-input-title">Aadhaar Number</span>
                                                                                    <span className="my-account-input-title-filled">{accountDetail?.aadhar_number != "" ? <>{accountDetail?.aadhar_number}</> : " XXXX-XXXX -XXXX"}
                                                                                        {accountDetail?.is_aadhar_verified == Mail_mobile_verify.Verified ?
                                                                                            <a className="theme_btn verified_btn"> <i className="far fa-check-circle"></i> Verified</a>
                                                                                            : <a className="theme_btn not_verified_btn"> <i className="far fa-times-circle"></i> Not Verified</a>
                                                                                        }
                                                                                    </span>
                                                                                </div>
                                                                                <div className="choice-list">
                                                                                    <span className="my-account-input-title">What is your gender?</span>
                                                                                    <span className="my-account-input-title-filled gender_col">
                                                                                        <span className="gender_btn_profile">
                                                                                            <a className={accountDetail?.gender == Selected_gender.Male ? "gender_btn selected" : "gender_btn"}> Male <input type="radio" className="d-none" /></a>
                                                                                            <a className={accountDetail?.gender == Selected_gender.Female ? "gender_btn selected" : "gender_btn"}> Female <input type="radio" className="d-none" /></a>
                                                                                            <a className={accountDetail?.gender == Selected_gender.Trans ? "gender_btn selected" : "gender_btn"}> Trans <input type="radio" className="d-none" /></a>
                                                                                        </span>
                                                                                        <a className="theme_btn update-button" onClick={(e: any) => {
                                                                                            e.preventDefault();
                                                                                            setShowProfileUpdateKey('gender');
                                                                                            setShowProfileUpdateModel(true);
                                                                                        }}> <i className="fas fa-edit"></i> Update</a>
                                                                                    </span>
                                                                                </div>
                                                                                <div className="choice-list">
                                                                                    <span className="my-account-input-title">What is your marital status?</span>
                                                                                    <span className="my-account-input-title-filled gender_col">
                                                                                        <span className="gender_btn_profile">
                                                                                            <a className={accountDetail?.married_status == Selected_marital_status.Single ? "gender_btn selected" : "gender_btn"}> Single <input type="checkbox" className="d-none" /></a>
                                                                                            <a className={accountDetail?.married_status == Selected_marital_status.Married ? "gender_btn selected" : "gender_btn"}> Married <input type="checkbox" className="d-none" /></a>
                                                                                            <a className={accountDetail?.married_status == Selected_marital_status.Others ? "gender_btn selected" : "gender_btn"}> Others <input type="checkbox" className="d-none" /></a>
                                                                                        </span>
                                                                                        <a className="theme_btn update-button" onClick={(e: any) => {
                                                                                            e.preventDefault();
                                                                                            setShowProfileUpdateKey('marital_status');
                                                                                            setShowProfileUpdateModel(true);
                                                                                        }}> <i className="fas fa-edit"></i> Update</a>
                                                                                    </span>
                                                                                </div>
                                                                            </div>
                                                                            <div className="col-xl-6 p-0">
                                                                                <div className="email-input input_field pl-0">
                                                                                    <label className="my-account-input-title">Address (As per Aadhaar)</label>
                                                                                    <textarea className="text-field" value={address.length > 0 ? address : "XXXXXX, XXXXXX, XXXXX"}></textarea>
                                                                                    <a className="theme_btn update-button" onClick={(e: any) => {
                                                                                        e.preventDefault();
                                                                                        setShowProfileUpdateKey('address');
                                                                                        setShowProfileUpdateModel(true);
                                                                                    }}> <i className="fas fa-edit"></i> Update</a>
                                                                                </div>
                                                                                <div className="choice-list">
                                                                                    <span className="my-account-input-title">What is your Occupation</span>
                                                                                    <span className="my-account-input-title-filled">{accountDetail?.occupation == "1" ? "Salary" : "Business"}</span>
                                                                                </div>
                                                                                <div className="choice-list">
                                                                                    <span className="my-account-input-title">What is your annual income?</span>
                                                                                    <span className="my-account-input-title-filled gender_col">
                                                                                        <span className="gender_btn_profile">
                                                                                            <a className={accountDetail?.annual_income == Annual_income_status.below_one ? "gender_btn selected" : "gender_btn"}> Below 1 Lakh <input type="checkbox" className="d-none" /></a>
                                                                                            <a className={accountDetail?.annual_income == Annual_income_status.one_to_five ? "gender_btn selected" : "gender_btn"}> 1 - 5 Lakhs <input type="checkbox" className="d-none" /></a>
                                                                                            <a className={accountDetail?.annual_income == Annual_income_status.five_to_ten ? "gender_btn selected" : "gender_btn"}> 5 - 10 Lakhs <input type="checkbox" className="d-none" /></a>
                                                                                            <a className={accountDetail?.annual_income == Annual_income_status.ten_to_twentyfive ? "gender_btn selected" : "gender_btn"}> 10 - 25 Lakhs <input type="checkbox" className="d-none" /></a>
                                                                                            <a className={accountDetail?.annual_income == Annual_income_status.above_twntyfive ? "gender_btn selected" : "gender_btn"}> Above 25 Lakhs <input type="checkbox" className="d-none" /></a>
                                                                                        </span>
                                                                                        <a className="theme_btn update-button" onClick={(e: any) => {
                                                                                            e.preventDefault();
                                                                                            setShowProfileUpdateKey('annual_income');
                                                                                            setShowProfileUpdateModel(true);
                                                                                        }}> <i className="fas fa-edit"></i> Update</a>
                                                                                    </span>
                                                                                </div>
                                                                                <div className="choice-list">
                                                                                    <span className="my-account-input-title">What is your trading experience?</span>
                                                                                    <span className="my-account-input-title-filled gender_col">
                                                                                        <span className="gender_btn_profile">
                                                                                            <a className={accountDetail?.trading_experience == Experience.below_one ? "gender_btn selected" : "gender_btn"}> Less than 1 year <input type="checkbox" className="d-none" /></a>
                                                                                            <a className={accountDetail?.trading_experience == Experience.one_to_two ? "gender_btn selected" : "gender_btn"}> 1 - 2 Years <input type="checkbox" className="d-none" /></a>
                                                                                            <a className={accountDetail?.trading_experience == Experience.two_to_five ? "gender_btn selected" : "gender_btn"}> 2 - 5 Years <input type="checkbox" className="d-none" /></a>
                                                                                            <a className={accountDetail?.trading_experience == Experience.five_to_ten ? "gender_btn selected" : "gender_btn"}> 5 - 10 Years <input type="checkbox" className="d-none" /></a>
                                                                                            <a className={accountDetail?.trading_experience == Experience.ten_to_twenty ? "gender_btn selected" : "gender_btn"}> 10 - 20 Years <input type="checkbox" className="d-none" /></a>
                                                                                            <a className={accountDetail?.trading_experience == Experience.twenty_to_twentyfive ? "gender_btn selected" : "gender_btn"}> 20 - 25 Years <input type="checkbox" className="d-none" /></a>
                                                                                            <a className={accountDetail?.trading_experience == Experience.above_twentyfive ? "gender_btn selected" : "gender_btn"}> Above 25 Years <input type="checkbox" className="d-none" /></a>
                                                                                        </span>
                                                                                        <a className="theme_btn update-button" onClick={(e: any) => {
                                                                                            e.preventDefault();
                                                                                            setShowProfileUpdateKey('trading_experience');
                                                                                            setShowProfileUpdateModel(true);
                                                                                        }}> <i className="fas fa-edit"></i> Update</a>
                                                                                    </span>
                                                                                </div>
                                                                                <div className="choice-list">
                                                                                    <span className="my-account-input-title">Mother’s Maiden Name</span>
                                                                                    <span className="my-account-input-title-filled">{accountDetail?.mothers_maiden_name}
                                                                                        <a className="theme_btn update-button" onClick={(e: any) => {
                                                                                            e.preventDefault();
                                                                                            setShowProfileUpdateKey('mothers_maiden_name');
                                                                                            setShowProfileUpdateModel(true);
                                                                                        }}> <i className="fas fa-edit"></i> Update</a>
                                                                                    </span>
                                                                                </div>
                                                                                <div className="choice-list d-flex">
                                                                                    <span className="my-account-input-title-filled-switch">Are You Politically Exposed</span>
                                                                                    <div className="my-account-input-title-filled-switch">
                                                                                        <div className="custom-switch custom-switch-label-yesno pl-0 my-account-profile-switch">
                                                                                            <input className="custom-switch-input" id="is_politically_exposed" type="checkbox" name="is_politically_exposed" checked={accountDetail?.is_politically_exposed == true_false_value.True ? true : false} />
                                                                                            <label className="custom-switch-btn" htmlFor="is_politically_exposed"></label>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <div className="choice-list d-flex">
                                                                                    <span className="my-account-input-title-filled-switch">Would Like To Activate Your Futures & Options, Currency & Commodity Segment As Well?</span>
                                                                                    <div className="my-account-input-title-filled-switch">
                                                                                        <div className="custom-switch custom-switch-label-yesno pl-0">
                                                                                            <input className="custom-switch-input" id="is_future" type="checkbox" name="would_you_like_to_activate" checked={accountDetail?.would_you_like_to_activate == true_false_value.True ? true : false} />
                                                                                            <label className="custom-switch-btn" htmlFor="is_future"></label>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <div className="choice-list">
                                                                                    <span className="my-account-input-title">IPV</span>
                                                                                    <span className="my-account-input-title-filled-switch">
                                                                                        In-Person Verification
                                                                                        {accountDetail?.in_person_verification != 0 ?
                                                                                            <a className="theme_btn verified_btn"> <i className="far fa-check-circle"></i> Verified</a>
                                                                                            : <a className="theme_btn not_verified_btn"> <i className="far fa-times-circle"></i> Not Verified</a>
                                                                                        }
                                                                                        {/* <a className="theme_btn verified_btn"> <i className="far fa-check-circle"></i> Verified</a> */}
                                                                                    </span>
                                                                                </div>
                                                                            </div>
                                                                        </form>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {nomineeDetails != undefined && nomineeDetails.length > 0 &&
                                        <div className="container custom-container-subs mt-5">
                                            <div className="sub-bg">
                                                <div className="row justify-content-center">
                                                    <div className="col-xl-12 p-0">
                                                        <div className="step_box_content" style={{ background: "#ffffff" }}>
                                                            <div className="sub-bg">
                                                                <div className="row justify-content-center">
                                                                    <div className="col-xl-12">
                                                                        <div className="subscribe-wrapper">
                                                                            <div className="section-title section-title-3">
                                                                                <h6 className="" data-wow-delay="0.1s">Nominee Details</h6>
                                                                                {/* <Link to={PageLinks.EDIT_NOMINEE} className="theme_btn profile_close_btn sub-btn edit-personal-detail" data-wow-delay="0.5s"><i className="far fa-plus"></i> Add</Link> */}
                                                                                <p className="theme_btn profile_close_btn sub-btn edit-personal-detail" data-wow-delay="0.5s" onClick={(e: any) => {
                                                                                    e.preventDefault();
                                                                                    localStorage.setItem('nominee', 'true');
                                                                                    navigate(PageLinks.ADD_NOMINEE);
                                                                                }}
                                                                                ><i className="far fa-plus"></i> Add</p>
                                                                                <hr></hr>
                                                                            </div>
                                                                            {nomineeDetails.length > 0 && nomineeDetails.map((nominee: APIData.NomineeDetailsData, index) => {
                                                                                return (
                                                                                    <Fragment>
                                                                                        <div className="section-title section-title-3">
                                                                                            <h6 className="" data-wow-delay="0.1s"><u>{index + 1 + "."}Nominee</u>
                                                                                                <a className="theme_btn update-button" onClick={(e: any) => {
                                                                                                    e.preventDefault();
                                                                                                    navigate({
                                                                                                        pathname: PageLinks.EDIT_NOMINEE + "/" + nominee.id,
                                                                                                    });
                                                                                                }}> <i className="fas fa-edit"></i> Update</a>
                                                                                                <a className="theme_btn delete-button" onClick={(e: any) => {
                                                                                                    e.preventDefault();
                                                                                                    deleteNomineeDetails(nominee.id);
                                                                                                }}> <i className="fa fa-trash" aria-hidden="true"></i>&nbsp;Delete</a></h6>
                                                                                        </div>
                                                                                        <form className="quote-form mb-10 d-lg-flex" action="#">
                                                                                            <div className="col-xl-6 p-0">
                                                                                                <div className="choice-list">
                                                                                                    <span className="my-account-input-title">Title</span>
                                                                                                    <span className="my-account-input-title-filled">{nominee.nominee_details_title}</span>
                                                                                                </div>
                                                                                                <div className="choice-list">
                                                                                                    <span className="my-account-input-title">Full Name</span>
                                                                                                    <span className="my-account-input-title-filled">
                                                                                                        {nominee.nominee_details_fname}
                                                                                                    </span>
                                                                                                </div>
                                                                                                <div className="choice-list">
                                                                                                    <span className="my-account-input-title">Relationship With Applicant</span>
                                                                                                    <span className="my-account-input-title-filled">{nominee.nominee_details_relationship}
                                                                                                    </span>
                                                                                                </div>
                                                                                                <div className="choice-list">
                                                                                                    {nominee.nominee_details_identification == 1 &&
                                                                                                        <span className="my-account-input-title">PAN Number</span>
                                                                                                    }
                                                                                                    {nominee.nominee_details_identification == 2 &&
                                                                                                        <span className="my-account-input-title">Aadhar Card Number</span>
                                                                                                    }
                                                                                                    {nominee.nominee_details_identification == 3 &&
                                                                                                        <span className="my-account-input-title">Voter ID Number</span>
                                                                                                    }
                                                                                                    {nominee.nominee_details_identification == 4 &&
                                                                                                        <span className="my-account-input-title">Driving License Number</span>
                                                                                                    }
                                                                                                    {nominee.nominee_details_identification == 5 &&
                                                                                                        <span className="my-account-input-title">Passport</span>
                                                                                                    }
                                                                                                    <span className="my-account-input-title-filled">{nominee.nominee_details_identification_number}
                                                                                                    </span>
                                                                                                </div>
                                                                                                <div className="choice-list">
                                                                                                    <span className="my-account-input-title">Mobile Number</span>
                                                                                                    <span className="my-account-input-title-filled">
                                                                                                        <div className=""><img className="preview-detail-flag" src="assets/img/icon/flag.png" />&nbsp;&nbsp;
                                                                                                            +91 {nominee?.nominee_details_mobile_number}
                                                                                                        </div>

                                                                                                    </span>
                                                                                                </div>
                                                                                            </div>
                                                                                            <div className="col-xl-6 p-0">
                                                                                                <div className="email-input input_field pl-0">
                                                                                                    <div className="choice-list">
                                                                                                        <span className="my-account-input-title">Date of Birth - Should be as per Aadhar</span>
                                                                                                        <span className="my-account-input-title-filled">{moment(nominee?.nominee_details_dob).format('DD/MM/YYYY')}  </span>
                                                                                                    </div>
                                                                                                    <label className="my-account-input-title">Address (As per Aadhaar)</label>
                                                                                                    <textarea className="text-field" value={nominee.nominee_details_address_line_1 && nominee.nominee_details_address_line_2 ? nominee.nominee_details_address_line_1 + ',' + nominee.nominee_details_address_line_2 + '.' : "XXXXXX, XXXXXX, XXXXX"}></textarea>
                                                                                                </div>
                                                                                            </div>
                                                                                        </form>
                                                                                    </Fragment>
                                                                                )
                                                                            })}

                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    }
                                    {newDematDetails[0] != undefined &&
                                        <div className="container custom-container-subs mt-5">
                                            <div className="sub-bg">
                                                <div className="row justify-content-center">
                                                    <div className="col-xl-12 p-0">
                                                        <div className="step_box_content" style={{ background: "#ffffff" }}>
                                                            <div className="sub-bg facta-details">
                                                                <div className="row justify-content-center">
                                                                    <div className="col-xl-12">
                                                                        <div className="subscribe-wrapper">
                                                                            <div className="section-title section-title-3">
                                                                                <h6 className="" data-wow-delay="0.1s">FACTA Details</h6>
                                                                                <hr></hr>
                                                                            </div>
                                                                            <form className="quote-form mb-10 d-lg-flex" action="#">
                                                                                <div className="col-xl-6 p-0">
                                                                                    <div className="choice-list">
                                                                                        <span className="my-account-input-title">Where were you born?</span>
                                                                                        <span className="my-account-input-title-filled">{newDematDetails[0].born_place}</span>
                                                                                    </div>
                                                                                    <div className="choice-list">
                                                                                        <span className="my-account-input-title">Your primary source of wealth?</span>
                                                                                        <span className="my-account-input-title-filled">
                                                                                            {newDematDetails[0].primary_source}
                                                                                        </span>
                                                                                    </div>
                                                                                </div>
                                                                                <div className="col-xl-6 p-0">
                                                                                    <div className="email-input input_field pl-0">
                                                                                        <div className="choice-list d-flex">
                                                                                            <span className="my-account-input-title-filled-switch">Are You A Citizen Of The U.S.A?</span>
                                                                                            <div className="my-account-input-title-filled-switch">
                                                                                                <div className="custom-switch custom-switch-label-yesno pl-0 my-account-profile-switch">
                                                                                                    <input className="custom-switch-input" id="is_politically_exposed" type="checkbox" name="is_politically_exposed" checked={newDematDetails[0]?.dp_resident_usa == true_false_value.True ? true : false} />
                                                                                                    <label className="custom-switch-btn" htmlFor="is_politically_exposed"></label>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className="choice-list d-flex">
                                                                                            <span className="my-account-input-title-filled-switch">Is Your Country Of Tax Residency, India?</span>
                                                                                            <div className="my-account-input-title-filled-switch">
                                                                                                <div className="custom-switch custom-switch-label-yesno pl-0 my-account-profile-switch">
                                                                                                    <input className="custom-switch-input" id="is_politically_exposed" type="checkbox" name="is_politically_exposed" checked={newDematDetails[0]?.dp_resident_india == true_false_value.True ? true : false} />
                                                                                                    <label className="custom-switch-btn" htmlFor="is_politically_exposed"></label>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </form>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    }
                                    <div className="container custom-container-subs mt-5">
                                        <div className="sub-bg">
                                            <div className="row justify-content-center">
                                                <div className="col-xl-12 p-0">
                                                    <div className="step_box_content" style={{ background: "#ffffff" }}>
                                                        <div className="sub-bg facta-details">
                                                            <div className="row justify-content-center">
                                                                <div className="col-xl-12">
                                                                    <div className="subscribe-wrapper">
                                                                        <div className="section-title section-title-3">
                                                                            <h6 className="" data-wow-delay="0.1s">TM Account Details</h6>
                                                                            <hr></hr>
                                                                        </div>
                                                                        <form className="quote-form mb-10 d-lg-flex" action="#">
                                                                            <div className="col-xl-6 p-0">
                                                                                <div className="choice-list">
                                                                                    <span className="my-account-input-title">Client ID</span>
                                                                                    <span className="my-account-input-title-filled">{localStorage.getItem('customerid')}</span>
                                                                                </div>
                                                                                {accountDetail?.activation_date != null &&
                                                                                    <div className="choice-list">
                                                                                        <span className="my-account-input-title">Activation Date</span>
                                                                                        <span className="my-account-input-title-filled">
                                                                                            {accountDetail?.activation_date}
                                                                                        </span>
                                                                                    </div>
                                                                                }
                                                                            </div>
                                                                            {/* <div className="col-xl-6 p-0">
                                                                                <div className="choice-list">
                                                                                    <span className="my-account-input-title">Relationship Manager</span>
                                                                                    <span className="my-account-input-title-filled">
                                                                                        Pravin Parmar
                                                                                    </span>
                                                                                </div>
                                                                            </div> */}
                                                                        </form>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </section>
                            </TabPanel>
                            <TabPanel>
                                <section className="subscribe-letter-area pb-100">
                                    <div className="container custom-container-subs">
                                        <div className="sub-bg">
                                            <div className="row justify-content-center">
                                                <div className="col-xl-12 p-0">
                                                    <div className="step_box_content">
                                                        <div className="sub-bg">
                                                            <div className="row justify-content-center">
                                                                <div className="col-xl-12">
                                                                    <div className="subscribe-wrapper">
                                                                        <div className="section-title section-title-3">
                                                                            <h6 className="mb--10 " data-wow-delay="0.1s">Bank Details</h6>
                                                                            <div className="theme_btn add_new_account_btn sub-btn" onClick={(e: any) => {
                                                                                e.preventDefault();
                                                                                localStorage.setItem('bank', 'true');
                                                                                navigate(PageLinks.BANK_DETAILS);
                                                                            }}><i className="fal fa-plus"></i> Add New Account</div>
                                                                            <hr></hr>
                                                                        </div>
                                                                        <p className="personal_sub_text">You can add more bank account once your KYC is completed</p>
                                                                        <div className="col-xl-12 p-0 d-lg-flex">
                                                                            <div className="col-xl-12 col-12 p-0  float-left">
                                                                                {bankDetails != undefined && bankDetails.length > 0 && bankDetails.map((detail: APIData.BankAccountDetails) => {
                                                                                    return (
                                                                                        <div className="bank_card_full mb-3">
                                                                                            <div className={detail.bank_details_status == 1 ? "bank_card_left" : "bank_card_left bank-disabled"}>
                                                                                                <div className="bank_card_icon">
                                                                                                    <i className="fal fa-piggy-bank"></i>
                                                                                                </div>
                                                                                                <div className="bank_card_text">
                                                                                                    <div className="bank_card_text_head">
                                                                                                        {detail.bank_name}
                                                                                                    </div>
                                                                                                    <p className={detail.bank_details_status == 1 ? "bank_saving_btn" : "bank_saving_btn bank-disabled-primary"}>{detail.account_type_1 == AccountTypeValues1.savings ? "Savings" : "Current"} A/C</p>
                                                                                                    <div className="bank_card_text_num">{maskingFunction(detail.account_number + "")}</div>
                                                                                                </div>
                                                                                            </div>
                                                                                            <div className={detail.bank_details_status == 1 ? "bank_card_right" : "bank_card_right bank-disabled"}>
                                                                                                <SwitchUnstyled component={Root} checked={detail.bank_details_status == 1 ? true : false} onChange={(e: any) => {
                                                                                                    if (e.target.checked == true) {
                                                                                                        bankStatusUpdate(detail.bank_details_id, 1);
                                                                                                    } else {
                                                                                                        bankStatusUpdate(detail.bank_details_id, 0);
                                                                                                    }
                                                                                                }} />
                                                                                                <div className="bank_card_right_icon_divider"></div>
                                                                                                <button onClick={(e: any) => {
                                                                                                    e.preventDefault();
                                                                                                    setConfirmationModel(true);
                                                                                                    setConfirmID(detail.bank_details_id);
                                                                                                    setConfirmType('deletebank');
                                                                                                }} className="bank_card_right_icon"><i className="fal fa-trash-alt"></i></button>
                                                                                            </div>
                                                                                            <div onClick={(e: any) => {
                                                                                                e.preventDefault();
                                                                                                BankSetasPrimary(detail.bank_details_id);
                                                                                            }} className={detail.is_primary == 1 ? "bank_primarybtn cursor" : "bank-disabled-primary bank_primarybtn cursor"}>{detail.is_primary == 1 ? "PRIMARY" : "Set as Primary"}</div>
                                                                                        </div>
                                                                                    )
                                                                                })}
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </section>
                            </TabPanel>
                            <TabPanel>
                                <section className="subscribe-letter-area pb-100">
                                    <div className="container custom-container-subs">
                                        <div className="sub-bg">
                                            <div className="row justify-content-center">
                                                <div className="col-xl-12 p-0">
                                                    <div className="step_box_content">
                                                        <div className="sub-bg">
                                                            <div className="row justify-content-center">
                                                                <div className="col-xl-12">
                                                                    <div className="subscribe-wrapper">
                                                                        <div className="section-title section-title-3">
                                                                            <h6 className="mb--10 " data-wow-delay="0.1s">Demat Details</h6>
                                                                            <div onClick={(e: any) => {
                                                                                e.preventDefault();
                                                                                if (accountDetail != undefined) {
                                                                                    if (accountDetail.existing_demat_account_count < 2 || accountDetail.new_demat_account_count < 1) {
                                                                                        console.log(accountDetail?.new_demat_account_count);
                                                                                        localStorage.setItem('demat', 'true');
                                                                                        navigate(PageLinks.DEMAT_DETAILS);
                                                                                    } else {
                                                                                        toast.error("Already 3 Demat Account Added.")
                                                                                    }
                                                                                }
                                                                            }} className="theme_btn add_new_account_btn sub-btn"><i className="fal fa-plus"></i> Add New Account</div>
                                                                            <hr></hr>
                                                                        </div>
                                                                        <p className="personal_sub_text">You can add up to 3 demat accounts</p>
                                                                        <div className="col-xl-12 p-0 d-lg-flex">
                                                                            <div className="col-xl-12 col-12 p-0  float-left">
                                                                                {newDematDetails != undefined && newDematDetails.length > 0 && newDematDetails.map((newDemat: APIData.NewDematDetails) => {
                                                                                    return (
                                                                                        <div className="demat_card_full mb-3">
                                                                                            <div className={newDemat.demat_account_details_status == 1 ? "demat_card_left" : "demat_card_left bank-disabled"}>
                                                                                                <div className="demat_card_icon">
                                                                                                    <i className="fal fa-exchange"></i>
                                                                                                </div>

                                                                                                <div className="demat_card_text">
                                                                                                    <p className="demat_saving_btn">{newDemat.depository}</p>
                                                                                                    <br />
                                                                                                    <div className="demat_card_text_head_one">{newDemat.fname + newDemat.lname}</div>
                                                                                                    <div className="demat_card_text_num">{newDemat.dp_account_number}</div>
                                                                                                </div>
                                                                                            </div>
                                                                                            <div className={newDemat.demat_account_details_status == 1 ? "demat_card_right" : "bank_card_right bank-disabled"}>
                                                                                                <SwitchUnstyled component={Root} checked={newDemat.demat_account_details_status == 1 ? true : false} onChange={(e: any) => {
                                                                                                    if (e.target.checked == true) {
                                                                                                        dematStatusUpdate(newDemat.new_demat_id, 1);
                                                                                                    } else {
                                                                                                        dematStatusUpdate(newDemat.new_demat_id, 0);
                                                                                                    }
                                                                                                }} />
                                                                                                <div className="bank_card_right_icon_divider"></div>
                                                                                                <button onClick={(e: any) => {
                                                                                                    e.preventDefault();
                                                                                                    setConfirmationModel(true);
                                                                                                    setConfirmID(newDemat.new_demat_id);
                                                                                                    setConfirmType('deletedemat');
                                                                                                }} className="demat_card_right_icon"><i className="fal fa-trash-alt"></i></button>
                                                                                            </div>

                                                                                            <div
                                                                                                onClick={(e: any) => {
                                                                                                    e.preventDefault();
                                                                                                    DematSetasPrimary(newDemat.new_demat_id, 1);
                                                                                                }}
                                                                                                className={newDemat.demat_account_primary_account == 1 ? "demat_primarybtn cursor" : "bank-disabled-primary bank_primarybtn cursor"}>{newDemat.demat_account_primary_account == 1 ? "Primary" : "Set as Primary"}</div>
                                                                                        </div>
                                                                                    )
                                                                                })}
                                                                            </div>
                                                                        </div>

                                                                        <div className="col-xl-12 p-0 d-lg-flex">
                                                                            <div className="col-xl-12 col-12 p-0  float-left">
                                                                                {exisitingDematDetails != undefined && exisitingDematDetails.length > 0 && exisitingDematDetails.map((exisitingDemat: APIData.ExisitingDematAccountDetails) => {
                                                                                    return (
                                                                                        <div className="exisiting_demat_card_full mb-3">
                                                                                            <div className={exisitingDemat.exist_demat_account_details_status_key == 1 ? "exisiting_demat_card_left" : "demat_card_left bank-disabled"}>
                                                                                                <div className="exisiting_demat_card_icon">
                                                                                                    <i className="fal fa-exchange"></i>
                                                                                                </div>

                                                                                                <div className="exisiting_demat_card_text">
                                                                                                    <p className="demat_saving_btn">{exisitingDemat.depository}</p>
                                                                                                    <br />
                                                                                                    <div className="exisiting_demat_card_text_head_one">{exisitingDemat.dp_name}</div>
                                                                                                    <div className="exisiting_demat_card_text_num">{exisitingDemat.dp_id}</div>
                                                                                                </div>
                                                                                            </div>
                                                                                            <div className={exisitingDemat.exist_demat_account_details_status_key == 1 ? "exisiting_demat_card_right" : "bank_card_right bank-disabled"}>
                                                                                                <SwitchUnstyled component={Root} checked={exisitingDemat.exist_demat_account_details_status_key == 1 ? true : false} onChange={(e: any) => {
                                                                                                    if (e.target.checked == true) {
                                                                                                        ExistingdematStatusUpdate(exisitingDemat.exist_demat_id, 1);
                                                                                                    } else {
                                                                                                        ExistingdematStatusUpdate(exisitingDemat.exist_demat_id, 0);
                                                                                                    }
                                                                                                }} />
                                                                                                <div className="exisiting_demat_card_right_icon_divider"></div>
                                                                                                <button onClick={(e: any) => {
                                                                                                    e.preventDefault();
                                                                                                    setConfirmationModel(true);
                                                                                                    setConfirmID(exisitingDemat.exist_demat_id);
                                                                                                    setConfirmType('deleteexisitdemat');
                                                                                                }} className="exisiting_demat_card_right_icon"><i className="fal fa-trash-alt"></i></button>
                                                                                            </div>
                                                                                            <div onClick={(e: any) => {
                                                                                                e.preventDefault();
                                                                                                DematSetasPrimary(exisitingDemat.exist_demat_id, 2);
                                                                                            }} className={exisitingDemat.exist_demat_account_primary_account == 1 ? "exisiting_demat_primarybtn cursor" : "bank-disabled-primary bank_primarybtn cursor"}>{exisitingDemat.exist_demat_account_primary_account == 1 ? "PRIMARY" : "set as Primary"}</div>
                                                                                        </div>
                                                                                    )
                                                                                })}
                                                                            </div>
                                                                        </div>

                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </section>
                            </TabPanel>
                        </Tabs>
                    </div>
                </main>
            </div>
            <ProfileUpdate showModal={profileUpdateModel} closeModal={() => { setShowProfileUpdateModel(false) }} updateKey={profileUpdateKey} reloadList={loadUserDetails} />
            <ConfirmationModel showModal={confirmationModel} closeModal={() => { setConfirmationModel(false) }} confirmtype={confirmtype} id={confirmID} reload={() => { confirmtype == 'deletebank' ? getbankAccounts() : getNewDematAccounts(); getExisitingDematAccounts(); }} reloadUser={() => { loadUserDetails(); }} />
        </Fragment>
    )
}

export default MyAccountprofile;
