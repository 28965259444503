import { Fragment, useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { PageLinks } from "../../common/Constants";
import Popover from '@mui/material/Popover';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import React from "react";

const Footer: React.FC = () => {

    const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);
    const year = new Date().getFullYear();
    const [page, setPage] = useState('');
    const location = useLocation();

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;


    return (
        <Fragment>
            <footer className="footer-area fix">
                <div className="container-fluid">
                    <div className="row hr-border pt-50">
                        <div className="col-xl-3 col-lg-4 col-md-6  " data-wow-delay='.1s'>
                            <div className="footer__widget text-center text-md-left mb-30">
                                <div className="footer-log mb-10">
                                    <a href="#" className="logo">
                                        <img src="assets/img/logo/header_logo_white.png" alt="" />
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-3 col-lg-4 col-md-6 " data-wow-delay='.3s'>
                            <div className="footer__widget text-left text-md-left mb-30 pl-40">
                                <h4 className="widget-title mb-20">Bonds</h4>
                                <ul className="fot-list">
                                    <li><Link to={PageLinks.PROFILE_LISTING}>Explore Bonds</Link></li>
                                    {/* <li><Link to={PageLinks.COLLECTION_OF_BONDS}>Collection of Bonds</Link></li>
                                    <li><Link to={PageLinks.FAQ}>Frequently Asked Questions</Link></li>
                                    <li><Link to={PageLinks.LEARN_FROM_BOND}>Learn, from Bond, Trust Bond</Link></li>
                                    <li><Link to={PageLinks.CHAT}>Talk to Bond, Trust Bond</Link></li> */}
                                    {/* <li><Link to={PageLinks.NEWSANDINSIGHTS}>Bond ki Khaberin</Link></li> */}
                                </ul>
                            </div>
                        </div>
                        <div className="col-xl-3 col-lg-4 col-md-6  " data-wow-delay='.5s'>
                            <div className="footer__widget text-left text-md-left mb-25 pl-85">
                                <h4 className="widget-title mb-20">Quick Link</h4>
                                <ul className="fot-list">
                                    {/* <li><Link to={PageLinks.ABOUTUS}>About Us</Link></li>
                            <li><Link to={PageLinks.NEWSANDINSIGHTS}>News and Insights</Link></li> */}
                                    <li><Link to={PageLinks.TERMS_AND_CONDITIONS}>Terms of Use</Link></li>
                                    <li><Link to={PageLinks.PRIVACY_POLICY}>Privacy Policy</Link></li>
                                    <li><Link to={PageLinks.REFUND_POLICY}>Refund Policy</Link></li>
                                    <li><Link to={PageLinks.CONTACT_US}>Contact Us</Link></li>
                                    {/* <li><Link to={PageLinks.DISCLAIMER}>Disclaimer</Link></li> */}
                                </ul>
                            </div>
                        </div>
                        <div className="col-xl-3 col-lg-6 col-md-6  " data-wow-delay='.7s'>
                            <div className="footer__widget text-left text-md-left mb-30 pl-30 pl-lg-0 pl-md-0 pl-xs-0">
                                {/* <h4 className="widget-title mb-20">Talk to expert</h4> */}
                                {/* <ul className="fot-list">
                                    <li><Link to={PageLinks.CONTACT_US}>Write to Us</Link></li>
                                    <li><a href="#">Login To Chat</a></li>
                                </ul> */}
                                <div className="footer-social mt-20 mb-40">
                                    <a href="#" onClick={(e: any) => {
                                        e.preventDefault();
                                    }}><i className="fab fa-facebook-f"></i></a>
                                    <a  href="#" onClick={(e: any) => {
                                        e.preventDefault();
                                    }}><i className="fab fa-twitter"></i></a>
                                    <a  href="#" onClick={(e: any) => {
                                        e.preventDefault();
                                    }}><i className="fab fa-linkedin-in"></i></a>
                                    <a  href="#" onClick={(e: any) => {
                                        e.preventDefault();
                                    }}><i className="fab fa-instagram"></i></a>
                                    <a  href="#" onClick={(e: any) => {
                                        e.preventDefault();
                                    }}><i className="fab fa-medium-m"></i></a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="copy-right-area pt-10">
                        <div className="row align-items-center">
                            <div className="col-lg-12">
                                <div className="copyright mb-40 text-center">
                                    <p>© {year} Trust Capital Pvt Ltd. | CIN: U65929MH2016PTC287266</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* <div className="home_chat_icon">
                <span id="home_chat" onClick={handleClick} >
                    <img src="assets/img/icon/home_chat.svg" />
                </span>
            </div> */}
                <Popover
                    id="home_chat"
                    open={open}
                    anchorEl={anchorEl}
                    onClose={handleClose}
                    anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }}
                >
                    <Typography sx={{ p: 2 }}>
                        <div className="call-option-content home_chat_cont">
                            <h5>Need help to find bonds</h5>
                            <h6>Learn how to pick quality bonds <br></br> and start investing...  </h6>
                            <Link to={PageLinks.CHAT}><button className="alert-button"><span><i className="fa fa-arrow-right" aria-hidden="true"></i></span></button></Link>
                        </div>

                    </Typography>
                </Popover>
            </footer>
            <div className="footer_fixed_menu">
                <ul>
                    <li>
                        <Link to={PageLinks.PROFILE} className={(location.pathname == PageLinks.PROFILE || page == 'home') ? "header-active" : "header-notactive"} ><i className="far fa-home" aria-hidden="true"></i><br />Home</Link>
                    </li>
                    <li>
                        <Link to={PageLinks.PROFILE} className={(location.pathname == PageLinks.PROFILE || page == 'Service') ? "header-active" : "header-notactive"} ><i className='far fa-clipboard-list'></i> <br />Portfolio</Link>
                    </li>
                    <li>
                        <Link to={PageLinks.PROFILE} className={(location.pathname == PageLinks.PROFILE || page == 'Scheme') ? "header-active" : "header-notactive"}>
                            <i className="far fa-shopping-cart" aria-hidden="true"></i><br />orders</Link>
                    </li>
                    <li>
                        <Link to={PageLinks.PROFILE} className={(location.pathname == PageLinks.PROFILE || page == 'Product') ? "header-active" : "header-notactive"}>
                            <i className="far fa-comments"></i><br />chat</Link>
                    </li>
                </ul>
            </div>
        </Fragment>

    )
}

export default Footer;
