import React, { Fragment, useEffect, useState } from "react";
import { toast } from "react-hot-toast";
import { Link, Navigate, useLocation, useNavigate } from "react-router-dom";
import { useAppStateAPI } from "../common/AppStateAPI";
import { AccountTypeValues1, bondPurchaseTypes, bondTypes, PageLinks, RazorPayKey } from "../common/Constants";
import { APIData } from "../common/DataTypes";
import { ServerAPI } from "../common/ServerAPI";
import { InitialData } from "../components/Form/InitialData";
import BankAccounts from "../components/Modals/BankAccounts";
import DematAccounts from "../components/Modals/DematAccounts";
import Footer from "../components/template/Footer";
import Header from "../components/template/Header";
import useRazorpay, { RazorpayOptions } from "react-razorpay";



const PayBondPayment: React.FC = () => {
    const [paymentMode, setPaymentMode] = useState('');
    const location = useLocation();
    const [isin, setIsin] = useState('');
    const [id, setId] = useState('');
    const [paymode, setPaymode] = useState(0);
    const [dematId, setDematId] = useState(0);
    const [bondID, setBondID] = useState(0);
    const [bankId, setBankId] = useState(0);
    const [cityID, setCityId] = useState(0);
    const [dematType, setDematType] = useState(0);
    const [bondType, setBondType] = useState(0);
    const { setShowPreloader} = useAppStateAPI();
    const [userDetail, setUserDetails] = useState<APIData.userDetail>(InitialData.UserDetails);
    const [exisitingDematDetails, setExisitingDematBankDetails] = useState<APIData.ExisitingDematAccountDetails[]>([]);
    const [newDematDetails, setNewDematDetails] = useState<APIData.NewDematDetails[]>([]);
    const [bankDetails, setBankDetails] = useState<any[]>([]);
    const [ncdSeriesID, setNCDSeriesID] = useState<APIData.NCDSeriesOrder[]>([]);
    const [asbaDetails, setASBADetails] = useState<APIData.ASBADetails[]>([]);
    const [paymentModes, setPaymentModes] = useState<APIData.PaymentModes[]>([]);
    const [dematSelect, setDematSelect] = useState(false);
    const [bankSelect, setBankSelect] = useState(false);
    const [selectedDemat, setSelectedDemat] = useState<any>({});
    const [selectedBank, setSelectedBank] = useState<any>({});
    const [ipoData, setIpoData] = useState<any>();
    const [sgbData, setSGBData] = useState<any>();
    const [upiID, setUPIID] = useState('');
    const [secondPanName, setSecondPanName] = useState('');
    const [secondPanNo, setSecondPanNo] = useState('');
    const [thirdPanName, setThirdPanName] = useState('');
    const [thirdPanNo, setThirdPanNo] = useState('');
    const [categoryid, setCategoryID] = useState(0);
    const [subCategoryCode, setSubCategoryCode] = useState('');
    const navigate = useNavigate();
    var data: any = {};
    const Razorpay = useRazorpay();

    const dataVerify = () => {
        let ncddata: any = [];
        data = location.state;
        console.log(data['data'][0]);
        if (data != null && data['data'][0] != undefined && data['data'][0] != null) {
            if (data['data'][0].bond_type == bondTypes.IPO) {
                setBondType(data['data'][0].bond_type);
                setBondID(data['data'][0].bond_id);
                if (data['data'][0] != undefined && data['data'][0].bond_ncd_series != undefined) {
                    if (data['data'][0].bond_ncd_series.length > 0) {
                        setNCDSeriesID(data['data'][0].bond_ncd_series);
                    }
                }
                setSecondPanName(data['data'][0].ipo_order_second_appl_name);
                setSecondPanNo(data['data'][0].ipo_order_second_pan);
                setThirdPanName(data['data'][0].ipo_order_third_appl_name);
                setThirdPanNo(data['data'][0].ipo_order_third_pan);
                setCategoryID(data['data'][0].ipo_order_investor_category_id);
                setSubCategoryCode(data['data'][0].ipo_order_sub_category_code);
            } else if (data['data'][0].bond_type == bondTypes.SGB) {
                setBondType(data['data'][0].bond_type);
                setBondID(data['data'][0].bond_id);
            }
        }
    }
    useEffect(() => {
        dataVerify();
    }, [location]);

    useEffect(() => {
        if (data != null && data['data'] != undefined) {
            if (data['data'][0].bond_type == bondTypes.IPO) {
                setIpoData(data['data'][0]);
            } else if (data['data'][0].bond_type == bondTypes.SGB) {
                setSGBData(data['data'][0]);
            }
        }
    }, [data])


    const getExisitingDematAccounts = () => {
        setShowPreloader(true);
        ServerAPI.ExisitingDematAccountList().then((response: any) => {
            setExisitingDematBankDetails(response);
        }).finally(() => {
            setShowPreloader(false);
        })
    }

    const getASBACities = () => {
        if (data['data'] != undefined && data['data'][0] != undefined) {
            setShowPreloader(true);
            ServerAPI.ASBALists(data['data'][0].bond_id, selectedBank.id).then((response: any) => {
                if (response != undefined && response['message'] != undefined) {
                    setASBADetails(response['message']);
                }
            }).finally(() => {
                setShowPreloader(false);
            })
        }
    }
    const getNewDematAccounts = () => {
        setShowPreloader(true);
        ServerAPI.NewDematAccountList().then((response: any) => {
            setNewDematDetails(response);
        }).finally(() => {
            setShowPreloader(false);
        })
    }

    const getbankAccounts = () => {
        if (data['data'] != undefined && data['data'][0] != undefined) {
            if (data['data'][0].bond_type == bondTypes.IPO) {
                setShowPreloader(true);
                ServerAPI.OrderBankList(data['data'][0].bond_id).then((response: any) => {
                    if (response.length > 0) {
                        let params: any = {};
                        params.id = response[0].bank_details_id;
                        params.number = response[0].account_number;
                        params.name = response[0].bank_name;
                        params.account_type = response[0].account_type_1;
                        params.primary = 1;
                        params.is_asba = response[0].is_asba;
                        setShowPreloader(true);
                        ServerAPI.ASBALists(data['data'][0].bond_id, response[0].bank_details_id).then((response: any) => {
                            if (response != undefined && response['message'] != undefined) {
                                setASBADetails(response['message']);
                            }
                        }).finally(() => {
                            setShowPreloader(false);
                        })
                        setSelectedBank(params);
                        setBankDetails(response);
                        setBankId(response[0].bank_details_id);
                    }
                }).finally(() => {
                    setShowPreloader(false);
                })
            }
        }
    }

    const PaymentModes = () => {
        if (bondType == bondTypes.SGB) {
            setShowPreloader(true);
            ServerAPI.GetSGBPaymentModes().then((response: any) => {
                if (response != undefined) {
                    setPaymentModes(response['payment_modes'])
                }
            }).finally(() => {
                setShowPreloader(false);
            })
        } else {
            setShowPreloader(true);
            ServerAPI.GetBondPaymentModes().then((response: any) => {
                if (response != undefined) {
                    setPaymentModes(response['payment_modes'])
                }
            }).finally(() => {
                setShowPreloader(false);
            })
        }
    }

    useEffect(() => {
        PaymentModes();
    }, [bondType])


    useEffect(() => {
        if (bankId != 0 && paymentMode == 'ASBA') {
            getASBACities();
        }
    }, [bankId, paymentMode])


    useEffect(() => {
        getExisitingDematAccounts();
        getNewDematAccounts();
        if ((bondType) != bondTypes.SGB) {
            getbankAccounts();
        }
        getDematAccounts();
    }, [location, bondType])


    useEffect(() => {
        if (dematId != 0) {
            let params: any = {};
            let demat: any = {};
            if (dematType == 1) {
                demat = newDematDetails.filter(account => account.new_demat_id == dematId);
                params.id = demat[0].new_demat_id;
                params.name = demat[0].fname;
                params.number = demat[0].dp_account_number;
                params.depository = demat[0].depository;
                params.primary = demat[0].demat_account_primary_account;
                params.demat_type = demat[0].demat_type;
            } else {
                demat = exisitingDematDetails.filter(account => account.exist_demat_id == dematId);
                params.id = demat[0].exist_demat_id;
                params.name = demat[0].dp_name;
                params.number = demat[0].dp_id;
                params.depository = demat[0].depository;
                params.primary = demat[0].exist_demat_account_primary_account;
                params.demat_type = demat[0].demat_type;
            }
            setSelectedDemat(params);

        }
    }, [dematId, dematType])
    useEffect(() => {
        if (bankId != 0) {
            let params: any = {};
            let bank: any = {};
            bank = bankDetails.filter(account => account.bank_details_id == bankId);
            params.id = bank[0].bank_details_id;
            params.number = bank[0].account_number;
            params.name = bank[0].bank_name;
            params.primary = bank[0].is_primary;
            params.is_asba = bank[0].is_asba;
            setSelectedBank(params);

        }
    }, [bankId])

    const getDematAccounts = () => {
        setShowPreloader(true);
        ServerAPI.GetAllDematAccounts().then((response: any) => {
            let demat: any = {};
            if (response['exist_demat'] != undefined && response['exist_demat'].length > 0) {
                let params: any = {};
                params.id = response['exist_demat'][0].exist_demat_id;
                params.name = response['exist_demat'][0].dp_name;
                params.number = response['exist_demat'][0].dp_id;
                params.depository = response['exist_demat'][0].depository;
                params.primary = 1;
                params.demat_type = response['exist_demat'][0].demat_type;
                setSelectedDemat(params);
            } else if (response['new_demat'] != undefined && response['new_demat'].length > 0) {
                let params: any = {};
                params.id = response['new_demat'][0].new_demat_id;
                params.name = response['new_demat'][0].fname;
                params.number = response['new_demat'][0].dp_account_number;
                params.depository = response['new_demat'][0].depository;
                params.primary = 1;
                params.demat_type = response['new_demat'][0].demat_type;
                setSelectedDemat(params);
            }
        }).finally(() => {
            setShowPreloader(false);
        })
    }

    useEffect(() => {
        if (paymentMode == 'UPI') {
            setPaymode(2);
        } else if (paymentMode == 'ASBA')
            setPaymode(1);
    }, [paymentMode])

    useEffect(() => {
        PaymentModes();
    }, [])

    const SGBOrderPayment = (razorPayorderId: string, orderId: number, sgb_order_payment_id: number) => {
        const options: RazorpayOptions = {
            "key": RazorPayKey,
            "amount": (sgbData.sgb_order_amount * 100) + "",
            "currency": "INR",
            "name": "Trustmony",
            "description": "SGB Order",
            "image": "../assets/img/logo/header_logo_dark.svg",
            "order_id": razorPayorderId,
            "handler": function (response: any) {
                PaymentVerify(1, sgb_order_payment_id, response['razorpay_order_id'], response['razorpay_signature'], response['razorpay_payment_id']);
                navigator(orderId);
            },
            "prefill": {
                "name": userDetail.firstname,
                "email": userDetail.email_id,
                "contact": userDetail.mobile_number,
                "method": paymentMode == 'UPI' ? 'upi' : 'netbanking'

            },
            "theme": {
                "color": "#ff405a"
            }
        };

        const rzp1 = new Razorpay(options);
        rzp1.on('payment.failed', function (response: any) {
            PaymentVerify(2, sgb_order_payment_id, response.error.metadata.order_id);
        })
        rzp1.open();
    }

    const navigator = (orderId: number) => {
        navigate({
            pathname: PageLinks.BOND_CONFIRMED_ORDER,
            search: "?id=" + orderId + "&&bond=" + bondType,
        });
    }

    const PaymentVerify = (paymentStatus: number, order_payment_id: number, razorpay_order_id: number, signature?: string, payment_id?: number) => {
        setShowPreloader(true);
        ServerAPI.SGBPaymentVerify(paymentStatus, order_payment_id, razorpay_order_id, signature, payment_id).then((response: any) => {
            if (response != undefined) {
                if (paymentStatus == 1) {
                    toast.success(response['message']);
                } else {
                    toast.error(response['message']);
                }
            }
        }).finally(() => {
            setShowPreloader(false);
        })

    }


    const SGBorder = () => {
        if (Object.keys(selectedDemat).length === 0) {
            toast.error("Please Choose Your Demat Account To Proceed!");
        } else if (Object.keys(selectedBank).length === 0 && paymentMode == 'ASBA') {
            toast.error("Please Choose Your Bank Account To Proceed!");
        } else {
            setShowPreloader(true);
            ServerAPI.OrderSGBBond(selectedDemat.id, selectedDemat.demat_type, sgbData.sgb_order_quantity, sgbData.sgb_order_amount, sgbData.bond_id,sgbData.price_per_gram).then((response: any) => {
                if (response != undefined) {
                    SGBOrderPayment(response['razorpay_order_id'], response['order_id'], response['sgb_order_payment_id']);
                }
            }).finally(() => {
                setShowPreloader(false);
            })
        }
    }

    const IPOOrder = () => {
        if (Object.keys(selectedDemat).length === 0) {
            toast.error("Please Choose Your Demat Account To Proceed!");
        } else if (Object.keys(selectedBank).length === 0 && paymentMode == 'ASBA') {
            toast.error("Please Choose Your Bank Account To Proceed!");
        } else if (cityID == 0 && asbaDetails.length > 0 && paymentMode == 'ASBA') {
            toast.error("Please Choose City For ASBA  To Proceed!");
        } else if (cityID == 0 && asbaDetails.length == 0 && paymentMode == 'ASBA') {
            toast.error("ASBA Cannot Be Processed Try Another Payment Mode");
        } else {
            if (ipoData != undefined) {
                setShowPreloader(true);
                ServerAPI.OrderIPOBond(selectedDemat.id, ipoData.ipo_order_quantity, ipoData.ipo_order_amount, ipoData.bond_id, selectedDemat.demat_type, paymentMode == "UPI" ? upiID : "", cityID, selectedBank.id, paymode, ncdSeriesID, categoryid, subCategoryCode, secondPanName, secondPanNo, thirdPanName, thirdPanNo,ipoData.ipo_order_investor_type_id).then((response: any) => {
                    if (response != undefined) {
                        toast.success(response['message']);
                        navigate({
                            pathname: PageLinks.BOND_CONFIRMED_ORDER,
                            search: "?id=" + response['order_id'] + "&&bond=" + bondType,
                        });
                    }
                }).finally(() => {
                    setShowPreloader(false);
                })
            }
        }
    }

    useEffect(() => {
        setShowPreloader(true);
        ServerAPI.userDetailData().then((res: APIData.userDetail) => {
            setUserDetails(res);
        }).finally(() => {
            setShowPreloader(false);
        })
    }, []);





    return (
        <div className="main-index-wrapper">
            <Header />
            <div className="body-overlay"></div>
            <main>
                <section className="sovereign_gold_bonds payment_bond pt-100 pb-0 pt-md-45 pb-md-40 pt-xs-45 pb-xs-40">
                    <div className="container-fluid ">
                        <div className="row py-4 mx-4 px-4">
                            <div className="col-lg-8 col-md-6 col-sm-12 py-3">
                                <div className=" pt-3">
                                    <p className="quicksand-midium fs-28">
                                        <b>Payment</b>
                                    </p>
                                    <span className="text-light-grey quicksand-midium fs-20 p-0">Choose your payment method</span>
                                </div>
                                <br />
                                <div className="payment-img-style text-center">
                                    <img src="/assets/img/Payment_img.png" alt="Payment_img" className="payment-img" />
                                </div>

                            </div>

                            <div className="col-lg-4 col-md-6 col-sm-12  py-3">
                                <div className="card-bg w-100 padding-20">
                                    <p className="quicksand-bold">Select Demat Account</p> <br />

                                    <div className="demat_card_full mb-5 w-100">
                                        <div className="demat_card_left">
                                            <div className="demat_card_icon">
                                                <i className="fal fa-exchange"></i>
                                            </div>

                                            <div className="demat_card_text">
                                                {Object.keys(selectedDemat).length === 0 ?
                                                    <div className="demat_card_text_head_one mt-4" style={{ fontSize: "16px" }}>Choose Your Demat Account</div>
                                                    :
                                                    <Fragment>
                                                        <p className="demat_saving_btn">{selectedDemat.depository}</p>
                                                        <br />
                                                        <div className="demat_card_text_head_one">{selectedDemat.name}</div>
                                                        <div className="demat_card_text_num">{selectedDemat.number}</div>
                                                    </Fragment>
                                                }
                                            </div>

                                        </div>
                                        <div className="demat_card_right h-100">
                                            <button onClick={(e: any) => {
                                                e.preventDefault();
                                                setDematSelect(true);
                                            }} className="demat_card_right_icon mt-4"><i className="fas">&#xf107;</i></button>
                                        </div>
                                        {selectedDemat.primary == 1 &&
                                            <div className="demat_primarybtn">PRIMARY</div>
                                        }
                                    </div>
                                    {
                                        <Fragment>
                                            <p className="quicksand-bold">Select Payment Mode</p>
                                            <div className="choice-list">
                                                <span className="input-title-filled gender_col" role={"group"}>
                                                    {paymentModes != undefined && paymentModes.length > 0 && paymentModes.map((mode: APIData.PaymentModes) => {
                                                        return (
                                                            <div className={paymentMode == mode.payment_mode_name ? "gender_btn selected" : "gender_btn"}>
                                                                <input type="radio" value={mode.payment_mode_id} onClick={(e: any) => {
                                                                    setPaymentMode(mode.payment_mode_name);
                                                                }} className="d-none" name={mode.payment_mode_name} id={mode.payment_mode_id + ""} />
                                                                <label
                                                                    htmlFor={mode.payment_mode_id + ""}
                                                                    className={paymentMode == mode.payment_mode_name ? "gender-btn-label-selected" : "gender_btn-label"}
                                                                >
                                                                    {mode.payment_mode_name}
                                                                </label>
                                                            </div>
                                                        )
                                                    })}
                                                </span>
                                            </div>
                                        </Fragment>
                                    }
                                    {paymentMode == 'ASBA' &&

                                        <div className={selectedBank.is_asba == 1 ? "bank_card_full mb-3 w-100" : "bank_card_full-disabled mb-3 w-100"}>
                                            <div className="demat_card_left">
                                                <div className="demat_card_icon">
                                                    <i className="fal fa-exchange"></i>
                                                </div>

                                                <div className="demat_card_text">
                                                    {Object.keys(selectedBank).length === 0 ?
                                                        <div className="demat_card_text_head_one mt-4" style={{ fontSize: "16px" }}>Choose Your Bank Account</div>
                                                        :
                                                        <Fragment>
                                                            <div className="bank_card_text_head">
                                                                {selectedBank.name}
                                                            </div>
                                                            <p className="bank_saving_btn">{selectedBank.account_type == AccountTypeValues1.savings ? "Savings" : "Current"} A/C</p>
                                                            <div className="bank_card_text_num">{selectedBank.number}</div>
                                                        </Fragment>
                                                    }
                                                </div>

                                            </div>
                                            <div className={selectedBank.is_asba != 1 ? "bank_card_right-disabled h-100" : "bank_card_right h-100"}>
                                                <button onClick={(e: any) => {
                                                    e.preventDefault();
                                                    setBankSelect(true);
                                                }} className="demat_card_right_icon mt-4"><i className="fas">&#xf107;</i></button>
                                            </div>
                                            {selectedBank.primary == 1 &&
                                                <div className="demat_primarybtn">PRIMARY</div>
                                            }
                                        </div>
                                    }

                                    {paymentMode == 'UPI' && bondType == bondTypes.IPO &&
                                        <Fragment>
                                            <p className="quicksand-bold mt-2">Pay by UPI</p>
                                            <div className="row mb-3 col-lg-12 mt-3">
                                                <div className="col-lg-12 pt-10 pb-10 mt-1">
                                                    <p>Enter Your UPI ID</p>
                                                    <input placeholder="UPI ID" onChange={(e: any) => {
                                                        setUPIID(e.target.value);
                                                    }} className="input-valu2 box-shadow text-start px-2 py-2 ipo-purchase-pan-input w-100" />
                                                </div>

                                            </div>
                                        </Fragment>

                                    }

                                    {paymentMode == 'ASBA' && asbaDetails != undefined && asbaDetails.length > 0 &&
                                        <div className="col-lg-12 row">
                                            <select placeholder="Chosse City" onChange={(e: any) => {
                                                e.preventDefault();
                                                setCityId(e.target.value);
                                            }} className="input-valu2 box-shadow text-start px-2 py-2 ipo-purchase-pan-input w-100 bond-pay-select mt-5">
                                                {asbaDetails != undefined && asbaDetails.length > 0 && asbaDetails.map((asba: APIData.ASBADetails) => {
                                                    return (
                                                        <option value={asba.asba_bank_location_id + ""}>{asba.asba_bank_location_fullname}</option>
                                                    )
                                                })
                                                }
                                            </select>
                                        </div>}

                                    <div className="text-center pb-3 mt-5">
                                        <button onClick={(e: any) => {
                                            e.preventDefault();
                                            console.log(bondType);
                                            if (userDetail.ekyc_application_status == '1') {
                                                if ((bondType) == bondTypes.SGB) {
                                                    SGBorder();
                                                } else if ((bondType) == bondTypes.IPO) {
                                                    if (paymentMode == 'UPI' && upiID == '') {
                                                        toast.error("Please Enter Your UPI Id to Proceed");
                                                    } else {
                                                        IPOOrder();
                                                    }
                                                }
                                            } else {
                                                toast.error("Please Complete Your KYC Before Proceeding To Buy This Bond")
                                            }
                                        }} className="apply-gold-btn  text-white">Confirm Order and Pay</button>
                                    </div>
                                </div>

                            </div>


                        </div>
                    </div>
                </section>
            </main>
            <Footer />
            <DematAccounts showModal={dematSelect} closeModal={() => { setDematSelect(false) }} dematType={setDematType} dematDetailsId={setDematId} />
            <BankAccounts showModal={bankSelect} closeModal={() => { setBankSelect(false) }} bankDetailsId={setBankId} bondID={bondID} />
        </div>
    );
}

export default PayBondPayment;
