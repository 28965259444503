import moment from "moment";
import React, { Fragment, useEffect, useState } from "react";
import { toast } from "react-hot-toast";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useAppStateAPI } from "../common/AppStateAPI";
import { bondPurchaseTypes, bondTypes, interestPaymentFrequency, PageLinks } from "../common/Constants";
import { APIData } from "../common/DataTypes";
import { ServerAPI } from "../common/ServerAPI";
import { numberWithCommas } from "../common/Utilities";
import { InitialData } from "../components/Form/InitialData";
import Copy from "../components/Svg/Copy";
import Footer from "../components/template/Footer";
import Header from "../components/template/Header";
import { styled } from '@mui/system';
import SwitchUnstyled, { switchUnstyledClasses } from '@mui/base/SwitchUnstyled';
import copy from 'copy-to-clipboard';

const ApplyBondPurchase = () => {

    const navigate = useNavigate();
    const location = useLocation();
    const [isin, setIsin] = useState('');
    const [bondsDetail, setBondDetail] = useState<APIData.SGBDetail>();
    const { setShowPreloader, setSGBData, SGBBond } = useAppStateAPI();
    const [calculatorType, setCalculatortype] = useState(false);
    const [validationFailed, setValidationFailed] = useState(false);
    const [Amount, setAmount] = useState('');
    const [TotalAmount, setTotalAmount] = useState('0');
    const [qty, setQty] = useState(1);
    const [userDetail, setUserDetails] = useState<APIData.userDetail>(InitialData.UserDetails);


    const Root = styled('span')(
        ({ theme }) => `
        font-size: 0;
        position: relative;
        display: inline-block;
        width: 40px;
        height: 24px;
        margin: 10px auto;
        cursor: pointer;
      
        &.${switchUnstyledClasses.disabled} {
          opacity: 0.4;
          cursor: not-allowed;
        }
      
        & .${switchUnstyledClasses.track} {
          background: #02AD41;
          border-radius: 20px;
          display: block;
          height: 40px;
          width: 62px;
          position: absolute;
        }
      
        & .${switchUnstyledClasses.thumb} {
          display: block;
          width: 34px;
          height: 34px;
          top: 4px;
          left: 4px;
          border-radius: 20px;
          background-color: #fff;
          position: relative;
          
          transition-property: all;
          transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
          transition-duration: 120ms;
        }
      
        &.${switchUnstyledClasses.focusVisible} .${switchUnstyledClasses.thumb} {
          background-color:#CCCCCC;
          box-shadow: 0 0 1px 8px rgba(0, 0, 0, 0.25);
        }
      
        &.${switchUnstyledClasses.checked} {
          .${switchUnstyledClasses.thumb} {
            left: 25px;
            top: 3px;
            background-color:#fff;
          }
      
          .${switchUnstyledClasses.track} {
            background: #02AD41;
            border:1px solid #02AD41;
          }
        }
      
        & .${switchUnstyledClasses.input} {
          cursor: inherit;
          position: absolute;
          width: 100%;
          height: 100%;
          top: 0;
          left: 0;
          opacity: 0;
          z-index: 1;
          margin: 0;
        }
        `,
    );

    const SGBBondOrder = () => {
        if (localStorage.getItem('login') != '0' && localStorage.getItem('login') != undefined && localStorage.getItem('login') != null) {
            if (userDetail.ekyc_application_status == '1') {
                if (qty != 0) {
                    let items: any[] = [];
                    let params: any = {};
                    params.sgb_order_quantity = qty;
                    params.sgb_order_amount = TotalAmount;
                    params.bond_id = bondsDetail?.bond_id;
                    params.bond_type = bondTypes.SGB;
                    params.price_per_gram = bondsDetail?.bonds_price_per_gram;
                    items.push(params);
                    navigate(PageLinks.PAYMENTBOND, { state: { data: items } });
                }
            } else {
                toast.error("Please Complete Your KYC Before Proceeding To Buy This Bond")
            }
        } else {
            localStorage.setItem('redirection', 'SGB');
            localStorage.setItem('isin', bondsDetail?.bond_isin_number!);
            navigate(PageLinks.LOGIN);
        }
    }

    useEffect(() => {
        if (location.search) {
            const query = new URLSearchParams(location.search);
            if (query.has("isin")) {
                const id = query.get("isin");
                setIsin((id!));
            }
        }
    }, [location]);

    useEffect(() => {
        if (isin != '') {
            setShowPreloader(true);
            ServerAPI.SGBBondDetail(isin).then((response: any) => {
                if (response != undefined) {
                    setBondDetail(response);
                    setAmount(response['bonds_price_per_gram'] + "");
                }
            }).finally(() => {
                setShowPreloader(false);
            })
        }
    }, [isin])

    useEffect(() => {
        if (localStorage.getItem('login') != '0' && localStorage.getItem('login') != undefined && localStorage.getItem('login') != null) {
            setShowPreloader(true);
            ServerAPI.userDetailData().then((res: APIData.userDetail) => {
                setUserDetails(res);
            }).finally(() => {
                setShowPreloader(false);
            })
        }
    }, [])



    const bondAmount = () => {
        if (Amount != '') {
            setTotalAmount(parseInt(Amount) * qty + "");
        }
    }

    useEffect(() => {
        setTotalAmount('0');
    }, [calculatorType])

    useEffect(() => {
        if (validationFailed != true) {
            bondAmount();
        }
    }, [qty, validationFailed])

    useEffect(() => {
        if (SGBBond.sgb_order_quantity != 0) {
            setQty(SGBBond.sgb_order_quantity);
        }
        if (SGBBond.sgb_order_amount != 0) {
            setTotalAmount(SGBBond.sgb_order_amount);
        }

    }, [SGBBond])

    return (
        <div className="main-index-wrapper">
            <Header /><br />
            <div className="body-overlay"></div>
            <main>
                <section className="sovereign_gold_bonds pt-xl-70 pt-lg-70  pt-md-45 pb-md-40 pt-xs-45 pb-xs-40">
                    <div className="container-fluid  px-30">
                        <div className="row ">
                            <div className="col-lg-12 ">
                                <div className="row align-items-left">
                                    <div className="col-lg-8 breadcums pt-4 ">
                                        <nav>
                                            <ul>
                                                <li className="fw-500">Home</li>
                                                <li className="fw-500">Bonds</li>
                                                <li className="fw-500">Explore Bonds</li>
                                                <li className="fw-500">{bondsDetail?.bond_name}</li>
                                                <li className="active fw-500">Buy</li>
                                            </ul>
                                        </nav>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-12 col-md-12 col-sm-12 pt-3 ">
                            <div className="row">
                                <div className="col-lg-6 col-md-12 col-sm-12 ">
                                    <div className="row">
                                        <div className="card-box  padding-20">
                                            <div className="row  pb-3">
                                                <div className="col-lg-2 col-md-3 col-sm-6">
                                                    <img src={bondsDetail?.bond_logo} alt="sovereign" className="bond-title-img" />
                                                </div>
                                                <div className="col-lg-10 col-md-9 col-sm-6 ">
                                                    <p className="fs-20">{bondsDetail?.bond_name}</p>

                                                    <div className="d-flex my-2">
                                                        <a className="">
                                                            <p className="padding-style-fs-12 background-green title-background-style text-white">RETURNS Based On Gold Prices</p>
                                                        </a>
                                                        <a className="background-grey title-background-style mx-3">
                                                            <p className="padding-style-fs-12"> <span className="text-red">ISIN: </span>{bondsDetail?.bond_isin_number}&nbsp;
                                                                <span className="cursor" onClick={(e: any) => {
                                                                    e.preventDefault();
                                                                    copy(bondsDetail?.bond_isin_number!, {
                                                                        debug: true,
                                                                    });
                                                                    toast.success('ISIN Number Copied To Clipboard');
                                                                }}>
                                                                    <Copy />
                                                                </span></p>
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>
                                            {bondsDetail != undefined && bondsDetail.bond_eligible_investors.length > 0 &&
                                                <div className="col-lg-12 col-md-6 " data-wow-delay="0.1s">
                                                    <div className="d-flex">
                                                        <a className="background-lightblack text-white sourceSansPro-regular px-2 fs-12" >
                                                            Eligible Investors*
                                                        </a> <span className="triangle-right"></span>
                                                        {bondsDetail.bond_eligible_investors.map((investor: APIData.BondEligibleInvestors) => {
                                                            return (
                                                                <a className="background-grey px-2 mx-2 fs-12">
                                                                    {investor.eligible_investor_name}
                                                                </a>
                                                            )

                                                        })}
                                                    </div>
                                                </div>
                                            }

                                            <div className=" mt-3 mb-3">
                                                <p className="title"><b> Key Points To Consider</b></p>
                                                <span className="text-light sourceSansPro-regular">Face Value (In INR) and Issue Size (In INR Crores)</span>
                                            </div>

                                            <div>
                                                <table className="w-100 bond-table">
                                                    <tr>
                                                        <td className="w-50">
                                                            <p className="table-text-light sourceSansPro-semibold">Coupon Rate (Returns)</p>
                                                            <p className="sourceSansPro-bold">{bondsDetail?.bond_coupon_rate}%</p>
                                                        </td>
                                                        <td>
                                                            <p className="table-text-light sourceSansPro-semibold">Yeild</p>
                                                            <p className="sourceSansPro-bold">{bondsDetail?.bonds_yeild}%</p>
                                                        </td>
                                                    </tr>
                                                    {/* <br /> */}
                                                    <tr>
                                                        <td>
                                                            <p className="table-text-light sourceSansPro-semibold">Allotment Date</p>
                                                            <p className="sourceSansPro-bold">{moment(bondsDetail?.bond_allotment_date).format("MMMM Do YYYY")}</p>
                                                        </td>
                                                        <td>
                                                            <p className="table-text-light sourceSansPro-semibold">Issue Opening - Closing Dates</p>
                                                            <p className="sourceSansPro-bold">{moment(bondsDetail?.bond_opening_date).format("MMMM Do YYYY")}&nbsp;- {moment(bondsDetail?.bond_closing_date).format("MMMM Do YYYY")}</p>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td className="d-block">
                                                            <p className="table-text-light sourceSansPro-semibold">Next Interest Payment Date</p>
                                                            <p className="sourceSansPro-bold">{moment(bondsDetail?.bonds_next_interest_payment_date).format("MMMM Do YYYY")}</p>
                                                        </td>
                                                        <td>
                                                            <p className="table-text-light sourceSansPro-semibold ">Interest Payment Frequency</p>
                                                            <div >
                                                                {parseInt(bondsDetail?.bond_interest_type!) == interestPaymentFrequency.FOR_ANNUAL &&
                                                                    <p className="sourceSansPro-bold">Annual</p>
                                                                }
                                                                {parseInt(bondsDetail?.bond_interest_type!) == interestPaymentFrequency.FOR_MONTHLY &&
                                                                    <p className="sourceSansPro-bold">Monthly</p>
                                                                }
                                                                {parseInt(bondsDetail?.bond_interest_type!) == interestPaymentFrequency.FOR_QUARTERLY &&
                                                                    <p className="sourceSansPro-bold">Quarterly</p>
                                                                }
                                                                {parseInt(bondsDetail?.bond_interest_type!) == interestPaymentFrequency.FOR_SEMI_ANNUAL &&
                                                                    <p className="sourceSansPro-bold">Semi-Annual</p>
                                                                }
                                                                {parseInt(bondsDetail?.bond_interest_type!) == null || parseInt(bondsDetail?.bond_interest_type!) == undefined || bondsDetail?.bond_interest_type! == "" &&
                                                                    <p className="sourceSansPro-bold">N/A</p>
                                                                }
                                                            </div>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>
                                                            <p className="table-text-light sourceSansPro-semibold mt-2">Maturity after</p>
                                                            <div >
                                                                <p className="sourceSansPro-bold mb-0">{moment(bondsDetail?.bond_maturity_date).format("MMMM Do YYYY")}
                                                                    <span className="table-text-light sourceSansPro-semibold d-block margin-top--10" > (1st Exit Date)</span></p>
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <p className="table-text-light sourceSansPro-semibold mt-2">Purchase Limit</p>
                                                            <div >
                                                                <p className="sourceSansPro-bold mb-0"> {bondsDetail?.bond_purchase_limit_metric} Kg/{bondsDetail?.bond_purchase_limit}
                                                                    <span className="table-text-light sourceSansPro-semibold d-block margin-top--10" >  During each financial year</span></p>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>
                                                            <p className="table-text-light sourceSansPro-semibold">Issuer</p>
                                                            <p className="sourceSansPro-bold">{bondsDetail?.bond_issuer_name}</p>
                                                        </td>
                                                        <td>
                                                            <p className="table-text-light sourceSansPro-semibold ">RBI Notice</p>
                                                            <a href="#" className="sourceSansPro-bold mb-0">{bondsDetail?.bonds_banner_rcb_notice_link}</a>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td className="d-block">
                                                            <p className="table-text-light sourceSansPro-semibold">Next Interest Payment Date</p>
                                                            <p className="sourceSansPro-bold">{moment(bondsDetail?.bonds_next_interest_payment_date).format("MMMM Do YYYY")}</p>
                                                        </td>
                                                        <td>
                                                            <p className="table-text-light sourceSansPro-semibold ">Exit Option</p>
                                                            {bondsDetail?.exit_option_available == 1 ?
                                                                <Fragment>
                                                                    <p className="sourceSansPro-bold mb-0">Yes</p>
                                                                    <p className="table-text-light sourceSansPro-semibold d-block margin-top--10" >After the 5th year. Investors can exercise it on the next </p>
                                                                </Fragment>
                                                                :
                                                                <h5>No.</h5>
                                                            }
                                                            <p className="margin-top--10 table-text-light sourceSansPro-semibold"> interest payment dates.</p>
                                                            {/* </div> */}
                                                        </td>
                                                    </tr>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/* <div className="col-lg-1 col-md-0 col-sm-0"></div> */}

                                <div className="col-lg-6 col-md-12 col-sm-12 padding-left-70 ">
                                    <div className="row detail-view-calculation h-98">
                                        <div className="col-lg-12 p-0 section">
                                            <div className="row d-flex section-head">
                                                <div className="col-lg-12">
                                                    <p className="title">The maximum limit of subscription shall be 4 kg for individuals, 4 kg for Hindu Undivided Family (HUF) and 20 kg for trusts and similar entities notified by the government from time to time</p>
                                                </div>
                                            </div>
                                            <div className="row section-form align-items-center">
                                                <div className="row align-items-center">
                                                    <div className="col-lg-6">
                                                        <div>
                                                            <h6>Mode Of Investment</h6>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-6 pt-10 pb-10 row">
                                                        <div className="col-lg-4 mt-3">
                                                            <p className={calculatorType == false ? "sgb-calculator-active sourceSansPro-bold" : "sgb-calculator-inactive sourceSansPro-regular"}>Gold Qty</p>
                                                        </div>
                                                        <div className="col-lg-4">
                                                            <SwitchUnstyled component={Root} onChange={(e: any) => {
                                                                if (e.target.checked == true) {
                                                                    setCalculatortype(true);
                                                                } else {
                                                                    setCalculatortype(false);
                                                                }
                                                            }} />
                                                        </div>
                                                        <div className="col-lg-4 mt-3">
                                                            <p className={calculatorType == true ? "sgb-calculator-active sourceSansPro-bold" : "sgb-calculator-inactive sourceSansPro-regular"}>Amount</p>
                                                        </div>

                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-lg-6 pt-10 pb-10">
                                                        {calculatorType == true ?
                                                            <div>
                                                                <h6>Enter Amount (A) </h6>
                                                                <p>(Range : {"₹" + (numberWithCommas(bondsDetail?.bonds_price_per_gram!) + "") + "-" + "₹" + numberWithCommas((bondsDetail?.bonds_price_per_gram!) * 4000) + ""})</p>
                                                            </div>
                                                            :
                                                            <div>
                                                                <h6>Enter gold quantity (A)</h6>
                                                                <p>(Range: 1 gm - 4000 gm)</p>
                                                            </div>
                                                        }
                                                    </div>
                                                    {calculatorType == true ?
                                                        <div className="col-lg-6 pt-10 pb-10">
                                                            <div className="row">
                                                                <input className="input-valu2" onBlur={(e: any) => {
                                                                    e.preventDefault();
                                                                    if ((e.target.value < bondsDetail?.bonds_price_per_gram!) || (e.target.value > ((bondsDetail?.bonds_price_per_gram!) * 4000))) {
                                                                        toast.error("Please Enter Amount Within the Range");
                                                                        setValidationFailed(true);
                                                                    } else {
                                                                        setTotalAmount(e.target.value);
                                                                        if (bondsDetail?.bonds_price_per_gram != undefined) {
                                                                            setQty(parseInt(e.target.value) / (bondsDetail?.bonds_price_per_gram));
                                                                        }
                                                                        setValidationFailed(false);
                                                                    }
                                                                }} />

                                                            </div>
                                                        </div>
                                                        :
                                                        <div className="col-lg-6 pt-10 pb-10 pl-0 pr-0">
                                                            <div className="row">
                                                                <div className="col-lg-3">
                                                                    <button onClick={(e: any) => {
                                                                        e.preventDefault();
                                                                        if (qty != 1) {
                                                                            setQty(qty - 1);
                                                                        }
                                                                    }} className="detail-view-calculator-button">-</button>
                                                                </div>
                                                                <div className="col-lg-6">
                                                                    <input value={qty} onChange={(e: any) => {
                                                                        e.preventDefault();
                                                                        if (e.target.value > 4000 && e.target.value < 1) {
                                                                            toast.error("Please Enter the Quantity Within the Range");
                                                                        } else {
                                                                            setQty(e.target.value);
                                                                        }
                                                                    }} className="input-value1 box-shadow sgb-calculator-input-1 w-100" />
                                                                </div>
                                                                <div className="col-lg-3">
                                                                    <button onClick={(e: any) => {
                                                                        e.preventDefault();
                                                                        setQty(qty + 1);
                                                                    }} className="detail-view-calculator-button">+</button>
                                                                </div>

                                                            </div>
                                                        </div>
                                                    }
                                                </div>
                                                <div className="row">
                                                    <div className="col-lg-6 pt-10 pb-10">
                                                        <div>
                                                            <h6>Issue Price (B)</h6>
                                                            <p>(Price of 1 gram of gold)</p>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-6 pt-10 pb-10">
                                                        <div className="row">
                                                            <input value={"₹" + numberWithCommas(Amount)} className="input-valu2" readOnly={true} />
                                                        </div>
                                                    </div>
                                                </div>
                                                {calculatorType == false ?
                                                    <div className="row">
                                                        <div className="col-lg-6 pt-10 pb-10">
                                                            <div>
                                                                <h6>Total Investment</h6>
                                                                <p>Amount (A) x Issue Price (B)</p>
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-6 pt-10 pb-10">
                                                            <div className="row">
                                                                <input value={"₹" + numberWithCommas(TotalAmount)} className="input-valu2" readOnly={true} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    :
                                                    <div className="row">
                                                        <div className="col-lg-6 pt-10 pb-10">
                                                            <div>
                                                                <h6>Total Investment</h6>
                                                                <p>Investment Grams</p>
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-6 pt-10 pb-10">
                                                            <div className="row">
                                                                <input value={(parseInt(TotalAmount) / bondsDetail?.bonds_price_per_gram!).toFixed(2)} onChange={(e: any) => {
                                                                    console.log(e.target.value);
                                                                }} className="input-valu2" readOnly={true} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                }
                                            </div>
                                            <div className="review-text text-left checkbox-top-margin d-flex align-items-baseline">
                                                <input className="signup-conditons" type="checkbox" id="partnerterms" name="agreed" />&nbsp;&nbsp; <p> I have read and agreed to <span className="terms-signup-highlight"><u>terms & conditions </u></span>  </p>
                                            </div>

                                            <br />
                                            <div className="row align-items-center pt-20 pb-10">
                                                <div className="text-center ">
                                                    <button className="apply-gold-btn  text-white " onClick={(e: any) => {
                                                        e.preventDefault();
                                                        if (validationFailed == true || TotalAmount == '0') {
                                                            toast.error("Please Enter Valid Amount or Gold Qty to Proceed");
                                                        } else {
                                                            SGBBondOrder();
                                                        }
                                                    }}>Apply for Gold Bond</button>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </main>
            <Footer />
        </div>
    );
}

export default ApplyBondPurchase;
