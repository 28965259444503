import { Field, Formik } from "formik";
import moment from "moment";
import { Fragment, useEffect, useState } from "react";
import DatePicker from "react-date-picker";
import toast from "react-hot-toast";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useAppStateAPI } from "../common/AppStateAPI";
import { PageLinks } from "../common/Constants";
import { APIData } from "../common/DataTypes";
import { ServerAPI } from "../common/ServerAPI";
import { InitialData } from "../components/Form/InitialData";
import StepsComponent from "../components/StepsComponent";

const EditAddNominee: React.FC = () => {
    const navigate = useNavigate();
    const [nomineeAddress, setNomineeAddress] = useState(false);
    const [nomineeDetail, setNomineeDetails] = useState<APIData.NomineeData>(InitialData.NomineeData);
    const [relationshipDropdown, setRelationshipDropdown] = useState<APIData.Relationship[]>([]);
    const [nomineeDropdown, setNomineeDropdown] = useState<APIData.NomineeProof[]>([]);
    const [proofType, setProofType] = useState('Proof');
    const maxDate = moment(new Date()).format('YYYY-MM-DD');
    const [dob, setdob] = useState('');
    const [cityValue, setCityValue] = useState('');
    const [userDetail, setUserDetails] = useState<APIData.userDetail>();
    const [state, setState] = useState('');
    const [pincode, setPincode] = useState('');
    const { setShowPreloader } = useAppStateAPI();
    const [id, setID] = useState(0);
    const location = useLocation();



    useEffect(() => {
        if (location.pathname) {
            const id = location.pathname.split("/").pop();
            setID(parseInt(id!));
        }
    }, [location]);

    useEffect(() => {
        setShowPreloader(true);
        ServerAPI.Relationship().then((res: APIData.Relationship[]) => {
            setRelationshipDropdown(res)
        }).finally(() => {
            setShowPreloader(false);
        })
        setShowPreloader(true);
        ServerAPI.NomineeProof().then((res: APIData.NomineeProof[]) => {
            setNomineeDropdown(res)
        }).finally(() => {
            setShowPreloader(false);
        })

        setShowPreloader(true);
        ServerAPI.userDetailData().then((res: APIData.userDetail) => {
            setUserDetails(res)
        }).finally(() => {
            setShowPreloader(false);
        })

        if (id != 0) {
            setShowPreloader(true);
            ServerAPI.GetNomineeDetails(id).then((res: any) => {
                setNomineeDetails(res);
            }).finally(() => {
                setShowPreloader(false);
            })
        }
    }, [])

    useEffect(() => {
        if (id != 0) {
            setShowPreloader(true);
            ServerAPI.GetNomineeDetails(id).then((res: any) => {
                console.log(res);
                setNomineeDetails(res);
            }).finally(() => {
                setShowPreloader(false);
            })
        }
    }, [id])

    useEffect(() => {
        setShowPreloader(true);
        if (pincode != '') {
            ServerAPI.pincodeSearch(pincode).then((response: any) => {
                setState(response[0]['pincode_state_name']);
                setCityValue(response[0]['pincode_district']);
            }).finally(() => {
                setShowPreloader(false);
            })
        }

    }, [pincode])

    const validate = (values: APIData.NomineeData) => {
        let errors: any = {};
        if (nomineeAddress == true) {
            if (values.nominee_details_address_line_1 == "") {
                errors.nominee_details_address_line_1 = " Address line 1 is required";
            }
            if (values.nominee_details_address_line_2 == "") {
                errors.nominee_details_address_line_2 = " Address line 2 is required";
            }
            if (values.nominee_details_address_zip == "") {
                errors.nominee_details_address_zip = "Pincode is required";
            }
            if (values.nominee_details_address_state == "") {
                errors.nominee_details_address_state = "State is required";
            }
            if (values.nominee_details_address_city == "") {
                errors.nominee_details_address_city = "City is required";
            }
        } else {
            if (values.nominee_details_fname == '') {
                errors.nominee_details_fname = "First Name is Required";
            }
            if (values.nominee_details_relationship == '0') {
                errors.nominee_details_relationship = "Please Select Relationship With The Nominee";
            }
            if (values.nominee_details_identification == 0) {
                errors.nominee_details_identification = "Please Select Nominee's Identification";
            }
            if (values.nominee_details_identification_number == '') {
                errors.nominee_details_identification_number = "Please Enter Nominee's Identification Number";
            }
        }
        return errors;

    }

    return (
        <div className="main-index-wrapper">
            <main>
                {/* Profile-details-edit-area start */}
                <section className="subscribe-letter-area pt-20 pb-20">
                    <div className="line-shape d-none d-lg-inline-block">
                        <img className="img-fluid" src={window.location.origin + "/assets/img/Intersection_4.svg"} alt="" />
                    </div>

                    <div className="container custom-container-subs">
                        <div className="sub-bg">
                            <div className="row justify-content-center">
                                <div className="col-xl-12">
                                    <div className="subscribe-wrapper">
                                        <div className="section-title section-title-3 text-center">
                                            <img className="header-logo mb-5" src={window.location.origin + "/assets/img/logo/header_logo_one.png"} alt="logo" />
                                        </div>
                                    </div>
                                    <div className="step_box_content">
                                        <div className="sub-bg">
                                            <div className="row justify-content-center">
                                                <div className="col-xl-12">
                                                    <div className="subscribe-wrapper">
                                                        <div className="section-title section-title-3">
                                                            <h6 className="wow page-main-heading" data-wow-delay="0.1s">Nominee Details<span className="page-main-heading-steps"></span></h6>
                                                            <hr></hr>
                                                            <Formik validate={validate} initialValues={nomineeDetail!} enableReinitialize onSubmit={(values, { setSubmitting }) => {
                                                                let data: APIData.NomineeData = { ...values };
                                                                let valuedata: APIData.NomineeData = { ...values };
                                                                data.nominee_details_dob = moment(values.nominee_details_dob).format("DD-MM-yyyy") + "";
                                                                valuedata.nominee_details_dob = moment(values.nominee_details_dob).format("DD-MM-yyyy") + "";
                                                                if (values.nominee_details_mobile_number.length < 10) {
                                                                    toast.error("Please Enter Valid Mobile Number");
                                                                } else {
                                                                    if (nomineeAddress == true) {
                                                                        setShowPreloader(true);
                                                                        ServerAPI.UpdateNomineeDetails(id, data).then((response: any) => {
                                                                            if (response['message'] != undefined) {
                                                                                toast.success(response['message']);
                                                                                navigate(PageLinks.MY_ACCOUNT_PROFILE);
                                                                            }
                                                                        }).finally(() => {
                                                                            setShowPreloader(false);
                                                                        })
                                                                    } else {
                                                                        valuedata.nominee_details_address_line_1 = userDetail?.address_line_1!;
                                                                        valuedata.nominee_details_address_line_2 = userDetail?.address_line_2!;
                                                                        valuedata.nominee_details_address_city = userDetail?.address_city!;
                                                                        valuedata.nominee_details_address_state = userDetail?.address_state!;
                                                                        setShowPreloader(true);
                                                                        ServerAPI.UpdateNomineeDetails(id, valuedata).then((response: any) => {
                                                                            if (response['message'] != undefined) {
                                                                                toast.success(response['message']);
                                                                                navigate(PageLinks.MY_ACCOUNT_PROFILE);
                                                                            }
                                                                        }).finally(() => {
                                                                            setShowPreloader(false);
                                                                        })
                                                                    }
                                                                }

                                                            }}>
                                                                {({
                                                                    values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting,
                                                                }) => (
                                                                    <form className="quote-form mb-10" onSubmit={handleSubmit}>
                                                                        <div className="col-xl-12 pl-0 float-left">
                                                                            <h6 className="pt-20 mb--10  pb-10" data-wow-delay="0.1s">Please Fill In Nominee Details</h6>
                                                                        </div>
                                                                        <div className="col-xl-8 p-0 float-left">
                                                                            <div className="col-xl-6 pl-0 float-left mt-20">
                                                                                <div className="email-input input_field pl-0">
                                                                                    <label className="input-title">Title</label>
                                                                                    <Field as="select" name="nominee_details_title" onChange={handleChange} onBlur={handleBlur}>
                                                                                        <option value={""}>select</option>
                                                                                        <option value={"Mr"}>Mr</option>
                                                                                        <option value={"Ms"}>Ms</option>
                                                                                        <option value={"Mrs"}>Mrs</option>
                                                                                    </Field>
                                                                                </div>
                                                                            </div>
                                                                            <div className="col-xl-6 pl-0 float-left mt-20">
                                                                                <div className="email-input input_field pl-0">
                                                                                    <label className={errors.nominee_details_fname && touched.nominee_details_fname ? "error" : "input-title"}>Full Name <span className="error-area">*</span></label>
                                                                                    <Field as="input" onChange={handleChange} onBlur={handleBlur} placeholder="Enter First Name" name="nominee_details_fname" />
                                                                                </div>
                                                                                {errors.nominee_details_fname && touched.nominee_details_fname && (
                                                                                    <span className="error">{errors.nominee_details_fname}</span>
                                                                                )}
                                                                            </div>
                                                                            <div className="col-xl-6 pl-0 float-left">
                                                                                <div className="email-input input_field pl-0">
                                                                                    <label className="input-title">Relationship with Application</label>
                                                                                    <Field className="" name="nominee_details_relationship" as="select" onChange={handleChange} onBlur={handleBlur}>
                                                                                        <option value="0">select</option>
                                                                                        {relationshipDropdown != undefined && relationshipDropdown.length > 0 && relationshipDropdown.map((relation: APIData.Relationship, index) => {
                                                                                            return (
                                                                                                <option key={index} value={relation.relationship_with_application_name} >{relation.relationship_with_application_name}</option>
                                                                                            )
                                                                                        })

                                                                                        }
                                                                                    </Field>
                                                                                </div>
                                                                            </div>
                                                                            <div className="col-xl-6 pl-0 float-left">
                                                                                <div className="email-input input_field pl-0">
                                                                                    <label className="input-title">Nominee Identification</label>
                                                                                    <Field as="select" className="" name="nominee_details_identification" onChange={(e: any) => {
                                                                                        if (e.target.value == 0) {
                                                                                            e.preventDefault();
                                                                                            handleChange(e);
                                                                                            handleBlur(e);
                                                                                            setProofType('Proof');
                                                                                        } else {
                                                                                            e.preventDefault();
                                                                                            handleChange(e);
                                                                                            handleBlur(e);
                                                                                            setProofType(e.nativeEvent.target[e.target.selectedIndex].text);
                                                                                        };
                                                                                    }
                                                                                    }>
                                                                                        <option>select</option>
                                                                                        {nomineeDropdown != undefined && nomineeDropdown.length > 0 && nomineeDropdown.map((proof: APIData.NomineeProof, index) => {
                                                                                            return (
                                                                                                <option key={index} value={proof.nominee_identification_id} >{proof.address_proof}</option>
                                                                                            )
                                                                                        })
                                                                                        }
                                                                                    </Field>
                                                                                </div>
                                                                            </div>
                                                                            <div className="col-xl-6 pl-0 float-left">
                                                                                <div className="email-input input_field pl-0">
                                                                                    <label className="input-title">{proofType} Number</label>
                                                                                    <Field as="input" placeholder={"Enter" + " " + proofType + " " + "Number"} onChange={handleChange} onBlur={handleBlur} name="nominee_details_identification_number" />
                                                                                </div>
                                                                            </div>
                                                                            <div className="col-xl-6 pl-0 float-left">
                                                                                <div className="email-input input_field pl-0">
                                                                                    <label className="input-title">Mobile Number</label>
                                                                                    <Field as="input" maxLength={10} placeholder="Enter Mobile Number" onChange={handleChange} onBlur={handleBlur} name="nominee_details_mobile_number" />
                                                                                </div>
                                                                            </div>
                                                                            <div className="col-xl-6 pl-0 float-left">
                                                                                <div className="email-input input_field pl-0">
                                                                                    <label className={errors.nominee_details_dob ? "error" : "input-title"}>Date of Birth
                                                                                    </label>
                                                                                    <Field type="date" className="date-picker-input profile-calendar" name="nominee_details_dob" max={maxDate} min="1900-01-01" onChange={(e: any) => {
                                                                                        handleChange(e);
                                                                                        setdob(e.target.value);
                                                                                    }} />
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-xl-4 p-0 float-left">

                                                                        </div>
                                                                        <div className="col-xl-8 p-0 float-left">
                                                                            <div className="email-input input_field pl-0">
                                                                                <label className="input-title">Address (Same as the Applicant)</label>
                                                                                <textarea rows={4} disabled className="text-field" value={(userDetail?.address_line_1) + "," + (userDetail?.address_line_2) + "," + "\n" + (userDetail?.address_city) + " - " + (userDetail?.address_zip) + "," + "\n" + (userDetail?.address_state) + "."
                                                                                }></textarea>
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-xl-4 p-0 float-left">

                                                                        </div>
                                                                        <div className="col-xl-12 p-0 mt-10 mb-10 float-left"><input type="checkbox" id="terms" onChange={(e: any) => {
                                                                            if (e.target.checked) {
                                                                                setNomineeAddress(true);
                                                                            } else {
                                                                                setNomineeAddress(false);
                                                                            }

                                                                        }} /> Got a different Address</div>
                                                                        {nomineeAddress == true &&
                                                                            <Fragment>
                                                                                <div className="col-xl-12 pl-0 float-left">
                                                                                    <h6 className="pt-20 mb--10  pb-10" data-wow-delay="0.1s">Enter Address Detail</h6>
                                                                                </div>
                                                                                <div className="col-xl-8 p-0 float-left">
                                                                                    <div className="col-xl-6 pl-0 float-left mt-20">
                                                                                        <div className="email-input input_field pl-0">
                                                                                            <label className={errors.nominee_details_address_line_1 && touched.nominee_details_address_line_1 ? "error" : "input-title"}>Address line 1 <span className="error-area">*</span></label>
                                                                                            <Field as="input" className={errors.nominee_details_address_line_1 && touched.nominee_details_address_line_1 ? "input-error" : ""} type="text" placeholder="Enter Address line 1" name="nominee_details_address_line_1" onChange={handleChange} />
                                                                                            {errors.nominee_details_address_line_1 && touched.nominee_details_address_line_1 && (
                                                                                                <span className="error">{errors.nominee_details_address_line_1}</span>
                                                                                            )}
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="col-xl-6 pl-0 float-left mt-20">
                                                                                        <div className="email-input input_field pl-0">
                                                                                            <label className={errors.nominee_details_address_line_2 && touched.nominee_details_address_line_2 ? "error" : "input-title"}>Address line 2 <span className="error-area">*</span></label>
                                                                                            <Field as="input" className={errors.nominee_details_address_line_2 && touched.nominee_details_address_line_2 ? "input-error" : ""} type="text" placeholder="Enter Address line 2" name="nominee_details_address_line_2" onChange={handleChange} />
                                                                                            {errors.nominee_details_address_line_2 && touched.nominee_details_address_line_2 && (
                                                                                                <span className="error">{errors.nominee_details_address_line_2}</span>
                                                                                            )}
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="col-xl-6 pl-0 float-left">
                                                                                        <div className="email-input input_field pl-0">
                                                                                            <label className={errors.nominee_details_address_zip && touched.nominee_details_address_zip ? "error" : "input-title"}>Pincode <span className="error-area">*</span></label>
                                                                                            <Field as="input" className={errors.nominee_details_address_zip && touched.nominee_details_address_zip ? "input-error" : ""} type="text" placeholder="Enter Pincode" name="nominee_details_address_zip" maxLength={6} onChange={(e: any) => {
                                                                                                handleChange(e);
                                                                                            }}

                                                                                                onBlur={(e: any) => {
                                                                                                    setPincode(e.target.value);
                                                                                                }} />
                                                                                            {errors.nominee_details_address_zip && touched.nominee_details_address_zip && (
                                                                                                <span className="error">{errors.nominee_details_address_zip}</span>
                                                                                            )}
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="col-xl-6 pl-0 float-left">
                                                                                        <div className="email-input input_field pl-0">
                                                                                            <label className={errors.nominee_details_address_state && touched.nominee_details_address_state ? "error" : "input-title"}>State <span className="error-area">*</span></label>
                                                                                            <Field as="input" className={errors.nominee_details_address_state && touched.nominee_details_address_state ? "input-error" : ""} type="text" placeholder="Enter State" name="nominee_details_address_state" onChange={handleChange} />
                                                                                            {errors.nominee_details_address_state && touched.nominee_details_address_state && (
                                                                                                <span className="error">{errors.nominee_details_address_state}</span>
                                                                                            )}
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="col-xl-6 pl-0 float-left">
                                                                                        <div className="email-input input_field pl-0">
                                                                                            <label className={errors.nominee_details_address_city && touched.nominee_details_address_city ? "error" : "input-title"}>City <span className="error-area">*</span></label>
                                                                                            <Field as="input" className={errors.nominee_details_address_city && touched.nominee_details_address_city ? "input-error" : ""} type="text" placeholder="Enter City" name="nominee_details_address_city" onChange={handleChange} />
                                                                                            {errors.nominee_details_address_city && touched.nominee_details_address_city && (
                                                                                                <span className="error">{errors.nominee_details_address_city}</span>
                                                                                            )}
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </Fragment>
                                                                        }
                                                                        <div className="col-xl-4 p-0 float-left">

                                                                        </div>
                                                                        <div className="col-xl-12 p-0 float-left text-center mt-100">
                                                                            <button type="submit" className="theme_btn primary_btn_one sub-btn wow w-auto" data-wow-delay="0.5s">Continue</button>
                                                                        </div>
                                                                    </form>
                                                                )}
                                                            </Formik>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                {/* Profile-details-edit-area end */}
            </main>
        </div >
    )
}

export default EditAddNominee;
