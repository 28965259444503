import { url } from "inspector";
import Header from "../components/template/Header";
import SlideBar from "../components/template/SlideBar";
import Footer from "../components/template/Footer";
import Subscribe from "../components/Subscribe";
import NeedHelp from "../components/NeedHelp";

const NewsAndInsights: React.FC = () => {

    return (
        <div className="main-index-wrapper">
            <Header />
            <SlideBar />
            <div className="body-overlay"></div>
            <main>
                <section className="blog-area pt-110 pb-50 pt-md-45 pb-md-40 pt-xs-45 pb-xs-40">
                    <div className="container-fluid">
                        <div className="col-lg-12">
                            <div className="row align-items-left">
                                <div className="col-lg-6 breadcums">
                                    <nav>
                                        <ul>
                                            <li>Home</li>
                                            <li className="active">News and Insights</li>
                                        </ul>
                                    </nav>
                                </div>
                            </div>
                        </div>
                        <div className="row news_insights_content">
                            <div className="col-xl-4 col-lg-4 col-md-4">
                                <div className="blogs mb-50">
                                    <div className="blogs-mask-img">
                                        <div className="blogs__thumb mb-40">
                                            <img className="img-fluid" src="assets/img/icon/news_1.png" alt="" />
                                        </div>
                                    </div>
                                    <div className="blogs__content">
                                        <span className="tag mb-25">Travel</span> Jan 24, 2022
                                        <h3 className="blog-title mb-20"><a href="#">New ideas for a Equity Trading</a></h3>
                                        <a className="blog-btn" href="#"><img src="assets/img/icon/icon12.svg" alt="" /></a>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-4 col-lg-4 col-md-4">
                                <div className="blogs mb-50">
                                    <div className="blogs-mask-img">
                                        <div className="blogs__thumb mb-40">
                                            <img className="img-fluid" src="assets/img/icon/news_2.png" alt="" />
                                        </div>
                                    </div>
                                    <div className="blogs__content">
                                        <span className="tag mb-25">Travel</span> Jan 24, 2022
                                        <h3 className="blog-title mb-20"><a href="#">New ideas for a Equity Trading</a></h3>
                                        <a className="blog-btn" href="#"><img src="assets/img/icon/icon12.svg" alt="" /></a>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-4 col-lg-4 col-md-4">
                                <div className="blogs mb-50">
                                    <div className="blogs-mask-img">
                                        <div className="blogs__thumb mb-40">
                                            <img className="img-fluid" src="assets/img/icon/news_3.png" alt="" />
                                        </div>
                                    </div>
                                    <div className="blogs__content">
                                        <span className="tag mb-25">Travel</span> Jan 24, 2022
                                        <h3 className="blog-title mb-20"><a href="#">New ideas for a Equity Trading</a></h3>
                                        <a className="blog-btn" href="#"><img src="assets/img/icon/icon12.svg" alt="" /></a>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-4 col-lg-4 col-md-4">
                                <div className="blogs mb-50">
                                    <div className="blogs-mask-img">
                                        <div className="blogs__thumb mb-40">
                                            <img className="img-fluid" src="assets/img/icon/news_1.png" alt="" />
                                        </div>
                                    </div>
                                    <div className="blogs__content">
                                        <span className="tag mb-25">Travel</span> Jan 24, 2022
                                        <h3 className="blog-title mb-20"><a href="#">New ideas for a Equity Trading</a></h3>
                                        <a className="blog-btn" href="#"><img src="assets/img/icon/icon12.svg" alt="" /></a>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-4 col-lg-4 col-md-4">
                                <div className="blogs mb-50">
                                    <div className="blogs-mask-img">
                                        <div className="blogs__thumb mb-40">
                                            <img className="img-fluid" src="assets/img/icon/news_2.png" alt="" />
                                        </div>
                                    </div>
                                    <div className="blogs__content">
                                        <span className="tag mb-25">Travel</span> Jan 24, 2022
                                        <h3 className="blog-title mb-20"><a href="#">New ideas for a Equity Trading</a></h3>
                                        <a className="blog-btn" href="#"><img src="assets/img/icon/icon12.svg" alt="" /></a>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-4 col-lg-4 col-md-4">
                                <div className="blogs mb-50">
                                    <div className="blogs-mask-img">
                                        <div className="blogs__thumb mb-40">
                                            <img className="img-fluid" src="assets/img/icon/news_3.png" alt="" />
                                        </div>
                                    </div>
                                    <div className="blogs__content">
                                        <span className="tag mb-25">Travel</span> Jan 24, 2022
                                        <h3 className="blog-title mb-20"><a href="#">New ideas for a Equity Trading</a></h3>
                                        <a className="blog-btn" href="#"><img src="assets/img/icon/icon12.svg" alt="" /></a>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-4 col-lg-4 col-md-4">
                                <div className="blogs mb-50">
                                    <div className="blogs-mask-img">
                                        <div className="blogs__thumb mb-40">
                                            <img className="img-fluid" src="assets/img/icon/news_1.png" alt="" />
                                        </div>
                                    </div>
                                    <div className="blogs__content">
                                        <span className="tag mb-25">Travel</span> Jan 24, 2022
                                        <h3 className="blog-title mb-20"><a href="#">New ideas for a Equity Trading</a></h3>
                                        <a className="blog-btn" href="#"><img src="assets/img/icon/icon12.svg" alt="" /></a>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-4 col-lg-4 col-md-4">
                                <div className="blogs mb-50">
                                    <div className="blogs-mask-img">
                                        <div className="blogs__thumb mb-40">
                                            <img className="img-fluid" src="assets/img/icon/news_2.png" alt="" />
                                        </div>
                                    </div>
                                    <div className="blogs__content">
                                        <span className="tag mb-25">Travel</span> Jan 24, 2022
                                        <h3 className="blog-title mb-20"><a href="#">New ideas for a Equity Trading</a></h3>
                                        <a className="blog-btn" href="#"><img src="assets/img/icon/icon12.svg" alt="" /></a>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-4 col-lg-4 col-md-4">
                                <div className="blogs mb-50">
                                    <div className="blogs-mask-img">
                                        <div className="blogs__thumb mb-40">
                                            <img className="img-fluid" src="assets/img/icon/news_3.png" alt="" />
                                        </div>
                                    </div>
                                    <div className="blogs__content">
                                        <span className="tag mb-25">Travel</span> Jan 24, 2022
                                        <h3 className="blog-title mb-20"><a href="#">New ideas for a Equity Trading</a></h3>
                                        <a className="blog-btn" href="#"><img src="assets/img/icon/icon12.svg" alt="" /></a>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-12">
                                <div className="pagination-area mt-70 mb-35">
                                    <nav aria-label="Page navigation">
                                        <ul className="pagination justify-content-center">
                                            <li className="page-item"><a className="page-link" href="#"><i className="far fa-chevron-left"></i></a></li>
                                            <li className="page-item"><a className="page-link active" href="#">1</a></li>
                                            <li className="page-item"><a className="page-link" href="#">2</a></li>
                                            <li className="page-item"><a className="page-link" href="#">3</a></li>
                                            <li className="page-item"><a className="page-link" href="#">...</a></li>
                                            <li className="page-item"><a className="page-link" href="#">13</a></li>
                                            <li className="page-item"><a className="page-link" href="#"><i className="far fa-chevron-right"></i></a></li>
                                        </ul>
                                    </nav>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <Subscribe />
                {/* <NeedHelp /> */}
            </main>
            <Footer />
        </div>
    )
}

export default NewsAndInsights;
