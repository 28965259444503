import { url } from "inspector";
import { Fragment, useEffect, useRef, useState } from "react";
import DownloadIssueDocuments from "../components/DownloadIssueDocuments";
import Header from "../components/template/Header";
import SlideBar from "../components/template/SlideBar";
import Popover from '@mui/material/Popover';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import React from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { interestPaymentFrequency, IssuerType, NatureofInstruments, PageLinks } from "../common/Constants";
import Footer from "../components/template/Footer";
import Subscribe from "../components/Subscribe";
import NeedHelp from "../components/NeedHelp";
import InvestSteps from "../components/InvestSteps";
import { APIData } from "../common/DataTypes";
import { ServerAPI } from "../common/ServerAPI";
import { useAppStateAPI } from "../common/AppStateAPI";
import moment from "moment";
import { numberWithCommas } from "../common/Utilities";
import Copy from "../components/Svg/Copy";
import Alert from "../components/Modals/Alert";
import Download from "../components/Svg/Download";
import copy from 'copy-to-clipboard';
import { toast } from "react-hot-toast";


const DetailViewOne: React.FC = () => {

    const [openEarnDropDown, setOpenEarnDropDown] = useState(true);
    const toggleEarnDropdown = () => setOpenEarnDropDown(!openEarnDropDown);
    const [subTable, setSubTable] = useState(false);
    const toggleEarnSubTableDropdown = () => setSubTable(!subTable);
    const [isin, setIsin] = useState('');
    const [qty, setQty] = useState(1);
    const location = useLocation();
    const [bondsDetail, setBondDetail] = useState<APIData.BondlistData>();
    const [investmentValues, setInvestmentValues] = useState<APIData.InvestmentCalculator>();
    const [bondsDetailCashflow, setBondDetailCashflow] = useState<APIData.BondCashFlow[]>([]);
    const [investmentToday, setInvestmentToday] = useState('');
    const [alertPopup, setAlertPopup] = useState(false);
    const [highlighterClass, setHighlighterClass] = useState('');
    let InterstEarned = 0;
    let principalAmount = 0;
    const scollToRef = useRef();
    let nextprincipleAmount = 0;
    let totalreturnAmount = 0;

    const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);
    const { setShowPreloader } = useAppStateAPI();
    const navigate = useNavigate();

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    // const goToItemPurchase = (isin: string) =>
    //     navigate({
    //         pathname: PageLinks.APPLYBONDNONGOLD,
    //         search: "?isin=" + isin,
    //     });

    useEffect(() => {
        if (location.search) {
            const query = new URLSearchParams(location.search);
            if (query.has("isin")) {
                const id = query.get("isin");
                setIsin((id!));
            }
        }
    }, [location]);

    useEffect(() => {
        principalAmount = parseInt(investmentToday);
    }, [investmentToday])

    useEffect(() => {
        if (isin != '') {
            setShowPreloader(true);
            ServerAPI.BondDetail(isin).then((response: any) => {
                if (response != undefined) {
                    setBondDetail(response);
                }
            }).finally(() => {
                setShowPreloader(false);
            })

            setShowPreloader(true);
            ServerAPI.Cashflow(isin).then((response: any) => {
                if (response != undefined) {
                    setBondDetailCashflow(response);
                }
            }).finally(() => {
                setShowPreloader(false);
            })
        }
    }, [isin])

    useEffect(() => {
        if (isin != '') {
            setShowPreloader(true);
            ServerAPI.BondInvestmentCalculator(isin, qty).then((response: any) => {
                if (response != undefined) {
                    setInvestmentValues(response);
                    setInvestmentToday(response['total_investment']);
                }
            }).finally(() => {
                setShowPreloader(false);
            })
        }
    }, [isin, qty])

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    const gotoHighlight = (id: string) => {
        const element = document.getElementById(id);
        if (element) {
            element.scrollIntoView({ behavior: "smooth", block: "end", inline: "nearest" });

        }
    };

    return (
        <div className="main-index-wrapper">
            <Header />
            <SlideBar />
            <main>
                <section className="mt-0 pt-110 pb-30 pb-md-130 pt-md-130 pt-xs-150 pb-xs-10">
                    <div className="container-fluid">
                        <div className="col-lg-12">
                            <div className="row align-items-left">
                                <div className="col-lg-6 breadcums">
                                    <nav>
                                        <ul>
                                            <li>Home</li>
                                            <li>Bonds</li>
                                            <li>Explore Bonds</li>
                                            <li className="active">{bondsDetail?.bond_issuer_name}</li>
                                        </ul>
                                    </nav>
                                </div>
                            </div>
                        </div>
                        <div className="container-fluid">
                            <div className="ipo-nav-top ml-1">
                                <table className="ipo-nav-table w-70">
                                    <tr>
                                        <td className={highlighterClass == 'Highlights' ? "highlighter-active cursor" : "cursor"} onClick={(e: any) => {
                                            e.preventDefault();
                                            gotoHighlight('Highlights');
                                            setHighlighterClass('Highlights');
                                        }}>Key Highlights</td>
                                        <td className={highlighterClass == 'Series' ? "highlighter-active cursor" : "cursor"} onClick={(e: any) => {
                                            e.preventDefault();
                                            gotoHighlight('Series');
                                            setHighlighterClass('Series');
                                        }}>Series</td>
                                        <td className={highlighterClass == 'InvestmentCalculator' ? "highlighter-active cursor" : "cursor"} onClick={(e: any) => {
                                            e.preventDefault();
                                            gotoHighlight('InvestmentCalculator');
                                            setHighlighterClass('InvestmentCalculator');
                                        }}>Investment Calculator</td>
                                        <td className={highlighterClass == 'AboutBond' ? "highlighter-active cursor" : "cursor"} onClick={(e: any) => {
                                            e.preventDefault();
                                            gotoHighlight('AboutBond');
                                            setHighlighterClass('AboutBond');
                                        }}>About Bond</td>
                                        <td className={highlighterClass == 'IssuerDetails' ? "highlighter-active cursor" : "cursor"} onClick={(e: any) => {
                                            e.preventDefault();
                                            gotoHighlight('IssuerDetails');
                                            setHighlighterClass('IssuerDetails');
                                        }}>Issuer Details</td>
                                    </tr>
                                </table>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="sovereign_gold_bonds_detail_desc mt-0 pt-0 pb-0 pb-md-20 pt-md-60 pt-xs-60 pb-xs-20">
                    <div className="container-fluid">
                        <div className="row align-items-center pt-200 detail-view-description">
                            <div className="row">
                                <div className="col-lg-1 col-md-1 col-xs-1 col-4">
                                    <div>
                                        <img className="detail-one-view-logo" src={bondsDetail?.bond_logo} alt="" />
                                    </div>
                                </div>
                                <div className="col-lg-11 col-md-11 col-xs-11 col-8">
                                    <h4>{bondsDetail?.bond_issuer_name}</h4>
                                    <div>
                                        <button className="return-btn">RETURNS Based On Market</button>
                                        {bondsDetail?.bond_isin_number != '' &&
                                            <button className="isin-btn"><span>ISIN:&nbsp;</span>{bondsDetail?.bond_isin_number}&nbsp;
                                                <span className="cursor" onClick={(e: any) => {
                                                    e.preventDefault();
                                                    copy(bondsDetail?.bond_isin_number!, {
                                                        debug: true,
                                                    });
                                                    toast.success('ISIN Number Copied To Clipboard');
                                                }}>
                                                    <Copy />
                                                </span>
                                            </button>
                                        }
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-8 col-md-6 " data-wow-delay="0.1s">
                                <div className="d-flex flex-row bd-highlight mb-3">
                                    {bondsDetail != undefined && bondsDetail?.bond_eligible_investors.length > 0 &&
                                        <Fragment>
                                            <div className="bd-highlight eligible-head">Eligible Investors*</div>
                                            <div className="triangle-right"></div>
                                        </Fragment>
                                    }
                                    {bondsDetail != undefined && bondsDetail?.bond_eligible_investors.length > 0 && bondsDetail?.bond_eligible_investors.map((investor: APIData.BondEligibleInvestors) => {
                                        return (
                                            <div className="bd-highlight eligible-sub-head">{investor.eligible_investor_name}</div>
                                        )
                                    })}
                                </div>
                                <div>
                                    <h5 className="table-title">Key Points To Consider</h5>
                                    <p className="table-desc">Face Value (In INR) and Issue Size (In INR Crores)</p>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-6 col-sm-6">
                                {bondsDetail != undefined && bondsDetail.bond_rating_details.length > 0 &&
                                    <h4>BOND’S Rating</h4>
                                }
                                <div className="detail-view-meter" style={{ marginTop: "20px" }}>
                                    <div className="row mb-2">
                                        <div className="col-lg-12 row">
                                            {bondsDetail != undefined && bondsDetail.bond_rating_details.length > 0 && bondsDetail.bond_rating_details.map((rating: APIData.BondRatingDetails) => {
                                                return (
                                                    <Fragment>
                                                        {
                                                            rating.bond_rating_image != null && rating.bond_rating_image != "https://api.trustmony.com/api/v1/image_download?name=" &&
                                                            <div className="col-lg-6">
                                                                <img className="bond-ipo-detail-view-meter" src={rating.bond_rating_image} alt="" />
                                                                <div>
                                                                    <table className="ipo-bond-download-table mt-3">
                                                                        <tr>
                                                                            <td className="sourceSansPro-bold">{rating.bond_rating_agency}</td>
                                                                            <td className="sourceSansPro-bold">{rating.bond_rating}</td>
                                                                            {rating.bond_rating_certificate != "" &&
                                                                                <td className="sourceSansPro-bold cursor" onClick={(e: any) => {
                                                                                    e.preventDefault();
                                                                                    window.open(rating.bond_rating_certificate);
                                                                                }}><Download /></td>
                                                                            }
                                                                        </tr>
                                                                    </table>
                                                                </div>
                                                            </div>
                                                        }
                                                    </Fragment>
                                                )
                                            })
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                            < div className="row section-table mt-3 ml-0">
                                <div className="col-lg-4 col-md-4">
                                    <div className="row row-first mr-1">
                                        <div className="col-lg-6">
                                            <p>Coupon Rate (Returns)</p>
                                            <h5>{bondsDetail?.bond_coupon_on != null && bondsDetail?.bond_coupon_on != '' ? bondsDetail?.bond_coupon_on + "% p.a" : "N/A"}</h5>
                                        </div>
                                        <div className="col-lg-6">
                                            <p>Yeild</p>
                                            <h5>{bondsDetail?.bonds_yeild != null && bondsDetail?.bonds_yeild != '' ? bondsDetail?.bonds_yeild + "%" : "N/A"}</h5>
                                        </div>
                                    </div>
                                    <div className="row row-sec mr-1 pb-1">
                                        <div className="col-lg-6">
                                            <p>Face Value</p>
                                            <h5>{bondsDetail?.bond_face_value != null && bondsDetail?.bond_face_value != '' ? "₹" + numberWithCommas(bondsDetail?.bond_face_value) : "N/A"}</h5>
                                        </div>
                                        <div className="col-lg-6">
                                            <p>Issue Size (in Crores)</p>
                                            <h5>{bondsDetail?.bond_issue_size != null && bondsDetail?.bond_issue_size != '' ? "₹" + numberWithCommas(bondsDetail?.bond_issue_size) : "N/A"}</h5>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-4">
                                    <div className="row row-first mr-1">
                                        <div className="col-lg-6">
                                            <p>Interest Payment Frequency</p>
                                            {parseInt(bondsDetail?.bond_interest_frequency!) == interestPaymentFrequency.FOR_ANNUAL &&
                                                <h5>Annual</h5>
                                            }
                                            {parseInt(bondsDetail?.bond_interest_frequency!) == interestPaymentFrequency.FOR_MONTHLY &&
                                                <h5>Monthly</h5>
                                            }
                                            {parseInt(bondsDetail?.bond_interest_frequency!) == interestPaymentFrequency.FOR_QUARTERLY &&
                                                <h5>Quarterly</h5>
                                            }
                                            {parseInt(bondsDetail?.bond_interest_frequency!) == interestPaymentFrequency.FOR_SEMI_ANNUAL &&
                                                <h5>Semi-Annual</h5>
                                            }
                                            {parseInt(bondsDetail?.bond_interest_frequency!) == 0 &&
                                                <h5>N/A</h5>
                                            }
                                            {bondsDetail?.bond_interest_frequency == null || bondsDetail?.bond_interest_frequency == '' &&
                                                <h5>N/A</h5>
                                            }
                                        </div>
                                        <div className="col-lg-6">
                                            <p>Maturity Date</p>
                                            <h5>{bondsDetail?.bond_maturity_date != null && bondsDetail?.bond_maturity_date != '' ? moment(bondsDetail?.bond_maturity_date).format("MMMM Do YYYY") : "N/A"}</h5>
                                        </div>
                                    </div>
                                    <div className="row row-sec mr-1 pb-1">
                                        <div className="col-lg-6">
                                            <p>Tax Free</p>
                                            <h5>{bondsDetail?.bond_nature_of_instrument == NatureofInstruments.TAXABLE ? "Taxable" : "Tax Free"}</h5>
                                        </div>
                                        <div className="col-lg-6">
                                            <p>TDS on Interest</p>
                                            <h5>{bondsDetail?.bond_nature_of_instrument == NatureofInstruments.TAXABLE ? "YES" : "NO"}</h5>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-4">
                                    <div className="row row-first">
                                        <div className="col-lg-6">
                                            <p>Call Option</p>
                                            {bondsDetail != undefined && bondsDetail.bond_call_details != undefined && bondsDetail.bond_call_details.length > 0 ?
                                                <Fragment>
                                                    <h5>Yes<span className="call-option" id="call" onClick={handleClick} >{moment(bondsDetail?.bond_call_details[0].bond_call_detail_datetime).format("MMMM Do YYYY")} <img src="assets/img/call-option-info.svg" /></span></h5>
                                                </Fragment>
                                                :
                                                <h5>No</h5>
                                            }
                                        </div>
                                        <Popover
                                            id="call"
                                            open={open}
                                            anchorEl={anchorEl}
                                            onClose={handleClose}
                                            anchorOrigin={{
                                                vertical: 'top',
                                                horizontal: 'right',
                                            }}
                                            transformOrigin={{
                                                vertical: 'top',
                                                horizontal: 'left',
                                            }}
                                        >
                                            <Typography sx={{ p: 2 }}>
                                                <div className="call-option-content">
                                                    <h5>Dates for Call Option</h5>
                                                    <h6>02 Oct 2022, 10.00am to 11.00am</h6>
                                                    <h6>02 Oct 2022, 10.00am to 11.00am</h6>
                                                    <h6 className="selected">02 Oct 2022, 10.00am to 11.00am</h6>
                                                    <div className="center-btn">
                                                        <button className="alert-button" onClick={(e: any) => {
                                                            e.preventDefault();
                                                            setAlertPopup(true);
                                                            handleClose();
                                                        }}>Set Alert<span><i className="fa fa-arrow-right" aria-hidden="true"></i></span></button>
                                                    </div>
                                                </div>
                                            </Typography>
                                        </Popover>
                                        <div className="col-lg-6">
                                            <p>Put Option</p>
                                            {bondsDetail != undefined && bondsDetail.bond_put_details != undefined && bondsDetail.bond_put_details.length > 0 ?
                                                <Fragment>
                                                    <h5>Yes<span className="call-option" id="call" onClick={handleClick} >{moment(bondsDetail?.bond_put_details[0].bond_put_detail_datetime).format("MMMM Do YYYY")} <img src="assets/img/call-option-info.svg" /></span></h5>
                                                </Fragment>
                                                :
                                                <h5>No</h5>
                                            }
                                        </div>
                                    </div>
                                    <div className="row row-sec pb-1">
                                        <div className="col-lg-6">
                                            <p>Govt. Guarantee</p>
                                            {bondsDetail?.bond_govt_guranatee == 1 &&
                                                <h5>YES</h5>
                                            }
                                            {bondsDetail?.bond_govt_guranatee == 0 &&
                                                <h5>No</h5>
                                            }
                                            {bondsDetail?.bond_govt_guranatee == null &&
                                                <h5>N/A</h5>
                                            }
                                        </div>
                                        <div className="col-lg-6">
                                            <p>Security</p>
                                            <h5>{bondsDetail?.bond_security_type != null && bondsDetail?.bond_security_type != undefined ? bondsDetail?.bond_security_type : "N/A"}</h5>
                                        </div>
                                    </div>
                                </div>
                                <div className="float-lg-end p-0">
                                    <button className="detail-one-buy-button" onClick={(e: any) => {
                                        e.preventDefault();
                                    }}><p style={{ cursor: "not-allowed" }} className="click-me mt-5">Request RFQ&nbsp;<img width="12px" height="12px" src="assets/img/icon/rightarrow.png"></img></p></button>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="ml-4" id="Highlights">
                    <div className="">
                        <div className="row detail-view-calculation">
                            {bondsDetail != undefined && bondsDetail.bond_key_highlights != '' &&
                                <div className="col-lg-6 pl-30">
                                    <h3>Key Highlights</h3>
                                </div>
                            }
                            <div className="col-lg-8 mt-2 ml-4">
                                <div>
                                    <ul className="ipo-bond-list">
                                        {bondsDetail != undefined && bondsDetail.bond_key_highlights &&
                                            bondsDetail.bond_key_highlights.split('\n').map((str: any) => {
                                                {
                                                    if (str != '') {
                                                        return (
                                                            <li className="mb-5">{str}</li>
                                                        )
                                                    }
                                                }
                                            })
                                        }
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                {bondsDetail != undefined && bondsDetail?.bond_ncd_series.length > 0 &&
                    <section className="ml-5 mr-5">
                        <div className="" id="Series">
                            <div className="row detail-view-calculation">
                                <div className="col-lg-12 section-left mt-5">
                                    <h3 className="ml-2">Upcoming Terms for Each Series of NCDs</h3>
                                    <table className="w-100 mt-5 bond-series-ncd mb-3 text-center">
                                        <thead>
                                            <tr>
                                                <th className="right-br-ipo-specific-terms br-top-12">Series</th>
                                                <th className="right-br-ipo-specific-terms">Frequency of Interest Payment</th>
                                                <th className="right-br-ipo-specific-terms">Tenor</th>
                                                <th className="right-br-ipo-specific-terms">Coupon (% per annum)</th>
                                                <th className="right-br-ipo-specific-terms">Effective Yield (% per annum)</th>
                                                <th>Redemption Amount (₹ / NCD) on Maturity</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {bondsDetail != undefined && bondsDetail?.bond_ncd_series.length > 0 && bondsDetail.bond_ncd_series.map((series: APIData.BondNcdSeries, index: number) => {
                                                return (
                                                    <tr>
                                                        <td className="right-br-ipo-specific-terms br-top-12">{index + 1}</td>
                                                        <td className="right-br-ipo-specific-terms br-top-12">{series.bond_ncd_frequency_of_interest_payment}</td>
                                                        <td className="right-br-ipo-specific-terms br-top-12">{series.bond_ncd_tenor}</td>
                                                        <td className="right-br-ipo-specific-terms br-top-12">{series.bond_ncd_coupon + "%"}</td>
                                                        <td className="right-br-ipo-specific-terms br-top-12">{series.bond_ncd_effective_yield}</td>
                                                        <td className="right-br-ipo-specific-terms br-top-12">{numberWithCommas(series.bond_ncd_redemption_amount)}</td>
                                                    </tr>
                                                )
                                            })}
                                        </tbody>
                                    </table>
                                    <div className="mb-2 ipo-specific-terms-footer">*Company shall allocate and allot Series IV NCDs wherein the Applicants have not indicated the choice of the relevant NCD Series.</div>
                                </div>
                            </div>
                        </div>
                    </section>
                }
                <section className="sovereign_gold_bonds_invest_calculation pb-md-20 pt-md-60 pt-xs-60 pb-xs-20">
                    <div className="container-fluid" id="InvestmentCalculator">
                        <div className="row detail-view-calculation">
                            <div className="col-lg-6 pl-30">
                                <h3 className="mt-5">Your Investment Calculator</h3>
                                <div className="center">
                                    <img className="detail-one-investment-cal-img" src="assets/img/calculator.png" alt="" />
                                </div>
                            </div>
                            <div className="col-lg-6 p-0 section">
                                <div className="row d-flex section-head">
                                    <div className="col-lg-3">
                                        <p className="title"><strong>Bond Value Details</strong></p>
                                    </div>
                                    <div className="col-lg-3 mt-2">
                                        <p className="sub-head">Face Value (A)</p>
                                        <h5 className="sub-value">₹ {numberWithCommas(bondsDetail?.bond_face_value)}</h5>
                                    </div>
                                    <div className="col-lg-3 mt-2">
                                        <p className="sub-head">Market Value (B)</p>
                                        <h5 className="sub-value">₹ {numberWithCommas(bondsDetail?.bond_face_value)}</h5>
                                    </div>
                                    <div className="col-lg-3 mt-2">
                                        <p className="sub-head">Yield (YTM)</p>
                                        <h5 className="sub-value">{bondsDetail?.bonds_yeild}%</h5>
                                    </div>
                                </div>
                                <div className="row section-form">
                                    <div className="row">
                                        <div className="col-lg-6 pt-10 pb-10">
                                            <div>
                                                <h6>Number of bonds</h6>
                                                <p> No. of Bonds you want to Purchase (C)</p>
                                            </div>
                                        </div>
                                        <div className="col-lg-6 pt-10 pb-10">
                                            <div className="row">
                                                <div className="col-lg-2 p-0 box-shadow">
                                                    <button onClick={(e: any) => {
                                                        e.preventDefault();
                                                        if (qty != 1) {
                                                            setQty(qty - 1);
                                                        }
                                                    }} className="detail-view-calculator-button box-shadow">-</button>
                                                </div>
                                                <div className="col-lg-8 pr-10">
                                                    <input value={qty} className="input-value1 box-shadow" />
                                                </div>
                                                <div className="col-lg-2 p-0 box-shadow">
                                                    <button onClick={(e: any) => {
                                                        e.preventDefault();
                                                        setQty(qty + 1);
                                                    }} className="detail-view-calculator-button box-shadow">+</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-lg-6 pt-10 pb-10">
                                            <div>
                                                <h6>Principal Amount (E)</h6>
                                                <p>Market value (B) x no. of bonds (C)</p>
                                            </div>
                                        </div>
                                        <div className="col-lg-6 pt-10 pb-10">
                                            <div className="row">
                                                <input disabled={true} value={' ₹ ' + numberWithCommas(investmentValues?.principal_amount + "")} className="input-valu2 box-shadow" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-lg-6 pt-10 pb-10">
                                            <div>
                                                <h6>Accrued Interest</h6>
                                                <p>(Interest earned on the bond this year) (D)</p>
                                            </div>
                                        </div>
                                        <div className="col-lg-6 pt-10 pb-10">
                                            <div className="row">
                                                <input disabled={true} value={' ₹ ' + numberWithCommas(investmentValues?.accrued_interest + "")} className="input-valu2 box-shadow" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-lg-6 pt-10 pb-10">
                                            <div>
                                                <h6>Total Investment</h6>
                                                <p>Principal Amount (E) + Accrued Interest (D)</p>
                                            </div>
                                        </div>
                                        <div className="col-lg-6 pt-10 pb-10">
                                            <div className="row">
                                                <input disabled={true} value={' ₹ ' + numberWithCommas(investmentValues?.total_investment + "")} className="input-valu2 box-shadow" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="mt-3 ml-3 text-center detail-one-buy-button">
                                            <button style={{ cursor: "not-allowed" }} className="bond-buy">Request RFQ!&nbsp;&nbsp;&nbsp;</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {bondsDetailCashflow != undefined && bondsDetailCashflow.length > 0 &&
                        <div className="container-fluid">
                            <div className="row">
                                <p className="detail-view-calculation-dec-below" onClick={() => { toggleEarnDropdown() }}>WHAT WILL I EARN
                                    {openEarnDropDown === true ?
                                        <i className="fa fa-angle-up" aria-hidden="true"></i>
                                        :
                                        <i className="fa fa-angle-down" aria-hidden="true"></i>
                                    }
                                </p>
                            </div>
                        </div>
                    }
                    {
                        openEarnDropDown === true && bondsDetailCashflow != undefined && bondsDetailCashflow.length > 0 &&
                        <section className="pb-md-20 pt-md-60 pt-xs-60 pb-xs-20">
                            <div className="container-fluid">
                                <div className="row detail-view-Earning pt-5">
                                    <div className="col-lg-6 m-20 section-left">
                                        <table className="table table-bordered">
                                            <thead>
                                                <tr>
                                                    <th scope="row" className="white-bg">
                                                        <h5>{moment().format("DD/MM/YYYY")}</h5>
                                                    </th>
                                                    <th colSpan={3} className="light-red-bg">
                                                        <p className="text-end cursor" style={{ color: "#ed4f66" }} onClick={(e: any) => {
                                                            e.preventDefault();
                                                            window.open("https://trust-api.trustmony.com/api/v1/investment_rate?isin=" + isin)
                                                        }}><u>View Raw Data</u></p>
                                                        <p className="text-start">Your investment as of today</p>
                                                        <h5>₹ {numberWithCommas(investmentToday)}</h5>
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {bondsDetailCashflow != undefined && bondsDetailCashflow.length > 0 && bondsDetailCashflow.map((cashflow: APIData.BondCashFlow) => {
                                                    principalAmount = (parseFloat(cashflow.bond_cashflow_amount)) + parseInt(investmentToday);
                                                    nextprincipleAmount += principalAmount;
                                                    InterstEarned += parseFloat(cashflow.bond_cashflow_amount);
                                                    totalreturnAmount += nextprincipleAmount;

                                                    return (
                                                        <tr>
                                                            <th scope="row" className="white-bg">
                                                                <h5>{(cashflow.bond_cashflow_date)}</h5>
                                                            </th>
                                                            <td className="light-blue-bg">
                                                                <p>Interest</p>
                                                                <h5>₹ {cashflow.bond_cashflow_amount}</h5>
                                                            </td>
                                                            <td colSpan={bondsDetail?.bond_interest_frequency != 'NONE' ? 1 : 2} className="light-red-bg">
                                                                <p>Returns</p>
                                                                <h5>₹{numberWithCommas(nextprincipleAmount + parseFloat(cashflow.bond_cashflow_amount))}</h5>
                                                            </td>
                                                            {bondsDetail?.bond_interest_frequency != 'NONE' &&
                                                                <td className="light-blue-bg align-middle">
                                                                    <i className="fa fa-chevron-down" aria-hidden="true" onClick={() => { toggleEarnSubTableDropdown() }}></i>
                                                                </td>
                                                            }
                                                        </tr>
                                                    )
                                                })}

                                                {/* {
                                                subTable &&
                                                <Fragment>
                                                    <tr className="sub-table-row">
                                                        <th scope="row">
                                                            <h5>12 / 05/ 2022</h5>
                                                        </th>
                                                        <td colSpan={1}>
                                                            <h5>₹ 21,20,202</h5>
                                                        </td>
                                                        <td colSpan={2}>
                                                            <h5>₹ 21,20,202</h5>
                                                        </td>
                                                    </tr>
                                                    <tr className="sub-table-row">
                                                        <th scope="row">
                                                            <h5>12 / 05/ 2022</h5>
                                                        </th>
                                                        <td colSpan={1}>
                                                            <h5>₹ 21,20,202</h5>
                                                        </td>
                                                        <td colSpan={2}>
                                                            <h5>₹ 21,20,202</h5>
                                                        </td>
                                                    </tr>
                                                    <tr className="sub-table-row">
                                                        <th scope="row">
                                                            <h5>12 / 05/ 2022</h5>
                                                        </th>
                                                        <td colSpan={1}>
                                                            <h5>₹ 21,20,202</h5>
                                                        </td>
                                                        <td colSpan={2}>
                                                            <h5>₹ 21,20,202</h5>
                                                        </td>
                                                    </tr>
                                                    <tr className="sub-table-row">
                                                        <th scope="row">
                                                            <h5>12 / 05/ 2022</h5>
                                                        </th>
                                                        <td colSpan={1}>
                                                            <h5>₹ 21,20,202</h5>
                                                        </td>
                                                        <td colSpan={2}>
                                                            <h5>₹ 21,20,202</h5>
                                                        </td>
                                                    </tr>
                                                    <tr className="sub-table-row">
                                                        <th scope="row">
                                                            <h5>12 / 05/ 2022</h5>
                                                        </th>
                                                        <td colSpan={1}>
                                                            <h5>₹ 21,20,202</h5>
                                                        </td>
                                                        <td colSpan={2}>
                                                            <h5>₹ 21,20,202</h5>
                                                        </td>
                                                    </tr>
                                                    <tr className="sub-table-row">
                                                        <th scope="row">
                                                            <h5>12 / 05/ 2022</h5>
                                                        </th>
                                                        <td colSpan={1}>
                                                            <h5>₹ 21,20,202</h5>
                                                        </td>
                                                        <td colSpan={2}>
                                                            <h5>₹ 21,20,202</h5>
                                                        </td>
                                                    </tr>
                                                    <tr className="sub-table-row">
                                                        <th scope="row">
                                                            <h5>12 / 05/ 2022</h5>
                                                        </th>
                                                        <td colSpan={1}>
                                                            <h5>₹ 21,20,202</h5>
                                                        </td>
                                                        <td colSpan={2}>
                                                            <h5>₹ 21,20,202</h5>
                                                        </td>
                                                    </tr>
                                                    <tr className="sub-table-row">
                                                        <th scope="row">
                                                            <h5>12 / 05/ 2022</h5>
                                                        </th>
                                                        <td colSpan={1}>
                                                            <h5>₹ 21,20,202</h5>
                                                        </td>
                                                        <td colSpan={2}>
                                                            <h5>₹ 21,20,202</h5>
                                                        </td>
                                                    </tr>
                                                </Fragment>
                                            } */}
                                            </tbody>
                                        </table>
                                    </div>
                                    <div className="col-lg-6 m-20 section-right">
                                        <img className="detail-view-Earning-flow-img" src="assets/img/earn_flow.svg" alt="" />
                                        <div className="row m-2 d-flex">
                                            <div className="col-lg-6 p-0">
                                                <div className="p-2 m-2 interest-earned">
                                                    <p>Interest Earned</p>
                                                    <h4>₹ {numberWithCommas(InterstEarned + "")}</h4>
                                                    {/* <p>( Yield at 7.97% p.a )</p> */}
                                                </div>
                                            </div>
                                            <div className="col-lg-6 p-0">
                                                <div className="p-2 m-2 total-return">
                                                    <p>Total Returns</p>
                                                    <h4>₹{numberWithCommas(totalreturnAmount)}</h4>
                                                </div>
                                            </div>
                                        </div>
                                        <p className="desc"><i>Disclaimer: The actual number can vary slightly based on actual Interest Payment date.</i></p>
                                    </div>
                                </div>
                            </div>
                        </section>
                    }
                </section>
                {bondsDetail?.bond_about_this_ipo != null &&
                    <div className="col-lg-12 section-left mt-5 ml-2 mb-3" id="AboutBond">
                        <h3>About this Bond</h3>
                        <p>{bondsDetail?.bond_about_this_ipo}</p>
                    </div>
                }
                {bondsDetail != undefined && bondsDetail.bond_issuer_details != undefined && bondsDetail.bond_issuer_details.length > 0 &&
                    <section className="pt-30 pb-30 pb-md-20 pt-md-60 pt-xs-60 pb-xs-20">
                        <div id="IssuerDetails">
                            <div className="row detail-view-financial">
                                <div className="d-flex">
                                    <h6 className="ipo-flow-issuer-title">Issuer</h6>
                                    <span className="triangle-right-ipo"></span>
                                </div>
                                <div className="col-lg-6 section-left">
                                    <h3>{bondsDetail?.bond_issuer_name}</h3>
                                    <p className="underline"></p>
                                    <h6>Issuer's Type:{bondsDetail?.bond_issuer_details[0].bond_type_of_issuer}</h6>
                                    <p>{bondsDetail?.bond_issuer_details[0].bond_issuer_about}</p>
                                </div>
                                <div className="col-lg-6">
                                    <p className="section-right-border-length"></p>
                                    <div className="section-right">
                                        <div className="row">
                                            <div className="col-lg-4 pt-10 pb-10">
                                                <div>
                                                    <p>Total Annual Revenue</p>
                                                    <h6>{bondsDetail?.bond_issuer_details[0].bond_issuer_total_annual_revenue != null && bondsDetail?.bond_issuer_details[0].bond_issuer_total_annual_revenue != '' ? "₹" + numberWithCommas(bondsDetail?.bond_issuer_details[0].bond_issuer_total_annual_revenue) : "N/A"}</h6>
                                                </div>
                                            </div>
                                            <div className="col-lg-4 pt-10 pb-10">
                                                <div>
                                                    <p>Year of Inception</p>
                                                    <h6>{bondsDetail?.bond_issuer_details[0].bond_issuer_year_of_inception != null ? bondsDetail?.bond_issuer_details[0].bond_issuer_year_of_inception : "N/A"}</h6>
                                                </div>
                                            </div>
                                            <div className="col-lg-4 pt-10 pb-10">
                                                <div>
                                                    <p>Industry</p>
                                                    <h6>{bondsDetail?.bond_issuer_details[0].bond_issuer_industry != null && bondsDetail?.bond_issuer_details[0].bond_issuer_industry != '' ? bondsDetail?.bond_issuer_details[0].bond_issuer_industry : "N/A"}</h6>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-lg-4 pt-10 pb-10">
                                                <div>
                                                    <p>Head Office</p>
                                                    <h6>{bondsDetail?.bond_issuer_details[0].bond_issuer_head_office != null && bondsDetail?.bond_issuer_details[0].bond_issuer_head_office != '' ? bondsDetail?.bond_issuer_details[0].bond_issuer_head_office : "N/A"}</h6>
                                                </div>
                                            </div>
                                            <div className="col-lg-4 pt-10 pb-10">
                                                <div>
                                                    <p>Type of Issuer</p>
                                                    {bondsDetail?.bond_issuer_details[0].bond_type_of_issuer == IssuerType.CENTRAL_GOVERNMENT &&
                                                        <h6>Central Government</h6>
                                                    }
                                                    {bondsDetail?.bond_issuer_details[0].bond_type_of_issuer == IssuerType.PRIVATE_BANK &&
                                                        <h6>Private Bank</h6>
                                                    }
                                                    {bondsDetail?.bond_issuer_details[0].bond_type_of_issuer == IssuerType.PRIVATE_SECTOR_CORPORATE &&
                                                        <h6>Private Sector Corporate</h6>
                                                    }
                                                    {bondsDetail?.bond_issuer_details[0].bond_type_of_issuer == IssuerType.PUBLIC_SECTOR_BANK_STATE_GOVERNMENT &&
                                                        <h6>Public Sector Bank State Government</h6>
                                                    }
                                                    {bondsDetail?.bond_issuer_details[0].bond_type_of_issuer == IssuerType.PUBLIC_SECTOR_UNDERTAKING &&
                                                        <h6>Public Sector Undertaking</h6>
                                                    }
                                                    {bondsDetail?.bond_issuer_details[0].bond_type_of_issuer == null &&
                                                        <h6>N/A</h6>
                                                    }
                                                </div>
                                            </div>
                                            {/* <div className="col-lg-4 pt-10 pb-10">
                                                <div>
                                                    <p>Current MD/CEO</p>
                                                    <h6>{bondsDetail?.bond_issuer_details[0].bond_type_of_issuer}</h6>
                                                </div>
                                            </div> */}
                                        </div>
                                        <div className="row">
                                            <div className="col-lg-6 pt-10 pb-10 cursor" onClick={(e: any) => {
                                                e.preventDefault();
                                                window.open(bondsDetail?.bond_issuer_details[0].bond_issuer_profile_link);
                                            }}>
                                                <a><u>Issuers Profile</u> <i className="fa fa-external-link" aria-hidden="true"></i></a>
                                            </div>
                                            <div className="col-lg-6 pt-10 pb-10" onClick={(e: any) => {
                                                e.preventDefault();
                                                window.open(bondsDetail?.bond_issuer_details[0].bond_issuer_finance_details)
                                            }}>
                                                <a><u>Issuers Finance Details</u> <i className="fa fa-external-link" aria-hidden="true"></i></a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                }
                {bondsDetail?.bond_issue_document != '' && bondsDetail?.bond_issue_document != null &&
                    <DownloadIssueDocuments document={bondsDetail?.bond_issue_document} />
                }
                <InvestSteps />
                <Subscribe />
                {/* <NeedHelp /> */}
                <Alert showModal={alertPopup} closeModal={() => { setAlertPopup(false) }} />
            </main>
            <Footer />
        </div >
    )
}

export default DetailViewOne;
