import moment from "moment";
import React, { Fragment, useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useAppStateAPI } from "../common/AppStateAPI";
import { bondTypes, OrderStatus, PageLinks, PaymentStatus, SGBPaymentStatus } from "../common/Constants";
import { APIData } from "../common/DataTypes";
import { ServerAPI } from "../common/ServerAPI";
import { numberWithCommas } from "../common/Utilities";
import { InitialData } from "../components/Form/InitialData";
import OrderStepsComponent from "../components/OrderStepsComponent";
import Footer from "../components/template/Footer";
import Header from "../components/template/Header";

const BondConfirmedOrder = () => {

    const [bondsDetail, setBondDetail] = useState<APIData.SGBDetail>();
    const [bondsOrderDetail, setBondOrderDetail] = useState<APIData.SGBOrderDetail>();
    const [bondsOrderDetailIPO, setBondOrderDetailIPO] = useState<APIData.IPOOrderDetail>();
    const navigate = useNavigate();
    const [isin, setIsin] = useState('');
    const [orderID, setOrderID] = useState(0);
    const { setShowPreloader, SGBBond } = useAppStateAPI();
    const location = useLocation();
    const [bondType, setBondType] = useState('');

    useEffect(() => {
        if (location.search) {
            const query = new URLSearchParams(location.search);
            if (query.has("id")) {
                const id = query.get("id");
                setOrderID(parseInt(id!));
            }
            if (query.has("bond")) {
                const bondtype = query.get("bond");
                setBondType((bondtype!));
            }
        }
    }, [location]);

    useEffect(() => {
        if (orderID != 0) {
            if (parseInt(bondType) == bondTypes.SGB) {
                SGBorderDetail();
            } else if (parseInt(bondType) == bondTypes.IPO) {
                IPOorderDetail();
            }
        }
    }, [orderID])

    const SGBorderDetail = () => {
        setShowPreloader(true);
        ServerAPI.GetOrderSGBBond(orderID).then((response: any) => {
            if (response != undefined) {
                setBondOrderDetail(response);
                setShowPreloader(true);
                ServerAPI.SGBBondDetail(response['bond_isin_number']).then((response: any) => {
                    if (response != undefined) {
                        setBondDetail(response);
                    }
                }).finally(() => {
                    setShowPreloader(false);
                })
            }
        }).finally(() => {
            setShowPreloader(false);
        })
    }

    const IPOorderDetail = () => {
        setShowPreloader(true);
        ServerAPI.GetOrderIPOBond(orderID).then((response: any) => {
            if (response != undefined) {
                setBondOrderDetailIPO(response);
                setShowPreloader(true);
                ServerAPI.GetIPODetail(response?.bond_id!).then((response: any) => {
                    if (response != undefined) {
                        setBondDetail(response);
                    }
                }).finally(() => {
                    setShowPreloader(false);
                })
            }
        }).finally(() => {
            setShowPreloader(false);
        })
    }


    useEffect(() => {
        if (isin != '' && parseInt(bondType) == bondTypes.SGB) {
            setShowPreloader(true);

        }
    }, [isin])

    const goToModify = (series: any) => {
        if (bondsOrderDetailIPO != undefined) {
            let items: any[] = [];
            let params: any = {};
            params.bond_ncd_series = series;
            items.push(params);
            navigate(PageLinks.BOND_IPO_PURCHASE + "?id=" + bondsOrderDetailIPO.bond_id, { state: { data: items } });
        }
    }

    return (
        <div className="main-index-wrapper">
            <Header />
            <div className="body-overlay"></div>
            <main>
                <section className="sovereign_gold_bonds pt-xl-70 pt-lg-70  pt-md-45 pb-md-40 pt-xs-45 pb-xs-40">
                    <div className="container-fluid ">
                        <div className="row padding-20">
                            <div className="col-lg-8 col-md-8 col-sm-12  py-3">
                                <div className="mt-4 pt-3">
                                    <p className="quicksand-midium fs-28">
                                        <b>Confirmation</b>
                                    </p>
                                    <p className="text-light-grey quicksand-midium fs-20 fw-500 p-0">We've got your request.</p>
                                </div>
                            </div>

                            <div className="">
                                <div className="confirm-image-style text-center my-3 justify-content-center">
                                    <img src="/assets/img/bond_confirmed.png" alt="bond_confirmed" className="confirm-img" />
                                    {(parseInt(bondType) == bondTypes.SGB) &&
                                        <p className="text-red pt-2 quicksand-midium fs-28"> <b>Order Placed Successfully!</b></p>
                                    }
                                    {(parseInt(bondType) == bondTypes.IPO) &&
                                        <p className="text-red pt-2 quicksand-midium fs-28"> <b>Bid Received Successfully!</b></p>
                                    }
                                </div>
                                <div className="text-center container col-lg-10 col-md-12 card-box-2">
                                    <div className="row py-3">
                                        <div className="col-lg-2 col-md-4 col-sm-6">
                                            <img src={bondsDetail?.bond_logo} alt="" className="img-small" />
                                        </div>
                                        <div className="col-lg-10 col-md-10 col-sm-6 mt-4">
                                            <p className="text-start quicksand-bold sgborder-bond-name">{bondsDetail?.bond_name} </p>
                                        </div>
                                        <div className="col-lg-12 row">
                                            <div className="col-lg-2"></div>
                                            <div className="col-lg-10 row">
                                                <div className="col-lg-3">
                                                    <p className="text-small text-start sourceSansPro-regular text-light-grey">Order No</p>
                                                    {(parseInt(bondType) == bondTypes.SGB) &&
                                                        <p className=" text-start quicksand-bold">{bondsOrderDetail?.order_id}</p>
                                                    }
                                                    {(parseInt(bondType) == bondTypes.IPO) &&
                                                        <p className=" text-start quicksand-bold">{bondsOrderDetailIPO?.order_id}</p>
                                                    }
                                                </div>
                                                <div className="col-lg-3">
                                                    <p className="text-small text-start sourceSansPro-regular text-light-grey">Order Date</p>
                                                    {(parseInt(bondType) == bondTypes.SGB) &&
                                                        <p className="text-start quicksand-bold">{moment(bondsOrderDetail?.sgb_order_datetime).format("MMMM Do YYYY")}</p>
                                                    }
                                                    {(parseInt(bondType) == bondTypes.IPO) &&
                                                        <p className="text-start quicksand-bold">{moment(bondsOrderDetailIPO?.ipo_order_datetime).format("MMMM Do YYYY")}</p>
                                                    }
                                                </div>
                                                <div className="col-lg-4">
                                                    <p className="text-small text-start sourceSansPro-regular text-light-grey">Payment Status</p>
                                                    {(parseInt(bondType) == bondTypes.IPO) &&
                                                        <div className="col-lg-4">
                                                            {bondsOrderDetailIPO?.ipo_order_current_status == PaymentStatus.PENDING &&
                                                                <p className="text-start quicksand-bold">Processing</p>
                                                            }
                                                            {bondsOrderDetailIPO?.ipo_order_current_status == PaymentStatus.SUCCESS &&
                                                                <p className="text-start quicksand-bold" style={{ color: "#02AD41" }}>Successful</p>
                                                            }
                                                            {bondsOrderDetailIPO?.ipo_order_current_status == PaymentStatus.FAILURE &&
                                                                <p className="text-start quicksand-bold">Failure</p>
                                                            }
                                                        </div>
                                                    }
                                                    {(parseInt(bondType) == bondTypes.SGB) &&
                                                        <div className="col-lg-4">
                                                            {bondsOrderDetail?.sgb_order_payments == SGBPaymentStatus.SUCCESS &&
                                                                <p className="text-start quicksand-bold" style={{ color: "#02AD41" }}>Successful</p>
                                                            }
                                                            {bondsOrderDetail?.sgb_order_payments == SGBPaymentStatus.FAILURE &&
                                                                <p className="text-start quicksand-bold" style={{ color: "#FF0023" }}>Failure</p>
                                                            }
                                                        </div>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {(parseInt(bondType) == bondTypes.SGB) &&
                                        <Fragment>
                                            <div className="col-lg-12 row text-start">
                                                <div className="col-lg-2"></div>
                                                <div className="background-light-grey d-flex justify-content-between border-radius mt-2 p-3 col-lg-10 row">
                                                    <div className="col-lg-2">
                                                        <span className="text-small sourceSansPro-regular text-light-grey">Price of 1 gm</span>
                                                        <p className="quicksand-bold">{"₹" + numberWithCommas(bondsOrderDetail?.sgb_order_amount)}</p>
                                                    </div>
                                                    <div className="col-lg-2">
                                                        <span className="text-small sourceSansPro-regular text-light-grey">Yield %</span>
                                                        {(parseInt(bondType) == bondTypes.SGB) &&
                                                            <p className="quicksand-bold">{bondsDetail?.bonds_yeild}</p>
                                                        }
                                                    </div>
                                                    <div className="col-lg-3">
                                                        <span className="text-small sourceSansPro-regular text-light-grey">Gold Quantity in Grams</span>
                                                        {(parseInt(bondType) == bondTypes.SGB) &&
                                                            <p className="quicksand-bold">{bondsOrderDetail?.sgb_order_quantity}</p>
                                                        }
                                                    </div>
                                                    <div className="col-lg-2">
                                                        <span className="text-small sourceSansPro-regular text-light-grey">Amount Invested</span>
                                                        <p className="quicksand-bold">{"₹" + numberWithCommas(bondsOrderDetail?.sgb_order_total_amount)}</p>
                                                    </div>
                                                    <div className="col-lg-1"></div>
                                                </div>
                                            </div>
                                            <div className="col-lg-12 row">
                                                <div className="col-lg-1"></div>
                                                <div className="col-lg-9">
                                                    <OrderStepsComponent step={2.5} />
                                                </div>
                                                <div className="col-lg-2 mt-4 text-start">
                                                    <span className="text-small sourceSansPro-regular text-light-grey">Order Status</span>
                                                    <p className="fs-16 fw-500 lh-sm text-wrap text-orange sourceSansPro-regular">{bondsOrderDetail?.sgb_order_status == OrderStatus.SUCCESS ? "Success" : "Under Processing"}</p>
                                                </div>
                                            </div>
                                            <div className="col-lg-12 row mt-3">
                                                <div className="text-center">
                                                    <button className="order-confirm-page-cancel-btn w-15"><span className="order-cancel-btn-symbol"><b>X</b></span>&nbsp;&nbsp;Cancel</button>
                                                </div>
                                            </div>
                                        </Fragment>
                                    }
                                    {(parseInt(bondType) == bondTypes.IPO) && bondsOrderDetailIPO != undefined &&
                                        <Fragment>
                                            <div className="col-lg-12 row text-start">
                                                <div className="col-lg-2"></div>
                                                <div className="background-light-grey d-flex justify-content-between border-radius mt-2 p-3 col-lg-10 row">
                                                    <div className="col-lg-2">
                                                        <span className="text-start text-small sourceSansPro-regular text-light-grey">Series</span>
                                                        {bondsOrderDetailIPO.ipo_ncd_series != undefined && bondsOrderDetailIPO.ipo_ncd_series.length > 0 && bondsOrderDetailIPO.ipo_ncd_series.map((ncd_series: APIData.IPOorderNCDSeries) => {
                                                            return (
                                                                <p className="quicksand-bold">{ncd_series.ipo_order_ncd_series_number}</p>
                                                            )
                                                        })}
                                                    </div>
                                                    <div className="col-lg-2">
                                                        <span className="text-small sourceSansPro-regular text-light-grey">Price</span>
                                                        {bondsOrderDetailIPO.ipo_ncd_series != undefined && bondsOrderDetailIPO.ipo_ncd_series.length > 0 && bondsOrderDetailIPO.ipo_ncd_series.map((ncd_series: APIData.IPOorderNCDSeries) => {
                                                            return (
                                                                <p className="quicksand-bold">{"₹" + numberWithCommas(ncd_series.ipo_order_ncd_series_number)}</p>
                                                            )
                                                        })}
                                                    </div>
                                                    <div className="col-lg-2">
                                                        <span className="text-small sourceSansPro-regular text-light-grey">Yield%</span>
                                                        {bondsOrderDetailIPO.ipo_ncd_series != undefined && bondsOrderDetailIPO.ipo_ncd_series.length > 0 && bondsOrderDetailIPO.ipo_ncd_series.map((ncd_series: APIData.IPOorderNCDSeries) => {
                                                            return (
                                                                <p className="quicksand-bold">{"₹" + numberWithCommas(ncd_series.ipo_order_ncd_series_number)}</p>
                                                            )
                                                        })}
                                                    </div>
                                                    <div className="col-lg-2">
                                                        <span className="text-small sourceSansPro-regular text-light-grey">No. of Quantity</span>
                                                        {bondsOrderDetailIPO.ipo_ncd_series != undefined && bondsOrderDetailIPO.ipo_ncd_series.length > 0 && bondsOrderDetailIPO.ipo_ncd_series.map((ncd_series: APIData.IPOorderNCDSeries) => {
                                                            return (
                                                                <p className="quicksand-bold">{"₹" + numberWithCommas(ncd_series.ipo_order_ncd_series_quantity)}</p>
                                                            )
                                                        })}
                                                    </div>
                                                    <div className="col-lg-2">
                                                        <span className="text-small sourceSansPro-regular text-light-grey">Total Amount</span>
                                                        {bondsOrderDetailIPO.ipo_ncd_series != undefined && bondsOrderDetailIPO.ipo_ncd_series.length > 0 && bondsOrderDetailIPO.ipo_ncd_series.map((ncd_series: APIData.IPOorderNCDSeries) => {
                                                            return (
                                                                <p className="quicksand-bold">{"₹" + numberWithCommas(ncd_series.ipo_order_ncd_series_amount)}</p>
                                                            )
                                                        })}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-12 row">
                                                <div className="col-lg-1"></div>
                                                <div className="col-lg-9">
                                                    <OrderStepsComponent step={2.5} />
                                                </div>
                                                <div className="col-lg-2 mt-4 text-start">
                                                    <span className="text-small sourceSansPro-regular text-light-grey">Order Status</span>
                                                    <p className="fs-16 fw-500 lh-sm text-wrap text-orange sourceSansPro-regular">{bondsOrderDetailIPO?.ipo_order_current_status == OrderStatus.SUCCESS ? "Success" : "Under Processing"}</p>
                                                </div>
                                            </div>
                                            <div className="col-lg-12 row mt-3">
                                                <div className="col-lg-2"></div>
                                                <div className="col-lg-3">
                                                    <button onClick={(e: any) => {
                                                        e.preventDefault();
                                                        window.open(bondsOrderDetailIPO.ipo_order_pdf);
                                                    }} className="order-confirm-page-download-btn"><span><b><i className="fa fa-download" aria-hidden="true"></i></b></span>&nbsp;&nbsp;Download Application</button>
                                                </div>
                                                <div className="col-lg-1">
                                                </div>
                                                <div className="col-lg-5 row">
                                                    <div className="col-lg-6">
                                                        <button className="order-confirm-page-modify-btn" onClick={(e: any) => {
                                                            e.preventDefault();
                                                            goToModify(bondsOrderDetailIPO.ipo_ncd_series);
                                                        }}><span><b><i className="fa fa-edit"></i></b></span>&nbsp;&nbsp;Modify Order</button>

                                                    </div>
                                                    <div className="col-lg-6">
                                                        <button className="order-confirm-page-cancel-btn"><span className="order-cancel-btn-symbol"><b>X</b></span>&nbsp;&nbsp;Cancel</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </Fragment>
                                    }

                                    <br />
                                </div>
                                <br />

                                <div className="text-start container col-lg-10 col-md-10 col-sm-12 background-light border-radius-4">
                                    <div className="row py-3 px-3">
                                        <p className="sourceSansPro-semibold m-0 p-0 fs-20 "> <b>Please note</b></p> <br />
                                        <div>


                                            <ul className="ul-style">
                                                <li className="">
                                                    <p className="sourceSansPro-regular text-light-grey">Your bid will be forwarded to the Stock Exchange. RBI and Stock Exchange shall approve your bid.</p></li>
                                                <li >
                                                    <p className="sourceSansPro-regular text-light-grey">On approval, the funds are transferred to Stock Exchange.</p></li>

                                                <li >
                                                    <p className="sourceSansPro-regular text-light-grey">Allotted Bonds shall be credited by RBI In your Demat account within 5 days from the Allotment Date.</p></li>

                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <br /> <br />
                                <div className="col-lg-12 row">
                                    <div className="col-lg-3 text-center"></div>
                                    <div className="col-lg-3 text-end">
                                        <Link to={PageLinks.PROFILE_LISTING} className="apply-gold-btn  text-white">Explore Other Bonds</Link>
                                    </div>
                                    <div className="col-lg-3 text-start">
                                        <Link to={PageLinks.PROFILE} className="order-confirm-page-home-btn">Go To Home</Link>
                                    </div>
                                    <div className="col-lg-3 text-center"></div>
                                </div>

                            </div>
                        </div>
                    </div>
                </section>
                <Footer />
            </main>

        </div>
    );
}

export default BondConfirmedOrder;
