import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useAppStateAPI } from "../common/AppStateAPI";
import { bondPurchaseTypes, PageLinks } from "../common/Constants";
import { APIData } from "../common/DataTypes";
import { ServerAPI } from "../common/ServerAPI";
import { numberWithCommas } from "../common/Utilities";
import Copy from "../components/Svg/Copy";
import Footer from "../components/template/Footer";
import Header from "../components/template/Header";

const ApplyBondNonGold = () => {
    const location = useLocation();
    const [isin, setIsin] = useState('');
    const [qty, setQty] = useState(1);
    const [bondsDetail, setBondDetail] = useState<APIData.BondlistData>();
    const [investmentValues, setInvestmentValues] = useState<APIData.InvestmentCalculator>();
    const [bondsDetailCashflow, setBondDetailCashflow] = useState<APIData.BondCashFlow[]>([]);
    const { setShowPreloader } = useAppStateAPI();
    const navigate = useNavigate();

    useEffect(() => {
        if (location.search) {
            const query = new URLSearchParams(location.search);
            if (query.has("isin")) {
                const id = query.get("isin");
                setIsin((id!));
            }
        }
    }, [location]);

    const goToBuy = (isin: string) =>
    navigate({
        pathname: PageLinks.PAYMENTBOND,
        search: "?isin=" + isin+"&bond="+bondPurchaseTypes.BONDS,
    });

    useEffect(() => {
        if (isin != '') {
            setShowPreloader(true);
            ServerAPI.BondDetail(isin).then((response: any) => {
                if (response['message']['bond_details'] != undefined) {
                    setBondDetail(response['message']['bond_details']);
                }
                if (response['message']['bond_cashflow_details'] != undefined) {
                    setBondDetailCashflow(response['message']['bond_cashflow_details']);
                }
            }).finally(() => {
                setShowPreloader(false);
            })
        }
    }, [isin])

    useEffect(() => {
        setShowPreloader(true);
        ServerAPI.BondInvestmentCalculator(isin, qty).then((response: any) => {
            if (response != undefined) {
                setInvestmentValues(response);
            }
        }).finally(() => {
            setShowPreloader(false);
        })
    }, [isin, qty])
    return (
        <div className="main-index-wrapper">
            <Header />
            <div className="body-overlay"></div>
            <main>
                <section className="sovereign_gold_bonds pt-100 pt-md-45 pb-md-40 pt-xs-45 pb-xs-40">
                    <div className="container-fluid ">
                        <div className="row pt-3">
                            <div className="col-lg-12">
                                <div className="row align-items-left">
                                    <div className="col-lg-6 breadcums pt-4 ">
                                        <nav>
                                            <ul>
                                                <li>Home</li>
                                                <li>Bonds</li>
                                                <li>Explore Bonds</li>
                                                <li>{bondsDetail?.bond_issuer_name}</li>
                                                <li className="active">Buy</li>
                                            </ul>
                                        </nav>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row py-3 mx-4 px-4 detail-view-calculation">
                            <div className="col-lg-6 col-md-6 col-sm-6 card-box">
                                <div className="row  pb-3">
                                    <div className="col-lg-2 col-md-3 col-sm-6">
                                        <img src="assets/img/img_placeholder.png" alt="sovereign" className="bond-title-img" />
                                    </div>
                                    <div className="col-lg-10 col-md-9 col-sm-6 ">
                                        <p>{bondsDetail?.bond_issuer_name}</p>

                                        <div className="d-flex my-2">
                                            <a className="background-green title-background-style">
                                                <p className="fs-15">RETURNS Based On Market</p>
                                            </a>
                                            <a className="background-grey title-background-style mx-3">
                                                <p className="fs-15"> <span>ISIN:&nbsp;</span>{bondsDetail?.bond_isin_number}&nbsp;<Copy /> </p>
                                            </a>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-lg-12 col-md-6 " data-wow-delay="0.1s">
                                    <div className="d-flex">
                                        <a className="background-lightblack text-white sourceSansPro-regular px-2 fs-12" >
                                            Eligible Investors*
                                        </a> <span className="triangle-right"></span>

                                        <a className="background-grey px-2 mx-2 fs-12">
                                            Individual
                                        </a>
                                        <a className="background-grey px-2 mx-2 fs-12">
                                            HUF
                                        </a>
                                        <a className="background-grey px-2 mx-2 fs-12">
                                            Corporate
                                        </a>
                                        <a className="background-grey px-2 mx-2 fs-12">
                                            Trust
                                        </a>
                                        <a className="background-grey px-2 mx-2 fs-12">
                                            Minor
                                        </a>
                                    </div>

                                    <div className=" mt-3 mb-2">
                                        <p className="title"><b> Key Points To Consider</b></p>
                                        <span className="text-light sourceSansPro-regular">Face Value (In INR) and Issue Size (In INR Crores)</span>
                                    </div>
                                </div>

                                <div>
                                    <table className="w-100 bond-table">
                                        <tr>
                                            <td className="w-50">
                                                <p className="table-text-light sourceSansPro-semibold">Coupon Rate (Returns)</p>
                                                <p className="sourceSansPro-bold">{bondsDetail?.bond_coupon_rate}%</p>
                                            </td>
                                            <td>
                                                <p className="table-text-light sourceSansPro-semibold">Yeild</p>
                                                <p className="sourceSansPro-bold">{investmentValues?.yield_value}%</p>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <p className="table-text-light sourceSansPro-semibold">Allotment Date</p>
                                                <p className="sourceSansPro-bold">Mar 08, 2022</p>
                                            </td>
                                            <td>
                                                <p className="table-text-light sourceSansPro-semibold">Issue Opening - Closing Dates</p>
                                                <p className="sourceSansPro-bold">Feb 28-Mar 04, 2022</p>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <p className="table-text-light sourceSansPro-semibold">Next Interest Payment Date</p>
                                                <p className="sourceSansPro-bold">Sep 07, 2022</p>
                                            </td>
                                            <td>
                                                <p className="table-text-light sourceSansPro-semibold mt-2">Interest Payment Frequency</p>
                                                <div >
                                                    <p className="sourceSansPro-bold mb-0"> Semi-Annually
                                                        <span className="table-text-light sourceSansPro-semibold d-block margin-top--10" > Every 6 Months</span></p>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <p className="table-text-light sourceSansPro-semibold mt-2">Maturity after</p>
                                                <div >
                                                    <p className="sourceSansPro-bold mb-0"> Mar 07, 2027
                                                        <span className="table-text-light sourceSansPro-semibold d-block margin-top--10" > (1st Exit Date)</span></p>
                                                </div>
                                            </td>
                                            <td>
                                                <p className="table-text-light sourceSansPro-semibold mt-2">Purchase Limit</p>
                                                <div >
                                                    <p className="sourceSansPro-bold mb-0"> 4 Kg/4000 units
                                                        <span className="table-text-light sourceSansPro-semibold d-block margin-top--10" >  During each financial year</span></p>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <p className="table-text-light sourceSansPro-semibold">Issuer</p>
                                                <p className="sourceSansPro-bold">{bondsDetail?.bond_issuer_name}</p>
                                            </td>
                                            <td>
                                                <p className="table-text-light sourceSansPro-semibold mt-2">RBI Notice</p>
                                                <a href="#" className="sourceSansPro-bold mb-0"> https://www.rbi.org.in</a>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <p className="table-text-light sourceSansPro-semibold">Next Interest Payment Date</p>
                                                <p className="sourceSansPro-bold">Sep 07, 2022</p>
                                            </td>
                                            <td>
                                                <p className="table-text-light sourceSansPro-semibold mt-2">Exit Option Available</p>
                                                <div >
                                                    <p className="sourceSansPro-bold mb-0">Yes
                                                        <span className="table-text-light sourceSansPro-semibold d-block margin-top--10" >After the 5th year. Investors can exercise it on the next interest payment dates.</span></p>
                                                </div>
                                            </td>
                                        </tr>
                                    </table>
                                </div>
                            </div>
                            <div className="col-lg-6 col-md-6 col-sm-12 section">
                                <div className="row d-flex section-head">
                                    <div className="col-lg-3">
                                        <p className="title mt-2"><strong>Bond Value Details</strong></p>
                                    </div>
                                    <div className="col-lg-3 mt-3">
                                        <p className="sub-head">Face Value (A)</p>
                                        <h5 className="sub-value">₹ {numberWithCommas(investmentValues?.face_value)}</h5>
                                    </div>
                                    <div className="col-lg-3 mt-3">
                                        <p className="sub-head">Market Value (B)</p>
                                        <h5 className="sub-value">₹ {numberWithCommas(investmentValues?.market_value + "")}</h5>
                                    </div>
                                    <div className="col-lg-3 mt-3">
                                        <p className="sub-head">Yield (YTM)</p>
                                        <h5 className="sub-value">{investmentValues?.yield_value}%</h5>
                                    </div>
                                </div>
                                <div className="row section-form">
                                    <div className="row">
                                        <div className="col-lg-6 pt-10 pb-30">
                                            <div>
                                                <h6>Number of bonds</h6>
                                                <p> No. of Bonds you want to Purchase (C)</p>
                                            </div>
                                        </div>
                                        <div className="col-lg-6 pt-10 pb-30">
                                            <div className="row">
                                                <div className="col-lg-2 p-0">
                                                    <button onClick={(e: any) => {
                                                        e.preventDefault();
                                                        if (qty != 1) {
                                                            setQty(qty - 1);
                                                        }
                                                    }} className="detail-view-calculator-button">-</button>
                                                </div>
                                                <div className="col-lg-8 pr-10">
                                                    <input value={qty} className="input-value1" />
                                                </div>
                                                <div className="col-lg-2 p-0">
                                                    <button onClick={(e: any) => {
                                                        e.preventDefault();
                                                        setQty(qty + 1);
                                                    }} className="detail-view-calculator-button">+</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-lg-6 pt-10 pb-30">
                                            <div>
                                                <h6>Principal Amount (E)</h6>
                                                <p>Market value (B) x no. of bonds (C)</p>
                                            </div>
                                        </div>
                                        <div className="col-lg-6 pt-10 pb-30">
                                            <div className="row">
                                                <input disabled={true} value={' ₹ ' + numberWithCommas(investmentValues?.principal_amount + "")} className="input-valu2" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-lg-6 pt-10 pb-30">
                                            <div>
                                                <h6>Accrued Interest</h6>
                                                <p>(Interest earned on the bond this year) (D)</p>
                                            </div>
                                        </div>
                                        <div className="col-lg-6 pt-10 pb-30">
                                            <div className="row">
                                                <input disabled={true} value={' ₹ ' + numberWithCommas(investmentValues?.accrued_interest + "")} className="input-valu2" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-lg-6 pt-10 pb-30">
                                            <div>
                                                <h6>Total Investment</h6>
                                                <p>Principal Amount (E) + Accrued Interest (D)</p>
                                            </div>
                                        </div>
                                        <div className="col-lg-6 pt-10 pb-30">
                                            <div className="row">
                                                <input disabled={true} value={' ₹ ' + numberWithCommas(investmentValues?.total_investment + "")} className="input-valu2" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <br />
                                <br />
                                <p className="review-text text-left">
                                    <input type="checkbox" className="signup" name="agreed" checked />&nbsp;&nbsp; I have read and agreed to <span className="terms-signup-highlight"><u>terms & conditions</u> </span>
                                </p>

                                <br />
                                <br />
                                <br />
                                <div className="text-center">
                                    <button className="apply-gold-btn  text-white" onClick={(e:any)=>{
                                        e.preventDefault();
                                        goToBuy(bondsDetail?.bond_isin_number!);
                                    }}>Continue to Buy</button>

                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </main>
            <Footer />
        </div>
    );
}

export default ApplyBondNonGold;
